import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { HelpGuidesComponent } from '../help-guides/help-guides.component';
import { FeedbackDialogComponent } from '../common-utility/feedback-dialog.component';
import { ContactUsComponent } from '../common-utility/contact-us.component';

@Component({
  selector: 'app-help-dashboard-client',
  templateUrl: './help-dashboard-client.component.html',
  styleUrls: ['./help-dashboard-client.component.css']
})

export class HelpDashboardClientComponent implements OnInit {
  // userType: string; 
  help_dashboard_client_form: UntypedFormGroup;
  loading = false;
  // v: number = this._authService.getCurrentTimeNumber();
  // upPII: boolean = false;
  username: string;
  isDialog: boolean = false;
  type: string;


  constructor(
    @Optional() private readonly _dialogRef: MatDialogRef<HelpDashboardClientComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly dialog: MatDialog, private readonly _authService: AuthenticateService,
  ) {
    this.type = data.type;
    if (this.data) {
      this.isDialog = true;
    }

  }

  ngOnInit() {

  }

  initControls() {
    this.help_dashboard_client_form = this._formBuilder.group({
      // userComments: [this.userComments]
    });


  }

  get f() { return this.help_dashboard_client_form.controls; }




  clickClose(val) {
    this._dialogRef.close(val);
  }


  openHelpGuides(type: string) {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    //config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.minWidth = "60em"
    config.height = "42em";
    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "20px", top: "138px" };
    config.data = { v: this._authService.getCurrentTimeNumber(), type: type };
    this.dialog.open(HelpGuidesComponent, config);
    this._dialogRef.close(true);

  }




  openFeedbackDialogComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "34.5em";//"1000px";
    config.width = "40em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "20px", top: "138px" };
    config.data = { isTaskFeedback: false, v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(FeedbackDialogComponent, config);
    this._dialogRef.close(true);
  }

  openContactUs() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.position = { left: "20px", top: "138px" };
    dialogConfig.data = {
      isInternal: true
    };
    this.dialog.open(ContactUsComponent, dialogConfig);
    this._dialogRef.close(true);

  }


}
