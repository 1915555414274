<div [ngClass]="isDialog? 'greyBack':''" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div *ngIf="upSubmitTrip" style="background-color:#f8f8f8;min-height:28em">
    <!--<div style="display: flex;padding-left:1em;" cdkDrag
         cdkDragRootElement=".cdk-overlay-pane"
         cdkDragHandle>
      <div style="margin-top:0.25em">
        <i class="material-icons-outlined" style="font-size: xx-large;cursor: pointer; color: gray">airline_stops</i>
      </div>
      <div style="margin-top:0.3125em;"><h5>Create New Trip {{selectTripCodeType=='e'? '- Estimate':''}}</h5></div>
    </div> -->
    <div style="display: flex; justify-content: space-between; align-items: center; padding: 0.5em 1em;">
      <!-- Left Side: Title -->
      <div style="display: flex; align-items: center; gap: 0.5em;">
        <span class="material-icons-outlined" style="font-size: 1.5em;">airline_stops</span>
        <h4  >Submit a Trip {{selectTripCodeType=='e'? '- Estimate':''}}</h4>
      </div>

      <!-- Right Side: Icons -->
      <div style="display: flex; gap: 0.5em;">
        <button mat-icon-button *ngIf="isDialog" (click)="openSchedulesClientComponent()">
          <i class="material-icons-outlined" style="font-size: xx-large;">arrow_circle_left</i>
        </button>      
        <button mat-icon-button *ngIf="isDialog" mat-dialog-close>
          <i class="material-icons" style="font-size: x-large;">close</i>
        </button>
      </div>
    </div>

    <div>
      <form *ngIf="ground_stop_creator_client_form" [formGroup]="ground_stop_creator_client_form">
        <div style="display:flex; margin-top:-0.5em; padding-left: 1em; padding-right:1em">
          <div style="margin-top: 0em; min-height: 20em; width:30em;padding-top:0.5em">
            <div style="background-color: white;min-height:7em; font-size: small; padding-left:0.5em; padding-right:0.5em; margin-top: 0em;padding-top:0.5em">
              <div *ngIf="grantAccessToAccountGrouping && customerList.length>0" style="margin-top:0.5em; margin-right:0.625em;margin-left:0.5em ">
                <div style="display:flex;margin-left:2.75em">
                  <label for="customer_select" style="font-size:small">Client:&nbsp;</label>
                  <div>
                    <div style="margin-top:0em;">
                      <select *ngIf="f" formControlName="customer_select" style="font-size: small;border-radius:0.25em;width: 20em; background-color:#eaf0fd" [ngClass]="{ 'is-invalid': (submitted || validatedAirport) && f.customer_select.errors, 'is-valid': !f.customer_select.errors}" (change)="customerChange($event)">
                        <!--<option value="" selected>Select a Client</option>-->
                        <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
                      </select>
                    </div>
                    <div *ngIf="(submitted || validatedAirport) && f.customer_select.errors" class="invalid-feedback" style="margin-top:-2em">
                      <div *ngIf="f.customer_select.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:16em">customer is a required field.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex">
                <div style="margin-right:1em; width:18em">
                  <div style="height:1.8em" *ngIf="customerGUID!='' || (!grantAccessToAccountGrouping && customerGUID=='') || customerList.length==0">
                    <div style="margin-top:0em; margin-right:0.625em; margin-left:0.5em">
                      <div style="display:flex; margin-top: 0em">
                        <label for="aircraft_select">Registration:&nbsp;</label>
                        <div>
                          <div style="margin-top:0em;">
                            <select *ngIf="f" formControlName="aircraft_select" placeholder="Select a Registration" style="border-radius: 0.25em; background-color: #eaf0fd; width: 11em; border: 1px solid #ccc;" [ngClass]="{ 'is-invalid': (submitted || validatedAirport) && f.aircraft_select.errors, 'is-valid': !f.aircraft_select.errors}" (change)="aircraftChange($event)">
                              <option value="null" selected>Select a Registration</option>
                              <option *ngFor="let r of aircraftList" [ngValue]="r">{{r.registration}}</option>
                            </select>
                          </div>
                          <div *ngIf="(submitted || validatedAirport) && f.aircraft_select.errors" class="invalid-feedback" style="margin-top:0em">
                            <div *ngIf="f.aircraft_select.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:12em">aircraft is a required field.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="selectedAircraftGUID!=''" style="display:flex; margin-top: 0em; margin-left:1.75em">
                    <label for="fartype_select">FAR Type:&nbsp;</label>
                    <div style="margin-top:0em;">
                      <select *ngIf="f" formControlName="fartype_select" style="border-radius:0.25em;background-color:#eaf0fd; width:11em" [ngClass]="{ 'is-invalid': (submitted || validatedAirport) && f.aircraft_select.errors, 'is-valid': !f.aircraft_select.errors}" (change)="aircraftChange($event)">
                        <option value="0" selected></option>
                        <option *ngFor="let fr of farTypeList" [ngValue]="fr.farTypeID">{{fr.farTypeDescription}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <div style="display: flex; ">
                    <label for="client_trip_number">Client Trip #&nbsp;</label>
                    <div style="margin-top:0em;">
                      <input type="text" formControlName="client_trip_number" class="form-control" maxlength="50" style="width:11em; height: 1.625em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd" />
                    </div>
                  </div>
                  <div *ngIf="(submitted || validatedAirport) && f.fartype_select.errors" class="invalid-feedback" style="margin-top:0.25em">
                    <div *ngIf="f.fartype_select.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:16em">FAR type is a required field.</div>
                  </div>
                </div>
              </div>
              <div>
                <div style="display: flex;">
                  <div style="font-weight: bold; float:left">Enter New Trip Route</div>
                  <div style="float: right">
                    <div *ngIf="(submitted || validatedAirport) && f.route.errors" class="invalid-feedback">
                      <div *ngIf="f.route.errors.required" style="background-color:#f9d2de;margin-left:11.5em;  padding-left:0.25em; width:8em;margin-top:0.25em">route is required</div>
                      <div *ngIf="f.route.errors.pattern" style="background-color:#f9d2de; margin-left:6em; padding-left:0.25em; width:14em;margin-top:0.25em">Invalid characters for airport</div>
                    </div>
                    <div *ngIf="errMsg!=''" class="d-block" style="margin-left:6em; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em;"><label style="color:black; font-size: small">{{errMsg}}</label></div>
                    <div *ngIf="duplicateIATAErrMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em;width:25em "><label style="color:black; font-size: small">{{duplicateIATAErrMsg}}</label></div>
                  </div>
                </div>
                <div style="display: flex;">
                  <div style="margin-top:0.2em;margin-right:0.5em;">
                    <textarea formControlName="route" class="form-control" [ngClass]="{ 'is-invalid': (submitted || validatedAirport) && f.route.errors }" style="width:26em; height:6em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
                  </textarea>
                  </div>
                  <div>
                    <div style="margin-top:0.2em;">
                      <button type="button" mat-button class="btn-high" [disabled]="loadingAirport" (click)="clickValidateAirport()"><span *ngIf="loadingAirport" class="spinner-border spinner-border-sm mr-1"></span>Validate</button>&nbsp;
                    </div>
                    <div style="font-size:x-small; margin-top:0em; white-space:pre-wrap">Use ICAO or IATA airport codes. Separate with blank space, comma or a dash</div>
                  </div>
                </div>
              </div>
              <div style="height:0.5em; margin-top: 0.5em; background-color: #f8f8f8;margin-left:-0.5em; margin-right:-0.5em"></div>
              <div>
                <div style="font-weight: bold;">Special Instructions for FPI Ops team</div>
                <div style="margin-top:0.2em;margin-right:0.5em;">
                  <textarea formControlName="special_instruction" class="form-control" style="width:35em; height:6em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
                  </textarea>
                </div>
              </div>
              <div>
                <div class="d-flex flex-column" style="margin-top:0.5em; margin-left:-3em; font-size: medium !important">
                  <!--<mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" formControlName="includeTripEstimate">Include Trip Estimate</mat-slide-toggle>-->
                  <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" formControlName="accquireAllPermits">Acquire all Permits</mat-slide-toggle>
                  <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" formControlName="includeFuel">Include Fuel Quotes and Arrangements on all Legs</mat-slide-toggle>
                  <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" formControlName="includeCrewHotel">Include Crew Hotel Quotes and Arrangements on all Legs</mat-slide-toggle>
                  <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" formControlName="includePaxHotel">Include Passenger Hotel Quotes and Arrangements on all Legs</mat-slide-toggle>
                  <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" formControlName="includeCrewTransportation">Include Crew Transportations on all Legs</mat-slide-toggle>
                  <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" formControlName="includePaxTransportation">Include Passenger Transportations on all Legs</mat-slide-toggle>
                  <!--<mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" formControlName="requiredSecurity">Required Security Coordination</mat-slide-toggle>-->
                </div>
              </div>
              <div *ngIf="errMsg2!=''" class="d-block" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em; white-space:pre-wrap"><label style="color:black; font-size: small">{{errMsg2}}</label></div>
            </div>
          </div>
          <div style="width:0.25em; background-color: #f8f8f8;"></div>
          <div style="margin-top:0em; ">
            <div style="padding-left: 0.5em; padding-top:0.5em">
              <div style="height: 30em;min-width:32.5em; overflow-y: auto;overflow-x:hidden;">

                <table mat-table *ngIf="cityPairList" [dataSource]="cityPairList" class="example-container2 mat-elevation-z8">
                  <tr>
                    <ng-container matColumnDef="business_stop">
                      <th mat-header-cell *matHeaderCellDef [attr.colspan]="4" style="border-bottom:none; text-align: left !important">
                        <div style="display: flex">
                          <div>Business Stops</div>
                          <div style="display: flex; margin-left:10em">
                            <div style="margin-right:0.5em; margin-top:0.25em">Choose</div>
                            <div style="display: flex; margin-top:0.5em; margin-right:0.5em">
                              <div style="margin-right:0.25em">
                                <input formControlName="time_type"
                                       type="radio"
                                       value="utc" [checked]="time_type=='utc'?true:false" (click)="timeTypeChangeChange($event)" />
                              </div>
                              <div style="white-space: nowrap;margin-top:-0.15em">
                                <label>UTC</label>
                              </div>
                            </div>
                            <div style="display: flex; margin-top:0.5em">
                              <div style="margin-right:0.25em">
                                <input formControlName="time_type"
                                       type="radio"
                                       value="local" [checked]="time_type=='local'?true:false" (click)="timeTypeChangeChange($event)" />
                              </div>
                              <div style="white-space: nowrap;margin-top:-0.15em">
                                <label>Local</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </th>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container matColumnDef="departure_airport">
                      <th mat-header-cell *matHeaderCellDef>
                        Departure
                      </th>
                      <td mat-cell *matCellDef="let element; let i = index"><div style="display: flex"><div style="margin-right:0.25em">{{element.departureICAO_IATA}}</div><div *ngIf="element.departureUTCTimeConversion!=''">({{'UTC '+element.departureUTCTimeConversion}})</div></div></td>
                    </ng-container>
                    <ng-container matColumnDef="departureDate_Time">
                      <th mat-header-cell *matHeaderCellDef>
                        Date / Time ({{time_type=='utc'? (time_type | uppercase) :(time_type | titlecase) }})
                      </th>
                      <td mat-cell *matCellDef="let element; let i = index" style="white-space:nowrap;text-align:left">
                        <div style="display:flex;margin-top:0em; margin-left:1.5em">
                          <div style="display:flex;">
                            <input type="text" matInput [matDatepicker]="deptdatepicker" [min]="minDate" placeholder="mm/dd/yyyy" [disabled]="element.depReadOnly" [value]="time_type=='utc'?element.departureDateUTC:element.departureDateLocal" class="form-control" [ngClass]="{ 'is-invalid1': !element.isValidDeptDate,  'is-valid1': element.isValidDeptDate }"
                                   [(ngModel)]="time_type=='utc'?element.departureDateUTC:element.departureDateLocal" [ngModelOptions]="{standalone: true}" style="padding: 0.25em; width: 6em; height: 1.6em; font-size: small; background-color:#eaf0fd; border-radius:0.25em" maxlength="10" (dateChange)="dateOnchange('D', $event, element, i)" (dateInput)="dateInputOnchange('D', $event, element)" />
                            <mat-datepicker-toggle matSuffix [for]="deptdatepicker" style="margin-top: -1em; margin-left: -0.85em; height: 1.5em; padding:0em;"></mat-datepicker-toggle>
                            <mat-datepicker #deptdatepicker [startAt]="element.depStartAt"></mat-datepicker>
                          </div>
                          <div>
                            <input type="text" matInput placeholder="hh:mm" maxlength="5" [disabled]="element.depReadOnly" [value]="time_type=='utc'?element.departureTimeUTC:element.departureTimeLocal" [(ngModel)]="time_type=='utc'?element.departureTimeUTC:element.departureTimeLocal" [ngModelOptions]="{standalone: true}" class="form-control" [ngClass]="{ 'is-invalid1': !element.isValidDeptTime,  'is-valid1': element.isValidDeptTime }" style="width:4em;height:1.6em; font-size: small;padding:0.25em; background-color:#eaf0fd; border-radius:0.25em" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('D', $event, element)" />
                          </div>
                        </div>
                        <div style="display:flex">
                          <div style="width:6.5em">
                            <mat-error *ngIf="!element.isValidDeptDate">
                              <div style="margin-left:2.5em; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.125em; width:6em; font-size: x-small; color: black;text-align:center;">Invalid date.</div>
                            </mat-error>
                          </div>
                          <mat-error *ngIf="!element.isValidDeptTime">
                            <div style="margin-left:4em; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.125em; width:6em; font-size: x-small; color: black; text-align:center">Invalid time.</div>
                          </mat-error>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="arrival_airport">
                      <th mat-header-cell *matHeaderCellDef>
                        Arrival
                      </th>
                      <td mat-cell *matCellDef="let element; let i = index"><div style="display: flex"><div style="margin-right:0.25em">{{element.arrivalICAO_IATA}}</div><div *ngIf="element.arrivalUTCTimeConversion!=''">({{'UTC '+element.arrivalUTCTimeConversion}})</div></div></td>
                    </ng-container>
                    <ng-container matColumnDef="arrivalDate_Time">
                      <th mat-header-cell *matHeaderCellDef>
                        Date / Time ({{time_type=='utc'? (time_type | uppercase) :(time_type | titlecase) }})
                      </th>
                      <td mat-cell *matCellDef="let element; let i = index">
                        <div style="display:flex;margin-top:0em; margin-left:1.5em; padding:0em">
                          <div style="display:flex;">
                            <input type="text" matInput [matDatepicker]="arrdatepicker" [min]="minDate" [disabled]="element.arrReadOnly" placeholder="mm/dd/yyyy" [value]="time_type=='utc'?element.arrivalDateUTC:element.arrivalDateLocal" class="form-control" [ngClass]="{ 'is-invalid1': !element.isValidArrDate,  'is-valid1': element.isValidArrDate }"
                                   [(ngModel)]="time_type=='utc'?element.arrivalDateUTC:element.arrivalDateLocal" [ngModelOptions]="{standalone: true}" style="padding: 0.25em; width: 6em; height: 1.6em; font-size: small; background-color:#eaf0fd;border-radius:0.25em" maxlength="10" (dateChange)="dateOnchange('A', $event, element, i)" (dateInput)="dateInputOnchange('A', $event, element)" />
                            <mat-datepicker-toggle matSuffix [for]="arrdatepicker" style="margin-top:-1em; margin-left:-0.85em; padding:0em;height:1.5em"></mat-datepicker-toggle>
                            <mat-datepicker #arrdatepicker [startAt]="element.arrStartAt"></mat-datepicker>
                          </div>
                          <div>
                            <input type="text" matInput placeholder="hh:mm" maxlength="5" [disabled]="element.arrReadOnly" [value]="time_type=='utc'?element.arrivalTimeUTC:element.arrivalTimeLocal" [(ngModel)]="time_type=='utc'?element.arrivalTimeUTC:element.arrivalTimeLocal" [ngModelOptions]="{standalone: true}" class="form-control" [ngClass]="{ 'is-invalid1': !element.isValidArrTime,  'is-valid1': element.isValidArrTime }" style="width:4em;height:1.6em; font-size: small;padding:0.25em; background-color:#eaf0fd; border-radius:0.25em" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('A', $event, element)" />
                          </div>
                        </div>
                        <div style="display:flex">
                          <div style="width:6.5em">
                            <mat-error *ngIf="!element.isValidArrDate">
                              <div style="margin-left:2.5em; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.125em; width:6em; font-size: x-small; color: black;text-align:center;">Invalid date.</div>
                            </mat-error>
                          </div>
                          <mat-error *ngIf="!element.isValidArrTime">
                            <div style="margin-left:4em; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.125em; width:6em; font-size: x-small; color: black; text-align:center">Invalid time.</div>
                          </mat-error>
                        </div>
                      </td>
                    </ng-container>

                  </tr>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnCityPair2; sticky: true"></tr>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnCityPair; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnCityPair"></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex;height:3em">
      <div style="margin-right:0.5em; display: flex">
        <div style="margin-right:0.5em">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          <button type="button" mat-button class="btn-high" *ngIf="cityPairList && cityPairList.length>0 && loading==false && showSuccessMsg==false" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Next</button>
          <!--<button type="button" mat-button class="btn-high" *ngIf="showSuccessMsg==false" [disabled]="loading" (click)="clickSaveTest()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Next</button>-->
        </div>
        <div>
          <button type="button" mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white;"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
        </div>
      </div>
      <div *ngIf="errMsg3!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errMsg3}}</label></div>
      <div *ngIf="showSuccessMsg">
        <app-success-message [successMsg]=""></app-success-message>
      </div>
    </div>

  </div>
  <div *ngIf="showSpin" style="position:absolute;margin-left: 20em; margin-top:-25em; width:5em; z-index:2000">
    <app-spin *ngIf="showSpin"></app-spin>
  </div>

  </div>
