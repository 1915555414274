<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="margin-top:-.75em;width:100%"
  class="d-inline-flex justify-content-between">
  <div class="d-inline-flex">
    <!-- <i class="material-icons-two-tone yellow mr-1">local_fire_department</i> -->
      <span style="margin-right: .25em;  color: yellow;  font-size: x-large" class="material-icons-two-tone yellow">
        local_fire_department
      </span>
    <h5>
      Critical Alerts 
    </h5>
  </div>
  <mat-icon mat-dialog-close>close</mat-icon>
</div>


<div   style="background-color:white;padding:.5em; max-height: 43em; height: 35em; overflow-y: auto;">

  <div class="mt-2" *ngIf="criticalAlerts!=null && criticalAlerts.length>0">
    <div style="font-weight:bold;  color: red;">
      Affected Legs
    </div> 

    

    <ng-template ngFor let-customer [ngForOf]="criticalAlerts">
      <div class="font-weight-bold" style="font-size:medium">{{customer.customerName}}</div>

      <!-- <div class="font-weight-bold">{{customer.customerName}}</div> -->
      <ng-template ngFor let-trip [ngForOf]="customer.trips">
        <div class="font-weight-bold "><a class="link" (click)="openTrip(trip)">{{trip.tripCode}}</a></div>
        <!-- <div class="font-weight-bold">{{trip.tripCode}}</div> -->
        <ng-template ngFor let-stop [ngForOf]="trip.groundStops">
          <div style="font-size: small; font-weight: bold;">{{stop.categoryName}}</div>
          <ng-template ngFor let-detail [ngForOf]="stop.alertDetails">
            <li>{{detail.serviceTypeDescription}}</li>
          </ng-template>
        </ng-template>
        <br>
      </ng-template> 
    </ng-template>


  </div>

  
  
</div>



