
<div [ngClass]="isDialog? 'greyBack':''" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div *ngIf="upPII" style="margin-top: 0em; padding-top: 0em;">
    <form *ngIf="trip_quick_search_control_client_form" [formGroup]="trip_quick_search_control_client_form" style=" max-width: 100%;max-height:100%;">
      <div class="divLeft">
        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 1em;">
          <div style="display: flex; align-items: center; gap: 0.5em;">
            <span class="material-icons-outlined" style="font-size: 1.5em;">event_available</span>
            <h4 >Trip Schedules</h4>
          </div>
          <div style="display: flex; gap: 0.5em;">
            <button mat-icon-button *ngIf="isDialog" (click)="openSchedulesClientComponent()">
              <i class="material-icons-outlined" style="font-size:xx-large;  ">arrow_circle_left</i>
            </button>
            <button mat-icon-button *ngIf="isDialog" mat-dialog-close>
              <i class="material-icons"
                 style="font-size: x-large;">close </i>
            </button>
          </div>
        </div>
          <div style="background-color:#f8f8f8; padding-left:0.25em; padding-top:0.25em; padding-right:1.25em; margin-top:-0.5em; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; min-height:4.5em">
            <div style="margin-left:0em;padding:0em; display: flex; justify-content: space-between; ">
              
              
              <div style="display: flex;">
                
              
                <div>
                  <div style="border: 0.0125em solid #337dff; margin-right:0.5em; padding-left:0.25em; border-radius: 0.25em">
                    <div style="display: flex;margin-top:0.25em"><div style="font-size: small; font-weight: bold">Enter a Date Range</div><div style="font-size:x-small; margin-top:0.35em">&nbsp;(MM/DD/YYYY)</div></div>
                    <div style="display: flex; width: 12.25em; margin-top:-1.25em">
                      <mat-date-range-input [rangePicker]="picker" style="width:10em; display: flex; margin-top:0.5em; ">
                        <input matStartDate formControlName="startDate" placeholder="Start date" maxlength="10" [ngClass]="{ 'is-invalid1': isValidStartDate==false,  'is-valid1': isValidStartDate==true }" (dateChange)="dateOnchange('S', $event)" style="font-size:small;margin-top:0.4em; margin-right:0em;background-color: white; border-radius:0.25em;padding-left:0.125em ">
                        <input matEndDate formControlName="endDate" placeholder="End date" maxlength="10" [ngClass]="{ 'is-invalid1': !isValidEndDate,  'is-valid1': isValidEndDate }" (dateChange)="dateOnchange('E', $event)" style="font-size:small;margin-left:0em; background-color: white; border-radius:0.25em;padding-left:0.125em;margin-top:.5em; ">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker" style="margin-top:0.7em; margin-left:-0.7em"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>
                    </div>
                  </div>
                </div>
                <div style="width: 35em">
                  <div style="display: flex;">
                    <div style="margin-top:0.5em; margin-right:0.25em;">
                      <button type="button" mat-button class="btn-low" style="width:8em" [ngClass]="selectedRegistrationCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickAircraftFilter()">Aircraft{{selectedRegistrationCountText}}</button>
                    </div>
                    <div style="margin-top:0.5em; margin-right:0.25em">
                      <button type="button" mat-button class="btn-low" style="width:8em; padding-left:0.5em !important" [ngClass]="selectedPersonCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickManifestFilter()">Manifest{{selectedPersonCountText}}</button>
                    </div>
                    <div style="margin-top:0.5em; margin-right:0.25em">
                      <button type="button" mat-button class="btn-low" style="width:8em" [ngClass]="selectedAirportCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickAirportFilter()">Airports{{selectedAirportCountText}}</button>
                    </div>
                    <div *ngIf="grantAccessToAccountGrouping" style="margin-top:0.5em; margin-right:0.25em;">
                      <button type="button" mat-button class="btn-low" style="width:8em; padding-left:0.5em !important" [ngClass]="selectedClientCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickClientFilter()">Client Acct{{selectedClientCountText}}</button>
                    </div>
                  </div>

                  <div style="display:flex; ">
                    <div *ngIf="f.startDate.errors" class="small alert alert-danger p-0" style="font-size: small; width: max-content; margin-top: 0em">
                      <div *ngIf="f.startDate.errors.isValidDate==false">Invalid start date.</div>
                    </div>
                    <div *ngIf="f.endDate.errors" class="small alert alert-danger p-0" style="font-size: small; width: max-content; margin-top: 0em">
                      <div *ngIf="f.endDate.errors.isValidDate==false">Invalid end date.</div>
                    </div>
                    <!--Aircraft list-->
                    <div *ngIf="showAircraftList" style="position:absolute; float: left; min-width: 11em; margin-top: 0.25em; margin-left: 0em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                      <div style="display:flex; justify-content:space-between">
                        <div style="font-weight: bold; text-decoration:underline">Registration</div>
                        <div style="float:right" (click)="showAircraftList=!showAircraftList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                      </div>
                      <div *ngIf="registrationList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                        <div *ngFor="let r of registrationList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                          <div style="display: flex; font-size: small; margin-top:-0.5em">
                            <div>
                              <mat-checkbox (click)="$event.stopPropagation()" (change)="checkRegistrationChange($event, r)" [checked]="r.selected">{{r.registration}}</mat-checkbox>
                            </div>
                            <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{r.registration}}</div></div>-->
                          </div>
                        </div>
                      </div>
                      <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                      <div style="display: flex; float: right">
                        <div style="margin-right:0.5em">
                          <button type="button" mat-button class="btn-low" (click)="clickResetAircraft()" style="height:2em">RESET</button>
                        </div>
                        <div>
                          <button type="button" mat-button class="btn-high" (click)="clickDoneAircraft()" style="height:2em">DONE</button>
                        </div>
                      </div>
                    </div>
                    <!--Aircraft list end-->
                    <!--Airport list-->
                    <div *ngIf="showAirportList" style="position: absolute; float: left; min-width: 12em; margin-top: 0.25em; margin-left: 18em; background-color: white; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                      <div style="display:flex; justify-content:space-between">
                        <div style="font-weight: bold; text-decoration:underline;">Airport ICAO</div>
                        <div style="float:right" (click)="showAirportList=!showAirportList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                      </div>
                      <div *ngIf="airportList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                        <div *ngFor="let a of airportList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                          <div style="display: flex; font-size: small; margin-top:-0.5em">
                            <div>
                              <mat-checkbox (click)="$event.stopPropagation()" (change)="checkAirportChange($event, a)" [checked]="a.selected">{{a.icao}}</mat-checkbox>
                            </div>
                            <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{a.icao}}</div></div>-->
                          </div>
                        </div>
                      </div>
                      <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                      <div style="display: flex; float: right">
                        <div style="margin-right:0.5em">
                          <button type="button" mat-button class="btn-low" (click)="clickResetAirport()" style="height:2em">RESET</button>
                        </div>
                        <div>
                          <button type="button" mat-button class="btn-high" (click)="clickDoneAirport()" style="height:2em">DONE</button>
                        </div>
                      </div>
                    </div>
                    <!--Airport list end-->
                    <!--manifest list-->
                    <div *ngIf="showPersonList" style="position: absolute; float: left; margin-top: 0.25em; margin-left: 5.8em; background-color: white; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                      <div style="display: flex">
                        <div style="margin-right:2em">
                          <div style="font-weight: bold; text-decoration:underline">Role</div>
                          <div>
                            <div style="display: flex; margin-top:0.5em">
                              <div style="margin-right:0.25em">
                                <input formControlName="person_role"
                                      type="radio"
                                      value="pilot" (click)="personRoleChange($event)" />
                              </div>
                              <div style="white-space: nowrap;margin-top:-0.15em">
                                <label>Pilot</label>
                              </div>
                            </div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em">
                                <input formControlName="person_role"
                                      type="radio"
                                      value="crew" (click)="personRoleChange($event)" />
                              </div>
                              <div style="white-space: nowrap;margin-top:-0.15em">
                                <label>Crew</label>
                              </div>
                            </div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em">
                                <input formControlName="person_role"
                                      type="radio"
                                      value="passenger" (click)="personRoleChange($event)" />
                              </div>
                              <div style="white-space: nowrap;margin-top:-0.15em">
                                <label>Passenger</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style="min-width: 14em">
                          <div style="display:flex; justify-content:space-between">
                            <div style="font-weight: bold; text-decoration:underline; white-space: nowrap">{{person_role=='pilot'?'PIC / SIC':person_role | titlecase}}</div>
                            <div style="float:right" (click)="showPersonList=!showPersonList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                          </div>
                          <div style="font-size: small; margin-left:0em">
                            <div style="margin-right:0.5em;">
                              <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                                <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                                <input type="text" formControlName="person_search_keyword" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="personSearchKeywordChange($event)" />
                              </div>
                            </div>
                          </div>
                          <!--pilot-->
                          <div *ngIf="person_role=='pilot' && pilotList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                            <div *ngFor="let p of pilotList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                              <div style="display: flex; font-size: small; margin-top:-0.5em">
                                <div>
                                  <mat-checkbox (click)="$event.stopPropagation()" (change)="checkPersonChange($event, p)" [checked]="p.selected">{{p.personName}}</mat-checkbox>
                                </div>
                                <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em; white-space: nowrap">{{p.personName}}</div></div>-->
                              </div>
                            </div>
                          </div>
                          <!--pilot end-->
                          <!--crew-->
                          <div *ngIf="person_role=='crew' && crewList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                            <div *ngFor="let p of crewList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                              <div style="display: flex; font-size: small; margin-top:-0.5em">
                                <div>
                                  <mat-checkbox (click)="$event.stopPropagation()" (change)="checkPersonChange($event, p)" [checked]="p.selected">{{p.personName}}</mat-checkbox>
                                </div>
                                <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em; white-space: nowrap">{{p.personName}}</div></div>-->
                              </div>
                            </div>
                          </div>
                          <!--crew end-->
                          <!--passenger-->
                          <div *ngIf="person_role=='passenger' && paxList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                            <div *ngFor="let p of paxList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                              <div style="display: flex; font-size: small; margin-top:-0.5em">

                                <div>

                                  <mat-checkbox (click)="$event.stopPropagation()" (change)="checkPersonChange($event, p)" [checked]="p.selected">{{p.personName}}</mat-checkbox>
                                </div>
                                <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em; white-space: nowrap">{{p.personName}}</div></div>-->
                              </div>
                            </div>
                          </div>
                          <!--passenger end-->
                        </div>

                      </div>
                      <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                      <div style="display: flex; float: right">
                        <div style="margin-right:0.5em">
                          <button type="button" mat-button class="btn-low" (click)="clickResetPerson()" style="height:2em">RESET</button>
                        </div>
                        <div>
                          <button type="button" mat-button class="btn-high" (click)="clickDonePerson()" style="height:2em">DONE</button>
                        </div>
                      </div>
                    </div>
                    <!--manifest list end-->
                    <!--client list-->
                    <div *ngIf="showClientList" style="position: absolute; float: left; min-width: 12em; margin-top: 0.25em; margin-left: 25em; background-color: white; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                      <div style="display:flex; justify-content:space-between">
                        <div style="font-weight: bold; text-decoration:underline">Client Accounts</div>
                        <div style="float:right" (click)="showClientList=!showClientList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                      </div>
                      <div *ngIf="customerList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                        <div *ngFor="let c of customerList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                          <div #tripClientRefs [attr.customerGUID]="c.customerGUID" style="display: flex; font-size: small; margin-top:-0.5em">
                            <div>
                              <mat-checkbox (click)="$event.stopPropagation()" (change)="checkClientChange($event, c)" [checked]="c.selected">{{c.customerName}}</mat-checkbox>
                            </div>
                            <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{c.customerName}}</div></div>-->
                          </div>
                        </div>
                      </div>
                      <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                      <div style="display: flex; float: right">
                        <div style="margin-right:0.5em">
                          <button type="button" mat-button class="btn-low" (click)="clickResetClient()" style="height:2em">RESET</button>
                        </div>
                        <div>
                          <button type="button" mat-button class="btn-high" (click)="clickDoneClient()" style="height:2em">DONE</button>
                        </div>
                      </div>
                    </div>
                    <!--client list end-->

                  </div>


                </div>
              </div>


              <div style="display: flex;">
                    
                  <div style="margin-top: .25em;  ">
                    <mat-slide-toggle style="transform:scale(.8); height:2em; " color="primary" [checked]="includeClosedBilledRecords" formControlName="includeClosedBilledRecords" (change)="includeClosedBilledRecordsOnChange($event)">Include Closed Trips</mat-slide-toggle>

                  </div>
                  <div style="margin-top:0.5em; margin-left:0em; margin-right:1em">
                    <div>
                      <button type="button" mat-button class="btn-low" (click)="clickSubmit()" style="height:2em">
                        <div style="display: flex">
                          <div style="margin-top: 0.25em; margin-left:-0.25em">
                            <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">refresh</i>
                          </div>
                          <div>
                            REFRESH
                          </div>
                        </div>
                      </button>
                    </div>
                    <div style="display:flex"><div style="font-size:small; margin-top: 0em; margin-left: 0.3125em; width: 8em;margin-right: -2em;">{{totalRecordsText}}</div><div *ngIf="errMsg!=''" class="d-block" style="margin-left:0.5em; display:flex;margin-top:-0.5em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errMsg}}</label></div></div>
                  </div>

                  <div style="margin-top:-0.25em">
                    <div><label for="sort_by" style="font-size:small; font-weight: bold">Sort By</label></div>
                    <div style="margin-left: 0em; margin-top:-0.75em">
                      <select *ngIf="f" formControlName="sort_by" style="font-size: small;border-radius:0.25em;border-color:lightgrey" (change)="sortByChange($event)">
                        <option value="departureDate asc" selected *ngIf="selectedTabName!='aircraft'">Trip Itinery Date asc</option>
                        <option value="departureDate desc" *ngIf="selectedTabName!='aircraft'">Trip Itinery Date desc</option>
                        <option value="registration asc">Registration asc</option>
                        <option value="registration desc">Registration desc</option>
                        <option value="tripCode asc" *ngIf="selectedTabName!='aircraft'">Trip Code asc</option>
                        <option value="tripCode desc" *ngIf="selectedTabName!='aircraft'">Trip Code desc</option>
                      </select>
                    </div>
                    <div style="margin-left: 6em;">
                      <a style="cursor: pointer; font-size: small; color: Highlight;" (click)="clickLegend()"> Legend</a>
                    </div>

                  </div>

              </div>







            </div>
          </div>
        </div>

      <!-- <div class="divLeft2">
        <div style="display: flex;margin-bottom:-0.75em; justify-content:space-between;">
          <div style="margin-top:0em;">
            <div>
              <ul class="tabul">
              <li [ngClass]="{ 'btn-high':selectedTabName=='tripcode_tactical','btn-low pointer':selectedTabName!='tripcode_tactical'  }" style="border-left: black 0.0625em solid;text-align:center" (click)="clickTab('tripcode_tactical')">Schedule Tactical View</li>
              <li [ngClass]="{ 'btn-high':selectedTabName=='tripcode_detail','btn-low pointer':selectedTabName!='tripcode_detail'  }" style="text-align:center" (click)="clickTab('tripcode_detail')">Schedule with Manifest</li>
              <li [ngClass]="{ 'btn-high':selectedTabName=='aircraft','btn-low pointer':selectedTabName!='aircraft' }" style="text-align:center" (click)="clickTab('aircraft')">Aircraft Registration View</li>
            </ul>
            </div>
          </div>
          <div>
            <a style="cursor: pointer; font-size: small; color: Highlight;" (click)="clickLegend()"> Legend</a>
          </div>

        </div>
      </div> -->


      <div class="divLeft2" style="z-index:100">
        <div class="scrollable-container">
          <div>
            <app-trip-code-tactical-viewlist-client *ngIf="selectedTabName=='tripcode_tactical'" [selectedTabName]="selectedTabName" [request]="request" [reloadChildPage]="reloadChildPage" (sendDataToParent)="onSendDataToParent($event)"></app-trip-code-tactical-viewlist-client>
            <!-- <app-trip-code-detail-viewlist-client *ngIf="selectedTabName=='tripcode_detail'" [selectedTabName]="selectedTabName" [request]="request" [reloadChildPage]="reloadChildPage" (sendDataToParent)="onSendDataToParent($event)"></app-trip-code-detail-viewlist-client>
      <app-aircraft-trip-viewlist-client *ngIf="selectedTabName=='aircraft'" [selectedTabName]="selectedTabName" [request]="request" [reloadChildPage]="reloadChildPage" (sendDataToParent)="onSendDataToParent($event)"></app-aircraft-trip-viewlist-client> -->
          </div>
          </div>
        </div>
</form>
  </div>
</div>

