<div  style="background-color:#f8f8f8;    margin: 1em; margin-bottom: 0em;">
  <div style="padding: 0em; background-color:white ; height: 100%;">


    <div style="display: flex;padding-left:1em;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div style="margin-top:0.3125em; width: 100%; display: flex; justify-content: space-between;  margin-left: -0.6em;">
        <!-- <h4 style="color: red;"> <i  class="material-icons" style="color: black; font-size: larger; margin-right: 0.3em; cursor: pointer; ">campaign</i> Send Feedback</h4> -->
        
        <h4 style="color: red; display: flex;"> <span class="material-symbols-outlined" style="font-size: xx-large; color: black; margin-right: 0.35em;margin-right: 0.35em; margin-top: -0.1em;">rate_review</span><h5> <span> Submit Feedback</span></h5>&nbsp; </h4>

        <div style="display: flex;">
          <button mat-icon-button *ngIf="isDialog" (click)="openHelpDashboardClientComponent()">
            <i class="material-icons-outlined" style="font-size:xx-large;">arrow_circle_left</i>
          </button>
          <button mat-icon-button *ngIf="isDialog" (click)="closeFeedbackAndGoBack()">
            <i class="material-icons" style="font-size: x-large;">close</i>
          </button>
        </div>
        <!--<button  mat-icon-button  (click)="clickClose(true)" style="margin-top: -1em;"> <i class="material-icons" style="font-size: x-large; ">close </i> </button>-->

      </div>
    </div>

    <div>
      <h6  *ngIf="!isTaskFeedback" style="padding-left: 1em;" >Assist in enhancing Flight Pro International. Share your feedback on your experience using the website.      </h6>
      <div *ngIf="isTaskFeedback"  style="text-align: left; margin: 0em;">
        <!--*ngIf="showModified"-->
        <div style="display: flex; ">
          <h5 style="color: dodgerblue; padding: 0.25em; padding-left: 0.5em; width: 7em; text-align: end;"> Trip Code :</h5>
          <div>
            <div style="padding-top: 0.3em;" > {{tripCode}} </div>
          </div>
        </div>
        <div  style="display: flex;  margin: -0.5em 0;">
          <h5 style="color: dodgerblue; padding: 0.25em; padding-left: 0.5em; width: 7em; text-align: end;">Registration :</h5>
          <div>
            <div style="padding-top: 0.3em;" > {{registration}} </div>
          </div>
        </div>
        <div  style="display: flex;  margin: -0.5em 0;">
          <h5 style="color: dodgerblue; padding: 0.25em; padding-left: 0.5em; width: 7em; text-align: end;">Route :</h5>
          <div style="padding-top: 0.3em;">
            <!-- <div style="padding-top: 0.3em;" > {{registration}} </div> -->
                  <div>
                    <div *ngIf="routeLegList.length>7" style="display: flex" class="divHover"  >
                      <div *ngFor="let gs of routeLegList; let i=index">
                        <div *ngIf="i<=6" style="display: flex">
                          <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==routeLegList.length-1? '': '-'}}</div>
                        </div>
                        <div *ngIf="i==7" style="display: flex">
                          <div [style.background-color]="gs.groundStopTypeColor">....</div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="routeLegList.length<=7" style="display: flex">
                      <div *ngFor="let gs of routeLegList; let i=index">
                        <div *ngIf="i<=6" style="display: flex">
                          <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==routeLegList.length-1? '': '-'}}</div>
                        </div>
                      </div>
                    </div>
                  </div>


          </div>
        </div>
        <div  style="display: flex;  margin: -0.5em 0;">
          <h5 style="color: dodgerblue; padding: 0.25em; padding-left: 0.5em; width: 7em; text-align: end;">Airport :</h5>
          <div>
            <div style="padding-top: 0.3em;" > {{airportDetails}} </div>
          </div>
        </div>
        <div  style="display: flex;  margin: -0.5em 0;">
          <h5 style="color: dodgerblue; padding: 0.25em; padding-left: 0.5em; width: 7em; text-align: end;"> Service :</h5>
          <div style="display: flex;">
            <div style="padding-top: 0.3em;" > {{serviceTypeDesc}} </div>
            <!-- <div style="padding-top: 0.3em;" >&nbsp; - {{selectedCountryName}} </div> -->
            <div *ngIf="serviceTypeDesc == 'Overflight Permit'" style="padding-top: 0.3em;" > &nbsp; - {{selectedCountryName}} </div>
            <div *ngIf="serviceTypeDesc == 'Handling'" style="padding-top: 0.3em;" > &nbsp; - {{selectedVendorName}} </div>

          </div>
        </div>
      </div>





    </div>

    <div style="margin-top: 1.5em;margin-left: 0em; display: flex; height: 6em;   ">
    
      <mat-star-rating [rating]="rating"  [starCount]="starCount" [color]="starColor" (ratingUpdated)="onRatingChanged($event)"></mat-star-rating>
      <div style="font-size: 1.2em; margin-top: 1.0em; margin-left: 1em; font-weight: 500;"> {{ratingExperience}} </div>
    </div>

    <textarea spellcheck="true" [(ngModel)]="feedbackUserServiceExperienceNote" class="form-control" placeholder="Share Details of Your Own Experience"
    style="width:42.5em; height:10em; font-size:small; margin-left: 1em; padding:0em 0em 0em 0em;background-color:#eaf0fd">
          </textarea>
 
    <div style="margin-top: 1em;margin-left: 1em; "> May we contact you for further information ?</div>
    <div style="margin-top: 1em;margin-left: 1em; height: 6em;   ">
    

      <!-- <div> May we Contact You For Further Information ?</div> -->

      <button *ngIf="showColorDislike" mat-icon-button [color]="color" style="margin-left: 1em;"    (click)="showIconDisike()" > 
        <i class="material-icons" style="color:#ffd740; font-size: xxx-large;  "   >thumb_down</i> 
        <span style="display: grid;">No</span> 
      </button> 
      <button *ngIf="!showColorDislike" mat-icon-button [color]="color" style="margin-left: 1em;"    (click)="showIconDisike()" > 
        <i class="material-icons-outlined" style="  font-size: xxx-large;  "   >thumb_down</i> 
        <span style="display: grid;">No</span>
      </button>

      <button *ngIf="showColorLike" mat-icon-button [color]="color" style="margin-left: 6em;"    (click)="showIconLike()" > 
        <i class="material-icons" style="color:#ffd740; font-size: xxx-large;  "   >thumb_up</i> 
        <span style="display: grid;">Yes</span> 
      </button> 
      <button *ngIf="!showColorLike" mat-icon-button [color]="color" style="margin-left: 6em;"    (click)="showIconLike()" > 
        <i class="material-icons-outlined" style="  font-size: xxx-large;  "   >thumb_up</i> 
        <span style="display: grid;">Yes</span> 
      </button> 
 
    </div>

    <div style="display: flex">
      <div
        style="justify-content:flex-start;padding-left: 1em;  margin-right:1em;  ">
        <!-- <button class="btn-high" mat-button (click)="clickSubmit()" [disabled]="rating == 0" style="background-color:grey; color: white; margin-left: 0em;">Submit  </button> -->

        <button mat-button class="btn-high"  [disabled]="rating == 0 || loading" style="margin-left: 1em;" (click)="clickSubmit()">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
           Submit
        </button>
        <button mat-button class="btn-low" mat-dialog-close style="margin-left: 1em;"> Close </button>&nbsp;
      
      </div>


      <!-- <div *ngIf="errMsg!=''" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em;"><label style="color:black; font-size: small">{{errMsg}}</label></div> -->
      <div *ngIf="showSuccessMsg" style="margin-top:-0.25em">
        <app-success-message [successMsg]="successMsg"></app-success-message>
      </div>
    </div>
  </div>
</div>
