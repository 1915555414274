import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { AdvisoryService } from '../services/advisory.service';
import { AdvisoryModel } from '../models/advisory.model';
import { ResponseModel } from '../models/response.model';
import { AdvisoryDocumentModel } from '../models/advisory-document.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { saveAs } from 'file-saver';
import { GroundStopService } from '../services/ground-stop.service';
import { CriticalAlertsModel } from '../models/critical-alerts.model';


export interface AlertList {
  categoryName: string;
  alertDetails: { serviceTypeDescription: string }[];
} 

export interface AlertCustomers {
  customerName: string;
  trips: AlertTrips[];
}

export interface AlertTrips {
  tripCode: string;
  tripCodeGUID: string;
  customerGUID: string;
  aircraftGUID: string;
  groundStops: AlertList[];
}
 
@Component({
  selector: 'app-view-critical-alerts',
  templateUrl: './view-critical-alerts.component.html',
  styleUrls: ['./view-critical-alerts.component.css']
})
export class ViewCriticalAlertsComponent implements OnInit {
  criticalAlertsList: CriticalAlertsModel[] = [];
  criticalAlerts: AlertCustomers[] = [];
  showSpin: boolean = false;
  errMsg: string = '';
  groupedAlerts: {};
 

  constructor(private _dialogRef: MatDialogRef<ViewCriticalAlertsComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _advisoryService: AdvisoryService, private readonly _authService: AuthenticateService,
    private readonly _groundStopService: GroundStopService, private readonly _dialog: MatDialog,) {

    
    if (_data.fromAdvisoryHomepage != undefined && _data.fromAdvisoryHomepage != null) {
    }
     this.criticalAlertsList = _data.criticalAlertsList;
    
  }

  ngOnInit() {
    this._authService.updateAccessTime();
 

    this.formatAlerts();

  }



  formatAlerts() {
    this.groupedAlerts  = {}; // Object to store grouped alerts

    // Checking if the list is not empty
    if (this.criticalAlertsList != null) {
      this.criticalAlertsList.forEach((alert: CriticalAlertsModel) => {

        // Grouping by customerGUID
        if (!this.groupedAlerts[alert.customerGUID]) {
          this.groupedAlerts[alert.customerGUID] = {
            customerName: alert.customerName,
            trips: {}
          };
        }

        // Grouping by tripCodeGUID
        if (!this.groupedAlerts[alert.customerGUID].trips[alert.tripCodeGUID]) {
          this.groupedAlerts[alert.customerGUID].trips[alert.tripCodeGUID] = {
            tripCode: alert.tripCode,
            tripCodeGUID: alert.tripCodeGUID,
            customerGUID: alert.customerGUID,
            aircraftGUID: alert.aircraftGUID,

            groundStops: {}
          };
        }

        // Grouping by groundStopGUID
        if (!this.groupedAlerts[alert.customerGUID].trips[alert.tripCodeGUID].groundStops[alert.groundStopGUID]) {
          this.groupedAlerts[alert.customerGUID].trips[alert.tripCodeGUID].groundStops[alert.groundStopGUID] = {
            categoryName: `${alert.departureIcao}-${alert.arrivalIcao} / ${alert.departureDateUTC} Z`,
            alertDetails: []
          };
        }

        // Adding alert details (avoiding duplicates)
        this.groupedAlerts[alert.customerGUID].trips[alert.tripCodeGUID].groundStops[alert.groundStopGUID].alertDetails.push({
          serviceTypeDescription: alert.serviceTypeDescription
        });
      });
    }

    // Converting the grouped data to the AlertCustomers array for easier rendering
    this.criticalAlerts = Object.values(this.groupedAlerts).map((customer: any) => ({
      customerName: customer.customerName,
      trips: Object.values(customer.trips).map((trip: any) => ({
        tripCode: trip.tripCode,
        tripCodeGUID: trip.tripCodeGUID,
        customerGUID: trip.customerGUID,
        aircraftGUID: trip.aircraftGUID,
        groundStops: Object.values(trip.groundStops).map((stop: any) => ({
          categoryName: stop.categoryName,
          alertDetails: stop.alertDetails
        }))
      }))
    }));

  }


 
 


  openTrip(p: any) {
    window.open('/ground-stops/trip-details-internal?tripId=' + p.tripCodeGUID + '&aircraftId=' + p.aircraftGUID + '&customerId=' + p.customerGUID + '&pf=&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  }


}
