import { Component, Input, OnInit, Output } from '@angular/core';
import { AuthenticateService } from '../services/authenticate.service';
import { MessageFolderModel } from '../models/message-folder.model'
import { MessageService } from '../services/message.service';
import { ResponseModel } from '../models/response.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { EmailInternalDialogComponent } from './email-internal-dialog.component';
import { Title } from '@angular/platform-browser';
import { MessageImportComponent } from './message-import.component';
import { DialogService } from '../services/dialog.service';


@Component({
  selector: 'app-message-center',
  templateUrl: './message-center.component.html',
  styleUrls: ['./message-center.component.css']
})
export class MessageCenterComponent implements OnInit {
  messageFolderList: MessageFolderModel[];
  hasChanges: boolean = false;
  showSearch: boolean = false;
  timeFrame: number = 2;
  grantAccessToMessages: boolean;
  showNewFolderInput: boolean;
  folderName: string;
  folderExist: boolean;
  expandComms: boolean = true;

  constructor(private readonly _authService: AuthenticateService, private readonly _messageService: MessageService, private readonly _dialog: MatDialog, private readonly _dialogService: DialogService) { }

  ngOnInit(): void {
    this._authService.updateAccessTime();
    this.grantAccessToMessages = false;
    if (localStorage.getItem('up.msg') == 'true') {
      this.grantAccessToMessages = true;
    }


    if (!this.grantAccessToMessages) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.messageFolderList = [];
    this._messageService.getMessageFolderList<ResponseModel<MessageFolderModel[]>>().subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.messageFolderList = response.model;
          this.messageFolderList[0].selected = true;
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
  }
  filterMessageList(mf: MessageFolderModel) {

    this.messageFolderList.forEach(v => {
      if (v.messageFolderID == mf.messageFolderID)
        v.selected = true;
      else
        v.selected = false;
    });
    this.hasChanges = !this.hasChanges;
  }

  newMessage() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", messageGUID: "", expandComms: this.expandComms, isDraft: false, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(EmailInternalDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.isModified = true;
      }

    });
  }

  importMessages() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", messageGUID: "", isDraft: false, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(MessageImportComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.isModified = true;
      }

    });
  }


}
