<div style="max-width: 100%; max-height: 100%; overflow: hidden;">
<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle mat-dialog-title>
  <div style="display: flex; justify-content: space-between; align-items: center; padding: 0.5em 1em;">
    <!-- Title and Icon Section -->
    <div style="display: flex; align-items: center; gap: 0.5em;margin-left:-1em;">
      <span class="material-icons-outlined" style="font-size: 1.5em;">summarize</span>
      <h4 >Trip Summary Report</h4>
    </div>
    <!-- Icons Section -->
    <div style="display: flex; gap: 0.5em; align-items: center;">
      <button mat-icon-button *ngIf="isDialog" (click)="openSchedulesClientComponent()">
        <i class="material-icons-outlined" style="font-size: 2.2em;">arrow_circle_left</i>
      </button>
      <!--<button mat-icon-button *ngIf="isDialog" (click)="clickRouteNew('ground-stops/trip-summary-report-dialog')">
        <i class="material-icons" style="font-size: 2.2em;">open_in_new</i>
      </button>-->
      <button mat-icon-button *ngIf="isDialog" mat-dialog-close>
        <i class="material-icons" style="font-size: 2.2em;">close</i>
      </button>
    </div>
  </div>
</div>


<div mat-dialog-content style="margin-top:-1em;padding-bottom:0; overflow: hidden;">
  <form *ngIf="trip_summary_report_form" [formGroup]="trip_summary_report_form">

    <div class="container" style="background-color:white;">

      <div *ngIf="tripCodeGUID==''" class="row justify-content-left no-gutters">
        <div *ngIf="userType=='internal' || grantAccessToAccountGrouping" class="col-5 form-group">
          <label style="font-size:small" for="client_select">Client Account</label>
          <select required *ngIf="f" formControlName="client_select" class="form-control form-control-sm dropdown" (change)="getTripCodeList()" style="margin-top:-.35em;width:17em" [ngClass]="{'is-invalid': f.client_select.errors && submitted}">
            <option value="">--Select--</option>
            <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
          </select>
          <div class="invalid-tooltip alert-danger py-0 px-1" style="font-size:small">Select a client.</div>
        </div>
        <div class="col-5 form-group">
          <label style="font-size:small" for="trip_code">Trip Code</label>
          <select required *ngIf="f" formControlName="trip_code" class="form-control form-control-sm dropdown" #tripCode (change)="getGroundStopList()" style="margin-top:-.35em;width:17em" [ngClass]="{'is-invalid': f.trip_code.errors && submitted}">
            <option value="">--Select--</option>
            <option *ngFor="let trip of tripCodeList" [ngValue]="trip.tripCodeGUID">{{trip.tripCode}}</option>
          </select>
          <div class="invalid-tooltip alert-danger py-0 px-1" style="font-size:small">Select a trip.</div>
        </div>
      </div>

      <div class="row justify-content-left no-gutters">
        <div class="col-5 form-group">
          <label for="checkBusinessStops" style="font-size:small">Included Stops</label>
          <div style="margin-top:-0.5em;">
            <mat-select #matBusinessStops placeholder="All" panelClass="custom-mat-select-panel4 placeholder1" multiple style="border: solid lightgray 0.0625em; font-size: small; color:black; border-radius: 0.25em; padding-left:0.25em; height:1.65em;width:17em; background-color: #eaf0fd;">
              <mat-select-trigger>
                <div style="display:flex;">
                  <div *ngFor="let g of selectedGroundStopList; let i=index" style="margin-top:0.4em;margin-left:0em; height:1.35em; padding:0em;display:flex "><div style="margin-top:-0.5em; margin-left:0em; white-space:nowrap;">{{i==0? g.groundStopBriefInfo:', '+g.groundStopBriefInfo}}</div></div>
                </div>
              </mat-select-trigger>
              <div>
                <mat-option *ngFor="let g of groundStopList" [value]="g" (onSelectionChange)="onGroundStopListChange($event, g)" style="height:1.5em; line-height:1.5em !important;background-color: #eaf0fd;">{{g.groundStopBriefInfo}} </mat-option>
              </div>
            </mat-select>
          </div>
        </div>


      </div>
      <div class="row">
        <div class="col">
          <div>
            <section style="display:flex;flex-direction:column">
              <label style="font-size:small">Additional Information</label>
              <mat-checkbox *ngIf="upPII" [(ngModel)]="includeCrewPax" [ngModelOptions]="{standalone: true}" value="true" style="font-size:small">Include Crew/PAX/Passports</mat-checkbox>
              <mat-checkbox *ngIf="upPII && userType=='internal'" [disabled]="!includeCrewPax" [(ngModel)]="includeVisaRequirements" [ngModelOptions]="{standalone: true}" value="true" style="font-size:small">Include Visa Requirements</mat-checkbox>
              <div class="d-inline-flex">
                <mat-checkbox [(ngModel)]="includeServices" [ngModelOptions]="{standalone: true}" value="true" style="font-size:small">Include Services</mat-checkbox>
                <div class="ml-2 mt-2" *ngIf="includeServices">
                  <!--<label for="checkServiceTypeArray" style="font-size:small">Included Services</label>-->
                  <div style="margin-top:-0.5em;">
                    <mat-select #matServiceType placeholder="All" panelClass="custom-mat-select-panel5" [formControl]="checkServiceTypeArray" multiple style="border: solid lightgray 0.0625em; font-size: small; color:black; border-radius: 0.25em; padding-left:0.25em; height:1.65em; background-color: #eaf0fd; min-width:15em; width:20em">
                      <!--<mat-select-trigger>
                        <div style="display:flex;" >
                          <div *ngFor="let a of selectedServiceTypeList; let i=index" style="margin-top:0.4em;margin-left:0em; height:1.35em; padding:0em;display:flex "><div style="margin-top:-0.5em; margin-left:0em; white-space:nowrap">{{i==0? a.serviceTypeDescription:', '+a.serviceTypeDescription}}</div></div>
                        </div>
                      </mat-select-trigger>-->
                      <div (mouseleave)="matServiceType.close()" style="background-color: #eaf0fd;">
                        se
                        <!--<mat-option *ngFor="let a of serviceTypeList" [value]="a" (onSelectionChange)="onServiceTypeChange($event, a)" style="height:1.5em;  line-height:1.5em !important;">{{a.serviceTypeDescription}}</mat-option>-->
                        <div *ngFor="let a of serviceTypeList; let i=index" style="margin-top:0.25em">
                          <div *ngIf="i % 5==0" style="display: flex;background-color: #eaf0fd; ">
                            <div style="font-size: small;width:12em;background-color: #eaf0fd;">
                              <mat-option [value]="a" (onSelectionChange)="onServiceTypeChange($event, a)" style="height:1.5em;  line-height:1.5em !important;background-color: #eaf0fd;" title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription.substr(0,15)}}</mat-option>
                            </div>
                            <div *ngIf="i + 1 < serviceTypeList.length" style="font-size: small;width:12em !important;background-color: #eaf0fd;">
                              <mat-option [value]="serviceTypeList[i+1]" (onSelectionChange)="onServiceTypeChange($event, serviceTypeList[i+1])" style="height:1.5em;  line-height:1.5em !important;" title="{{serviceTypeList[i+1].serviceTypeDescription}}">{{serviceTypeList[i+1].serviceTypeDescription.substr(0,15)}}</mat-option>
                            </div>
                            <div *ngIf="i + 2 < serviceTypeList.length" style="font-size: small;width:12em !important;background-color: #eaf0fd;">
                              <mat-option [value]="serviceTypeList[i+2]" (onSelectionChange)="onServiceTypeChange($event, serviceTypeList[i+2])" style="height:1.5em;  line-height:1.5em !important;" title="{{serviceTypeList[i+2].serviceTypeDescription}}">{{serviceTypeList[i+2].serviceTypeDescription.substr(0,15)}}</mat-option>
                            </div>
                            <div *ngIf="i + 3 < serviceTypeList.length" style="font-size: small;width:12em !important;background-color: #eaf0fd;">
                              <mat-option [value]="serviceTypeList[i+3]" (onSelectionChange)="onServiceTypeChange($event, serviceTypeList[i+3])" style="height:1.5em;  line-height:1.5em !important;" title="{{serviceTypeList[i+3].serviceTypeDescription}}">{{serviceTypeList[i+3].serviceTypeDescription.substr(0,15)}}</mat-option>
                            </div>
                            <div *ngIf="i + 4 < serviceTypeList.length" style="font-size: small;width:12em !important;background-color: #eaf0fd;">
                              <mat-option [value]="serviceTypeList[i+4]" (onSelectionChange)="onServiceTypeChange($event, serviceTypeList[i+4])" style="height:1.5em;  line-height:1.5em !important;" title="{{serviceTypeList[i+4].serviceTypeDescription}}">{{serviceTypeList[i+4].serviceTypeDescription.substr(0,15)}}</mat-option>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-select>
                  </div>
                </div>
              </div>
              <mat-checkbox [(ngModel)]="includeHandling" [ngModelOptions]="{standalone: true}" value="true" style="font-size:small">Include Handling Brief</mat-checkbox>
              <!--<mat-checkbox *ngIf="userType=='internal'" [(ngModel)]="includeCoverPage" [ngModelOptions]="{standalone: true}" value="true" style="font-size:small">Add Cover Page</mat-checkbox>-->
            </section>
          </div>
        </div>
      </div>
      <!--<div *ngIf="includeCoverPage" class="row">
        <div class="col form-group">
          <label style="font-size:small">Cover Note</label>
          <textarea *ngIf="f" formControlName="cover_note" class="form-control inputBox" style="width:25em;height:8em;font-family:Courier New, Courier, monospace;font-size:small"></textarea>
        </div>
        <div class="col">
          <div>
            <div style="margin-top:3em;display:inline-flex">

              <label style="font-size:small">To:</label>
              <input class="form-control form-control-sm inputBox ml-1" formControlName="summary_to" style="width:21em" />

            </div>
            <div>
              <div class="d-inline-flex">
                <label style="font-size:small;margin-top:.25em">Address:</label>

                <mat-radio-group [(ngModel)]="addressType" [ngModelOptions]="{standalone: true}">
                  <mat-radio-button style="transform:scale(.75);font-size:medium;" value="Email">Email</mat-radio-button>
                  <mat-radio-button style="transform:scale(.75);font-size:medium;margin-left:-.75em" value="Fax">Fax</mat-radio-button>
                </mat-radio-group>
              </div>
              <div>
                <input class="form-control form-control-sm inputBox" formControlName="summary_address" style="width:22.5em;" />
              </div>
            </div>
          </div>
        </div>
      </div>-->
      <div class="row">
        <div class="col form-group">
          <label style="font-size:small">Important Notes</label>
          <textarea *ngIf="f" formControlName="important_note" class="form-control inputBox" style="width: 100%; max-width: 50em; height: 10em; font-family: Courier New, Courier, monospace; font-size: small"></textarea>
        </div>
      </div>



    </div>
  </form>
</div>
  </div>
<div align="start" class="d-inline-flex">
  <div>
    <button mat-button class="mt-2 btn-high" (click)="createTripSummary('pdf')" [disabled]="loadingPDF"><span *ngIf="loadingPDF" class="spinner-border spinner-border-sm mr-1"></span>Create PDF Document</button>
    <button *ngIf="!isMobile" mat-button class="mt-2 ml-2 btn-high" (click)="createTripSummary('word')" [disabled]="loadingWord"><span *ngIf="loadingWord" class="spinner-border spinner-border-sm mr-1"></span>Create Word Document</button>
   <button mat-button class="mt-2 ml-2 btn-low" mat-dialog-close>Close</button>
  </div>
  <div *ngIf="errMsg!=''" class="alert alert-danger p-0 mt-2">{{errMsg}}</div>
</div>
