import { PersonCommsAddressModel } from "./person-comms-address.model";

export class PersonModel {
  personGuid: string; // for api.identity use
  personGUID: string; // for api.common use
  personName: string;
  personType: string;
  lastName: string;
  firstName: string;
  selected: boolean;
  middleName: string;
  jobTitle: string;
  dob: string;
  gender: string;
  cityOfBirth: string;
  state_Prov_OfBirth: string;
  countryOfBirthGUID: string;
  nationalityGUID: string;
  countryOfResidenceGUID: string;
  isActive: boolean;
  personRoleGUIDList: string;
  customerGUID: string;
  modifiedBy: string;
  modifiedDate: string;
  countryNameOfBirth: string;
  nationalityName: string;
  countryNameOfResidence: string;
  personPhotoName: string;
  personPhotoPath: string;
  filterBy: string;
  textSearch: string;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
  personCommAddressList: string;
  personRoleList: string;
  canLogin: string;
  personClassGUIDList: string;
  personClassList: string;
  actionName: string;
  selectedPersonClassGUID: string;
  commAddressList: PersonCommsAddressModel[];
  vendorGUID: string;
  includeInBrief: boolean;
  dataEntryStatusID: number;
  dataEntryStatusDescription: string;
  eesetiasOutOfScope: boolean;
  eesetiasOutOfScopeExpirationDate: string;
  tripCodeGUID: string;
  syncCount: number;
  filterDataEntryStatus: boolean;
  hasActiveTrip: boolean;
  activeTripsDetails: string;
  sortOrderNo: number;
}
