import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerModel } from '../models/customer.model';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DOCUMENT } from '@angular/common';
import { GroundStopTypeModel } from '../models/ground-stop-type.model';
import { GroundStopService } from '../services/ground-stop.service';
import { TripModel } from '../models/trip.model';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { GlobalConstant } from '../common-utility/global-constant';
import { GroundStopCreatorCityPairDialogComponent } from '../ground-stops/ground-stop-creator-citypair-dialog.component';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { UserModel } from '../models/user.model';
import { GroundStopCreatorClientDialogComponent } from './ground-stop-creator-client-dialog.component';
import { DialogService } from '../services/dialog.service';
import { TripPersonlistEditorClientDialogComponent } from '../trip-legs-client/trip-personlist-editor-client-dialog.component';
import { DisplayDialogComponent } from '../ckeditor5/display-dialog.component';


@Component({
  selector: 'app-trip-code-tactical-viewlist-client',
  templateUrl: './trip-code-tactical-viewlist-client.component.html',
  styleUrls: ['./trip-code-tactical-viewlist-client.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ],
  //encapsulation: ViewEncapsulation.None
})

export class TripCodeTacticalViewlistClientComponent implements OnInit, OnChanges {
  @Input() request: TripModel;
  @Input() reloadChildPage: boolean;
  @Input() selectedTabName: string;
  @Output() public sendDataToParent = new EventEmitter();

  trip_code_viewlist_client_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  
  //userType: string;
  showSpin: boolean = false;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;

  recordList: TripModel[];

  displayedColumn: string[] = ['tripcode', 'registration', 'crew', 'pax', 'dateRange', 'route', 'clientRef', 'action'];
  scrHeight: number;
  grantAccessSubmitTripsandRequestQuotes: boolean;
  upPII: boolean = false;
  isActiveClient: boolean;
  isDialog: boolean = false;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _customerService: CustomerService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopClientService: GroundStopClientService, private readonly _dialogService: DialogService, @Optional() @Inject(MAT_DIALOG_DATA) private _data: any, @Optional() private readonly _dialogRef: MatDialogRef<TripCodeTacticalViewlistClientComponent>
  ) {
    if (_data) {
      this.isDialog = true;
    }
  }

  getAllData(): Observable<any[]> {
    let getClientResponse;
    getClientResponse = this._customerService.getCustomerBycId('');
    return forkJoin([getClientResponse]);
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.reloadChildPage || this.selectedTabName != 'tripcode_tactical') {
      return;
    }

    this.trip_code_viewlist_client_form = this._formBuilder.group({

    });
    this._authService.updateAccessTime();
    this.grantAccessSubmitTripsandRequestQuotes = false;
    this.upPII = false;
    if (localStorage.getItem('up.submitTrip') == 'true'){
      this.grantAccessSubmitTripsandRequestQuotes = true;
    }
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }
   
    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.isActiveClient = false;
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.scrHeight = window.innerHeight;
    if (this.scrHeight < 900) {
      this.pageSize = 10;
    }
    else {
      this.pageSize = 15;
    }
    this.request.customerGUID = "";
    this.request.pageSize = 0;// this.pageSize;
    this.request.pageIndex = this.currentPageIndex;
    this.request.totalRecords = this.totalRecords;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          if (responses[0].model!=null) {
            let c = new CustomerModel();
            c = responses[0].model;
            if (c.customerTypeId == 1)
              this.isActiveClient = true;

              this.initControls();
              this.getSearchResults();

            }            
          }
        }     
    });    

  }


  initControls() {
    this.trip_code_viewlist_client_form = this._formBuilder.group({

    });
  }

  get f() { return this.trip_code_viewlist_client_form.controls; }

  getSearchResults() {
    this.showSpin = true;
    this.totalRecordsText = "";
    this._groundStopClientService.getTripListForTripCodeViewClientByConditions<ResponseModel<TripModel[]>>(this.request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecords = this.recordList[0].totalRecords;
            if (this.totalRecords==1)
              this.totalRecordsText = this.totalRecords.toString() + ' record found';
            else
              this.totalRecordsText = this.totalRecords.toString() + ' records found';
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)
          }

        }
        else {
          this.totalRecordsText = "No record found";
        }
        this.showSpin = false;
        this.sendDataToParent.emit({ totalRecordsText: this.totalRecordsText });
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    })

  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.totalRecords = 0;
      this.totalRecordsText = "";
      this.totalPageNumber = 0;
      this.pageSize = e.pageSize;
      this.request.pageSize = this.pageSize;
      this.request.totalRecords = this.totalRecords;
    }
    this.request.pageIndex = this.currentPageIndex;
    this.getSearchResults();

  }

  clickAdd(event: any, p: TripModel) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, tripType: ''};

    const dialogRef = this._dialog.open(GroundStopCreatorClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
      
        let trip: TripModel;
        trip = new TripModel();
        trip.tripCodeGUID = result.newId;
        trip.aircraftGUID = result.aircraftGUID;
        trip.customerGUID = result.customerGUID;
        this.openTripPersonListEditorClient(trip.tripCodeGUID, trip.aircraftGUID, trip.customerGUID);
        //this.getSearchResults();
      }

    });
  }

  openTripPersonListEditorClient(tripCodeGUID: string, aircraftGUID: string, customerGUID: string) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, tripCodeGUID: tripCodeGUID, aircraftGUID: aircraftGUID, customerGUID: customerGUID };

    const dialogRef = this._dialog.open(TripPersonlistEditorClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {

        let trip: TripModel;
        trip = new TripModel();
        trip.tripCodeGUID = result.newId;
        trip.aircraftGUID = result.aircraftGUID;
        trip.customerGUID = result.customerGUID;
        this.clickOpen(null, trip);
        //this.getSearchResults();
      }

    });

  }

  clickOpen(event: any, p: TripModel) {
    // if (this.isDialog) { 
    //   this._dialogRef.close();
    // }
    //   let navigationExtras: NavigationExtras = {
    //     queryParams: {
    //       "tripId": p.tripCodeGUID,
    //       "aircraftId": p.aircraftGUID,
    //       //"customerId": p.customerGUID,
    //       "pf": "",
    //       tType: p.tripCodeType,
    //       v: this._authService.getCurrentTimeNumber()
    //     }
    //   };
    // this._router.navigate(['/ground-stops-client/trip-details-client'], navigationExtras);
    
    window.open('/ground-stops-client/trip-details-client?tripId=' + p.tripCodeGUID + '&aircraftId=' + p.aircraftGUID + '&pf=&tType=&v=' + this._authService.getCurrentTimeNumber(), '_blank');

  }


  // openTrip(p: TripModel) {
  //   window.open('/ground-stops-client/trip-details-client?tripId=' + p.tripCodeGUID + '&aircraftId=' + p.aircraftGUID + '&pf=&tType=&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  // }

  viewNotes(trip: TripModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { dialogTitle: "Client Instructions", displayText: trip.clientInstructions };

    this._dialog.open(DisplayDialogComponent, dialogConfig);
  }

}
