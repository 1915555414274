<div *ngIf="ua==1 && ut=='i'">
  <div style="height:21em">
    <form *ngIf="user_permission_restrict_form" [formGroup]="user_permission_restrict_form" (ngSubmit)="clickSave()">
       <div style="font-size: small">
         <div style="height: 3em; background-color:#f8f8f8;margin-left:-1.25em; margin-right: -1.25em; position: relative"><div style="margin-left:0.5em; position:absolute; bottom:0.25em; font-weight: bold">Manage Permissions</div></div>
         <div style="display: flex; margin-left:-1em; margin-top:1em">
           <div class="d-flex flex-column" style="font-size: medium; width:27em;  ">
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToPIIData" formControlName="grantAccessToPIIData" (change)="restrictChange($event)">Grant Access To PII Data</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToManageManifestProfiles" formControlName="grantAccessToManageManifestProfiles" (change)="restrictChange($event)">Grant Access To Manage Manifest Profiles</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessCompanyProfiles" formControlName="grantAccessCompanyProfiles" (change)="restrictChange($event)">Grant Access Client Profiles</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessAircraftProfiles" formControlName="grantAccessAircraftProfiles" (change)="restrictChange($event)">Grant Access Aircraft Profiles</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToMessages" formControlName="grantAccessToMessages" (change)="restrictChange($event)">Grant Access To Messages</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToLockedTrips" formControlName="grantAccessToLockedTrips" (change)="restrictChange($event)">Grant Access To Locked Trips</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToFuel" formControlName="grantAccessToFuel" (change)="restrictChange($event)">Grant Access To Fuel Management</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToAirportDocuments" formControlName="grantAccessToAirportDocuments" (change)="restrictChange($event)">Grant Access To Manage Airport & Country Profiles </mat-slide-toggle>
           </div>
           <div class="d-flex flex-column" style="width:22em; font-size: medium">
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToInvoices" formControlName="grantAccessToInvoices" (change)="restrictChange($event)">Grant Access To Invoices</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToCompanyBusinessRules" formControlName="grantAccessToCompanyBusinessRules" (change)="restrictChange($event)">Grant Access To Company Business Rules</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToVendorProfiles" formControlName="grantAccessToVendorProfiles" (change)="restrictChange($event)">Grant Access To Vendor Profiles</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessCreateTripAlerts" formControlName="grantAccessCreateTripAlerts" (change)="restrictChange($event)">Grant Access To Trip Alerts</mat-slide-toggle>
             <!--<mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToManageCompanyEvents" formControlName="grantAccessToManageCompanyEvents" (change)="restrictChange($event)">Grant Access To Manage Company Events</mat-slide-toggle>-->
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToHeadlineNews" formControlName="grantAccessToHeadlineNews" (change)="restrictChange($event)">Grant Access To Headline News</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToManageAccountGrouping" formControlName="grantAccessToManageAccountGrouping" (change)="restrictChange($event)">Grant Access To Manage Account Grouping</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToAPISCredentials" formControlName="grantAccessToAPISCredentials" (change)="restrictChange($event)">Grant Access To Manage APIS Credentials</mat-slide-toggle>
           </div>
           <div class="d-flex flex-column" style="min-width:22em; font-size: medium">
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToTripCloseout" formControlName="grantAccessToTripCloseout" (change)="restrictChange($event)">Grant Access To Trip Closeout</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessSubmitToBilling" formControlName="grantAccessSubmitToBilling" (change)="restrictChange($event)">Grant Access To Submit to Billing</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToVendorProfiles" formControlName="grantAccessToMessageTemplates" (change)="restrictChange($event)">Grant Access To Message Templates</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessCreateTripAlerts" formControlName="grantAccessToHelpGuides" (change)="restrictChange($event)">Grant Access To Help Guides</mat-slide-toggle>
             <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToManageCompanyEvents" formControlName="grantAccessToAccountingDashboard" (change)="restrictChange($event)">Grant Access To Accounting Dashboard</mat-slide-toggle>
           </div>
         
         </div>
       </div>
           
    </form>
  </div>
  <div style="padding-left: 0em; padding-bottom:0.5em; display:flex; ">
    <div style="margin-right:1em">
      <button mat-button class="btn-high" (click)="clickSave()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Save
      </button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white">&nbsp;Close&nbsp;</button>
    </div>
    <div *ngIf="showSuccessMsg" style="margin-top:-0.25em">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
    <div style="margin-left:0em; margin-top:0em;">
      <div *ngIf="errMsg!=''" style="background-color: #f9d2de; padding-left: 0.25em; width: 18em; font-size: small">{{errMsg}}</div>
    </div>
  </div>
</div>
  





