import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, ViewChildren, QueryList, ViewContainerRef, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { TripModel } from '../models/trip.model';
import { GroundStopTypeModel } from '../models/ground-stop-type.model';
import { TripTaskStatusModel } from '../models/trip-task-status.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { AccountingStatusModel } from '../models/accounting-status.model';
import { TripRevisionModel } from '../models/trip-revision.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { IconLegendDialogComponent } from '../common-utility/icon-legend-dialog.component';
import { DeliveryCommsDialogComponent } from '../ground-stop-advanced/delivery-comms-dialog.component';
import { GroundStopModel } from '../models/ground-stop.model';
import { TripAuditDialogComponent } from '../ground-stops/trip-audit-dialog.component';
import { AircraftSelectionDialogComponent } from '../ground-stops/aircraft-selection-dialog.component';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { MatLegacyRow as MatRow } from '@angular/material/legacy-table';
import { TripClientInstructionEditDialogComponent } from './trip-client-instruction-edit-dialog.component';
import { EmailClientDialogComponent } from '../messages-client/email-client-dialog.component';
import { TripSummaryReportDialogComponent } from '../ground-stops/trip-summary-report-dialog.component';
import { TripPersonlistEditorClientDialogComponent } from '../trip-legs-client/trip-personlist-editor-client-dialog.component';
import { TripDocumentSelectionDialogComponent } from '../messages/trip-document-selection-dialog.component';
import { TripMessagesClientComponent } from '../messages-client/trip-messages-client.component';
import { TripPlannerComponent } from '../trip-planner/trip-planner.component';
import { SchedulesClientComponent } from '../ground-stops-client/schedules-client.component';
import { CompanyProfileClientComponent } from '../customers/company-profile-client.component';
import { AircraftProfileComponent } from '../aircraft/aircraft-profile.component';

@Component({
  selector: 'app-trip-details-client',
  templateUrl: './trip-details-client.component.html',
  styleUrls: ['./trip-details-client.component.css']
})

export class TripDetailsClientComponent implements OnInit {
  @ViewChildren('tripCodeRefs', { read: ViewContainerRef }) tripCodeRefs: QueryList<ViewContainerRef>;

  aircraftGUID: string;
  tripCodeType: string;
  selectedTabName: string;
  trip_details_client_form: UntypedFormGroup;
  errGSMsg: string;
  errTSMsg: string;
  errMsg: string;
  msg: string;
  clientName: string;
  registration: string;
  acType: string;
  aircraftInfo: string;
  tripCodeList: TripModel[];
  selectedTripCodeGUID: string;
  selectedTripCode: string;
  taskStatusList: TripTaskStatusModel[];
  selectedTaskStatusList: TripTaskStatusModel[];
  reloadChildPage: boolean;
  request: TripModel;
  showSpin: boolean = true;
  pageFrom: string;
  lockdownTrip: boolean = false;
  //checkGSTypeArray: FormControl; // for mat-select
  checkTaskStatusArray: UntypedFormControl; // for mat-select
  checkServiceTypeArray: UntypedFormControl; // for mat-select
  refreshChildColumn: string;

  v: number = this._authService.getCurrentTimeNumber();
  warningMsg: string = "Trip is locked. No edits allowed";
  homeBaseAirport: string;
  auditHr: number;
  auditHrText: string;
  usernameList: TripRevisionModel[];
  auditHourList: Array<number>;
  auditIndex: number;
  orgServiceTypeList: ServiceTypeModel[];
  //serviceTypeList: ServiceTypeModel[];
  //selectedServiceTypeList: ServiceTypeModel[];

  showMoreUsernameList: boolean = false;
  bias: number;
  tas: number;
  maxRange: string;
  wingspan: string;
  tripStarted: boolean;
  selectedGroundStopList: GroundStopModel[] = [];
  acTooltip: string;
  grantAccessCompanyProfiles: boolean=false;
  grantAccessAircraftProfiles: boolean = false;
  upPII: boolean = false;
  clientInstructions: string;
  //selectedServiceTypeCountText: string = "";
  selectedTaskStatusCountText: string = "";
  displayedColumnTripCode: string[] = ['select', 'tripcode', 'route', 'startDate', 'endDate'];
  showTripCodeList: boolean = false;
  showTaskStatusList: boolean = false;
  flightPlanProviderName: string = "";
  uplinkVendor: string = "";
  customerGUID: string = "";
  hidePastStop: boolean;
  //includeDisabledTask: boolean;
  includeActiveStopOnly: boolean;
  grantAccessToAccountGrouping: boolean = false;
  customerTripID: string = "";
  showWholeRoute: boolean = false;
  childTableWidth: number = 90.5;
  activeGroundStopList: GroundStopModel[] = [];
  allGroundStopList: GroundStopModel[] = [];
  hasCancelledStop: boolean = false;
  hidePastlegs: boolean = false;
  acCustomer: AircraftTripModel;
  isDialog: boolean = false;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopService: GroundStopService, private readonly _groundStopClientService: GroundStopClientService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialogService: DialogService, @Optional() @Inject(MAT_DIALOG_DATA) private _data: any, @Optional() private readonly _dialogRef: MatDialogRef<TripDetailsClientComponent>) {
    if (_data) {
      this.isDialog = true;
    }
  }

  getAllData(): Observable<any[]> {
    let request = new AircraftTripModel();
    request.aircraftGUID = this.aircraftGUID;   
    request.tripCodeType = this.tripCodeType;
    request.startDateText = localStorage.getItem('trip.sd');
    request.endDateText = localStorage.getItem('trip.ed');
    request.tripCodeGUID = this.selectedTripCodeGUID;
    request.tripCodeGUID = this.selectedTripCodeGUID;
    let getACCustomerResponse = this._groundStopService.getAircraftCustomerByByacIdcId(request);
    let getTripResponse;
    if (this.selectedTripCodeGUID != "" && this.selectedTripCodeGUID != null && this.selectedTripCodeGUID!=undefined)
      getTripResponse = this._groundStopClientService.getTripBytId(request);
    else
      getTripResponse = this._groundStopClientService.getTripCodeListForClientByacIdcId(request);
    let getTaskStatusResponse = this._groundStopService.getTripTaskStatusList();
    //let getService = this._groundStopAdvancedService.getServiceClass_ServiceTypeList(1);
    return forkJoin([getACCustomerResponse, getTripResponse, getTaskStatusResponse]);

  }

  ngOnInit() {
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");
     
      return;
    }
    else {
      this.upPII = true;
    }
    if (localStorage.getItem('up.cGrp') == 'true') {
      this.grantAccessToAccountGrouping = true;
    }

    this.aircraftGUID = this._route.snapshot.paramMap.get('aircraftId');
    this.selectedTripCodeGUID = this._route.snapshot.paramMap.get('tripId');
    this.tripCodeType = this._route.snapshot.paramMap.get('tType');
    if (this.aircraftGUID == null || this.aircraftGUID == undefined || this.aircraftGUID == "") {
      this._route.queryParams.subscribe(params => {
        this.aircraftGUID = params["aircraftId"];
       
        this.selectedTripCodeGUID = params["tripId"];
        this.pageFrom = params["pf"];
        this.tripCodeType = params["tType"];
      });
    }
   
    this._authService.updateAccessTime();
    this.grantAccessAircraftProfiles = false;
    this.grantAccessCompanyProfiles = false;
    if (localStorage.getItem('up.acPro') == 'true') {
      this.grantAccessAircraftProfiles = true;
    }
    if (localStorage.getItem('up.cPro') == 'true') {
      this.grantAccessCompanyProfiles = true;
    }
    this.errGSMsg = "";
    this.errTSMsg = "";
    this.errMsg = "";
    this.msg = "";
    this.clientName = "";
    this.registration = "";
    this.acType = "";
    this.aircraftInfo = "";
    this.tripCodeList = [];
    
    //this.selectedGSTypeList = [];
    this.taskStatusList = [];
    this.selectedTaskStatusList = [];
    
    this.orgServiceTypeList = [];
    //this.serviceTypeList = [];
    //this.selectedServiceTypeList = [];
    this.selectedGroundStopList = [];
    
    this.reloadChildPage = false;
    this.selectedTabName = "groundStop"; // "leg";
    this.homeBaseAirport = "";
    this.lockdownTrip = false;
    this.request = new TripModel();

    this.usernameList = [];
    this.auditHourList = [1, 8, 12, 24, 36, 48, 60, 72, 0];
    this.auditIndex = 0;
    this.auditHr = this.auditHourList[this.auditIndex];
    this.auditHrText = this.auditHr.toString() + " hr";
    this.refreshChildColumn = "";
    this.showMoreUsernameList = false;
    this.bias = 0;
    this.tas = 0;
    this.maxRange = '';
    this.wingspan = ''
    this.tripStarted = false;
    this.acTooltip = "";
    this.clientInstructions = "";
    this.selectedTripCode = "";
    this.hidePastStop = false;
    this.hidePastlegs = false;
    this.includeActiveStopOnly = true;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let ac_customer = new AircraftTripModel();
          ac_customer = responses[0].model;
          this.acCustomer = ac_customer;
          this.clientName = ac_customer.customerName;
          this.registration = ac_customer.registration;
          this.acType = ac_customer.aircraftManufacturer + " " + ac_customer.aircraftTypeDesignator;// ac_customer.aircraftModel;
          this.aircraftInfo = ac_customer.aircraftInfo;
          this.homeBaseAirport = ac_customer.homeBaseAirport;
          this.bias = ac_customer.bias;
          this.tas = ac_customer.tas;
          this.wingspan = ac_customer.wingspan;
          this.maxRange = ac_customer.maxRange;
          if (this.bias > 0) {
            if (this.acTooltip != "") {
              this.acTooltip += " ";
            }
            this.acTooltip += "Bias: " + this.bias.toString();
          }
          if (this.tas > 0) {
            if (this.acTooltip != "") {
              this.acTooltip += " ";
            }
            this.acTooltip += "TAS: " + this.tas.toString();
          }
          if (this.wingspan != "") {
            if (this.acTooltip != "") {
              this.acTooltip += " ";
            }
            this.acTooltip += "Wingspan: " + this.wingspan;
          }
          if (this.maxRange != "") {
            if (this.acTooltip != "") {
              this.acTooltip += " ";
            }
            this.acTooltip += "Max Range: " + this.maxRange + " nm";
          }
          if (ac_customer.aircraftLength != "") {
            if (this.acTooltip != "") {
              this.acTooltip += " ";
            }
            this.acTooltip += "Length: " + ac_customer.aircraftLength;
            this.flightPlanProviderName = ac_customer.flightPlanProviderName;
            this.uplinkVendor = ac_customer.uplinkVendor;

          }
          this.customerGUID = ac_customer.customerGUID.toLowerCase();
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }


      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {        
          if (this.selectedTripCodeGUID == null || this.selectedTripCodeGUID == "" || this.selectedTripCodeGUID == undefined) {
            let tl : TripModel[];
            tl = responses[1].model;
            if (tl.length > 0) {
              this.selectedTripCodeGUID = tl[0].tripCodeGUID;
              this.allGroundStopList = tl[0].groundStopList;
              this.lockdownTrip = tl[0].lockdownTrip;
              this.tripStarted = tl[0].tripStarted;
              this.clientInstructions = tl[0].clientInstructions;
              this.selectedTripCode = tl[0].tripCode;
              this.tripCodeType = tl[0].tripCodeType;
              this.customerTripID = tl[0].customerTripID;
              this.activeGroundStopList = tl[0].activeGroundStopList;
              this.hasCancelledStop = tl[0].hasCancelledStop;
            }
          }
          else {
            let t = new TripModel();
            t = responses[1].model;
            this.selectedTripCodeGUID = t.tripCodeGUID;
            this.allGroundStopList = t.groundStopList;
            this.lockdownTrip = t.lockdownTrip;
            this.tripStarted = t.tripStarted;
            this.clientInstructions = t.clientInstructions;
            this.selectedTripCode = t.tripCode;
            this.tripCodeType = t.tripCodeType;
            this.customerTripID = t.customerTripID;
            this.activeGroundStopList = t.activeGroundStopList;
            this.hasCancelledStop = t.hasCancelledStop;
          }
          if (this.includeActiveStopOnly) {
            this.selectedGroundStopList = this.activeGroundStopList;
          }
          else {
            this.selectedGroundStopList = this.allGroundStopList;
          }
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          //this.taskStatusList = responses[2].model;
          let t = new TripTaskStatusModel();
          t.taskStatusGUID = "all";
          t.taskStatusDescription = "Select All"; 
          t.selected = false;
          this.taskStatusList.push(t);
          responses[2].model.forEach(x => {
            t = new TripTaskStatusModel();
            t.taskStatusGUID = x.taskStatusGUID;
            t.taskStatusDescription = x.taskStatusDescription;
            t.selected = false;
            if (this.selectedTaskStatusList.length > 0) {
              let f: TripTaskStatusModel[];
              f = this.selectedTaskStatusList.filter(y => y.taskStatusGUID.toLowerCase() == x.taskStatusGUID.toLowerCase());
              if (f.length > 0) {
                t.selected = true;
              }
            }
            this.taskStatusList.push(t);
          });
          this.setTaskStatusList();
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.showSpin = false;

      this.openChildPage('');
   
      //this.getUsernameList().subscribe(res1 => {
      //});
       
    });


  }

  initControls() {
    this.trip_details_client_form = this._formBuilder.group({
      trip_code_select: [this.selectedTripCodeGUID],
      //includeDisabledTask: [false] //,
      includeActiveStopOnly: [this.includeActiveStopOnly],
      hidePastStop: [this.hidePastStop]
    });

    
  }

  get f() { return this.trip_details_client_form.controls; }

  //setGSTypeList() {
  //  this.selectedGSTypeList = this.gsTypeList.filter(u => u.selected == true);
  //}

  //setServiceTypeList(selected: boolean) {   
  //  this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);
  //}

  // trip code list
  clickTripCode() {
    this.getTripCodeList();
  }

  getTripCodeList() {
    var root = this;
    this.showTripCodeList = true;
    this.showTaskStatusList = false;
    let request = new AircraftTripModel();
    request.aircraftGUID = this.aircraftGUID;
    request.tripCodeType = this.tripCodeType;
    request.startDateText = localStorage.getItem('trip.sd');
    request.endDateText = localStorage.getItem('trip.ed');
    request.customerGUID = "";
    this._groundStopClientService.getTripCodeListForClientByacIdcId<ResponseModel<TripModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.tripCodeList = response.model;
          if (this.tripCodeList.length > 0) {
            this.lockdownTrip = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].lockdownTrip;
            this.tripStarted = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].tripStarted;
            this.selectedGroundStopList = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].groundStopList;
            this.clientInstructions = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].clientInstructions;
            this.selectedTripCode = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].tripCode;
            this.tripCodeType = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].tripCodeType;
            this.customerTripID = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].customerTripID;
            this.activeGroundStopList = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].activeGroundStopList;
            this.hasCancelledStop = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].hasCancelledStop;
            if (this.includeActiveStopOnly) {
              this.selectedGroundStopList = this.activeGroundStopList;
            }
            else {
              this.selectedGroundStopList = this.allGroundStopList;
            }
            let i = this.tripCodeList.findIndex(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase());
            this.tripCodeList[i].selected = true;

            setTimeout(function () {
              var selectedItem = root.tripCodeRefs.find(r => r.element.nativeElement.getAttribute('tripCode') === root.selectedTripCode);
              selectedItem.element.nativeElement.scrollIntoView(false, {behavior: 'instant'});
            }, 500);
          }

        }
        //else {
        //  if (response.code == "401") {
        //    this._authService.signOut();
        //  }
        //}
      }

    })
  }

  tripCodeOnChange(e: any, item: TripModel) {
    this.tripCodeList.forEach(x => {
      x.selected = false;
    });
    item.selected = true;
  }

  clickDoneTripCode() {
    let i: number = this.tripCodeList.findIndex(x => x.selected == true);
    let item=new TripModel();
    item = this.tripCodeList[i];
    this.selectedTripCodeGUID = item.tripCodeGUID;
    this.selectedTripCode = item.tripCode;
    this.lockdownTrip = item.lockdownTrip;
    this.tripStarted = item.tripStarted;
    this.allGroundStopList = item.groundStopList;
    this.clientInstructions = item.clientInstructions;
    this.tripCodeType = item.tripCodeType;
    this.customerTripID = item.customerTripID;
    this.activeGroundStopList = item.activeGroundStopList;
    this.hasCancelledStop = item.hasCancelledStop;
    this.includeActiveStopOnly = true;
    this.f.includeActiveStopOnly.setValue(this.includeActiveStopOnly);
    if (this.includeActiveStopOnly) {
      this.selectedGroundStopList = this.activeGroundStopList;
    }
    else {
      this.selectedGroundStopList = this.allGroundStopList;
    }
    this.showTripCodeList = false;    
    this.clickSubmit();
  }

  clickCloseTripCode() {
    this.showTripCodeList = false;
  }
  // trip code list end

  //clickService() {

  //}

  // task status
  clickTaskStatus() {
    this.showTripCodeList = false;
    this.showTaskStatusList = true;    
  }

  onTaskStatusChange(e: any, item: TripTaskStatusModel) {
    if (item.taskStatusGUID == 'all') {
      this.taskStatusList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.taskStatusList.forEach(x => {
        if (item.taskStatusGUID.toLowerCase() == x.taskStatusGUID.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setTaskStatusList();
    // for mat-select end

    this.reloadChildPage = false;

  }

  setTaskStatusList() { 
    let excludeallaclist: TripTaskStatusModel[]
    excludeallaclist = this.taskStatusList.filter(x => x.taskStatusGUID != 'all' && x.selected == false);
    if (excludeallaclist.length == 0) {
      this.taskStatusList.find(x => x.taskStatusGUID == 'all').selected = true;
    }
    else {
      this.taskStatusList.find(x => x.taskStatusGUID == 'all').selected = false;
    }
    this.selectedTaskStatusList = this.taskStatusList.filter(u => u.selected == true);
    this.selectedTaskStatusCountText = "";
    if (this.taskStatusList.length > 0) {
      if (this.selectedTaskStatusList.length == this.taskStatusList.length) {
        this.selectedTaskStatusCountText = ": All";
      }
      else {
        if (this.selectedTaskStatusList.length > 0)
          this.selectedTaskStatusCountText = ": " + this.selectedTaskStatusList.length.toString();
      }
    }

  }

  clickDoneTaskStatus() {
    this.showTaskStatusList = false;
    this.clickSubmit();
  }

  clickResetTaskStatus() {
    this.taskStatusList.forEach(x => {
      x.selected = false;
    });
    this.setTaskStatusList();
  }

  // task status end


  //onServiceTypeChange(e: any, item: ServiceTypeModel) {
  //  item.selected = e.source.selected;   
  //  this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);
  //}


  auditSlideChange(e: any) {
    this._authService.updateAccessTime();
    this.msg = "";
    this.auditIndex = e.target.value;
    this.auditHr = this.auditHourList[this.auditIndex];
    switch (this.auditHr) {
      case 1:
        this.auditHrText = this.auditHr.toString() + " hr";
        break;
      case 0:
        this.auditHrText = " All Time";
        break;
      default:
        this.auditHrText = this.auditHr.toString() + " hrs";
    }

    this.getUsernameList().subscribe(res1 => {
    });

    this.refreshChildColumn = "callLog";
    this.openChildPage(this.refreshChildColumn);

  }

  //clickAudit(username: string) {
  //  this._authService.updateAccessTime();
  //  this.getUsernameList().subscribe(res1 => {
  //    if (res1 != null || res1 != undefined) {
  //      if (res1 == false) {
  //        switch (this.auditHr) {
  //          case 1:
  //            this.msg = "There is no trip audit record within past " + this.auditHr.toString() + " hour";
  //            break;
  //          case 0:
  //            this.msg = "There is no trip audit record within past time.";
  //            break;
  //          default:
  //            this.msg = "There is no trip audit record within past " + this.auditHr.toString() + " hours";
  //        }
  //        return;
  //      }
  //      else {

  //        this.openTripAuditDialog(username);
  //      }

  //    }

  //  });


  //}

  //openTripAuditDialog(username) {
   
  //  let s = "";
  //  const dialogConfig = new MatDialogConfig();
  //  dialogConfig.autoFocus = true;
  //  //dialogConfig.width = "65em";//"1000px";
  //  //dialogConfig.height = "28em";//"500px";
  //  dialogConfig.disableClose = true;

  //  dialogConfig.data = {
  //    dialogTitle: s, aircraftGUID: this.aircraftGUID, tripCodeGUID: this.selectedTripCodeGUID, pastHours: this.auditHr,
  //    userName: username, v: this._authService.getCurrentTimeNumber()
  //  };

  //  const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
  //  dialogRef.afterClosed().subscribe(result => {


  //  });
  //}

  clickContact() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.selectedTripCodeGUID, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(DeliveryCommsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  clickRefreshAudit() {
    this.msg = "";
    //this.getUsernameList();
    this.getUsernameList().subscribe(res1 => {

    });
  }


  getUsernameList(): Observable<boolean> {
    this.msg = "";
    this._authService.updateAccessTime();

    return new Observable<boolean>(ob => {
      //if (this.userType != 'internal') {
      //  ob.next(false);
      //}
      //else {
        let obj = new TripRevisionModel();
        obj.aircraftGUID = this.aircraftGUID;
        obj.customerGUID = "";//this.customerGUID;
        obj.tripCodeGUID = this.selectedTripCodeGUID;
        obj.pastHours = Math.round(this.auditHr);//parseInt(this.auditHr);
        this._groundStopService.getTripRevisionUsernameListBytIdHours<ResponseModel<TripRevisionModel[]>>(obj).subscribe(response => {
          if (response != null && response.code == "200") {
            this.usernameList = [];
            if (response.model.length > 0) {
              this.usernameList = response.model;
              if (this.usernameList.length < 10) {
                this.showMoreUsernameList = false;
              }
              ob.next(true);
            }
            else {
              ob.next(false);
            }
          }
          else {
            if (response.code == "401") {
              //this.errMsg = response.message
              this._authService.signOut();
            }
            ob.next(false);
          }

        })
      //}

    });

  }

  includeActiveStopOnlyChange(e: any) {
    this.includeActiveStopOnly = e.checked;
    this.selectedGroundStopList = [];
    if (e.checked) {
      this.selectedGroundStopList = this.activeGroundStopList;
    }
    else {
      this.selectedGroundStopList = this.allGroundStopList;
    }
    this.clickSubmit();
  }

  // hidePastStopChange(e: any) {
  //   this.hidePastStop = e.checked;
  //   this.reloadChildPage = false;
  // }

  hidePastLegsChange(e: any) {
    this.hidePastlegs = e.checked;
    this.reloadChildPage = true;
      
    this.clickSubmit();
  }


  clickSubmit() {
    
    this.openChildPage('');
  }

  openChildPage(refreshColumn: string) {
    this.errGSMsg = "";
    this.errTSMsg = "";
    this.msg = "";
    //this.showSpin = true;
    this._authService.updateAccessTime();
    this.request = new TripModel();
    this.request.customerGUID = "";
    //if (this.userType == "internal") {
    //  this.request.customerGUID = this.customerGUID;
    //}
    this.request.aircraftGUID = this.aircraftGUID;
    this.request.tripCodeGUID = "";// this.f.trip_code_select.value;
    if (this.selectedTripCodeGUID != null && this.selectedTripCodeGUID != undefined && this.selectedTripCodeGUID != "") {
      this.request.tripCodeGUID = this.selectedTripCodeGUID;
    }
    this.request.groundStopTypeGUIDList = "";// Array.prototype.map.call(this.selectedGSTypeList, s => s.groundStopTypeGUID).toString();
    if (this.selectedTaskStatusList.length == this.taskStatusList.length) {
      this.request.tripTaskStatusGUIDList = "";
    }
    else {
      this.request.tripTaskStatusGUIDList = Array.prototype.map.call(this.selectedTaskStatusList, s => s.taskStatusGUID).toString();
    }

    this.request.serviceTypeIDList = "";//Array.prototype.map.call(this.selectedServiceTypeList, s => s.serviceTypeID).toString();
    //this.request.tripAlertFilter = "";//this.f.trip_alert_filter.value;
    //this.request.accountingStatusGUIDList = Array.prototype.map.call(this.selectedAccountingStatusList, s => s.accountingStatusGUID).toString();
    this.request.pastHours = this.auditHr;
    this.request.includeDisabledTask = false;// this.f.includeDisabledTask.value;
    this.refreshChildColumn = refreshColumn;

    if (refreshColumn == "") {
      this.reloadChildPage = true;
    }
    else {
      this.reloadChildPage = false;
    }
    this.request.includeActiveStopOnly = this.f.includeActiveStopOnly.value;
    this.request.hidePastStop = this.hidePastlegs;
  }

  clickOpenTripSearch() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        pf: "tripdetail",
        v: this._authService.getCurrentTimeNumber()
      }
    };
    // this._router.navigate(['/schedules/schedules-dashboard-client'], navigationExtras);
    this.openSchedulesClientComponent();
  }

  openSchedulesClientComponent() {
    if (this.isDialog) {
      // Close the current dialog
      this._dialogRef.close();

      // Proceed after dialog closes
      setTimeout(() => {
        const config = new MatDialogConfig();
        config.panelClass = "custom-dialog-container3";
        config.height = "23.3em";
        config.width = "52.6em";
        config.restoreFocus = false;
        config.hasBackdrop = false;
        config.data = {
          isDialog: true,
          v: this._authService.getCurrentTimeNumber()
        };
        config.position = { left: "20px", top: "138px" };
        this._dialog.open(SchedulesClientComponent, config);
      }, 200); // Delay ensures the current dialog is fully closed
    } else {
      // If not in a dialog, open directly
      const config = new MatDialogConfig();
      config.panelClass = "custom-dialog-container3";
      config.height = "23.3em";
      config.width = "52.6em";
      config.restoreFocus = false;
      config.hasBackdrop = false;
      config.data = {
        v: this._authService.getCurrentTimeNumber()
      };
      config.position = { left: "20px", top: "138px" };
      this._dialog.open(SchedulesClientComponent, config);
    }
  }

  onUpdateParentPage(e: any) {

    switch (e.updateSection) {
      //case "tripSetup":
      //  this.lockdownTrip = e.lockdownTrip;
      //  this.tripCodeList.find(x => x.tripCodeGUID == this.selectedTripCodeGUID.toLowerCase()).lockdownTrip = this.lockdownTrip;
      //  this.getUsernameList().subscribe(res1 => {

      //  });

      //  break;
      case "groundStop":
        this.getUsernameList().subscribe(res1 => {

        });

        break;
    }

  }


  clickTab(s: string) {
    this.errMsg = "";
    if (this.selectedTabName != s) {      
      this.selectedTabName = s;
      if (this.selectedTabName == 'groundStop') {
        this.childTableWidth = 90.5;
      }
      else {
        this.childTableWidth = 63.5;
      }
      this.reloadChildPage = true;
      this.openChildPage('');
    }
  }
  
  // clickClient() {
  //   //window.open('/customers/customer-profile-setup-edit?customerGuid=' + this.customerGUID + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  //   window.open('/customers/customer-commlist?customerGuid=' + this.customerGUID + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  // }
  
  // clickAircraft() {
  //   window.open('/aircraft/aircraft-profile-details?aircraftGuid=' + this.aircraftGUID + '&customerGuid=&textSearch=&filterBy=&v=' + this._authService.getCurrentTimeNumber(), '_blank');

  // }
    
    
  
  // openCustomerCommlistComponent() {
    clickClient() { 
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    config.minWidth = "70em";
    config.height = "42.5em";
    //  config.maxHeight = "max-content";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "20px", top: "138px" };
    config.data = { isDialog: true, v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(CompanyProfileClientComponent, config);
  }


  // openAircraftProfileComponent() {
  clickAircraft() { 
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container5"
    config.width = "91em";
    config.height = "36em";
    config.maxWidth = "95vw";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "20px", top: "138px" };
    config.data = { customerGUID: "", isDialog: true, v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(AircraftProfileComponent, config);
  }




  clickLegend() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    let legendType: string = "";
    dialogConfig.data = {
      dialogTitle: s, legendType: legendType
    };

    const dialogRef = this._dialog.open(IconLegendDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  clickClientInstruction() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.selectedTripCodeGUID, customerGUID: this.customerGUID, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripClientInstructionEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTripBytId();
        this.clickRefreshAudit();
      }

    });

  }

  getTripBytId() {
    this._authService.updateAccessTime();
    
    let request = new AircraftTripModel();
    
    request.tripCodeGUID = this.selectedTripCodeGUID;
    this._groundStopClientService.getTripBytId<ResponseModel<TripModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model!=null) {
          let t = new TripModel();
          t = response.model;
          this.selectedTripCodeGUID = t.tripCodeGUID;
          this.allGroundStopList = t.groundStopList;
          this.lockdownTrip = t.lockdownTrip;
          this.tripStarted = t.tripStarted;
          this.clientInstructions = t.clientInstructions;
          this.selectedTripCode = t.tripCode;
          this.activeGroundStopList = t.activeGroundStopList;
          this.hasCancelledStop = t.hasCancelledStop;
          if (this.includeActiveStopOnly) {
            this.selectedGroundStopList = this.activeGroundStopList;
          }
          else {
            this.selectedGroundStopList = this.allGroundStopList;
          }
        }
        else {
          
        }
        this.showSpin = false;

      }
      else {
        if (response.code == "401") {
          if (response.message == "")
            this._authService.signOut();
          else
            this.errMsg = response.message;
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    })

  }

  clickMessages() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container"
    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.selectedTripCodeGUID, registration: this.registration, selectedGroundStopList: this.selectedGroundStopList, tripCode: this.selectedTripCode
    };

    const dialogRef = this._dialog.open(TripMessagesClientComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });


  }

  openTripSummaryReport() {

    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    config.restoreFocus = false;
    config.data = { tripCodeGUID: this.selectedTripCodeGUID };
    this._dialog.open(TripSummaryReportDialogComponent, config);
  }

  clickAddEditCrewPax() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = { dialogTitle: s, customerGUID: this.customerGUID, tripCodeGUID: this.selectedTripCodeGUID, aircraftGUID: this.aircraftGUID, v: this._authService.getCurrentTimeNumber(), canEdit:false };
    const dialogRef = this._dialog.open(TripPersonlistEditorClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      

    });

  }


  clickTripDocuments() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    /*dialogConfig.disableClose = true;*/

    //let leg: string = "";

    //leg = item.departureICAO;
    //if (item.nextArrivalICAO != "") {
    //  leg += "-" + item.nextArrivalICAO;
    //}

    dialogConfig.data = {
      tripCodeGUID: this.selectedTripCodeGUID, customerGUID: this.customerGUID,
      aircraftGUID: this.aircraftGUID, pageFrom: 'trip', v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripDocumentSelectionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {

        //this.getTripLegBydgIdagId(item.groundStopGUID, item.nextArrivalGroundStopGUID);
      }
    });

  }


  openTripPlanner() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container-resize-hor";
    config.disableClose = true;
    config.minWidth = "75em";
    //config.maxWidth = "92em";//"1000px";
    config.restoreFocus = false;
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { tID: this.selectedTripCodeGUID, cID: this.customerGUID, acCustomer: this.acCustomer };
    this._dialog.open(TripPlannerComponent, config);
  }


}
