import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { ManageContactDetailsComponent } from '../persons/manage-contact-details.component';
import { CustomerCommlistComponent } from '../customers/customer-commlist.component';
import { AircraftProfileComponent } from '../aircraft/aircraft-profile.component';
import { CompanyEventListComponent } from '../current-events/company-event-list.component';
import { CompanyProfileClientComponent } from '../customers/company-profile-client.component';

@Component({
  selector: 'app-maintenance-dashboard-client',
  templateUrl: './maintenance-dashboard-client.component.html',
  styleUrls: ['./maintenance-dashboard-client.component.css']
})

export class MaintenanceDashboardClientComponent implements OnInit {
  // userType: string; 
  maintenance_dashboard_client_form: UntypedFormGroup;
  loading = false;
  // v: number = this._authService.getCurrentTimeNumber();
  // upPII: boolean = false;
  username: string;
  isDialog: boolean = false;


  constructor(   
    @Optional() private readonly _dialogRef: MatDialogRef<MaintenanceDashboardClientComponent>,
  @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
  private readonly _formBuilder: UntypedFormBuilder, private readonly dialog: MatDialog, private readonly _authService: AuthenticateService, 
    ) {
   
    // this.tripRevisionID = _data.tripRevisionID;
    // this.userComments = _data.userComments;
    if (this.data) {
      this.username = this.data.username;
      this.isDialog = true;
    }  

  }

  ngOnInit() {

    // this._authService.updateAccessTime();



   }

  initControls() {
    this.maintenance_dashboard_client_form = this._formBuilder.group({
      // userComments: [this.userComments]
    });


  }

  get f() { return this.maintenance_dashboard_client_form.controls; }


 

  clickClose(val) {
    this._dialogRef.close(val);
  }

  clickRouteNew(route: string, display: string) {
    if (this.isDialog) {
      this.clickClose(true);
    }
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }
  
  
  openManageContactDetailsComponent() {
    if (this.isDialog) {
      this.clickClose(true);
    }
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    config.width = "83em";
    config.height = "39em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "20px", top: "138px" };
    config.data = { isDialog: true, v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(ManageContactDetailsComponent, config);
  }
  
  openCustomerCommlistComponent() {
    if(this.isDialog){  
      this.clickClose(true);
    }     
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    config.minWidth = "70em";
    config.height = "42.5em";
  //  config.maxHeight = "max-content";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "20px", top: "138px" };
    config.data = { isDialog: true, v: this._authService.getCurrentTimeNumber()   };
    this.dialog.open(CompanyProfileClientComponent, config);  
  }

    

  openAircraftProfileComponent() {
    if(this.isDialog){  
      this.clickClose(true);
    }      
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container5"
    config.width = "91em";
    config.height = "36em";
    config.maxWidth = "95vw";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "20px", top: "138px" };
    config.data = { customerGUID: "", isDialog: true, v: this._authService.getCurrentTimeNumber()   };
    this.dialog.open(AircraftProfileComponent, config);
  }

  openCompanyEventListComponent() {
    if(this.isDialog){  
      this.clickClose(true);
    }      
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    config.height = "40em";
    config.width = "83em";
    config.maxWidth = "83em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = {left: "20px", top:"138px"};
    config.data = { isDialog: true, v: this._authService.getCurrentTimeNumber() , userType: 'internal'  };
    this.dialog.open(CompanyEventListComponent, config);
  }



}
