<div *ngIf="upPII" style="margin-top: 0em; padding-top: 0em; display: flex; padding-bottom: 1em; overflow-x:hidden">
  <form *ngIf="ground_stoplist_internal_form" [formGroup]="ground_stoplist_internal_form">
    <div style="min-height: 25.5em; max-height: 41.5em; overflow-y: auto; overflow-x: hidden " #uiElement
      (scroll)="onScrollLoadData()">
      <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container mat-elevation-z8">
        <tr>
          <ng-container matColumnDef="airport_itinerary_crew_pax_fbo_service">
            <th mat-header-cell *matHeaderCellDef style="width:87%;text-align:left !important">
              <div style="display: flex">
                <div style="margin-top:-0.25em; min-width: 8.5em;">
                  <div style="margin-right:0.25em">Airport</div>
                  <div style="margin-right:0.25em"></div>
                </div>
                <div style="margin-top:-0.25em; min-width: 13.5em">
                  <div style="white-space: nowrap">Itinerary </div>
                  <div style="display:flex">
                    <div style="margin-top:0em; white-space: nowrap; font-size:x-small; font-weight: 500 !important">
                      MM/DD HH:MM</div>
                    <div style="font-size:x-small; font-weight: normal; margin-left:0.15em">(UTC)</div>
                  </div>
                </div>
                <div style="margin-top:0.5em; min-width: 18.75em">Crew / PAX Manifest</div>
                <div style="margin-top:0.5em; min-width: 10.5em">Point of Contact</div>
                <div style="margin-top:0.5em; min-width: 13.75em">Airport Services</div>
                <div style="margin-top:0.5em; min-width: 13.5em">Preflight Services</div>
                <div style="margin-top:0.5em; min-width: 14.25em">Flight Plan WX & Permits</div>
                <div style="margin-top:0.5em; min-width: 11em">Flight Following</div>
              </div>
            </th>
            <td mat-cell *matCellDef="let element" #groundStopGUIDRefs [attr.groundStopGUID]="element.groundStopGUID"
              style="text-align: left !important;">
              <div *ngIf="element.isFirstGroundStop==false" [hidden]="hidePastStop?element.arrivalDateTimePassed: false"
                style="margin-top:0.25em">
                <div style="display: flex">
                  <div style="width:8.5em" [style.background-color]="element.groundStopTypeColor">
                    <div style="display: flex">
                      <div *ngIf="element.icao!='XXXX'"
                        [ngClass]="element.isHomeBaseAirport?'homeBaseAirport divHover':'divHover'"
                        style="cursor: pointer;font-weight: bold; margin-right:0.25em"
                        (click)="clickICAO($event, element)">{{element.icaO_IATA}}</div>
                    </div>
                    <div *ngIf="element.icao=='XXXX'" style="font-weight: bold">{{element.icaO_IATA}}</div>
                    <div style="font-size:x-small;margin-top:-0.25em; white-space:nowrap"
                      title="{{element.airportName}}">
                      {{element.airportName.length>20?element.airportName.substr(0,20)+'...':element.airportName}}</div>
                    <div style="font-size:x-small;margin-top:0em;padding:0em">{{element.airportState_CountryCode}}</div>
                    <div *ngIf="element.isTechStop"
                      style="border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color:#e5e5e5">
                      <div style="margin-top:-0.3em; margin-left:0.25em">Tech Stop</div>
                    </div>
                    <div style="font-size:x-small;margin-top:-0.25em">{{element.groundStopTypeDescription}}</div>
                    <div *ngIf="element.arrivalOnHold"
                      style="border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
                      <div style="margin-top:-0.3em; text-align:center">On Hold</div>
                    </div>
                  </div>
                  <div style="min-width: 13.5em">
                    <div style="display: flex;  cursor: pointer;"  (click)="clickGroundStopDetail($event, element)" >
                      <div  class="divHover"  style="margin-right:0.25em"><i class="material-icons"
                          style="border: black 0.0625em solid; font-size:large">flight_land</i></div>
                      <div  class="divHover"  style="margin-top:-0.5em;margin-right:0.25em">
                        <div style="display:flex">
                          <div style="white-space: nowrap; font-weight: bold">
                            {{element.arrivalDateTextUTC!=''?element.arrivalDateTimeUTCDisplay+' Z':'TBA'}}</div>
                          <div *ngIf="element.arrivalUTCTimeConversion!=''"
                            style="font-size:small;margin-top:0em;padding:0em; white-space:nowrap">
                            ({{element.arrivalUTCTimeConversion}})</div>
                        </div>
                        <div style="white-space: nowrap; margin-top:-0.5em">
                          {{element.arrivalDateTextLocal!=''?element.arrivalDateTextLocal+' L':''}}</div>
                      </div>
                    </div>
                    <div *ngIf="element.arrivalStatusDescription=='Actual'"
                      style="border-radius: 0.5em; width: 7em; height: 1.25em; border: gray solid 0.0625em;"
                      [style.background-color]="element.arrivalStatusColorCode">
                      <div style="margin-top:-0.3em; text-align:center">{{element.arrivalStatusDescription}}</div>
                    </div>
                    <div *ngIf="element.arrivalGroundStopAlternateAirportList.length>0">
                      <div style="display: flex">
                        <div><i class="material-icons"
                            style="color:gray; font-size:large; margin-top:0.25em">where_to_vote</i></div>
                        <div>
                          <div style="font-weight: bold">Alternate:</div>
                          <div style="display: flex; margin-top:-0.25em">
                            <div *ngFor="let a of element.arrivalGroundStopAlternateAirportList">
                              <div style="cursor: pointer; margin-right:0.25em" class="divHover"
                                (click)="clickAltICAO($event, element, a)">{{a.icao}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="min-width: 18.75em">
                    <div style="display:flex; margin-top:-0.5em;">
                      <div style="min-width: 9.375em;">
                        <div *ngIf="element.arrivalCrewCount>0" style="white-space:nowrap;font-weight: bold">Crew
                          ({{element.arrivalCrewCount}})</div>
                        <div style="display: flex; margin-top:-0.4em;height:1.5em; overflow:hidden"
                          *ngIf="element.arrivalPIC!=null">
                          <div style="white-space:nowrap; font-weight: bold; margin-right:0.25em">PIC:</div>
                          <div class="divHover" style="cursor: pointer" matTooltipClass="tooltipLineBreak"
                            matTooltip="{{element.arrivalPIC.airportViewTooltip}}"
                            (click)="clickPersonName($event, element.arrivalPIC, element)">
                            {{element.arrivalPIC.personNameAbbr.substr(0,11)}}</div>
                          <div *ngIf="(element.arrivalPIC.isBellAlert || element.arrivalPIC.hasExpiredPassport) && element.arrivalPIC.capacityID < 6"
                            title="{{element.arrivalPIC.missingInfoTooltip}}" style="cursor: pointer; margin-top:0.25em"
                            (click)="clickPersonName($event, element.arrivalPIC, element)"><i
                              class="material-icons-two-tone icon_orange"
                              style="font-size: medium">notifications_active</i></div>
                          <!-- <div *ngIf="element.arrivalPIC.isMultiplePassports" title="Multiple Passports"
                            style="margin-top:0.25em"><i class="material-icons-two-tone icon_green"
                              style="font-size: medium">content_copy</i></div> -->
                        </div>
                        <div style="display: flex; margin-top: -0.4em; height: 1.5em; overflow: hidden "
                          *ngIf="element.arrivalSIC!=null">
                          <div style="white-space:nowrap; font-weight: bold; margin-right:0.25em">SIC:</div>
                          <div class="divHover" style="cursor: pointer" matTooltipClass="tooltipLineBreak"
                            matTooltip="{{element.arrivalSIC.airportViewTooltip}}"
                            (click)="clickPersonName($event, element.arrivalSIC, element)">
                            {{element.arrivalSIC.personNameAbbr.substr(0,11)}}</div>
                          <div *ngIf="(element.arrivalSIC.isBellAlert || element.arrivalSIC.hasExpiredPassport) && element.arrivalSIC.capacityID < 6"
                            title="{{element.arrivalSIC.missingInfoTooltip}}" style="cursor: pointer; margin-top:0.25em"
                            (click)="clickPersonName($event, element.arrivalSIC, element)"><i
                              class="material-icons-two-tone icon_orange"
                              style="font-size: medium">notifications_active</i></div>
                          <!-- <div *ngIf="element.arrivalSIC.isMultiplePassports" title="Multiple Passports"
                            style="margin-top:0.25em"><i class="material-icons-two-tone icon_green"
                              style="font-size: medium">content_copy</i></div> -->
                        </div>
                        <div *ngFor="let p of element.arrivalCrewList; let i=index"
                          style="display: flex; margin-top: -0.4em; height: 1.5em; overflow: hidden ">
                          <div style="white-space:nowrap; width:3em; font-weight: bold">{{i==0? 'Crew: ' :''}}</div>
                          <div style="cursor: pointer; white-space:nowrap" class="divHover"
                            matTooltipClass="tooltipLineBreak" matTooltip="{{p.airportViewTooltip}}"
                            (click)="clickPersonName($event, p, element)">{{p.personNameAbbr.substr(0,10)}}</div>
                          <div *ngIf="(p.isBellAlert || p.hasExpiredPassport)"
                            style="cursor: pointer; margin-top:0.25em" (click)="clickPersonName($event, p, element)"
                            title="{{p.missingInfoTooltip}}"><i class="material-icons-two-tone icon_orange"
                              style="font-size: medium">notifications_active</i></div>
                          <!-- <div *ngIf="p.isMultiplePassports" title="Multiple Passports" style="margin-top:0.25em"><i
                              class="material-icons-two-tone icon_green" style="font-size: medium">content_copy</i>
                          </div> -->
                        </div>
                        <div *ngIf="element.arrivalCrewCount==0" style="white-space: nowrap;">
                          <div style="white-space:nowrap;font-weight: bold">Crew</div>
                          <div>Missing Crew</div>
                        </div>
                      </div>
                      <div style="margin-left:0.25em;min-width: 9.125em;">
                        <div *ngIf="element.arrivalPAXCount>0" style="white-space:nowrap; font-weight: bold">PAX
                          ({{element.arrivalPAXCount}})</div>
                        <div *ngFor="let p of element.arrivalPAXList"
                          style="display: flex; margin-top: -0.4em; height: 1.5em; overflow: hidden ">
                          <div style="white-space:nowrap;cursor: pointer;" matTooltipClass="tooltipLineBreak"
                            matTooltip="{{p.airportViewTooltip}}" class="divHover" (click)="clickPersonName($event, p, element)">
                            {{p.personNameAbbr.substr(0,11)}}</div>
                          <div *ngIf="(p.isBellAlert || p.hasExpiredPassport)"
                            style="cursor: pointer; margin-top:0.25em" title="{{p.missingInfoTooltip}}"
                            (click)="clickPersonName($event, p, element);"><i
                              class="material-icons-two-tone icon_orange"
                              style="font-size: medium">notifications_active</i></div>
                          <div style="margin-top:0.25em" *ngIf="p.isMinorOnboard==true" title="Minor on board"><i
                              class="material-icons-two-tone icon_green"
                              style="font-size: medium">assignment_ind<!--warning_amber--></i></div>
                          <!-- <div *ngIf="p.isMultiplePassports" title="Multiple Passports" style="margin-top:0.25em"><i
                              class="material-icons-two-tone icon_green" style="font-size: medium">content_copy</i>
                          </div> -->
                        </div>
                        <div *ngIf="element.isArrFerryFlight && element.arrivalPAXCount==0">
                          <div style="white-space:nowrap; font-weight: bold">PAX</div>
                          <div>Ferry Flight</div>
                        </div>
                      </div>
                    </div>
                    <div style="font-size: x-small">
                      <div *ngIf="element.arrivalPetsOnBoard"><mat-checkbox disabled
                          [checked]="element.arrivalPetsOnBoard" style="transform: scale(0.5)"></mat-checkbox>Pets on
                        Board</div>
                      <div *ngIf="element.arrivalWeaponsOnBoard" style="margin-top:-1em"><mat-checkbox disabled
                          [checked]="element.arrivalWeaponsOnBoard" style="transform: scale(0.5)"></mat-checkbox>Weapons
                        on Board</div>
                    </div>
                  </div>
                  <div style="width: 9.35em">
                    <div *ngFor="let p of element.pointOfContactList" style="display:flex">
                      <div style="white-space:nowrap;cursor: pointer" class="divHover" title="{{ p.pointOfContact }}"
                        (click)="clickICAO($event, element)">{{p.pointOfContact.substr(0,19)}}</div>
                      <div></div>
                    </div>
                  </div>
                  <div style="width:13.75em">
                    <div *ngFor="let p of element.arrAirportServiceTaskList" style="margin-left:0em">
                      <div style="display: flex; min-height:1.25em">
                        <div *ngIf="p.isRevisionRequiredValue==1" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_orange"
                            style="font-size: medium">notifications_active</i></div>
                        <div *ngIf="p.isRevisionRequiredValue==2" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_red" style="font-size: medium">notifications_active</i>
                        </div>
                        <div *ngIf="p.isRevisionRequiredValue==0" style="width:1.25em"></div>
                        <div style="border-radius: 50%; width: 0.9em; height: 0.9em; border: black solid 0.0625em;"
                          [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>
                        <div style="margin-top:-0.35em; white-space:normal; margin-left:0.125em">
                          <div style="display: flex;height:1.5em">
                            <div class="divHover" *ngIf="p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><s
                                style="color:red"><a style="cursor: pointer"
                                  (click)="clickServiceType($event,element, p, 'A')"
                                  title="{{p.originalTaskStatusDescription}}"><span
                                    class="textHover">{{p.serviceTypeDescription.substr(0,17)}}</span></a></s></div>
                            <div class="divHover" *ngIf="!p.strikeOutService"   
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><a
                                style="cursor: pointer" (click)="clickServiceType($event,element, p, 'A')"
                                title="{{p.originalTaskStatusDescription}}">{{p.serviceTypeDescription.substr(0,17)}}</a>
                            </div>
                            <div *ngIf="p.hasTaskDocument" style="margin-top:0.25em" title="documents"><i
                                class="material-icons"
                                style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>
                            <div *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak"
                              matTooltip="{{p.publicNote}}"><i class="material-icons"
                                style="font-size: small;">notes</i></div>
                            <div *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i
                                class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>

                            <div *ngIf="p.messageInCount>0 || p.messageOutCount>0" class="divHover">
                              <i class="material-icons" style="cursor: pointer;font-size: medium; margin-top:0.25em"
                                (click)="openMessages($event, element ,p, 'A')">mail_outline</i>
                            </div>
                          </div>
                          <div *ngIf="p.vendorName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.vendorName}}">{{p.vendorName.substr(0,19)}}</div>
                          <div *ngIf="p.countryName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.countryName}}">{{p.countryName.substr(0,19)}}</div>
                          <div *ngIf="p.hotelName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.hotelName}}">{{p.hotelName.substr(0,19)}}</div>
                          <div *ngIf="p.fpiConfirmationReference!=''" style="margin-top: -0.25em; color: darkblue;"
                            title="{{p.fpiConfirmationReference}}">{{p.fpiConfirmationReference!='' &&
                            p.fpiConfirmationReference.length>15?p.fpiConfirmationReference.substr(0,15)+'...':p.fpiConfirmationReference}}
                          </div>
                          <div *ngIf="p.serviceDate!='' || p.serviceTime!=''"
                            style="margin-top: -0.25em; color: darkblue">{{p.serviceDate | date:'dd-MMM'}}
                            {{p.serviceTime!=''? p.serviceTime + ' Z' : ''}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width:13.5em">
                    <div *ngFor="let p of element.arrPreflightServiceTaskList" style="margin-left:0em">
                      <div style="display: flex; min-height:1.25em">
                        <div *ngIf="p.isRevisionRequiredValue==1" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_orange"
                            style="font-size: medium">notifications_active</i></div>
                        <div *ngIf="p.isRevisionRequiredValue==2" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_red" style="font-size: medium">notifications_active</i>
                        </div>
                        <div *ngIf="p.isRevisionRequiredValue==0" style="width:1.25em"></div>
                        <div style="border-radius: 50%; width: 0.9em; height: 0.9em; border: black solid 0.0625em;"
                          [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>
                        <div style="margin-top:-0.35em; white-space:normal; margin-left:0.125em">
                          <div style="display: flex;height:1.5em">
                            <div class="divHover" *ngIf="p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><s
                                style="color:red"><a style="cursor: pointer"
                                  (click)="clickServiceType($event,element, p, 'A')"
                                  title="{{p.originalTaskStatusDescription}}"><span
                                    class="textHover">{{p.serviceTypeDescription.substr(0,17)}}</span></a></s></div>
                            <div class="divHover" *ngIf="!p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><a
                                style="cursor: pointer" (click)="clickServiceType($event,element, p, 'A')"
                                title="{{p.serviceTypeDescription}}">{{p.serviceTypeDescription.substr(0,17)}}</a></div>
                            <div *ngIf="p.hasTaskDocument" style="margin-top:0.25em" title="documents"><i
                                class="material-icons"
                                style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>
                            <div *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak"
                              matTooltip="{{p.publicNote}}"><i class="material-icons"
                                style="font-size: small;">notes</i></div>
                            <div *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i
                                class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>
                            <div *ngIf="p.messageInCount>0 || p.messageOutCount>0" style="margin-top:0.25em"
                              class="divHover">
                              <i class="material-icons" style="font-size: medium;"
                                (click)="openMessages($event, element ,p, 'A')">mail_outline</i>
                            </div>
                          </div>
                          <div *ngIf="p.vendorName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.vendorName}}">{{p.vendorName.substr(0,19)}}</div>
                          <div *ngIf="p.countryName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.countryName}}">{{p.countryName.substr(0,19)}}</div>
                          <div *ngIf="p.fpiConfirmationReference!='' && p.tripLegList.length==0"
                            style="margin-top: -0.25em; color: darkblue" title="{{p.fpiConfirmationReference}}">
                            {{p.fpiConfirmationReference!='' &&
                            p.fpiConfirmationReference.length>15?p.fpiConfirmationReference.substr(0,15)+'...':p.fpiConfirmationReference}}
                          </div>
                          <div *ngFor="let t of p.tripLegList" style="margin-left:0em">
                            <div>{{t.departureICAO+ ' - '+t.nextArrivalICAO}}</div>
                          </div>
                          <div *ngIf="p.serviceDate!='' || p.serviceTime!=''"
                            style="margin-top: -0.25em; color: darkblue">{{p.serviceDate | date:'dd-MMM'}}
                            {{p.serviceTime!=''? p.serviceTime + ' Z' : ''}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width:14.25em">
                    <div *ngFor="let p of element.arrFlightPlanServiceTaskList" style="margin-left:0em">
                      <div style="display: flex; min-height:1.25em">
                        <div *ngIf="p.isRevisionRequiredValue==1" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_orange"
                            style="font-size: medium">notifications_active</i></div>
                        <div *ngIf="p.isRevisionRequiredValue==2" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_red" style="font-size: medium">notifications_active</i>
                        </div>
                        <div *ngIf="p.isRevisionRequiredValue==0" style="width:1.25em"></div>
                        <div style="border-radius: 50%; width: 0.9em; height: 0.9em; border: black solid 0.0625em;"
                          [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>
                        <div style="margin-top:-0.35em; white-space:normal; margin-left:0.125em">
                          <div style="display: flex;height:1.5em">
                            <div class="divHover" *ngIf="p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><s
                                style="color:red"><a style="cursor: pointer"
                                  (click)="clickServiceType($event,element, p, 'A')"
                                  title="{{p.originalTaskStatusDescription}}"><span
                                    class="textHover">{{p.serviceTypeDescription.substr(0,17)}}</span></a></s></div>
                            <div class="divHover" *ngIf="!p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><a
                                style="cursor: pointer" (click)="clickServiceType($event,element, p, 'A')"
                                title="{{p.serviceTypeDescription}}">{{p.serviceTypeDescription.substr(0,17)}}</a></div>
                            <div *ngIf="p.hasTaskDocument" style="margin-top:0.25em" title="documents"><i
                                class="material-icons"
                                style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>
                            <div *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak"
                              matTooltip="{{p.publicNote}}"><i class="material-icons"
                                style="font-size: small;">notes</i></div>
                            <div *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i
                                class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>
                            <div *ngIf="p.messageInCount>0 || p.messageOutCount>0" style="margin-top:0.25em"
                              class="divHover">
                              <i class="material-icons" style="font-size: medium;"
                                (click)="openMessages($event, element ,p,'A')">mail_outline</i>
                            </div>
                          </div>
                          <div *ngIf="p.vendorName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.vendorName}}">{{p.vendorName.substr(0,19)}}</div>
                          <div *ngIf="p.countryName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.countryName}}">{{p.countryName.substr(0,19)}}</div>
                          <div *ngIf="p.fpiConfirmationReference!='' && p.tripLegList.length==0"
                            style="margin-top: -0.25em; color: darkblue" title="{{p.fpiConfirmationReference}}">
                            {{p.fpiConfirmationReference!='' &&
                            p.fpiConfirmationReference.length>15?p.fpiConfirmationReference.substr(0,15)+'...':p.fpiConfirmationReference}}
                          </div>

                          <div *ngFor="let t of p.tripLegList" style="margin-left:0em">
                            <div>{{t.departureICAO+ ' - '+t.nextArrivalICAO}}</div>
                          </div>
                          <div *ngIf="p.serviceDate!='' || p.serviceTime!=''"
                            style="margin-top: -0.25em; color: darkblue">{{p.serviceDate | date:'dd-MMM'}}
                            {{p.serviceTime!=''? p.serviceTime + ' Z' : ''}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width:12em">
                    <div *ngFor="let p of element.arrFlightFollowingTaskList" style="margin-left:0em">
                      <div style="display: flex; min-height:1.25em">
                        <div *ngIf="p.isRevisionRequiredValue==1" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p, 'A')"><i
                            class="material-icons-two-tone icon_orange"
                            style="font-size: medium">notifications_active</i></div>
                        <div *ngIf="p.isRevisionRequiredValue==2" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p, 'A')"><i
                            class="material-icons-two-tone icon_red" style="font-size: medium">notifications_active</i>
                        </div>
                        <div *ngIf="p.isRevisionRequiredValue==0" style="width:1.25em"></div>
                        <div style="border-radius: 50%; width: 0.9em; height: 0.9em; border: black solid 0.0625em;"
                          [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>
                        <div style="margin-top:-0.35em; white-space:normal; margin-left:0.125em">
                          <div style="display: flex;height:1.5em">
                            <div class="divHover" *ngIf="p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><s
                                style="color:red"><a style="cursor: pointer"
                                  (click)="clickServiceType($event,element, p)"
                                  title="{{p.originalTaskStatusDescription}}"><span
                                    class="textHover">{{p.serviceTypeDescription.substr(0,17)}}</span></a></s></div>
                            <div class="divHover" *ngIf="!p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><a
                                style="cursor: pointer" (click)="clickServiceType($event,element, p)"
                                title="{{p.serviceTypeDescription}}">{{p.serviceTypeDescription.substr(0,17)}}</a></div>
                            <div *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak"
                              matTooltip="{{p.publicNote}}"><i class="material-icons"
                                style="font-size: small;">notes</i></div>
                            <div *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i
                                class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>
                            <div *ngIf="p.messageInCount>0 || p.messageOutCount>0" style="margin-top:0.25em"
                              class="divHover">
                              <i class="material-icons" style="font-size: medium;"
                                (click)="openMessages($event, element ,p,'A')">mail_outline</i>
                            </div>
                          </div>
                          <div *ngFor="let f of p.flightFollowingList">
                            <div style="margin-top: -0.25em; color: darkblue" title="{{f.subServiceTypeDescription}}">
                              {{f.subServiceTypeDescription.substr(0,19)}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="!element.isLastGroundStop" style="margin-top:-1em; margin-bottom:-1em">
                  <hr style="border: 0.0625em solid darkgray;" />
                </div>
              </div>
              <div
                *ngIf="!element.isLastGroundStop || (element.isLastGroundStop && element.hasNextGroundStop && !includeActiveStopOnly)"
                [hidden]="hidePastStop?element.departureDateTimePassed: false" style="margin-top:0.25em">
                <div style="display: flex">
                  <div style="width:8.5em"
                    [style.background-color]="element.isLastGroundStop && element.hasNextGroundStop?'orange':element.groundStopTypeColor">
                    <div style="display: flex">
                      <div *ngIf="element.icao!='XXXX'"
                        [ngClass]="element.isHomeBaseAirport?'homeBaseAirport divHover':'divHover'"
                        style="cursor: pointer;font-weight: bold; margin-right:0.25em"
                        (click)="clickICAO($event, element)">{{element.icaO_IATA}}</div>
                    </div>
                    <div *ngIf="element.icao=='XXXX'" style="font-weight: bold">{{element.icaO_IATA}}</div>
                    <div style="font-size:x-small;margin-top:-0.25em; white-space:nowrap"
                      title="{{element.airportName}}">
                      {{element.airportName.length>20?element.airportName.substr(0,20)+'...':element.airportName}}</div>
                    <div style="font-size:x-small;margin-top:0em;padding:0em">{{element.airportState_CountryCode}}</div>
                    <div *ngIf="element.isTechStop"
                      style="border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color:#e5e5e5">
                      <div style="margin-top:-0.3em; margin-left:0.25em">Tech Stop</div>
                    </div>
                    <div style="font-size:x-small;margin-top:-0.25em">{{element.isLastGroundStop &&
                      element.hasNextGroundStop &&
                      element.nextGroundStopTypeColor!=''?element.nextGroundStopTypeColor:element.groundStopTypeDescription}}
                    </div>
                    <div *ngIf="element.departureOnHold"
                      style="border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
                      <div style="margin-top:-0.3em; text-align:center">On Hold</div>
                    </div>
                  </div>
                  <div style="min-width: 13.5em">
                    <div style="display: flex;  cursor: pointer;" (click)="clickGroundStopDetail($event, element)" >
                      <div  class="divHover" style="margin-right:0.25em;  "><i class="material-icons"
                          style="border: black 0.0625em solid; font-size:large">flight_takeoff</i></div>
                      <div class="divHover"  style="margin-top:-0.4em;margin-right:0.25em">
                        <div style="display: flex">
                          <div [style.background-color]="element.isDateTimeConflict?'#f9d2de':'transparent'"
                            title="{{element.isDateTimeConflict?'Time conflict':''}}"
                            style="white-space: nowrap; font-weight: bold">
                            {{element.departureDateTextUTC!=''?element.departureDateTimeUTCDisplay+' Z':'TBA'}}</div>
                          <div *ngIf="element.departureUTCTimeConversion!=''"
                            style="font-size:small;margin-top:0em;padding:0em; white-space:nowrap">
                            ({{element.departureUTCTimeConversion}})</div>
                        </div>
                        <div style="white-space: nowrap; margin-top:-0.5em">
                          {{element.departureDateTextLocal!=''?element.departureDateTextLocal+' L':''}}</div>
                      </div>
                    </div>
                    <div style="cursor: pointer; display:flex"   (click)="clickGroundStopDetail($event, element)" >
                      <div class="divHover" >{{element.ete!='' || element.eteChanged? 'ETE: '+element.ete:''}}</div>
                      <div class="divHover" *ngIf="element.eteChanged" style="margin-top:0.25em"><i class="material-icons-outlined"
                          style="font-size:medium; color: darkgreen; font-weight:bold" title="ETE changed">check</i>
                      </div>
                    </div>
                    <div    (click)="clickGroundStopDetail($event, element)"  style="cursor: pointer; white-space: nowrap; font-weight: bold; display:flex">
                      <div   style="margin-right:0em"
                        [style.background-color]="element.departureFARTypeID==2?'yellow':'transparent'">
                        {{element.departureFARTypeID>0?element.departureFARTypeDescription:''}}</div>
                      <div *ngIf="element.departureFARTypeID && element.departureCallSign!=''" style="margin-right:0em">
                        /</div>
                      <div [style.background-color]="element.departureCallSign!=''?'yellow':'transparent'">
                        {{element.departureCallSign!=''?element.departureCallSign:''}}</div>
                    </div>
                    <div *ngIf="element.departureStatusDescription=='Actual'"
                      style="border-radius: 0.5em; width: 7em; height: 1.25em; border: gray solid 0.0625em;"
                      [style.background-color]="element.departureStatusColorCode">
                      <div style="margin-top:-0.3em; text-align:center">{{element.departureStatusDescription}}</div>
                    </div>
                    <div *ngIf="element.departureGroundStopAlternateAirportList.length>0">
                      <div style="display: flex">
                        <div><i class="material-icons"
                            style="color: gray; font-size:large; margin-top:0.25em">where_to_vote</i></div>
                        <div>
                          <div style="font-weight: bold">Alternate:</div>
                          <div style="display: flex; margin-top:-0.25em">
                            <div *ngFor="let a of element.departureGroundStopAlternateAirportList">
                              <div style="cursor: pointer; margin-right:0.25em" class="divHover"
                                (click)="clickAltICAO($event, element, a)">{{a.icao}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="element.etopGroundStopAlternateAirportList.length>0">
                      <div style="display:flex">
                        <div><i class="material-icons"
                            style="color:gray; font-size:large; margin-top:0.25em">where_to_vote</i></div>
                        <div>
                          <div style="font-weight: bold">ETOP:</div>
                          <div *ngFor="let a of element.etopGroundStopAlternateAirportList; let i=index"
                            style="margin-top:-0.25em">
                            <div *ngIf="i % 2==0" style="display: flex">
                              <div style="font-size: small;margin-right:0.25em">
                                <div style="cursor: pointer" class="divHover"
                                  (click)="clickAltICAO($event, element, a)">{{a.icao}}</div>
                              </div>
                              <div *ngIf="i + 1 < element.etopGroundStopAlternateAirportList.length"
                                style="font-size: small;">
                                <div style="cursor: pointer" class="divHover"
                                  (click)="clickAltICAO($event, element, element.etopGroundStopAlternateAirportList[i+1])">
                                  {{element.etopGroundStopAlternateAirportList[i+1].icao}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="element.etpGroundStopAlternateAirportList.length>0">
                      <div style="display:flex">
                        <div><i class="material-icons"
                            style="color:gray; font-size:large; margin-top:0.25em">where_to_vote</i></div>
                        <div>
                          <div style="font-weight: bold">ETP:</div>
                          <div *ngFor="let a of element.etpGroundStopAlternateAirportList; let i=index"
                            style="margin-top:-0.25em">
                            <div *ngIf="i % 2==0" style="display: flex">
                              <div style="font-size: small;margin-right:0.25em">
                                <div style="cursor: pointer" class="divHover"
                                  (click)="clickAltICAO($event, element, a)">{{a.icao}}</div>
                              </div>
                              <div *ngIf="i + 1 < element.etpGroundStopAlternateAirportList.length"
                                style="font-size: small;">
                                <div style="cursor: pointer" class="divHover"
                                  (click)="clickAltICAO($event, element, element.etpGroundStopAlternateAirportList[i+1])">
                                  {{element.etpGroundStopAlternateAirportList[i+1].icao}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="min-width: 18.75em">
                    <div style="display:flex;margin-top:-0.5em; ">
                      <div style="min-width: 9.375em;">
                        <div *ngIf="element.departureCrewCount>0" style="white-space: nowrap; font-weight:bold">Crew
                          ({{element.departureCrewCount}})</div>
                        <div style="display: flex; margin-top: -0.4em; height: 1.5em"
                          *ngIf="element.departurePIC!=null">
                          <div style="white-space:nowrap; font-weight: bold; margin-right:0.25em">PIC:</div>
                          <div class="divHover" style="cursor: pointer" matTooltipClass="tooltipLineBreak"
                            matTooltip="{{element.departurePIC.airportViewTooltip}}"
                            (click)="clickPersonName($event, element.departurePIC, element)">
                            {{element.departurePIC.personNameAbbr.substr(0,11)}}</div>
                          <div *ngIf="element.isPICChanged" style="cursor: pointer; margin-top:0.25em"
                            title="PIC changed"><i class="material-icons-two-tone icon_red2"
                              style="font-size: medium">people_alt</i></div>
                          <div *ngIf="(element.departurePIC.isBellAlert || element.departurePIC.hasExpiredPassport) && element.departurePIC.capacityID < 6"
                            style="cursor: pointer; margin-top:0.25em"
                            title="{{element.departurePIC.missingInfoTooltip}}"
                            (click)="clickPersonName($event, element.departurePIC, element)"><i
                              class="material-icons-two-tone icon_orange"
                              style="font-size: medium">notifications_active</i></div>
                          <!-- <div *ngIf="element.departurePIC.isMultiplePassports" title="Multiple Passports"
                            style="margin-top:0.25em"><i class="material-icons-two-tone icon_green"
                              style="font-size: medium">content_copy</i></div> -->
                        </div>
                        <div style="display: flex; margin-top: -0.4em; height: 1.5em"
                          *ngIf="element.departureSIC!=null">
                          <div style="white-space:nowrap; font-weight: bold; margin-right:0.25em">SIC:</div>
                          <div class="divHover" style="cursor: pointer" matTooltipClass="tooltipLineBreak"
                            matTooltip="{{element.departureSIC.airportViewTooltip}}"
                            (click)="clickPersonName($event, element.departureSIC, element)">
                            {{element.departureSIC.personNameAbbr.substr(0,11)}}</div>
                          <div *ngIf="element.isSICChanged" style="cursor: pointer; margin-top:0.25em"
                            title="SIC changed"><i class="material-icons-two-tone icon_red2"
                              style="font-size: medium">people_alt</i></div>
                          <div *ngIf="(element.departureSIC.isBellAlert || element.departureSIC.hasExpiredPassport) && element.departureSIC.hasExpiredPassport < 6"
                            style="cursor: pointer; margin-top:0.25em"
                            title="{{element.departureSIC.missingInfoTooltip}}"
                            (click)="clickPersonName($event, element.departureSIC, element)"><i
                              class="material-icons-two-tone icon_orange"
                              style="font-size: medium">notifications_active</i></div>
                          <!-- <div *ngIf="element.departureSIC.isMultiplePassports" title="Multiple Passports"
                            style="margin-top:0.25em"><i class="material-icons-two-tone icon_green"
                              style="font-size: medium">content_copy</i></div> -->
                        </div>
                        <div *ngFor="let p of element.departureCrewList; let i=index"
                          style="display: flex; margin-top: -0.4em; height: 1.5em">
                          <div style="white-space:nowrap; width:3em; font-weight: bold">{{i==0? 'Crew: ':''}}</div>
                          <div class="divHover" style="cursor: pointer; white-space:nowrap"
                            matTooltipClass="tooltipLineBreak" matTooltip="{{p.airportViewTooltip}}"
                            (click)="clickPersonName($event, p, element)">{{p.personNameAbbr.substr(0,10)}}</div>
                          <div *ngIf="(p.isBellAlert || p.hasExpiredPassport)"
                            style="cursor: pointer; margin-top:0.25em" title="{{p.missingInfoTooltip}}"
                            (click)="clickPersonName($event, p, element)"><i class="material-icons-two-tone icon_orange"
                              style="font-size: medium">notifications_active</i></div>
                          <!-- <div *ngIf="p.isMultiplePassports" title="Multiple Passports" style="margin-top:0.25em"><i
                              class="material-icons-two-tone icon_green" style="font-size: medium">content_copy</i>
                          </div> -->
                        </div>
                        <div *ngIf="element.departureCrewCount==0" style="white-space: nowrap;">
                          <div style="white-space:nowrap;font-weight: bold">Crew</div>
                          <div>Missing Crew</div>
                        </div>
                      </div>
                      <div style="margin-left:0.25em; min-width:9.125em">
                        <div *ngIf="element.departurePAXCount>0" style="white-space:nowrap; font-weight:bold">PAX
                          ({{element.departurePAXCount}})</div>
                        <div *ngFor="let p of element.departurePAXList"
                          style="display: flex; margin-top: -0.4em; height: 1.5em">
                          <div style="white-space:nowrap;cursor: pointer;" class="divHover"
                            matTooltipClass="tooltipLineBreak" matTooltip="{{p.airportViewTooltip}}"
                            (click)="clickPersonName($event, p, element)">{{p.personNameAbbr.substr(0,11)}}</div>
                          <div *ngIf="(p.isBellAlert || p.hasExpiredPassport)"
                            style="cursor: pointer; margin-top:0.25em" title="{{p.missingInfoTooltip}}"
                            (click)="clickPersonName($event, p, element);"><i
                              class="material-icons-two-tone icon_orange"
                              style="font-size: medium">notifications_active</i></div>
                          <div style="margin-top:0.25em" title="Minor on board" *ngIf="p.isMinorOnboard==true"><i
                              class="material-icons-two-tone icon_green"
                              style="font-size: medium">assignment_ind<!--warning_amber--></i></div>
                          <!-- <div *ngIf="p.isMultiplePassports" title="Multiple Passports" style="margin-top:0.25em"><i
                              class="material-icons-two-tone icon_green" style="font-size: medium">content_copy</i>
                          </div> -->
                        </div>
                        <div *ngIf="element.isDeptFerryFlight && element.departurePAXCount==0">
                          <div style="white-space:nowrap; font-weight: bold">PAX</div>
                          <div>Ferry Flight</div>
                        </div>
                      </div>
                    </div>
                    <div style="font-size: x-small">
                      <div *ngIf="element.departurePetsOnBoard"><mat-checkbox disabled
                          [checked]="element.departurePetsOnBoard" style="transform: scale(0.5)"></mat-checkbox>Pets on
                        Board</div>
                      <div *ngIf="element.departureWeaponsOnBoard" style="margin-top:-1em"><mat-checkbox disabled
                          [checked]="element.departureWeaponsOnBoard"
                          style="transform: scale(0.5)"></mat-checkbox>Weapons on Board</div>
                    </div>
                  </div>
                  <div style="width: 9.35em">
                    <div *ngFor="let p of element.pointOfContactList" style="display:flex">
                      <div style="white-space:nowrap;cursor: pointer" class="divHover" title="{{ p.pointOfContact }}"
                        (click)="clickICAO($event, element)">{{p.pointOfContact.substr(0,19)}}</div>
                      <div></div>
                    </div>
                  </div>
                  <div style="width:13.75em">
                    <div *ngFor="let p of element.depAirportServiceTaskList" style="margin-left:0em">
                      <div style="display: flex; min-height:1.25em">
                        <div *ngIf="p.isRevisionRequiredValue==1" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_orange"
                            style="font-size: medium">notifications_active</i></div>
                        <div *ngIf="p.isRevisionRequiredValue==2" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_red" style="font-size: medium">notifications_active</i>
                        </div>
                        <div *ngIf="p.isRevisionRequiredValue==0" style="width:1.25em"></div>
                        <div style="border-radius: 50%; width: 0.9em; height: 0.9em; border: black solid 0.0625em;"
                          [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>
                        <div style="margin-top:-0.35em; white-space:normal; margin-left:0.125em">
                          <div style="display: flex;">
                            <div style="display: flex;height:1.5em">
                              <div class="divHover" *ngIf="p.strikeOutService"
                                [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><s
                                  style="color:red"><a style="cursor: pointer"
                                    (click)="clickServiceType($event,element, p, 'D')"
                                    title="{{p.serviceTypeDescription}}"><span
                                      class="textHover">{{p.serviceTypeDescription.substr(0,17)}}</span></a></s></div>
                              <div class="divHover" *ngIf="!p.strikeOutService"
                                [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><a
                                  style="cursor: pointer" (click)="clickServiceType($event,element, p, 'D')"
                                  title="{{p.serviceTypeDescription}}">{{p.serviceTypeDescription.substr(0,17)}}</a>
                              </div>
                              <div *ngIf="p.hasTaskDocument" style="margin-top:0.25em" title="documents"><i
                                  class="material-icons"
                                  style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>
                              <div *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak"
                                matTooltip="{{p.publicNote}}"><i class="material-icons"
                                  style="font-size: small;">notes</i></div>
                              <div *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i
                                  class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>
                              <div *ngIf="p.messageInCount>0 || p.messageOutCount>0" style="margin-top:0.25em"
                                class="divHover">
                                <i class="material-icons" style="font-size: medium;"
                                  (click)="openMessages($event, element ,p,'D')">mail_outline</i>
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div *ngIf="p.vendorName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.vendorName}}">{{p.vendorName.substr(0,19)}}</div>
                          <div *ngIf="p.countryName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.countryName}}">{{p.countryName.substr(0,19)}}</div>
                          <div *ngIf="p.hotelName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.hotelName}}">{{p.hotelName.substr(0,19)}}</div>
                          <div *ngIf="p.fpiConfirmationReference!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.fpiConfirmationReference}}">{{p.fpiConfirmationReference!='' &&
                            p.fpiConfirmationReference.length>15?p.fpiConfirmationReference.substr(0,15)+'...':p.fpiConfirmationReference}}
                          </div>
                          <div *ngIf="p.serviceDate!='' || p.serviceTime!=''"
                            style="margin-top: -0.25em; color: darkblue">{{p.serviceDate | date:'dd-MMM'}}
                            {{p.serviceTime!=''? p.serviceTime + ' Z' : ''}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width:13.5em">
                    <div *ngFor="let p of element.depPreflightServiceTaskList" style="margin-left:0em">
                      <div style="display: flex; min-height:1.25em">
                        <div *ngIf="p.isRevisionRequiredValue==1" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_orange"
                            style="font-size: medium">notifications_active</i></div>
                        <div *ngIf="p.isRevisionRequiredValue==2" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_red" style="font-size: medium">notifications_active</i>
                        </div>
                        <div *ngIf="p.isRevisionRequiredValue==0" style="width:1.25em"></div>
                        <div style="border-radius: 50%; width: 0.9em; height: 0.9em; border: black solid 0.0625em;"
                          [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>
                        <div style="margin-top:-0.35em; white-space:normal; margin-left:0.125em">
                          <div style="display: flex;height:1.5em">
                            <div class="divHover" *ngIf="p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><s
                                style="color:red"><a style="cursor: pointer"
                                  (click)="clickServiceType($event,element, p, 'D')"
                                  title="{{p.serviceTypeDescription}}"><span
                                    class="textHover">{{p.serviceTypeDescription.substr(0,17)}}</span></a></s></div>
                            <div class="divHover" *ngIf="!p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><a
                                style="cursor: pointer" (click)="clickServiceType($event,element, p, 'D')"
                                title="{{p.serviceTypeDescription}}">{{p.serviceTypeDescription.substr(0,17)}}</a></div>
                            <div *ngIf="p.hasTaskDocument" style="margin-top:0.25em" title="documents"><i
                                class="material-icons"
                                style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>
                            <div *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak"
                              matTooltip="{{p.publicNote}}"><i class="material-icons"
                                style="font-size: small;">notes</i></div>
                            <div *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i
                                class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>

                            <div *ngIf="p.messageInCount>0 || p.messageOutCount>0" class="divHover">
                              <i class="material-icons" style="font-size: medium; cursor: pointer; margin-top:0.25em "
                                (click)="openMessages($event, element ,p,'D')">mail_outline</i>
                            </div>

                          </div>
                          <div *ngIf="p.vendorName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.vendorName}}">{{p.vendorName.substr(0,19)}}</div>
                          <div *ngIf="p.countryName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.countryName}}">{{p.countryName.substr(0,19)}}</div>
                          <div *ngIf="p.fpiConfirmationReference!='' && p.tripLegList.length==0"
                            style="margin-top: -0.25em; color: darkblue" title="{{p.fpiConfirmationReference}}">
                            {{p.fpiConfirmationReference!='' &&
                            p.fpiConfirmationReference.length>15?p.fpiConfirmationReference.substr(0,15)+'...':p.fpiConfirmationReference}}
                          </div>
                          <div *ngFor="let t of p.tripLegList" style="margin-left:0em">
                            <div>{{t.departureICAO+ ' - '+t.nextArrivalICAO}}</div>
                          </div>
                          <div *ngIf="p.serviceDate!='' || p.serviceTime!=''"
                            style="margin-top: -0.25em; color: darkblue">{{p.serviceDate | date:'dd-MMM' }}
                            {{p.serviceTime}} </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width:14.25em">
                    <div *ngFor="let p of element.depFlightPlanServiceTaskList" style="margin-left:0em">
                      <div style="display: flex; min-height:1.25em">
                        <div *ngIf="p.isRevisionRequiredValue==1" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_orange"
                            style="font-size: medium">notifications_active</i></div>
                        <div *ngIf="p.isRevisionRequiredValue==2" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_red" style="font-size: medium">notifications_active</i>
                        </div>
                        <div *ngIf="p.isRevisionRequiredValue==0" style="width:1.25em"></div>
                        <div style="border-radius: 50%; width: 0.9em; height: 0.9em; border: black solid 0.0625em;"
                          [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>
                        <div style="margin-top:-0.35em; white-space:normal; margin-left:0.125em">
                          <div style="display: flex;height:1.5em">
                            <div class="divHover" *ngIf="p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><s
                                style="color:red"><a style="cursor: pointer"
                                  (click)="clickServiceType($event,element, p, 'D')"
                                  title="{{p.serviceTypeDescription}}"><span
                                    class="textHover">{{p.serviceTypeDescription.substr(0,17)}}</span></a></s></div>
                            <div class="divHover" *ngIf="!p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><a
                                style="cursor: pointer" (click)="clickServiceType($event,element, p, 'D')"
                                title="{{p.serviceTypeDescription}}">{{p.serviceTypeDescription.substr(0,17)}}</a></div>
                            <div *ngIf="p.hasTaskDocument" style="margin-top:0.25em" title="documents"><i
                                class="material-icons"
                                style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>
                            <div *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak"
                              matTooltip="{{p.publicNote}}"><i class="material-icons"
                                style="font-size: small;">notes</i></div>
                            <div *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i
                                class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>

                            <div *ngIf="p.messageInCount>0 || p.messageOutCount>0" class="divHover">
                              <i class="material-icons" style="font-size: medium; cursor: pointer; margin-top:0.25em "
                                (click)="openMessages($event, element ,p,'D')">mail_outline</i>
                            </div>
                          </div>

                          <div *ngIf="p.vendorName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.vendorName}}">{{p.vendorName.substr(0,19)}}</div>
                          <div *ngIf="p.countryName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.countryName}}">{{p.countryName.substr(0,19)}}</div>
                          <div *ngIf="p.fpiConfirmationReference!='' && p.tripLegList.length==0"
                            style="margin-top: -0.25em; color: darkblue" title="{{p.fpiConfirmationReference}}">
                            {{p.fpiConfirmationReference!='' &&
                            p.fpiConfirmationReference.length>15?p.fpiConfirmationReference.substr(0,15)+'...':p.fpiConfirmationReference}}
                          </div>
                          <div *ngFor="let t of p.tripLegList" style="margin-left:0em">
                            <div>{{t.departureICAO+ ' - '+t.nextArrivalICAO}}</div>
                          </div>
                          <div *ngIf="p.serviceDate!='' || p.serviceTime!=''"
                            style="margin-top: -0.25em; color: darkblue">{{p.serviceDate | date:'dd-MMM'}}
                            {{p.serviceTime!=''? p.serviceTime + ' Z' : ''}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width:12em">
                    <div *ngFor="let p of element.depFlightFollowingTaskList" style="margin-left:0em">
                      <div style="display: flex; min-height:1.25em">
                        <div *ngIf="p.isRevisionRequiredValue==1" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_orange"
                            style="font-size: medium">notifications_active</i></div>
                        <div *ngIf="p.isRevisionRequiredValue==2" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_red" style="font-size: medium">notifications_active</i>
                        </div>
                        <div *ngIf="p.isRevisionRequiredValue==0" style="width:1.25em"></div>
                        <div style="border-radius: 50%; width: 0.9em; height: 0.9em; border: black solid 0.0625em;"
                          [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>
                        <div style="margin-top:-0.35em; white-space:normal; margin-left:0.125em">
                          <div style="display: flex;height:1.5em">
                            <div class="divHover" *ngIf="p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><s
                                style="color:red"><a style="cursor: pointer"
                                  (click)="clickServiceType($event,element, p, 'D')"
                                  title="{{p.serviceTypeDescription}}"><span
                                    class="textHover">{{p.serviceTypeDescription.substr(0,17)}}</span></a></s></div>
                            <div class="divHover" *ngIf="!p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><a
                                style="cursor: pointer" (click)="clickServiceType($event,element, p, 'D')"
                                title="{{p.serviceTypeDescription}}">{{p.serviceTypeDescription.substr(0,17)}}</a></div>
                            <div *ngIf="p.hasTaskDocument" style="margin-top:0.25em" title="documents"><i
                                class="material-icons"
                                style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>

                            <div *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak"
                              matTooltip="{{p.publicNote}}"><i class="material-icons"
                                style="font-size: small;">notes</i></div>
                            <div *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i
                                class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>
                            <div *ngIf="p.messageInCount>0 || p.messageOutCount>0" class="divHover">
                              <i class="material-icons" style="font-size: medium; cursor: pointer; margin-top:0.25em "
                                (click)="openMessages($event, element ,p,'D')">mail_outline</i>
                            </div>
                          </div>

                          <div *ngFor="let f of p.flightFollowingList">
                            <div style="margin-top: -0.25em; color: darkblue" title="{{f.subServiceTypeDescription}}">
                              {{f.subServiceTypeDescription.substr(0,19)}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="element.isFirstGroundStop && element.isLastGroundStop" style="margin-top:0.25em">
                <div style="display: flex">
                  <div style="width:8.5em" [style.background-color]="element.groundStopTypeColor">
                    <div style="display: flex">
                      <div *ngIf="element.icao!='XXXX'"
                        [ngClass]="element.isHomeBaseAirport?'homeBaseAirport divHover':'divHover'"
                        style="cursor: pointer;font-weight: bold; margin-right:0.25em"
                        (click)="clickICAO($event, element)">{{element.icaO_IATA}}</div>
                    </div>
                    <div *ngIf="element.icao=='XXXX'" style="font-weight: bold">{{element.icaO_IATA}}</div>
                    <div style="font-size:x-small;margin-top:-0.25em; white-space:nowrap"
                      title="{{element.airportName}}">
                      {{element.airportName.length>20?element.airportName.substr(0,20)+'...':element.airportName}}</div>
                    <div style="font-size:x-small;margin-top:0em;padding:0em">{{element.airportState_CountryCode}}</div>
                    <div *ngIf="element.isTechStop"
                      style="border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color:#e5e5e5">
                      <div style="margin-top:-0.3em; margin-left:0.25em">Tech Stop</div>
                    </div>
                    <div style="font-size:x-small;margin-top:-0.25em">{{element.groundStopTypeDescription}}</div>
                  </div>
                  <div style="min-width: 11.5em">
                    <div style="display: flex;  cursor: pointer;"  class="divHover" (click)="clickGroundStopDetail($event, element)" >
                      <div style="margin-right:0.25em"><i class="material-icons"
                          style="border: black 0.0625em solid; font-size:large">flight_land</i></div>
                      <div style="margin-top:-0.5em;margin-right:0.25em">
                        <div style="display:flex">
                          <div style="white-space: nowrap; font-weight: bold">
                            {{element.arrivalDateTextUTC!=''?element.arrivalDateTimeUTCDisplay+' Z':'TBA'}}</div>
                          <div *ngIf="element.arrivalUTCTimeConversion!=''"
                            style="font-size:small;margin-top:0em;padding:0em; white-space:nowrap">
                            ({{element.arrivalUTCTimeConversion}})</div>
                        </div>
                        <div style="white-space: nowrap; margin-top:-0.5em">
                          {{element.arrivalDateTextLocal!=''?element.arrivalDateTextLocal+' L':''}}</div>
                      </div>
                    </div>
                    <div style="border-radius: 0.5em; width: 7em; height: 1.25em; border: gray solid 0.0625em;"
                      [style.background-color]="element.arrivalStatusColorCode">
                      <div style="margin-top:-0.3em; text-align:center">{{element.arrivalStatusDescription}}</div>
                    </div>
                    <div *ngIf="element.arrivalGroundStopAlternateAirportList.length>0">
                      <div style="display: flex">
                        <div><i class="material-icons"
                            style="color:gray; font-size:large; margin-top:0.25em">where_to_vote</i></div>
                        <div>
                          <div style="font-weight: bold">Alternate:</div>
                          <div style="display: flex; margin-top:-0.25em">
                            <div *ngFor="let a of element.arrivalGroundStopAlternateAirportList">
                              <div style="cursor: pointer; margin-right:0.25em" class="divHover"
                                (click)="clickAltICAO($event, element, a)">{{a.icao}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="min-width: 18.75em">
                    <div style="display:flex; margin-top:-0.5em; ">
                      <div style="min-width: 9.375em;">
                        <div *ngIf="element.arrivalCrewCount>0" style="white-space:nowrap;font-weight: bold">Crew
                          ({{element.arrivalCrewCount}})</div>
                        <div style="display: flex; margin-top: -0.4em; height: 1.5em" *ngIf="element.arrivalPIC!=null">
                          <div style="white-space:nowrap; font-weight: bold; margin-right:0.25em">PIC:</div>
                          <div class="divHover" style="cursor: pointer" matTooltipClass="tooltipLineBreak"
                            matTooltip="{{element.arrivalPIC.airportViewTooltip}}"
                            (click)="clickPersonName($event, element.arrivalPIC,element)">
                            {{element.arrivalPIC.personNameAbbr.substr(0,12)}}</div>
                          <div
                            *ngIf="(element.arrivalPIC.isBellAlert || element.arrivalPIC.hasExpiredPassport) && (!element.arrivalDateTimePassed && !element.lockdownTrip) && element.arrivalPIC.capacityID < 6"
                            title="{{element.arrivalPIC.missingInfoTooltip}}" style="cursor: pointer; margin-top:0.25em"
                            (click)="clickPersonName($event, element.arrivalPIC,element)"><i
                              class="material-icons-two-tone icon_orange"
                              style="font-size: medium">notifications_active</i></div>
                        </div>
                        <div style="display: flex; margin-top: -0.4em; height: 1.5em" *ngIf="element.arrivalSIC!=null">
                          <div style="white-space:nowrap; font-weight: bold; margin-right:0.25em">SIC:</div>
                          <div class="divHover" style="cursor: pointer" matTooltipClass="tooltipLineBreak"
                            matTooltip="{{element.arrivalSIC.airportViewTooltip}}"
                            (click)="clickPersonName($event, element.arrivalSIC,element)">
                            {{element.arrivalSIC.personNameAbbr.substr(0,12)}}</div>
                          <div
                            *ngIf="(element.arrivalSIC.isBellAlert || element.arrivalSIC.hasExpiredPassport) && (!element.arrivalDateTimePassed && !element.lockdownTrip) && element.arrivalSIC.capacityID < 6"
                            title="{{element.arrivalSIC.missingInfoTooltip}}" style="cursor: pointer; margin-top:0.25em"
                            (click)="clickPersonName($event, element.arrivalSIC,element)"><i
                              class="material-icons-two-tone icon_orange"
                              style="font-size: medium">notifications_active</i></div>
                        </div>
                        <div *ngFor="let p of element.arrivalCrewList; let i=index"
                          style="display: flex; margin-top: -0.4em; height: 1.5em">
                          <div style="white-space:nowrap; width:3em; font-weight: bold">{{i==0? 'Crew: ' :''}}</div>
                          <div style="cursor: pointer; white-space:nowrap" class="divHover"
                            matTooltipClass="tooltipLineBreak" matTooltip="{{p.airportViewTooltip}}"
                            (click)="clickPersonName($event, p, element)">{{p.personNameAbbr.substr(0,12)}}</div>
                          <div
                            *ngIf="(p.isBellAlert || p.hasExpiredPassport) && (!element.arrivalDateTimePassed && !element.lockdownTrip)"
                            style="cursor: pointer; margin-top:0.25em" (click)="clickPersonName($event, p,element)"
                            title="{{p.missingInfoTooltip}}"><i class="material-icons-two-tone icon_orange"
                              style="font-size: medium">notifications_active</i></div>
                        </div>
                        <div *ngIf="element.arrivalCrewCount==0" style="white-space: nowrap;">
                          <div style="white-space:nowrap;font-weight: bold">Crew</div>
                          <div>Missing Crew</div>
                        </div>
                      </div>
                      <div style="margin-left:0.25em; min-width:9.125em">
                        <div *ngIf="element.arrivalPAXCount>0" style="white-space:nowrap; font-weight: bold">PAX
                          ({{element.arrivalPAXCount}})</div>
                        <div *ngFor="let p of element.arrivalPAXList"
                          style="display: flex; margin-top: -0.4em; height: 1.5em">
                          <div style="white-space:nowrap;cursor: pointer;" matTooltipClass="tooltipLineBreak"
                            matTooltip="{{p.airportViewTooltip}}" class="divHover" (click)="clickPersonName($event, p,element)">
                            {{p.personNameAbbr.substr(0,11)}}</div>
                          <div
                            *ngIf="(p.isBellAlert || p.hasExpiredPassport) && (!element.arrivalDateTimePassed && !element.lockdownTrip)"
                            style="cursor: pointer; margin-top:0.25em" title="{{p.missingInfoTooltip}}"
                            (click)="clickPersonName($event, p,element)"><i class="material-icons-two-tone icon_orange"
                              style="font-size: medium">notifications_active</i></div>
                          <div style="margin-top:0.25em"
                            *ngIf="p.isMinorOnboard==true && (!element.arrivalDateTimePassed && !element.lockdownTrip)"
                            title="Minor on board"><i class="material-icons-two-tone icon_green"
                              style="font-size: medium">assignment_ind<!--warning_amber--></i></div>
                        </div>
                        <div *ngIf="element.isArrFerryFlight && element.arrivalPAXCount==0">
                          <div style="white-space:nowrap; font-weight: bold">PAX</div>
                          <div>Ferry Flight</div>
                        </div>
                      </div>
                    </div>
                    <div style="font-size: x-small">
                      <div *ngIf="element.arrivalPetsOnBoard"><mat-checkbox disabled
                          [checked]="element.arrivalPetsOnBoard" style="transform: scale(0.5)"></mat-checkbox>Pets on
                        Board</div>
                      <div *ngIf="element.arrivalWeaponsOnBoard" style="margin-top:-1em"><mat-checkbox disabled
                          [checked]="element.arrivalWeaponsOnBoard" style="transform: scale(0.5)"></mat-checkbox>Weapons
                        on Board</div>
                    </div>
                  </div>
                  <div style="width: 9.35em">
                    <div *ngFor="let p of element.pointOfContactList" style="display:flex">
                      <div style="white-space:nowrap;cursor: pointer" class="divHover" title="{{ p.pointOfContact }}"
                        (click)="clickICAO($event, element)">{{p.pointOfContact.substr(0,19)}}</div>
                      <div></div>
                    </div>
                  </div>
                  <div style="width:13.75em">
                    <div *ngFor="let p of element.arrAirportServiceTaskList" style="margin-left:0em">
                      <div style="display: flex; min-height:1.25em">
                        <div *ngIf="p.isRevisionRequiredValue==1" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_orange"
                            style="font-size: medium">notifications_active</i></div>
                        <div *ngIf="p.isRevisionRequiredValue==2" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_red" style="font-size: medium">notifications_active</i>
                        </div>
                        <div *ngIf="p.isRevisionRequiredValue==0" style="width:1.25em"></div>
                        <div style="border-radius: 50%; width: 0.9em; height: 0.9em; border: black solid 0.0625em;"
                          [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>
                        <div style="margin-top:-0.35em; white-space:normal; margin-left:0.125em">
                          <div style="display: flex;">
                            <div style="display: flex;height:1.5em">
                              <div class="divHover" *ngIf="p.strikeOutService"
                                [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><s
                                  style="color:red"><a style="cursor: pointer"
                                    (click)="clickServiceType($event,element, p, 'D')"
                                    title="{{p.serviceTypeDescription}}"><span
                                      class="textHover">{{p.serviceTypeDescription.substr(0,17)}}</span></a></s></div>
                              <div class="divHover" *ngIf="!p.strikeOutService"
                                [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><a
                                  style="cursor: pointer" (click)="clickServiceType($event,element, p, 'D')"
                                  title="{{p.serviceTypeDescription}}">{{p.serviceTypeDescription.substr(0,17)}}</a>
                              </div>
                              <div *ngIf="p.hasTaskDocument" style="margin-top:0.25em" title="documents"><i
                                  class="material-icons"
                                  style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>
                              <div *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak"
                                matTooltip="{{p.publicNote}}"><i class="material-icons"
                                  style="font-size: small;">notes</i></div>
                              <div *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i
                                  class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>
                              <div *ngIf="p.messageInCount>0 || p.messageOutCount>0" style="margin-top:0.25em"
                                class="divHover">
                                <i class="material-icons" style="font-size: medium;"
                                  (click)="openMessages($event, element ,p,'A')">mail_outline</i>
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div *ngIf="p.vendorName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.vendorName}}">{{p.vendorName.substr(0,19)}}</div>
                          <div *ngIf="p.countryName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.countryName}}">{{p.countryName.substr(0,19)}}</div>
                          <div *ngIf="p.hotelName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.hotelName}}">{{p.hotelName.substr(0,19)}}</div>
                          <div *ngIf="p.fpiConfirmationReference!=''" style="margin-top: -0.25em; color: darkblue;"
                            title="{{p.fpiConfirmationReference}}">{{p.fpiConfirmationReference!='' &&
                            p.fpiConfirmationReference.length>15?p.fpiConfirmationReference.substr(0,15)+'...':p.fpiConfirmationReference}}
                          </div>
                          <div *ngIf="p.serviceDate!='' || p.serviceTime!=''"
                            style="margin-top: -0.25em; color: darkblue">{{p.serviceDate | date:'dd-MMM'}}
                            {{p.serviceTime!=''? p.serviceTime + ' Z' : ''}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width:13.5em">
                    <div *ngFor="let p of element.arrPreflightServiceTaskList" style="margin-left:0em">
                      <div style="display: flex; min-height:1.25em">
                        <div *ngIf="p.isRevisionRequiredValue==1" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_orange"
                            style="font-size: medium">notifications_active</i></div>
                        <div *ngIf="p.isRevisionRequiredValue==2" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_red" style="font-size: medium">notifications_active</i>
                        </div>
                        <div *ngIf="p.isRevisionRequiredValue==0" style="width:1.25em"></div>
                        <div style="border-radius: 50%; width: 0.9em; height: 0.9em; border: black solid 0.0625em;"
                          [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>
                        <div style="margin-top:-0.35em; white-space:normal; margin-left:0.125em">
                          <div style="display: flex;height:1.5em">
                            <div class="divHover" *ngIf="p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><s
                                style="color:red"><a style="cursor: pointer"
                                  (click)="clickServiceType($event,element, p, 'D')"
                                  title="{{p.serviceTypeDescription}}"><span
                                    class="textHover">{{p.serviceTypeDescription.substr(0,17)}}</span></a></s></div>
                            <div class="divHover" *ngIf="!p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><a
                                style="cursor: pointer" (click)="clickServiceType($event,element, p, 'D')"
                                title="{{p.serviceTypeDescription}}">{{p.serviceTypeDescription.substr(0,17)}}</a></div>
                            <div *ngIf="p.hasTaskDocument" style="margin-top:0.25em" title="documents"><i
                                class="material-icons"
                                style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>
                            <div *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak"
                              matTooltip="{{p.publicNote}}"><i class="material-icons"
                                style="font-size: small;">notes</i></div>
                            <div *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i
                                class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>
                            <div *ngIf="p.messageInCount>0 || p.messageOutCount>0" style="margin-top:0.25em"
                              class="divHover">
                              <i class="material-icons" style="font-size: medium;"
                                (click)="openMessages($event, element ,p,'A')">mail_outline</i>
                            </div>
                          </div>
                          <div *ngIf="p.vendorName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.vendorName}}">{{p.vendorName.substr(0,19)}}</div>
                          <div *ngIf="p.countryName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.countryName}}">{{p.countryName.substr(0,19)}}</div>
                          <div *ngIf="p.fpiConfirmationReference!='' && p.tripLegList.length==0"
                            style="margin-top: -0.25em; color: darkblue" title="{{p.fpiConfirmationReference}}">
                            {{p.fpiConfirmationReference!='' &&
                            p.fpiConfirmationReference.length>15?p.fpiConfirmationReference.substr(0,15)+'...':p.fpiConfirmationReference}}
                          </div>
                          <div *ngFor="let t of p.tripLegList" style="margin-left:0em">
                            <div>{{t.departureICAO+ ' - '+t.nextArrivalICAO}}</div>
                          </div>
                          <div *ngIf="p.serviceDate!='' || p.serviceTime!=''"
                            style="margin-top: -0.25em; color: darkblue">{{p.serviceDate | date:'dd-MMM'}}
                            {{p.serviceTime!=''? p.serviceTime + ' Z' : ''}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width:14.25em">
                    <div *ngFor="let p of element.arrFlightPlanServiceTaskList" style="margin-left:0em">
                      <div style="display: flex; min-height:1.25em">
                        <div *ngIf="p.isRevisionRequiredValue==1" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_orange"
                            style="font-size: medium">notifications_active</i></div>
                        <div *ngIf="p.isRevisionRequiredValue==2" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_red" style="font-size: medium">notifications_active</i>
                        </div>
                        <div *ngIf="p.isRevisionRequiredValue==0" style="width:1.25em"></div>
                        <div style="border-radius: 50%; width: 0.9em; height: 0.9em; border: black solid 0.0625em;"
                          [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>
                        <div style="margin-top:-0.35em; white-space:normal; margin-left:0.125em">
                          <div style="display: flex;height:1.5em">
                            <div class="divHover" *ngIf="p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><s
                                style="color:red"><a style="cursor: pointer"
                                  (click)="clickServiceType($event,element, p, 'D')"
                                  title="{{p.serviceTypeDescription}}"><span
                                    class="textHover">{{p.serviceTypeDescription.substr(0,17)}}</span></a></s></div>
                            <div class="divHover" *ngIf="!p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><a
                                style="cursor: pointer" (click)="clickServiceType($event,element, p, 'D')"
                                title="{{p.serviceTypeDescription}}">{{p.serviceTypeDescription.substr(0,17)}}</a></div>
                            <div *ngIf="p.hasTaskDocument" style="margin-top:0.25em" title="documents"><i
                                class="material-icons"
                                style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>
                            <div *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak"
                              matTooltip="{{p.publicNote}}"><i class="material-icons"
                                style="font-size: small;">notes</i></div>
                            <div *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i
                                class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>
                            <div class="divHover" *ngIf="p.messageInCount>0 || p.messageOutCount>0"
                              style="margin-top:0.25em">
                              <i class="material-icons" style="font-size: medium;"
                                (click)="openMessages($event, element ,p,'A')">mail_outline</i>
                            </div>
                          </div>
                          <div *ngIf="p.vendorName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.vendorName}}">{{p.vendorName.substr(0,19)}}</div>
                          <div *ngIf="p.countryName!=''" style="margin-top: -0.25em; color: darkblue"
                            title="{{p.countryName}}">{{p.countryName.substr(0,19)}}</div>
                          <div *ngIf="p.fpiConfirmationReference!='' && p.tripLegList.length==0"
                            style="margin-top: -0.25em; color: darkblue" title="{{p.fpiConfirmationReference}}">
                            {{p.fpiConfirmationReference!='' &&
                            p.fpiConfirmationReference.length>15?p.fpiConfirmationReference.substr(0,15)+'...':p.fpiConfirmationReference}}
                          </div>

                          <div *ngFor="let t of p.tripLegList" style="margin-left:0em">
                            <div>{{t.departureICAO+ ' - '+t.nextArrivalICAO}}</div>
                          </div>
                          <div *ngIf="p.serviceDate!='' || p.serviceTime!=''"
                            style="margin-top: -0.25em; color: darkblue">{{p.serviceDate | date:'dd-MMM'}}
                            {{p.serviceTime!=''? p.serviceTime + ' Z' : ''}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width:12em">
                    <div *ngFor="let p of element.arrFlightFollowingTaskList" style="margin-left:0em">
                      <div style="display: flex; min-height:1.25em">
                        <div *ngIf="p.isRevisionRequiredValue==1" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_orange"
                            style="font-size: medium">notifications_active</i></div>
                        <div *ngIf="p.isRevisionRequiredValue==2" style="margin-top:-0.25em; cursor: pointer;"
                          (click)="clickTripRevisionRequired($event, element, p)"><i
                            class="material-icons-two-tone icon_red" style="font-size: medium">notifications_active</i>
                        </div>
                        <div *ngIf="p.isRevisionRequiredValue==0" style="width:1.25em"></div>
                        <div style="border-radius: 50%; width: 0.9em; height: 0.9em; border: black solid 0.0625em;"
                          [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>
                        <div style="margin-top:-0.35em; white-space:normal; margin-left:0.125em">
                          <div style="display: flex;height:1.5em">
                            <div class="divHover" *ngIf="p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><s
                                style="color:red"><a style="cursor: pointer"
                                  (click)="clickServiceType($event,element, p, 'D')"
                                  title="{{p.serviceTypeDescription}}"><span
                                    class="textHover">{{p.serviceTypeDescription.substr(0,17)}}</span></a></s></div>
                            <div class="divHover" *ngIf="!p.strikeOutService"
                              [ngClass]="{ 'divImmediatePriority': p.immediateActionRequired, 'divHighLight': gstId !== '' && gstId === p.groundStopTaskGUID }"><a
                                style="cursor: pointer" (click)="clickServiceType($event,element, p, 'D')"
                                title="{{p.serviceTypeDescription}}">{{p.serviceTypeDescription.substr(0,17)}}</a></div>
                            <div *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak"
                              matTooltip="{{p.publicNote}}"><i class="material-icons"
                                style="font-size: small;">notes</i></div>
                            <div *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i
                                class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>
                            <div class="divHover" *ngIf="p.messageInCount>0 || p.messageOutCount>0"
                              style="margin-top:0.25em">
                              <i class="material-icons" style="font-size: medium;"
                                (click)="openMessages($event, element ,p,'A')">mail_outline</i>
                            </div>
                          </div>
                          <div *ngFor="let f of p.flightFollowingList">
                            <div style="margin-top: -0.25em; color: darkblue" title="{{f.subServiceTypeDescription}}">
                              {{f.subServiceTypeDescription.substr(0,19)}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="!element.isLastGroundStop" style="margin-top:-1em; margin-bottom:-1em">
                  <hr style="border: 0.0625em solid darkgray;" />
                </div>
              </div>

            </td>
          </ng-container>
          <ng-container matColumnDef="callLog">
            <!--<th mat-header-cell *matHeaderCellDef style="width:6%;text-align:left !important">-->
            <th mat-header-cell *matHeaderCellDef style="width:9%;text-align:left !important">
              <div style="margin-top:-0.25em;">Audit Trail</div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div *ngFor="let u of element.tripRevisionList" style="display:flex">
                <button mat-button class="btn-low" *ngIf="!u.isExternal"
                  style="align-self:center;min-width: 5em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em"
                  (click)="clickUsername($event, element,u.userName)">
                  <div style="margin-top:-0.25em; font-weight: normal !important;">{{u.userName}}</div>
                </button>
                <button mat-button class="btn-green" *ngIf="u.isExternal"
                  style="align-self:center;min-width: 4em; height:1.5em"
                  (click)="clickUsername($event, element,u.userName)">
                  <div style="margin-top:-0.25em; font-weight: normal !important;">{{u.userName}}</div>
                </button>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <!--<th mat-header-cell *matHeaderCellDef style="width:9%">-->
            <th mat-header-cell *matHeaderCellDef style="width:4%">
              <div>Actions</div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div style="display: flex; width:5em;justify-content: center">
                <!--<div><i class="material-icons-outlined iconHover" style="font-size: x-large;cursor: pointer;" title="Manage Services" (click)="clickAddServices($event, element);">task_alt</i></div>-->
                <div><i class="material-icons-outlined iconHover" style="font-size: large;cursor: pointer;"
                    title="Update Business Stop Details"
                    (click)="clickGroundStopDetail($event, element)">event_available</i></div>
                <!-- <div><i class="material-icons-outlined iconHover" style="font-size: large;cursor: pointer;" title="Manage Crew & PAX Details" (click)="clickEditCrewPax($event, element);">manage_accounts</i></div> -->
                <div *ngIf="!element.isLastGroundStop"><i class="material-icons-outlined iconHover"
                    style="font-size: large;cursor: pointer;" title="Configure Alternate Airports, ETP &ETOPS"
                    (click)="clickEditAlternateAirport($event, element);">edit_location_alt</i></div>
                <!-- <div><i class="material-icons-outlined iconHover" style="font-size: large;cursor: pointer;" title="Add/Edit Business Stops" (click)="clickAdd($event, element);">airline_stops</i></div> -->
              </div>
            </td>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn"
          [class.hidden]="hidePastStop && row.arrivalDateTimePassed && row.departureDateTimePassed?true:false"
          [style.background-color]="gsId!='' && row.groundStopGUID==gsId?'lightgray':''"></tr>
      </table>
    </div>

  </form>
</div>
<div style="z-index:100; top: 23.5em ; left: 32em; position:absolute; min-width: 20em;  box-shadow: 15px 10px 15px rgba(0, 0, 0, 0.2), 2px 11px 17px rgba(0, 0, 0, 0.1) !important">
  <app-spin-details *ngIf="showSpin && recordList.length == 0"></app-spin-details>
  <app-spin  *ngIf="showSpin &&  recordList.length > 0"></app-spin >
    <!-- <app-spin *ngIf="showSpin "></app-spin> -->
</div>
