<div class="divLeft" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [cdkDragDisabled]="!isPopup">
  <div style="width:83em" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [cdkDragDisabled]="!isPopup">
    <div>
      <div style="display:flex;justify-content:space-between;margin-bottom:.5em">
        <div>
          <h3 style="display: inline-flex;">Aircraft Profile - {{registration}} - <h3 style="color: #1976e6;">&nbsp;{{customerName}}</h3></h3>
          
        </div>
        <div *ngIf="isPopup">
          <button mat-icon-button (click)="openAircraftProfileComponent()"> <i class="material-icons-outlined"
              style="font-size:x-large;  ">arrow_circle_left</i> </button>
          <button mat-icon-button (click)="clickRouteNew('/aircraft/aircraft-profile-details')"> <i class="material-icons"
              style="font-size:x-large;  ">open_in_new</i> </button>
          <button mat-icon-button mat-dialog-close><i class="material-icons" style="font-size: x-large;">close
            </i></button>
        </div>
        <button *ngIf="!isPopup" mat-button class="btn-low" style="height: 2em;" (click)="clickClose()"><mat-icon
            style="margin-top:-.2em">arrow_back</mat-icon>Manage Aircraft Profiles</button>

      </div>
    </div>
    <div>
      <form *ngIf="aircraft_profile_edit_form" [formGroup]="aircraft_profile_edit_form" (ngSubmit)="clickSave()">
        <div>
          <div
            style="display:inline-flex;background-color:#f8f8f8;margin-right:0.5em;margin-bottom:0.5em;padding-left:0.5em;width:83em">
            <div style="margin-right:0.25em;margin-top:.5em">
              <div style="width:6em; height:4em; border: 0.0625em black solid ;border-radius: 0.25em">
                <img *ngIf="photoFilePath!=''" [src]="photoFilePath" style="width:6em;height:4em; border:0" />
              </div>
              <div>
                <label *ngIf="photoFilePath==''"
                  style="font-size: small; text-decoration:underline; cursor: pointer; color: blue">
                  <input #photo formControlName="photo" id="photo" type="file" ng2FileSelect [uploader]="uploader"
                    style="display:none" accept="*" (change)="importPhoto($event)" maxFileSize="28000000" />
                  Load photo
                </label>
                <a *ngIf="photoFilePath!=''" (click)="clickRemovePhoto()"
                  style="font-size: small; text-decoration:underline; cursor: pointer; color: blue">Remove photo</a>
              </div>

              <div *ngIf="errMsg!=''" class="inline-block"
                style="margin-top:-0.5em; white-space:pre-wrap;background-color: #f9d2de"><label
                  style="color: black; font-size:small">{{errMsg}}</label></div>
            </div>
            <div class="form-group" style="margin-left: .5em;margin-top:.5em;margin-right:0.625em;width:max-content">
              <label for="client_name" style="font-size:small">Client</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                <input type="text" formControlName="client_name" class="form-control" readonly
                  style="background-color:white; padding: 0em;min-width:13em;height:1.5625em;font-size:small;" />
              </div>
            </div>
            <div class="form-group" style="margin-left: 0em;margin-top:.5em;margin-right:0.625em;">
              <label for="registration" style="font-size:small">Registration</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                <input type="text" formControlName="registration" class="form-control" readonly
                  style="background-color:white; padding: 0em;width:8em;height:1.5625em;font-size:small;" />
              </div>
            </div>
            <div class="form-group" style="margin-left: 0em;margin-top:.5em;margin-right:0.625em;">
              <label for="call_sign" style="font-size:small">Call Sign</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                <input type="text" formControlName="call_sign" class="form-control" readonly
                  style="background-color:white; padding: 0em;width:8em;height:1.5625em;font-size:small;" />
              </div>
            </div>
            <div class="form-group" style="margin-top:.5em; margin-right:0.625em">
              <label for="manufacturer" style="font-size:small">Manufacturer </label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                <input type="text" formControlName="manufacturer" class="form-control" readonly
                  style="background-color:white;padding: 0em;width:13em;height:1.5625em;font-size:small;" />
              </div>
            </div>

            <div class="form-group" style="margin-top:.5em; margin-right:0.625em">
              <label for="aircraft_model" style="font-size:small">Model</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                <input type="text" formControlName="aircraft_model" class="form-control" readonly
                  style="background-color:white;padding: 0em;width:13em;height:1.5625em;font-size:small;" />
              </div>
            </div>

            <div class="form-group" style="margin-top:.5em; margin-right:0.625em">
              <label for="aircraft_type" style="font-size:small">Type</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                <input type="text" formControlName="aircraft_type" readonly class="form-control"
                  style="padding: 0em; width: 8em; height: 1.5625em; background-color:white; font-size: small" />
              </div>
            </div>
            <div style="display:inline-flex;margin-top:1.65em;margin-right:.5em">
              <button *ngIf="userType=='internal'" mat-button class="btn-high btn-primary"
                (click)="clickEditMain()">Edit</button>
            </div>
            <div class="form-group" style="margin-top:.5em; margin-left:.5em; margin-right:0.625em">
              <label for="disable_record" style="font-size:small">Disable Record</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                <input type="text" formControlName="disable_record" readonly class="form-control"
                  style="padding: 0em; width: 7em; height: 1.5625em;  font-size: small" />
              </div>
            </div>
            <div class="form-group" style="margin-top:.5em; margin-right:0.625em">
              <label for="modifiedBy" style="font-size:small">Modified By</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                <input type="text" formControlName="modifiedBy" readonly class="form-control"
                  style="padding: 0em; width: 8em; height: 1.5625em;  font-size: small" />
              </div>

              <label for="modifiedDate" style="font-size:small">Modified Date</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                <input type="text" formControlName="modifiedDate" readonly class="form-control"
                  style="padding: 0em; width: 8em; height: 1.5625em;  font-size: small" />
              </div>
            </div>

          </div>
        </div>
        <div style="display:inline-flex">
          <div class="form-group form-inline" style="margin-bottom: 0;" >
            <ul class="tabul">
              <li [ngClass]="{ 'btn-low':selectedTabName=='details','btn-high pointer':selectedTabName!='details'  }"
                (click)="clickTab('details')" style=" border-left  : black 0.0625em solid;">
                <div style="margin-top:0.25em">Aircraft Details</div>
              </li>
              <li [ngClass]="{ 'btn-low':selectedTabName=='fp','btn-high pointer':selectedTabName!='fp' }"
                (click)="clickTab('fp')">
                <div style="margin-top:0.25em">Flight Planning</div>
              </li>
              <li [ngClass]="{ 'btn-low':selectedTabName=='equip','btn-high pointer':selectedTabName!='equip' }"
                (click)="clickTab('equip')">
                <div style="margin-top:0.25em">Comm, Nav, Approach & Equipment</div>
              </li>
              <li [ngClass]="{ 'btn-low':selectedTabName=='documents','btn-high pointer':selectedTabName!='documents' }"
                (click)="clickTab('documents')">
                <div style="margin-top:0.25em">Documents</div>
              </li>
              <li
                [ngClass]="{ 'btn-low':selectedTabName=='ownerOperator','btn-high pointer':selectedTabName!='ownerOperator' }"
                (click)="clickTab('ownerOperator')">
                <div style="margin-top:0.25em">Operator/Owner Details</div>
              </li>
              <li
                [ngClass]="{ 'btn-low':selectedTabName=='regulatory','btn-high pointer':selectedTabName!='regulatory' }"
                (click)="clickTab('regulatory')">
                <div style="margin-top:0.25em">Regulatory Services</div>
              </li>
              <li [ngClass]="{ 'btn-low':selectedTabName=='comms','btn-high pointer':selectedTabName!='comms' }"
                (click)="clickTab('comms')">
                <div style="margin-top:0.25em">Comms, Phones, and Emails</div>
              </li>

            </ul>

          </div>
          <div
            *ngIf="selectedTabName!='details' && selectedTabName!='equip' && selectedTabName!='fp' && userType=='internal'"
            style="display:flex; margin-left: 2em; margin-top:0.5em">
            <mat-checkbox color="primary" formControlName="includeDisabledRecords" style="font-size:small"
              (change)="includeDisabledRecordsOnChange($event)">Include disabled records</mat-checkbox>
          </div>
        </div>

      </form>

      <div>
        <app-aircraft-profile-details #aircraftDetails 
          *ngIf="selectedTabName=='details'"  style="overflow-y: auto;height: 39em; display: block;  user-select:text" (mousedown)="$event.stopPropagation()"></app-aircraft-profile-details>
        <app-aircraft-equipment #aircraftEquipment *ngIf="selectedTabName=='equip'" 
          [aircraftId]="aircraftGuid" style="overflow-y: auto;height: 39em; display: block;  user-select:text" (mousedown)="$event.stopPropagation()"></app-aircraft-equipment>
        <app-aircraft-documents *ngIf="selectedTabName=='documents'" [aircraftId]="aircraftGuid" 
          [includeDisabledRecords]="includeDisabledRecords" style="overflow-y: auto;height: 39em; display: block;  user-select:text" (mousedown)="$event.stopPropagation()"></app-aircraft-documents>
        <app-aircraft-owner-operator *ngIf="selectedTabName=='ownerOperator'" [aircraftId]="aircraftGuid" 
          [includeDisabledRecords]="includeDisabledRecords" style="overflow-y: auto;height: 39em; display: block; user-select:text" (mousedown)="$event.stopPropagation()"></app-aircraft-owner-operator>
        <app-aircraft-regulatory-services *ngIf="selectedTabName=='regulatory'" [aircraftId]="aircraftGuid" 
          [includeDisabledRecords]="includeDisabledRecords" style="overflow-y: auto;height: 39em; display: block; user-select:text" (mousedown)="$event.stopPropagation()"></app-aircraft-regulatory-services>
        <app-aircraft-comms *ngIf="selectedTabName=='comms'" [aircraftId]="aircraftGuid" 
          [includeDisabledRecords]="includeDisabledRecords" style="overflow-y: auto;height: 39em; display: block; user-select:text" (mousedown)="$event.stopPropagation()"></app-aircraft-comms>
        <app-aircraft-flight-planning #flightPlanning *ngIf="selectedTabName=='fp'" [aircraftId]="aircraftGuid" style="overflow-y: auto;height: 39em; display: block; user-select:text" (mousedown)="$event.stopPropagation()"></app-aircraft-flight-planning> 
      </div>
    </div>
  </div>
</div>
