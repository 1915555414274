<div *ngIf="grantAccessVendorProfiles" [ngClass]="{ 'divLeft':!isPopup}"  cdkDrag
cdkDragRootElement=".cdk-overlay-pane"
cdkDragHandle [cdkDragDisabled]="!isPopup">
  <div >
    <div class="d-inline-flex justify-content-between" style="width:100%">
      <h5>Vendor Profile Setup</h5>
      <div style="justify-content:flex-end;padding-left: 0em;padding-bottom:0.5em">
        <button *ngIf="!isPopup" mat-button class="btn-low" (click)="clickClose()"><mat-icon style="margin-top:-.2em">arrow_back</mat-icon> Vendor Profiles</button>
        <mat-icon class="mr-2 pointer material-icons-outlined" *ngIf="isPopup" (click)="clickClose()">arrow_circle_left</mat-icon>
        <mat-icon class="mr-2 pointer" *ngIf="isPopup" (click)="openInNew()">open_in_new</mat-icon>
        <mat-icon *ngIf="isPopup" mat-dialog-close>close</mat-icon>
      </div>
    </div>
    <div>
      <form *ngIf="vendor_profile_setup_form" [formGroup]="vendor_profile_setup_form">
        <div  [ngClass]="{ 'divPopup':isPopup}">
          <div class="d-inline-flex">
            <div style="background-color:#f0f0f0">
              <div class="d-inline-flex">
                <div class="form-group" style="margin-left: .5em;margin-top:0em;margin-right:0.625em;">
                  <label for="vendorName" style="font-size:small">Vendor Name</label>
                  <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                    <input type="text" formControlName="vendorName" class="form-control inputBoxReadOnly" style="width:18em" readonly  (mousedown)="$event.stopPropagation()"/>
                  </div>
                </div>
                <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                  <label for="legalEntityName" style="font-size:small">Legal Entity Name</label>
                  <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                    <input type="text" formControlName="legalEntityName" class="form-control inputBoxReadOnly" style="width:18em"  (mousedown)="$event.stopPropagation()"/>
                  </div>
                </div>
                <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                  <label for="location" style="font-size:small">Location</label>
                  <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                    <input type="text" formControlName="location" class="form-control inputBoxReadOnly" (mousedown)="$event.stopPropagation()" />
                  </div>
                </div>
                <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                  <label for="rating" style="font-size:small">Rating</label>
                  <div style="margin-top:-0.35em;">
                    <input type="text" formControlName="rating" class="form-control" readonly style="padding: 0em;width:13em;background-color:white;height:1.5625em;font-size:small;" (mousedown)="$event.stopPropagation()" />
                  </div>
                </div>
                <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                  <label for="contractStatus" style="font-size:small">Contract Status</label>
                  <div style="margin-top:-0.35em;">
                    <input type="text" formControlName="contractStatus" class="form-control" readonly style="padding: 0em;width:15em;background-color:white;height:1.5625em;font-size:small;" (mousedown)="$event.stopPropagation()" />
                  </div>
                </div>
                <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                  <label for="creationDate" style="font-size:small">Creation Date</label>
                  <div style="margin-top:-0.35em;">
                    <input type="text" formControlName="creationDate" class="form-control text-uppercase" readonly style="padding: 0em;width:6em;background-color:white;height:1.5625em;font-size:small;"  (mousedown)="$event.stopPropagation()"/>
                  </div>

                </div>
                <div class="form-group" style="margin-right:0.625em; margin-top:1em ">
                  <button mat-raised-button class="btn-high" (click)="clickEdit()">EDIT</button>
                </div>
              </div>
              <div class="position-relative">
                <div class="d-inline-flex">
                  <div class="form-group" style="margin-left: .5em;margin-top:0em;margin-right:0.625em;">
                    <label for="account_type" style="font-size:small">Account Type</label>
                    <div style="margin-top:-0.35em;">
                      <input type="text" formControlName="account_type" class="form-control inputBoxReadOnly" readonly  (mousedown)="$event.stopPropagation()"/>
                    </div>
                  </div>
                  <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                    <label for="role" style="font-size:small">Role</label>
                    <div style="margin-top:-0.35em;">
                      <input type="text" formControlName="role" class="form-control inputBoxReadOnly" style="width:20em" readonly  (mousedown)="$event.stopPropagation()"/>
                    </div>
                  </div>
                  <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                    <label for="facility_type" style="font-size:small">Facility Type</label>
                    <div style="margin-top:-0.35em;">
                      <input type="text" formControlName="facility_type" class="form-control inputBoxReadOnly" style="width:10em" readonly (mousedown)="$event.stopPropagation()" />
                    </div>
                  </div>

                  <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                    <label for="compliance_status" style="font-size:small">Compliance Status</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                      <input type="text" formControlName="compliance_status" readonly class="form-control inputBoxReadOnly" style="padding: 0em; height: 1.5625em; font-size: small" (mousedown)="$event.stopPropagation()" />
                    </div>
                  </div>
                  <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                    <label for="regulatory_status" style="font-size:small">Regulatory Status</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                      <input type="text" formControlName="regulatory_status" readonly class="form-control inputBoxReadOnly" style="padding: 0em; height: 1.5625em; font-size: small"  (mousedown)="$event.stopPropagation()"/>
                    </div>
                  </div>
                  <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                    <label for="accounting_status" style="font-size:small">Accounting Status</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                      <input type="text" formControlName="accounting_status" readonly class="form-control inputBoxReadOnly" style="padding: 0em; height: 1.5625em; font-size: small" (mousedown)="$event.stopPropagation()" />
                    </div>
                  </div>
                  <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                    <label for="operation_status" style="font-size:small">Operation Status</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                      <input type="text" formControlName="operation_status" readonly class="form-control inputBoxReadOnly" style="padding: 0em; height: 1.5625em; font-size: small"  (mousedown)="$event.stopPropagation()"/>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div style="background-color:#f0f0f0">
              <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                <label for="modifiedBy" style="font-size:small">Modified by</label>
                <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <input type="text" formControlName="modifiedBy" readonly class="form-control" style="padding: 0em; width: 10em; height: 1.5625em; font-size: small" />
                </div>
              </div>
              <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                <label for="modifiedDate" style="font-size:small">Modified Date</label>
                <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <input type="text" formControlName="modifiedDate" readonly class="form-control" style="padding: 0em; width: 10em; height: 1.5625em; font-size: small" />
                  {{getModifiedDate}}
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; white-space:pre-wrap"><label style="color:red; font-size: small">{{errMsg}}</label></div>

          <div *ngIf="msg!=''" class="d-block" style="margin:0"><label style="color:forestgreen">{{msg}}</label></div>
          <section class="mt-2">
            <mat-button-toggle-group [(ngModel)]="selectedTabName" [ngModelOptions]="{standalone: true}">
              <mat-button-toggle [value]="'notes'">Notes</mat-button-toggle>
              <mat-button-toggle [value]="'comms'">Comms</mat-button-toggle>
              <mat-button-toggle [value]="'person'">Contacts</mat-button-toggle>
              <mat-button-toggle [value]="'hours'">Hours</mat-button-toggle>
              <mat-button-toggle [value]="'address'">Address</mat-button-toggle>
              <mat-button-toggle [value]="'document'">Documents</mat-button-toggle>
              <mat-button-toggle [value]="'map'">Map Profile</mat-button-toggle>
            </mat-button-toggle-group>
            <mat-checkbox color="primary" formControlName="includeDisabledRecords" style="transform:scale(.8);margin-bottom:-.5em" (change)="includeDisabledRecordsOnChange($event)">Include disabled records</mat-checkbox>
          </section>
          <div [ngStyle]="{'max-height': isPopup? '30em':''}" style="overflow-y:auto;overflow-x:hidden">
            <app-vendor-note style="user-select:text" (mousedown)="$event.stopPropagation()" #vendorNote *ngIf="selectedTabName=='notes'" [vendorGUID]="vendorGUID" [includeDisabledRecords]="includeDisabledRecords" (getModifiedBy)="getModifiedByHandler($event)" (getModifiedDate)="getModifiedDateHandler($event)"></app-vendor-note>
            <app-vendor-comms style="user-select:text" (mousedown)="$event.stopPropagation()" #vendorComms *ngIf="selectedTabName=='comms'" [vendorGUID]="vendorGUID" [includeDisabledRecords]="includeDisabledRecords" (getModifiedBy)="getModifiedByHandler($event)" (getModifiedDate)="getModifiedDateHandler($event)"></app-vendor-comms>
            <app-vendor-personlist style="user-select:text" (mousedown)="$event.stopPropagation()" #vendorPersonComms *ngIf="selectedTabName=='person'" [vendorGUID]="vendorGUID" [isPopup]="isPopup" [includeDisabledRecords]="includeDisabledRecords" (getModifiedBy)="getModifiedByHandler($event)" (getModifiedDate)="getModifiedDateHandler($event)"></app-vendor-personlist>
            <app-vendor-hours-list style="user-select:text" (mousedown)="$event.stopPropagation()" #vendorHours *ngIf="selectedTabName=='hours'" [vendorGUID]="vendorGUID" [includeDisabledRecords]="includeDisabledRecords" (getModifiedBy)="getModifiedByHandler($event)" (getModifiedDate)="getModifiedDateHandler($event)"></app-vendor-hours-list>
            <app-vendor-address style="user-select:text" (mousedown)="$event.stopPropagation()" #vendorAddress *ngIf="selectedTabName=='address'" [vendorGUID]="vendorGUID" [includeDisabledRecords]="includeDisabledRecords" (getModifiedBy)="getModifiedByHandler($event)" (getModifiedDate)="getModifiedDateHandler($event)"></app-vendor-address>
            <app-vendor-documentlist style="user-select:text" (mousedown)="$event.stopPropagation()" #vendorDocument *ngIf="selectedTabName=='document'" [vendorGUID]="vendorGUID" [includeDisabledRecords]="includeDisabledRecords" (getModifiedBy)="getModifiedByHandler($event)" (getModifiedDate)="getModifiedDateHandler($event)"></app-vendor-documentlist>
            <app-vendor-map-profile-list style="user-select:text" (mousedown)="$event.stopPropagation()" *ngIf="selectedTabName=='map'" [vendorGUID]="vendorGUID" [airportID]="airportID" [includeDisabledRecords]="includeDisabledRecords" [markerTitle]="icao" [defaultLat]="latitude" [defaultLon]="longitude"></app-vendor-map-profile-list>
          </div>
 </div>

      </form>

    </div>


  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>





