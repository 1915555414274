import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { CustomValidators } from '../common-utility/custom.validators';
import { GlobalConstant } from '../common-utility/global-constant';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { HeadlineModel } from '../models/headline.model';
import { ContentDocumentModel } from '../models/content-document.model';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { FileModel } from '../models/file.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { CustomerService } from '../services/customer.service';
import { DialogService } from '../services/dialog.service';
import { CustomerModel } from '../models/customer.model';
import { map, startWith } from 'rxjs/operators';
import { HeadlineTypeModel } from '../models/headline-type.model';
import { CKEditorConfig } from '../ckeditor5/ckeditor-config';
import * as DecoupledEditor from '../ckeditor5/ckeditor';

@Component({
  selector: 'app-fpc-news-edit-dialog',
  templateUrl: './fpc-news-edit-dialog.component.html',
  styleUrls: ['./fpc-news-edit-dialog.component.css']
})

/** headline-dialog component*/
export class FPCNewsEditDialogComponent implements OnInit {
  public Editor = DecoupledEditor;

  public CKEConfig = CKEditorConfig.ckeditorConfigClient;
  sortOrder: number ;

  public onReady(editor) {

    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()

    );

    //const clipboardPlugin = editor.plugins.get('ClipboardPipeline');
    //const editingView = editor.editing.view;

    //editingView.document.on('clipboardInput', (evt, data) => {
    //  if (editor.isReadOnly) {
    //    return;
    //  }
    //  const dataTransfer = data.dataTransfer;
    //  let content = plainTextToHtml(dataTransfer.getData('text/plain'));
    //  content = content.replace(/<p>/gi, '<p class=\"email-body\">')
    //  data.content = editor.data.htmlProcessor.toView(content);

    //  editingView.scrollToTheSelection();
    //}, { priority: 'high' });


    //const imageUploadEditing = editor.plugins.get('ImageUploadEditing');

    //imageUploadEditing.on('uploadComplete', (evt, { data, imageElement }) => {

    //  let img = new MessageEmbeddedImageModel();
    //  img.imageBase64String = data.model[0].bFile;
    //  img.documentName = data.model[0].fileName;
    //  img.mimeType = data.model[0].mimeType;
    //  img.isInline = true;
    //  img.contentId = data.model[0].fileName;
    //  this.embeddedImageList.push(img);
    //});
    //let data = "";
    //if (!this.isDraft)
    //  data += "<p class=\"email-body\"><br><br></p>" + this.defaultSignature
    //data += this.emailBody;
    //editor.setData(data);
    ////editor.focus();
    //editor.execute('style', 'Email Body');
    // editor.execute('style', { name: 'Email Body' });
    //editor.execute('fontSize', { value: '14px' });
    editor.setData(this.headlineStory);
  }
  @Input() headlineID: number;
  
  showSpin: boolean = false;
  fpc_news_edit_form: UntypedFormGroup;
  headlineTitle: string = "";
  headlineStory: string = "";
  effectiveDate: Date = new Date();
  expirationDate: Date;
  validTimeStart: string = "";
  validTimeEnd: string = "";
  startDate: Date = new Date();
  endDate: Date;
  contentDocumentList: ContentDocumentModel[]=[];
  hasExistingDocument: boolean = false;
  maxTempDocId: number = 0;
  allowedFileType: string;
  uploader: FileUploader;
  fileList: FileModel[] = [];
  finishAllUploads: boolean = true;
  docSize: string;
  isModified: boolean = false;
  allowedFileTypeList: string[];
  maxFileSize: number;
  maxFileUploadNumber: number = 0;
  showSuccessMsg: boolean = false;
  errMsg: string = "";
  errMsg2: string = "";
  submitted: boolean = false;
  loading: boolean = false;
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;
  webLink: string;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  isUTC: boolean = false;
  isEventReleased: boolean = false;
  isInternalOnly: boolean = false;
  isDistributionRestricted: boolean = false;
  showClientList: boolean = false;
  showClientButton: boolean = false;
  clientList: CustomerModel[];
  selectedClientCountText: string = "";
  selectedClientList: CustomerModel[]=[];
  customerGUIDList: string = "";
  filteredClientList: Observable<CustomerModel[]>;
  headlineTypeList: HeadlineTypeModel[];
  selectedHeadlineType: number = 6;
  showOnLoginPage: boolean = false;
  headlineTypeSlider: boolean = false;

  @ViewChild('effectiveDate') effectiveDateRef: ElementRef;
  @ViewChild('expirationDate') expirationDateRef: ElementRef;
  @ViewChild('startDate') startDateRef: ElementRef;
  @ViewChild('endDate') endDateRef: ElementRef;
  @ViewChild('notesCKEditor') notesCKEditorRef: any;//ElementRef;

    /** headline-dialog ctor */
  constructor(private readonly _dialogRef: MatDialogRef<FPCNewsEditDialogComponent>, private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder, 
    private readonly _customerService: CustomerService, private readonly _commonService: CommonService, @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _dialogService: DialogService) {
    this.headlineID = _data.headlineID;   

  }

  ngOnInit() {
    this.maxTempDocId = 0;
    this.hasExistingDocument = false;
    this.allowedFileTypeList = [];
    this.maxFileSize = 0;
    this.maxFileUploadNumber = 0;

    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Common/uploadFiles",
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false
    });
    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
    };
   
      this.getData();
  }

  getAllData(): Observable<any[]> {
    let getHeadlineResponse;
    if (this.headlineID == 0)
      getHeadlineResponse = of(null);
    else
      getHeadlineResponse = this._commonService.getHeadlineByHeadlineID(this.headlineID)
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();
    let getHeadlineTypeResponse = this._commonService.getHeadlineTypeList();
    return forkJoin([getHeadlineResponse, getAllowedDocTypeResponse, getHeadlineTypeResponse])
  }
   
  getData() {
    this.showSpin = true;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let headline = new HeadlineModel();
          headline = responses[0].model;
          this.headlineTitle = headline.headlineTitle;
          this.headlineStory = headline.headlineStory.replace(/\n/gi,'<br><br>');
          if (headline.effectiveDate != null && headline.effectiveDate != "")
            this.effectiveDate = new Date(headline.effectiveDate);
          if (headline.expirationDate != null && headline.expirationDate != "")
            this.expirationDate = new Date(headline.expirationDate);
          if (headline.displayDateStart != null && headline.displayDateStart != "")
            this.startDate = new Date(headline.displayDateStart);
          if (headline.displayDateEnd != null && headline.displayDateEnd != "")
            this.endDate = new Date(headline.displayDateEnd);
          this.contentDocumentList = headline.contentDocuments;
          this.validTimeStart = headline.validTimeStart;
          this.validTimeEnd = headline.validTimeEnd;
          this.isUTC = headline.isUTC;
          this.modifiedBy = headline.modifiedBy;
          this.modifiedDate = headline.modifiedDate;
          this.webLink = headline.documentWeblink;
          if (headline.isActive == true)
            this.isActive = 1;
          else
            this.isActive = 0;
          if (this.contentDocumentList.length > 0)
            this.hasExistingDocument = true;
          this.isEventReleased = headline.isEventReleased;
          this.isInternalOnly = headline.isInternalOnly;
          this.sortOrder = headline.sortOrder;
          this.isDistributionRestricted = headline.isDistributionRestricted;
          this.selectedHeadlineType = headline.headlineTypeID;
         
          if (this.selectedHeadlineType == 7) {
            this.headlineTypeSlider = true;
          }
          else {
            this.headlineTypeSlider = false;
          }
                    

          this.showOnLoginPage = headline.showOnLoginPage;
          this.customerGUIDList = headline.customerGUIDList;
          if (this.customerGUIDList != null) {
            if (this.customerGUIDList.length > 0) {
              let f = this.customerGUIDList.split(",");

              f.forEach(v => {
                let c = new CustomerModel();
                c.customerGUID = v;
                this.selectedClientList.push(c);
              });
              this.selectedClientCountText = ": " + f.length.toString();
              this.showClientButton = true;
            }
          }
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          if (responses[1].model != null) {
            let fileObj = new FileModel();
            fileObj = responses[1].model;
            this.allowedFileTypeList = fileObj.allowedFileTypeList;
            this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
            this.maxFileSize = fileObj.fileSize;
            this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
          }
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.headlineTypeList = responses[2].model;
          this.headlineTypeList = this.headlineTypeList.filter(x => x.headlineTypeID != 10 && x.isFpc == true)
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }
    
      this.loading = false;
      this.showSpin = false;
      this.initControls();
    });
  }

  initControls() {
    this.fpc_news_edit_form = this._formBuilder.group({
      eventTitle: [this.headlineTitle,Validators.required],
      eventStory: [this.headlineStory,Validators.required],
      effectiveDate: [this.effectiveDate, Validators.required],
      expirationDate: this.expirationDate,
      validTimeStart: this.validTimeStart,
      validTimeEnd: this.validTimeEnd,
      document: null,
      isUTC: this.isUTC,
      event_status: this.isActive,
      modifiedBy: this.modifiedBy,
      modifiedDate: this.modifiedDate,
      startDate: this.startDate,
      endDate: this.endDate,
      webLink: this.webLink,
      isEventReleased: this.isEventReleased,
      isInternalOnly: this.isInternalOnly,
      isDistributionRestricted: this.isDistributionRestricted,
      clientSearch: "",
      headlineType: this.selectedHeadlineType,
      headlineTypeSlider: this.headlineTypeSlider,
      showOnLoginPage: this.showOnLoginPage,
      sortOrder: this.sortOrder,
    });
  }


  clickPreviewDoc(event: any, doc: ContentDocumentModel) {

    let byteArray = UtilityFunctions.base64ToArrayBuffer(doc.bFile);
    let file = new Blob([byteArray], { type: doc.mimeType });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  timeOnchange(controlName: string, e: any) {

    if (e.target.value != "") {
      let timeObj = new DateTimeObjModel();
      timeObj.timeString = e.target.value;
      timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
      this.fpc_news_edit_form.get(controlName).setValue(timeObj.timeString);

      if (!timeObj.isValidDateTime) {
        this.fpc_news_edit_form.get(controlName).setErrors({ 'valid': false });
      }

    }
    else {
      this.fpc_news_edit_form.get(controlName).setValue(null);
      this.fpc_news_edit_form.get(controlName).setErrors(null);
    }
  }

  clickClose() {
    this._dialogRef.close(this.submitted);
  }

  importFile(event: any) {
    //let fileList: GroundStopTaskDocumentModel[];
    //fileList = this.taskDocumentList.filter(x => x.groundStopTaskDocumentGUID == "" || x.groundStopTaskDocumentGUID == null || x.groundStopTaskDocumentGUID == undefined)
    if ((this.fileList.length + event.target.files.length) > this.maxFileUploadNumber) { //GlobalConstant.maxUploadFileNum) {
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    //this.fileList = [];
    var files = [];
    this.errMsg = ""
    if (event.target.files.length > this.maxFileUploadNumber) { //GlobalConstant.maxUploadFileNum) {     
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; //GlobalConstant.maxUploadFileNum + '.';
      this.finishAllUploads = true;
      return;
    }
    else {
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;
        //var a = CustomValidators.checkValidFileType(filename);
        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {
          if (event.target.files[i].size >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {             
            this.errMsg = 'The file size "' + filename + '" is too big to upload.';
            this.finishAllUploads = true;
            return;
          }
          else {
            files.push(event.target.files[i]);

          }
        }
        else {
          this.finishAllUploads = true;
          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          return;
        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
  }

  uploadSubmit() {
    this.finishAllUploads = false;
    this.uploader.uploadAll();
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {
          result.model.forEach((item, index) => {
            let obj = new FileModel();
            let obj2 = new ContentDocumentModel();
            this.maxTempDocId += 1;
            obj.fileName = item.fileName;
            obj.filePath = item.filePath;
            obj.fileSize = item.fileSize;
            obj.bFile = item.bFile;
            obj.mimeType = item.mimeType;
            obj.fileType = item.fileType;
            obj.tempId = this.maxTempDocId;
            this.fileList.push(obj);

            obj2.documentName = item.fileName;
            obj2.fileExtension = item.fileType;
            obj2.documentSizeInKB = item.fileSize;
            obj2.mimeType = item.mimeType;
            obj2.bFile = item.bFile;
            obj2.tempId = this.maxTempDocId;
            obj2.contentDocumentGUID = "";
            this.contentDocumentList.push(obj2);
          });
        }
      }
      else {
        this.errMsg = result.message;
      }
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }


  clickRemoveDocument(e: any, item: ContentDocumentModel  ) {
    this.confirmDialogRef = this._dialogService.openConfirmDialog("Are you sure you want remove the document: " + item.documentName + "?");
    this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (result) {
        this.isModified = true;
        if (item.contentDocumentGUID != "") {
          // remove doc from database, get newlist and then add unsaved file list
          let request = new ContentDocumentModel();
          request.contentDocumentGUID = item.contentDocumentGUID;
          request.documentName = item.documentName;
          this._commonService.removeContentDocumentBycontentDocId<ResponseModel<boolean>>(request).subscribe(response => {
            if (response != null && response.code == "200") {
              if (response.model) {
                this.getContentDocumentList();
              }
            }
            else {
              if (response.code == "401") {
                //this.errMsg = response.message
                this._authService.signOut();
              }

            }

          });
        }
        else {
          this.fileList.forEach((x, index) => {
            if (x.tempId == item.tempId) {
              this.fileList.splice(index, 1);
            }
          }
          );
          this.contentDocumentList.forEach(
            (x, index) => {
              if (x.tempId == item.tempId && x.contentDocumentGUID == "") {
                this.contentDocumentList.splice(index, 1);
              }
            });
        }
      }
    });
  }

  onFileClick(event) {
    event.target.value = '';
  }

  getContentDocumentList() {
    let request = new ContentDocumentModel()
    request.headlineID = 0;
    request.headlineID = this.headlineID;
    this._commonService.getContentDocumentByxId<ResponseModel<ContentDocumentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.contentDocumentList = [];
        this.contentDocumentList = response.model;
        if (this.contentDocumentList.length > 0) {
          this.hasExistingDocument = true;
        }
        if (this.fileList.length > 0) {
          this.fileList.forEach(x => {
            let obj = new ContentDocumentModel();
            obj.documentName = x.fileName;
            obj.fileExtension = x.fileType;
            obj.documentSizeInKB = x.fileSize;
            obj.mimeType = x.mimeType;
            //obj.bFile = x.bFile;
            obj.tempId = x.tempId;
            obj.contentDocumentGUID = "";
            this.contentDocumentList.push(obj);
          });
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }

    });
  }

  get f() { return this.fpc_news_edit_form.controls; }

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.effectiveDateRef.nativeElement.value !== "") {
      if (this.f.effectiveDate.value != "" && this.f.effectiveDate.value != null && this.f.effectiveDate.value != undefined) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.effectiveDate.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.effectiveDate.setErrors({ isValidDate: f1 });
        }
      }
      else {
        this.f.effectiveDate.setErrors({ isValidDate: false });
      }
    }

    if (this.expirationDateRef.nativeElement.value !== "") {
      if (this.f.expirationDate.value != "" && this.f.expirationDate.value != null && this.f.expirationDate.value != undefined) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.expirationDate.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.expirationDate.setErrors({ isValidDate: f1 });
        }
      }
      else {
        this.f.expirationDate.setErrors({ isValidDate: false });
      }
    }

    if (this.effectiveDateRef.nativeElement.value !== "" && this.expirationDateRef.nativeElement.value !== "") {
      let s1 = "";
      let s2 = "";
      if (this.f.effectiveDate.value != "" && this.f.effectiveDate.value != null && this.f.effectiveDate.value != undefined)
        s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.effectiveDate.value));
      if (this.f.expirationDate.value != "" && this.f.expirationDate.value != null && this.f.expirationDate.value != undefined)
        s2 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.expirationDate.value));
      let f1 = CustomValidators.isValidDateRange(s1, s2);
      if (!f1) {
        this.f.effectiveDate.setErrors({ isValidDateRange: f1 });
      }
    }

    if (this.startDateRef.nativeElement.value !== "") {
      if (this.f.startDate.value != "" && this.f.startDate.value != null && this.f.startDate.value != undefined) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.startDate.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.startDate.setErrors({ isValidDate: f1 });

        }
      }
      else {
        this.f.startDate.setErrors({ isValidDate: false });

      }
    }


    if (this.endDateRef.nativeElement.value !== "") {
      if (this.f.endDate.value != "" && this.f.endDate.value != null && this.f.endDate.value != undefined) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.expirationDate.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.endDate.setErrors({ isValidDate: f1 });

        }
      }
      else {
        this.f.endDate.setErrors({ isValidDate: false });

      }
    }
    if (this.startDateRef.nativeElement.value !== "" && this.endDateRef.nativeElement.value !== "") {
      let s1 = "";
      let s2 = "";
      if (this.f.startDate.value != "" && this.f.startDate.value != null && this.f.startDate.value != undefined)
        s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.effectiveDate.value));
      if (this.f.endDate.value != "" && this.f.endDate.value != null && this.f.endDate.value != undefined)
        s2 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.endDate.value));
      let f1 = CustomValidators.isValidDateRange(s1, s2);
      if (!f1) {
        this.f.startDate.setErrors({ isValidDateRange: f1 });
      }
    }

    if ( (this.f.eventTitle.value?.trim() == "" || this.f.effectiveDate.value == "") || this.errMsg != "") {
      return;
    }
    if (this.f.showOnLoginPage.value && (this.f.endDate.value == '' || this.f.endDate.value == null)) {
      this.errMsg2 = 'Display End Date is Required';
      return;
    }


    let request = new HeadlineModel();
    request.headlineID = this.headlineID;
    request.headlineTitle = this.f.eventTitle.value;
    request.documentWeblink = this.f.webLink.value;
    //request.headlineStory = this.f.eventStory.value;
    request.headlineStory = this.notesCKEditorRef.editorInstance.getData();// this.f.eventStory.value;
    request.headlineStoryText = this.notesCKEditorRef.elementRef.nativeElement.innerText;
    if (request.headlineStoryText != "") {
      let re = /Paragraph\n/gi;
      request.headlineStoryText = request.headlineStoryText.replace(re, '');
      request.headlineStoryText = request.headlineStoryText.trim();
      re = /Styles\n/gi;
      request.headlineStoryText = request.headlineStoryText.replace(re, '');
      request.headlineStoryText = request.headlineStoryText.trim();
      re = /Email Body\n/gi;
      request.headlineStoryText = request.headlineStoryText.replace(re, '');
      request.headlineStoryText = request.headlineStoryText.trim();
      re = /Signature\n/gi;
      request.headlineStoryText = request.headlineStoryText.replace(re, '');
      request.headlineStoryText = request.headlineStoryText.trim();
      re = /Heading 1\n/gi;
      request.headlineStoryText = request.headlineStoryText.replace(re, '');
      request.headlineStoryText = request.headlineStoryText.trim();
      re = /Heading 2\n/gi;
      request.headlineStoryText = request.headlineStoryText.replace(re, '');
      request.headlineStoryText = request.headlineStoryText.trim();
      re = /Heading 3\n/gi;
      request.headlineStoryText = request.headlineStoryText.replace(re, '');
      request.headlineStoryText = request.headlineStoryText.trim();
    }
    if (this.f.isUTC.value == "1")
      request.isUTC = true;
    else
      request.isUTC = false;
    request.displayDateStart = null;
    if (this.f.startDate.value != null) {
      request.displayDateStart = this.startDateRef.nativeElement.value;
    }
    request.displayDateEnd = null;
    if (this.f.endDate.value != null) {
      request.displayDateEnd = this.endDateRef.nativeElement.value;
    }
    request.effectiveDate = null;
    if (this.f.effectiveDate.value != null) {
      request.effectiveDate = this.effectiveDateRef.nativeElement.value;
    }
    request.expirationDate = null;
    if (this.f.expirationDate.value != null) {
      request.expirationDate = this.expirationDateRef.nativeElement.value;
    }
    request.validTimeStart = this.f.validTimeStart.value;
    request.validTimeEnd = this.f.validTimeEnd.value;
    request.isActive = true;
    if (this.f.event_status.value == "1")
      request.isActive = true;
    else
      request.isActive = false;
    request.isDistributionRestricted = this.f.isDistributionRestricted.value;
    request.isEventReleased = this.f.isEventReleased.value;
    request.isInternalOnly = this.f.isInternalOnly.value;
    request.sortOrder = this.f.sortOrder.value;
    request.customerGUIDList = Array.prototype.map.call(this.selectedClientList, s => s.customerGUID).toString();
    request.headlineTypeID = this.f.headlineType.value;
    request.showOnLoginPage = this.f.showOnLoginPage.value;
    request.contentDocuments = [];

    if (this.fileList.length > 0) {
      this.fileList.forEach(x => {
        let obj = new ContentDocumentModel();
        obj.documentName = x.fileName;
        obj.fileExtension = x.fileType;
        obj.documentSizeInKB = x.fileSize;
        obj.mimeType = x.mimeType;
        obj.bFile = x.bFile;
        obj.contentDocumentGUID = "";
        //obj.containerPath = localStorage.getItem('cn');
        request.contentDocuments.push(obj);
      });
    }

    this.loading = true;
    this._commonService.saveHeadline<ResponseModel<number>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.fileList = [];
          this.maxTempDocId = 0;
          this.errMsg = "";
          this.errMsg2 = "";
          this.getContentDocumentList();
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.clickClose();
          }, 1000);

        }
        else {
          //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          this.errMsg2 = "Failed to save this record at this time. Please try later.";
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg2 = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    });

  }

  clickClientFilter() {
    this.getClientList();
  }

  getClientList() {
    this.showClientList = true;
    this.clientList = [];
    
    this._commonService.getCustomerListByType<ResponseModel<CustomerModel[]>>(1).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let c = new CustomerModel();
          c.customerName = "Flight Pro International";
          c.customerGUID = "F35BC662-0E34-4CC4-ACCE-16FAE0E7EE3B";
          c.selected = false;
          response.model.push(c);
          response.model.sort((a, b) => a.customerName.localeCompare(b.customerName));
          response.model.forEach(x => {
            c = new CustomerModel();
            c.customerName = x.customerName            
            c.customerGUID = x.customerGUID;
            c.selected = false;
            if (this.selectedClientList.length > 0) {
              let f: CustomerModel[];
              f = this.selectedClientList.filter(y => y.customerGUID.toLowerCase() == x.customerGUID.toLowerCase());
              if (f.length > 0) {
                c.selected = true;
              }
            }         
            this.clientList.push(c);            
          });
          this.clientList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.customerName.localeCompare(b.customerName));
          this.filteredClientList = this.f.clientSearch.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.customerName)),
            map(customerName => (customerName ? this._filter(customerName) : this.clientList.slice())),
          );
          this.setClientList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayName(customer: CustomerModel): string {
    return customer && customer.customerName ? customer.customerName : '';
  }

  private _filter(name: string): CustomerModel[] {
    const filterValue = name.toLowerCase();

    return this.clientList.filter(option => option.customerName.toLowerCase().includes(filterValue));
  }

  checkClientChange(e: any, item: CustomerModel) {    
      this.clientList.forEach(x => {
        if (item.customerGUID == x.customerGUID) {
          x.selected = e.checked;
        }
      });
    
    this.setClientList();
  }

  setClientList() {
    this.selectedClientList= this.clientList.filter(u => u.selected == true);
    this.selectedClientCountText = "";
    if (this.clientList.length > 0) {
      if (this.selectedClientList.length == this.clientList.length) {
        this.selectedClientCountText = ": All";
      }
      else {
        if (this.selectedClientList.length > 0)
          this.selectedClientCountText = ": " + this.selectedClientList.length.toString();
      }
    }
  }

  clickDoneClient() {
    this.showClientList = false;
  }

  clickResetClient() {
    this.clientList.forEach(x => {
      x.selected = false;
    });
    this.setClientList();
  }

  onHeadlineTypeChange(e: any) {
    if (e.checked) {
      this.f.headlineType.setValue(7);
    }
    else {
      this.f.headlineType.setValue(6);
      this.f.showOnLoginPage.setValue(false);
    }
    
  }
}
