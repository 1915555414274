<div>
  <div class="d-flex justify-content-start" style="width: 100%;">
    <div class="input-group mb-3 searchbar mr-2" style="width:29%;height: 2.5em;">
      <div class="input-group-prepend searchbarleft">
        <span class="input-group-text material-icons-outlined searchbarleft">search</span>
      </div>
      <input type="text" [(ngModel)]="searchQuery" class="form-control form-control-sm searchbarinput"
             (keyup.enter)="searchMessages($event)" placeholder="Search mail">
      <div>
        <span class="input-group-text material-icons-outlined searchbarright pointer" (click)="resetSearchFilter()"
              cdkOverlayOrigin #trigger="cdkOverlayOrigin">close</span>
      </div>
    </div>


    <div>
      <div style="display: flex;   margin-left: 1em; margin-top: -.6em;" align="end">

        <div style="margin-top:.5em; display:flex;   font-size:small">
          <div style="margin-top: -0.2em;"><b>Filter</b></div>

          <div style="display: flex; margin-left: 1em; ">
            <div style="margin-right:0.25em">
              <input id="fAll" type="radio" name="message_type" value="all" [checked]="searchFiltervalue==3"
                     (change)="searchFilterChange($event)" />
            </div>
            <label style="margin-top:-0.2em;white-space: nowrap " for="fAll">All </label>
          </div>

          <div style="display: flex; margin-left: 1em; ">
            <div style="margin-right:0.25em">
              <input id="fFrom" type="radio" name="message_type" value="from" [checked]="searchFiltervalue==0"
                     (change)="searchFilterChange($event)" />
            </div>
            <label style="margin-top:-0.2em;white-space: nowrap " for="fFrom">From </label>
          </div>
          <div style="display: flex; margin-left: 1em;">
            <div style="margin-right:0.25em">
              <input id="fTo" type="radio" name="message_type" value="to" [checked]="searchFiltervalue==1"
                     (change)="searchFilterChange($event)" />
            </div>
            <label style="margin-top:-0.2em; white-space: nowrap" for="fTo">To </label>
          </div>
          <div style="display: flex; margin-left: 1em;">
            <div style="margin-right:0.25em">
              <input id="fSubject" type="radio" name="message_type" value="subject" [checked]="searchFiltervalue==2"
                     (change)="searchFilterChange($event)" />
            </div>
            <label style="margin-top:-0.2em;white-space: nowrap" for="fSubject">Subject </label>
          </div>
        </div>
        <!--<div style="margin-top: -0.4em; margin-left: 0.5em">
          <mat-slide-toggle style="transform:scale(.9); height:2.5em; font-size: 0.9rem; margin-top: 0.1em;" color="primary"
                            [checked]="showFlagMessages" (change)="showFlaggedOnChange($event)">
            Flagged Messages
          </mat-slide-toggle>
        </div>-->

      </div>
      
    </div>


    <div style="display: flex; justify-content: space-between;  margin-left: auto; " align="end">



      <div align="end">
        <button mat-button class="btn-low " style="margin-right: 1em;" (click)="getData(true)">
          <mat-icon>refresh</mat-icon>
          Refresh
        </button>
      </div>
    </div>


  </div>

  <!-- <p> a{{resultsLength | number}}</p> -->

  <div class="d-flex justify-content-between" style="margin-top: -1.05em;">
    <div >
      <div style="font-size:small;margin-top:.5em" *ngIf="messageFolderID==4">Messages Sent from FPC</div>
    </div>
    

    <mat-paginator style="background-color:transparent;margin-top:-1.5em" [pageSize]="25"
                   [showFirstLastButtons]="true"
                   [length]="resultsLength "
                   [pageSizeOptions]="[15,25, 50, 100]"
                   aria-label="Select page">
    </mat-paginator>
  </div>
  <div style="overflow:auto;margin-top:-.5em; height: 30.5em;" [ngClass]="currentScreenSize">

    <table mat-table matSort matSortActive="created" matSortDisableClear matSortDirection="desc" [dataSource]="messageList">

      <ng-container matColumnDef="action1">
        <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 3%;width:3%;">
          <mat-checkbox style="height:1.5em;width:1.5em;" (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>

        </th>
        <td mat-cell *matCellDef="let row" style="text-align:center !important;flex:0 0 3%;width:3%">
          <mat-checkbox style="height:1.5em;width:1.5em" (click)="$event.stopPropagation()"
                        (change)="$event ? checkBox(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
          <!-- <i *ngIf="row.isFlagged" class="material-icons-outlined" style="font-size: small; margin-right:-1em;color: red; ">flag</i> -->
          <i *ngIf="row.isFlagged" class="material-icons" style="font-size: small; margin-right: -1em; color: red;">flag</i>

        </td>
      </ng-container>

      <ng-container matColumnDef="linkToTrip">
        <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 6%;width:6%">
          Trip Code
        </th>
        <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 6%;width:6%">
          
          <div *ngIf="element.tripCode!=''">
            {{element.tripCode.split(',')[0]=='' || element.tripCode==''?element.accountId:element.tripCode.split(',')[0]}}
          </div>

          <!--<div *ngIf="element.messageKeyID!=null && element.tripCode == '' && element.messageFolderID !=1 " style="cursor: pointer">
            <i class="material-icons-outlined" (click)="linkMessage(element)" style="font-size: x-large; margin-top:0em;color: #7dacff;">add_circle</i></div>-->

        </td>
      </ng-container>

      <ng-container matColumnDef="date_time">
        <th *matHeaderCellDef mat-header-cell mat-sort-header disableClear style="text-align:left !important;flex:0 0 7%;width:7%">
          {{messageFolderID==5? 'Scheduled Delivery ':'Date (z)'}}
        </th>
        <td mat-cell *matCellDef="let element" style="text-align:left !important;flex:0 0 7%;width:7%" (click)="openMessage(element)">{{element.sentDateTime}}</td>
      </ng-container>
      <ng-container matColumnDef="sender">
        <th mat-header-cell *matHeaderCellDef style="text-align:left !important;flex:0 0 15%;width:15%">
          Sender
        </th>
        <td mat-cell style="justify-content:left !important;flex:0 0 15%;width:15%" *matCellDef="let element" (click)="openMessage(element)">
          <span class="ellipsis">
            {{element.sender}}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef style="justify-content:left !important;flex:0 0 28%;width:28%; padding-right: 2em !important;">
          Subject
        </th>
        <td mat-cell style="justify-content:left !important;flex:0 0 28%;width:28%; padding-right: 2em !important;" *matCellDef="let element"
            (click)="openMessage(element)">
          <span class="ellipsis">
            {{element.subject   }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="body">
        <th mat-header-cell *matHeaderCellDef style="vertical-align: middle !important; justify-content: left !important; flex: 0 0 30%; width: 30%">
          <div class="d-flex">
            Body
            <mat-progress-bar mode="indeterminate" style="width:30%;margin-left:.5em;margin-top:.75em" color="primary" *ngIf="showSpin"></mat-progress-bar>
          </div>
        </th>
        <td mat-cell style="text-align:left !important;flex:0 0 30%;width:30%" *matCellDef="let element" (click)="openMessage(element)"><span class="ellipsis">{{element.bodyPreview=='' ? element.bodyTxt : element.bodyPreview}}</span></td>
      </ng-container>
      <ng-container matColumnDef="has_attachments">
        <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 2%;width:2%"></th>
        <td mat-cell *matCellDef="let element; let i=index" style="text-align:center !important;flex:0 0 2%;width:2%" (click)="openMessage(element)">
          <span *ngIf=element.hasAttachments class="material-symbols-outlined" style="transform:rotate(-45deg);font-size:medium">
            attachment
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="action2">
        <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 3%;width:3%"></th>
        <td mat-cell *matCellDef="let element; let i=index" style="text-align:center !important;flex:0 0 3%;width:3%">
          <span class="material-icons-outlined" style="cursor:pointer;font-size:medium" (click)="openMessage(element)">
            open_in_new
          </span>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumn;sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumn;"></tr>

    </table>
  </div>
</div>

