import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CommAddressTypeModel } from '../models/comm-address-type.model';
import { AddressRatingModel } from '../models/address-rating.model';
import { AddressUseModel } from '../models/address-use.model';
import { VendorService } from '../services/vendor.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { PersonClassModel } from '../models/person-class.model';
import { PersonService } from '../services/person.service';
import { CountryModel } from '../models/country.model';
import { PersonRoleModel } from '../models/person-role.model';
import { PersonModel } from '../models/person.model';
import { VendorPersonEditDialogComponent } from './vendor-person-edit-dialog.component';
import { FileItem, FileUploader, ParsedResponseHeaders, FileUploadModule, FileSelectDirective } from 'ng2-file-upload';
import { GlobalConstant } from '../common-utility/global-constant';
import { FileModel } from '../models/file.model';

@Component({
  selector: 'app-vendor-person-details',
  templateUrl: './vendor-person-details.component.html',
  styleUrls: ['./vendor-person-details.component.css']
})

export class VendorPersonDetailsComponent implements OnInit {
  userType: string;
  vendorGUID: string;
  personGUID: string;
  selectedPersonClass: PersonClassModel;
  selectedTabName: string;
  person_details_form: UntypedFormGroup;
  firstName: string;
  middleName: string;
  lastName: string;
  title: string;
  dob: string;
  gender: string;
  countryofbirth: string;
  countryofresidence: string;
  state_provinceofbirth: string;
  cityofbirth: string;
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;
  rolelist: string[];
  errMsg: string;
  msg: string;
  uploader: FileUploader;
  fileList: FileModel[];
  finishAllUploads: boolean = true;
  photoName: string;
  photoFilePath: string;
  includeDisabledRecords: boolean;
  imageToShow: any;
  isCrewPax: boolean = true;
  person_status: string;
  includeInBrief: boolean = false;
  sortOrderNo: number;
  isPopup: boolean = false;
  isModified: boolean = false;
  constructor(@Optional() private _dialogRef: MatDialogRef<VendorPersonDetailsComponent>, private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _personService: PersonService, private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog, @Optional() @Inject(MAT_DIALOG_DATA) private _data: any) {
    if (_data) {
      this.personGUID = _data.personGUID;
      this.isPopup = true;
    }
  }

  ngOnInit() {
    //this.personGUID = this._route.snapshot.paramMap.get('pId');
    if (!this.isPopup) {
      this._route.queryParams.subscribe(params => {
        this.personGUID = params["pId"];

      });
    }
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.firstName = "";
    this.middleName = "";
    this.lastName = "";
    this.title = "";
    this.dob = "";
    this.gender = "";
    this.countryofbirth = "";
    this.countryofresidence = "";
    this.state_provinceofbirth = "";
    this.cityofbirth = "";
    this.isActive = 1;
    this.photoName = "";
    this.rolelist = [];
    this.selectedTabName = 'mailAddress';
    this.includeDisabledRecords = false;
    this.photoFilePath = "";
    this.isCrewPax = true;
    this.person_status = "";
    
    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Person/uploadPersonPhoto",
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false
    });

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('pId', this.personGUID); //note comma separating key and value
      //form.append('Field2', this.filedValue2);
    };

    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
    };

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "vendor";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getPersonInfo(); 
    
  }

  initControls() {
    this.person_details_form = this._formBuilder.group({
      firstName: [this.firstName],
      middleName: [this.middleName],
      lastName: [this.lastName],
      title: [this.title],
      dob: [this.dob],
      gender: [this.gender],
      countryofbirth: [this.countryofbirth],
      countryofresidence: [this.countryofresidence],
      state_provinceofbirth: [this.state_provinceofbirth],
      cityofbirth: [this.cityofbirth],
      person_status: [this.person_status],
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],
      includeDisabledRecords: this.includeDisabledRecords,
      photo: [null],
      sort_order: this.sortOrderNo
    });
  }

  get f() { return this.person_details_form.controls; }

  getPersonInfo() {
    this._personService.getPersonBypId<ResponseModel<PersonModel>>(this.personGUID).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let person = response.model;
          this.vendorGUID = person.customerGUID;
          this.firstName = person.firstName;
          this.middleName = person.middleName;
          this.lastName = person.lastName;
          this.title = person.jobTitle;
          this.dob = person.dob;
          this.gender = person.gender;
          this.countryofbirth = person.countryNameOfBirth;
          this.countryofresidence = person.countryNameOfResidence;
          this.state_provinceofbirth = person.state_Prov_OfBirth;
          this.cityofbirth = person.cityOfBirth;
          if (person.isActive == true)
            this.person_status = "No";
          else
            this.person_status = "Yes";
          this.modifiedBy = person.modifiedBy;
          this.modifiedDate = person.modifiedDate;
          this.photoFilePath = person.personPhotoPath;
          this.photoName = person.personPhotoName;
          this.includeInBrief = person.includeInBrief;
          if (person.personRoleList != "")
            this.rolelist = person.personRoleList.split(";");
          if (person.personClassList.toLowerCase().indexOf('crew') == -1 && person.personClassList.toLowerCase().indexOf('pax') == -1) {
            this.isCrewPax = false;
          }
          else {
            this.isCrewPax = true;
          }
          this.sortOrderNo = person.sortOrderNo;
        }
        else {

        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }

      this.initControls();
    })
  }

  clickEditPerson() {
    let s = "";
    let personGUID = "";
    
    s = "EDIT Contact";
    personGUID = this.personGUID;
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
   // dialogConfig.width = "65em";//"1000px";
   // dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

      dialogConfig.data = { dialogTitle: s, vendorGUID: this.vendorGUID,  personGUID: this.personGUID };
    
    const dialogRef = this._dialog.open(VendorPersonEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.isModified = true;
        this.getPersonInfo();
      }
      //else {
      //  if (result.newId != "") {
      //    // goto person details pages
      //    this._router.navigate(['/persons/person-details', { pId: result.newId }]);
      //  }
      //}

    });
  }

  // upload photo
  importPhoto(event: any) {
    this.errMsg = "";
    this.finishAllUploads = false;
    this.fileList = [];
    var files = [];
    var maxFileSize = 5 * 1024 * 1024;
    this.errMsg = "";
    this.photoFilePath = "";
    
    if (event.target.files.length >1) {
      this.errMsg = 'Only upload one file.';
      return;
    }
    else { 
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;
        //var a = this.checkValidFileType(filename);
        var k = filename.lastIndexOf('.');
        var ext = filename.substring(k + 1).toLocaleLowerCase();
        if (ext != "png" && ext != "jpg" && ext != "gif") {
          //this.errMsg += 'The file type of "' + filename + '" is not allowed to upload.';
          this.errMsg = 'The file type is not\nallowed to upload.';
          //this.uploader.removeFromQueue(event.target.files[i]);
          this.uploader.clearQueue();
          return;
        }
        else {
          if (event.target.files[i].size >= maxFileSize) {
            //if (this.errMsg != "") {
            //  this.errMsg += "\n";
            //}
            //this.errMsg += 'The file size "' + filename + '" is too big to upload.';
            this.errMsg = 'The file size is too\nbig to upload.';
            //this.uploader.removeFromQueue(event.target.files[i]);
            this.uploader.clearQueue();
            return;
          }
          else {
            files.push(event.target.files[i]);
            //this.f.document_name.setValue(filename);
          }                  
        }
        break;
      }
      
    }

    if (this.errMsg != "")
      return;

    //if (files.length > 0) {
    //  this.uploader.addToQueue(files);
    //}

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      return;
    }
    
    this.uploadSubmit();

  }


  uploadSubmit() {
    let x=this.uploader.queue.length;
    this.finishAllUploads = false;
    this.uploader.uploadAll();
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {
          result.model.forEach((item, index) => {
            this.photoFilePath = item.filePath;
            this.photoName = item.fileName;
            //let obj = new FileModel();
            //obj.fileName = item.fileName;
            //obj.filePath = item.filePath;
            //obj.fileSize = item.fileSize;
            //obj.bFile = item.bFile;
            //obj.mimeType = item.mimeType;
            //obj.fileType = item.fileType;
           // this.f.size.setValue(obj.fileSize.toString() + " KB");
            //this.fileList.push(obj)
            //this._commonService.getImageById<ResponseModel<Blob>>(this.personGUID).subscribe(res => {
            
            //    this.createImageFromBlob(res);
              
            //});

          });
        }
      }
      else {
        this.errMsg = result.message;
      }
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }

  //createImageFromBlob(image: Blob) {
  //  let reader = new FileReader();
  //  reader.addEventListener("load", () => {
  //    this.imageToShow = reader.result;
  //  }, false);

  //  if (image) {
  //    reader.readAsDataURL(image);
  //  }
  //}
  //clickPreviewDocument() {
  //  let item = this.fileList[0];
  //  let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
  //  let file = new Blob([byteArray], { type: item.mimeType });
  //  var fileURL = URL.createObjectURL(file);
  //  window.open(fileURL);
  //}

  clickRemovePhoto() {
    //this.confirmDialogRef = this._dialogService.openConfirmDialog("Are you sure you want remove the document?");
    //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
    //  this.eventSubscription.unsubscribe();
    //  if (result) {
    //    this.fileList = [];
    //    this.document_name = '';
    //    this.f.document_name.setValue(this.document_name);
    //  }
    //})
    let request = new PersonModel();
    request.personGUID = this.personGUID;
    request.personPhotoName = this.photoName;
    request.actionName = 'removePhoto';
    this._personService.updatePersonPhoto<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model ==true) {
          this.photoFilePath = "";
          this.photoName = "";
        }
      }
    })

  }
  // upload file end

  includeDisabledRecordsOnChange(event: any) {
    this.includeDisabledRecords = event.checked;
  }

  clickBack() {
    if (this.isPopup) {
      this._dialogRef.close(this.isModified);
    }
    else {
      this._router.navigate(['/vendors/vendor-profile-setup', { vendorGUID: this.vendorGUID }]);
    }
  }

  clickTab(s: string) {
    if (this.selectedTabName != s) {
      this.selectedTabName = s;
    }

  }
  openInNew() {
    this._dialogRef.close();
    window.open('/vendor/vendor-person-details?pId=' + this.personGUID, "_blank");
  }
}
