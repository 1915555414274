import { Component, Inject, Optional, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerModel } from '../models/customer.model';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { CustomerCommEditDialogComponent } from './customer-comm-edit-dialog.component';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DOCUMENT } from '@angular/common';
import { DialogService } from '../services/dialog.service';
import { EmailClientDialogComponent } from '../messages-client/email-client-dialog.component';
import { CustomerGroupService } from '../services/customer-group-service';
import { CustomerGroupModel } from '../models/customer-group.model';
import { MaintenanceDashboardClientComponent } from '../common-utility/maintenance-dashboard-client.component';
import { CustomerDocumentlistComponent } from '../customers/customer-documentlist.component';
import { CustomerMaillistComponent } from '../customers/customer-maillist.component';
import { ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'app-customer-commlist',
  templateUrl: './customer-commlist.component.html',
  styleUrls: ['./customer-commlist.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ],
  //encapsulation: ViewEncapsulation.None
})

export class CustomerCommlistComponent implements OnInit, OnDestroy {
  customer_commlist_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  isDialog: boolean = false;
  errMsg: string;
  msg: string;
  customerList: CustomerModel[];
  userType: string;
  showSpin: boolean = false;
  selectedTabName: string;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  customerGuid: string="";
  text_search: string;
  filter_by: string;
  includeDisabledRecords: boolean=false;
  recordList: PersonCommsAddressModel[];
  limitTo: number = 10;
  
  displayedColumn: string[] = ['addressUse', 'address', 'type', 'isPreferred', 'remark', 'data_entry_status', 'disabled', 'action'];
  upPII: boolean = false;
  grantAccessCompanyProfiles: boolean = false;
  hideColumn: boolean = false;
  grantAccessToMessages: boolean = false;
  grantAccessToAccountGrouping: boolean = false;
  x: string = "";
  @Input() clientId!: string;



  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _customerService: CustomerService, @Inject(DOCUMENT) private _document, private readonly _dialogService: DialogService,
    private readonly _customerGroupService: CustomerGroupService, @Optional() private readonly _dialogRef: MatDialogRef<CustomerCommlistComponent>, @Optional() @Inject(MAT_DIALOG_DATA) private data: any, private cdr: ChangeDetectorRef) {

    if (this.data) {
      this.isDialog = true;
    }
  }

  getAllData(): Observable<any[]> {
    let getClistResponse;
    if (this.userType == 'internal') {
      getClistResponse = this._commonService.getCustomers();
    }
    else {
      if (this.grantAccessToAccountGrouping) {       
        let req = new CustomerGroupModel();
        getClistResponse = this._customerGroupService.getSelectedCustomerListBycgrpId(req);
      }
      else {      
        getClistResponse = of(null);
      }
    }
    
    return forkJoin([getClistResponse]);

  }

  ngOnInit() {     
    this._authService.updateAccessTime();
    this.grantAccessCompanyProfiles = false;
    if (localStorage.getItem('up.cPro') == 'true') {
      this.grantAccessCompanyProfiles = true;
    }
    this.upPII = false;
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }

    if (!this.upPII || !this.grantAccessCompanyProfiles) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.grantAccessToMessages = false;
    if (localStorage.getItem('up.msg') == 'true') {
      this.grantAccessToMessages = true;
    }

    if (localStorage.getItem('up.cGrp') == 'true') {
      this.grantAccessToAccountGrouping = true;
    }
    this.showSpin = true;
    this.errMsg = "";
    this.msg = "";
    this.customerList = [];
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    
    this._route.queryParams.subscribe(params => {
      //this.customerGuid = params["customerGuid"];
      //this.x = 'cid-1: ' + this.customerGuid
     
      if (params["tabName"] == null || params["tabName"] == "undefined") {
        this.selectedTabName = 'comm';
        this.customerGuid = "";
        if (params["customerGuid"] != null || params["customerGuid"] != undefined) {
          this.customerGuid = params["customerGuid"];
        }
        this.text_search = "";
        this.filter_by = "AddressUse";
      }
      else {
        this.customerGuid = params["customerGuid"];       
        this.selectedTabName = params["tabName"];
        this.text_search = params["text_search"];
        this.filter_by = params["filter_by"];
        //this.includeDisabledRecords = params["includeDisabledRecords"];
        if (params["includeDisabledRecords"] == "false") {
          this.includeDisabledRecords = false;
        }
        else {
          this.includeDisabledRecords = true;
        }
      }
      
    });

    if (this.customerGuid != "") {
      this.customerGuid = this.customerGuid.toLowerCase();
    }
    
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    if (this.userType == 'customer') {
      this.hideColumn = true;
    }

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.customerList = responses[0].model;
          if (this.userType == "customer" && this.grantAccessToAccountGrouping) {
            if (this.customerGuid == "")
              this.customerGuid = this.customerList.find(x => x.isDefaultClient == true).customerGUID.toLowerCase();          
          }
          
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      if (this.userType == 'customer') {
        if (!this.grantAccessToAccountGrouping) {
          this.getSearchResults();
        }
        else {
          if (this.customerGuid != '') {
            this.getSearchResults();
          }
        }
      }
      else {
        if (this.customerGuid != '') {
          this.getSearchResults();
        }
      }
      this.showSpin = false;

    });

    //if (this.userType == "internal") {
    //  this._commonService.getCustomers<ResponseModel<CustomerModel[]>>().subscribe(response => {
    //    if (response != null && response.code == "200") {
    //      if (response.model.length > 0) {
    //        this.customerList = response.model;
    //      }
    //      else {

    //      }
    //    }
    //    this.showSpin = false;

    //  })

    //  this.initControls();
   
    //  if (this.customerGuid != "") {
    //    this.getSearchResults();
    //  }    

    //}
    
  }

  initControls() {
    this.customer_commlist_form = this._formBuilder.group({
      customer_select: this.customerGuid,
      text_search: [this.text_search],
      filter_by: [this.filter_by],
      includeDisabledRecords: this.includeDisabledRecords
    });
  }




  ngOnDestroy() {
    // remove the class form body tag
    //this._document.body.classList.remove('bodybg-color');
  }

  get f() { return this.customer_commlist_form.controls; }

  customerChange(e: any) {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.customerGuid = this.f.customer_select.value;
    if (e.target.value != "") {
      this.getSearchResults();
    }
    else {
      if (this.userType == "internal") {
        if (this.f.customer_select.value == "") {
          this.errMsg = "Please select a client";
          return;
        }
      }
    }

  }

  includeDisabledRecordsOnChange(event: any) {
    //let x = event.target.checked;
    if (this.userType == "internal") {
      if (this.f.customer_select.value == "") {
        this.errMsg = "Please select a client";
        return;
      }
    }
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.getSearchResults();
  }

  clickEdit(event: any, comm: PersonCommsAddressModel) {
    if (this.userType == "internal") {
      if (this.f.customer_select.value == "") {
        this.errMsg = "Please select a client";
        return;
      }
    }
    let s = "";
    let personCommsAddressGUID = "";
    if (comm == null) {
      s = "ADD New Communications";
    }
    else {
      s = "Edit Communications";
      personCommsAddressGUID = comm.personCommsAddressGUID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "58em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;

    if (this.userType == "internal" || (this.userType == "customer" && this.grantAccessToAccountGrouping)) {
      dialogConfig.data = { dialogTitle: s, customerGUID: this.f.customer_select.value, personCommsAddressGUID: personCommsAddressGUID };
    }
    else {
      dialogConfig.data = { dialogTitle: s, personCommsAddressGUID: personCommsAddressGUID };
    }
    const dialogRef = this._dialog.open(CustomerCommEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSearchResults();
        
      }
    });

  }

  clickReset() {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.selectedTabName = 'comm';
    this.customerGuid = "";
    this.text_search = "";
    this.filter_by = "AddressUse";
    this.initControls();
  }

  clickShowAllRecords() {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.text_search = "";
    this.f.text_search.setValue(this.text_search);
    if (this.userType == "internal") {
      if (this.f.customer_select.value == "") {
        this.errMsg = "Please select a client";
        return;
      }
    }
    this.getSearchResults();

  }

  onSubmit() {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
   
    if (this.f.customer_select.value == "") {
      this.errMsg = "Please select a client";
      return;
    }
    if (this.f.text_search.value == "") {
      this.errMsg = "Please enter a keyword.";
      return;
    }
    this.loading = true;
    this.getSearchResults();
  }

  getSearchResults() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    let request = new PersonCommsAddressModel();
    //request.customerGUID = this.f.customer_select.value;
    request.customerGUID = this.clientId;
    request.textSearch = this.f.text_search.value;
    request.filterBy = this.f.filter_by.value; 
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    request.isActive = true;
    if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
      request.isActive = !this.f.includeDisabledRecords.value;
    }
    
    this._customerService.getCustomerOfficeCommsAddress<ResponseModel<PersonCommsAddressModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)

          }

        }
        else {
          this.recordList = [];
          //this.msg = "No data return.";
          if (this.f.text_search.value != "") {
            switch (this.f.filter_by.value) {
              case "AddressUse":
                this.msg = "Address use not found.";
                break;
              case "Address":
                this.msg = "Address not found.";
                break;
              case "Rating":
                this.msg = "Rating not found.";
                break;
              case "Type":
                this.msg = "Type not found.";
                break;
            }
          }
          else {
            this.msg = "No records found.";
          }
        }
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;

    })

  }
  

  


  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    this.getSearchResults();

  }

  clickTab(s: string) {
    
    if (this.selectedTabName != s) {
      if (s == "document") {
        this.f.filter_by.setValue("DocumentName");
      }
      else {
        if (s == "mail") {
          if (this.f.filter_by.value != "AddressUse" && this.f.filter_by.value != "Address") {
            this.f.filter_by.setValue("AddressUse");
          }
        }
      }
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "tabName": s,
          "customerGuid": this.f.customer_select.value,
          "text_search": this.f.text_search.value,
          "filter_by": this.f.filter_by.value,
          "includeDisabledRecords": this.f.includeDisabledRecords.value
        }
      };
      switch (s) {
        case "comm":
          this._router.navigate(['/customers/customer-commlist'], navigationExtras );
          break;
        case "mail":

          this._router.navigate(['/customers/customer-maillist'], navigationExtras);
          break;
        case "document":
          this._router.navigate(['/customers/customer-documentlist'], navigationExtras);
          break;

      }
    }    

  }

  clickSendEmail(e: any) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    
    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", groundStopGUID: "", groundStopTaskGUID: "", customerGUID: this.customerGuid
    };

    const dialogRef = this._dialog.open(EmailClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });
  }

  clickRouteNew(route: string, display: string) {
    this._dialogRef.close(true);
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }

  openDataMaintenanceComponent() {
    if (this.isDialog) {
      // Close the current dialog
      this._dialogRef.close();

      // Proceed after dialog closes
      setTimeout(() => {
        const config = new MatDialogConfig();
        config.panelClass = "custom-dialog-container3";
        config.height = "18.3em";
        config.width = "56em";
        config.restoreFocus = false;
        config.hasBackdrop = false;
        config.data = {
          isDialog: true,
          v: this._authService.getCurrentTimeNumber()
        };
        config.position = { left: "20px", top: "138px" };
        this._dialog.open(MaintenanceDashboardClientComponent, config);
      }, 200); // Delay ensures the current dialog is fully closed
    } else {
      // If not in a dialog, open directly
      const config = new MatDialogConfig();
      config.panelClass = "custom-dialog-container3";
      config.height = "24em";
      config.width = "56em";
      config.restoreFocus = false;
      config.hasBackdrop = false;
      config.data = {
        v: this._authService.getCurrentTimeNumber()
      };
     
      this._dialog.open(MaintenanceDashboardClientComponent, config);
    }
  }


  clickNewTab(tabName: string) {
    if (this.selectedTabName !== tabName) {
      this.selectedTabName = tabName;

    
    }
  }



}
