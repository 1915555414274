<div *ngIf="upPII && grantAccessToManageManifestProfiles" style="margin-top:0em; padding-top:0em; display:flex">
  <form *ngIf="customer_person_commlist_form" [formGroup]="customer_person_commlist_form">
    <div>
      
      <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container">
        
        <tr>
          <ng-container matColumnDef="addressUse">
            <th mat-header-cell *matHeaderCellDef style="width:20%;text-align:left !important">
              <div style="width:100%;padding-right:.25em;padding-left:.25em" class="d-flex justify-content-between">
                <div class="material-icons-outlined">phone</div>
                <div>Address Use</div>
                <div>&nbsp;</div>
              </div>
            </th>
            <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.addressUseDescriptionList}}</td>
          </ng-container>
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef style="width:15%">
              <div style="margin-top:-0.25em;">Address</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.address}}</td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef style="width:10%">
              <div style="margin-top:-0.25em;">Type</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.commAddressType}}</td>
          </ng-container>
          <ng-container matColumnDef="preferred">
            <th mat-header-cell *matHeaderCellDef style="width:5%">
              <div style="margin-top:-0.25em;">Preferred</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.isPreferred? 'Yes':'&nbsp;'}}</td>
          </ng-container>
          <ng-container matColumnDef="remark">
            <th mat-header-cell *matHeaderCellDef style="width:15%">
              <div style="margin-top:-0.25em;">Remark</div>
            </th>
            <td mat-cell *matCellDef="let element"><a *ngIf="element.commRemarks.length>15" style="cursor:pointer" matTooltipClass="tooltipLineBreak" matTooltip="{{element.commRemarks}}">{{ element.commRemarks.substring(0,14)+'...' }}</a><span *ngIf="element.commRemarks.length<=15">{{element.commRemarks}}</span></td>
          </ng-container>
          <ng-container matColumnDef="data_entry_status">
            <th mat-header-cell *matHeaderCellDef style="width:15%">
              <div style="margin-top:-0.25em;">Data Entry Status</div>
            </th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger': element.dataEntryStatusID!=2}">{{ element.dataEntryStatusDescription }}</td>
          </ng-container>
          <ng-container matColumnDef="disabled">
            <th mat-header-cell *matHeaderCellDef style="width:5%" [hidden]="hideColumn">
              <div style="margin-top:-0.25em;">Disabled</div>
            </th>
            <td mat-cell *matCellDef="let element" [hidden]="hideColumn">{{element.isActive? '&nbsp;': 'Yes'}}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="width:5%">
              <div>Action</div><!--<div style="margin-top:0em;padding-bottom:0.25em"><a mat-button class="btn-high grid" style="align-self:center" (click)="clickEdit($event, null);">Add</a></div>-->
            </th>
            <td mat-cell *matCellDef="let element">
              <a mat-button class="btn-low grid" *ngIf="userType=='internal'" style="align-self:center" >
                Edit
              </a>
              <!-- <a mat-button class="btn-low grid" *ngIf="userType=='customer'" style="align-self:center">
                View
              </a> -->
            </td>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="clickEdit($event, row);"></tr>
      </table>
    </div>
    <!--<div>
      <mat-paginator *ngIf="recordList && totalPageNumber>1" style="border: none;width:80em;" #paginator [length]="totalRecords" [pageSize]="pageSize"
                     [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
      </mat-paginator>
    </div>-->
  </form>
</div>

<app-spin *ngIf="showSpin"></app-spin>
