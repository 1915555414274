
<div>
  <div style="  max-height:45em;"> 
    <div >
      <div class="d-flex justify-content-between " cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle> 
        <div><h4><i class="material-icons" style="font-size: x-large;margin-top:  0em;">settings</i> Template Profile</h4></div> 
        <div style="float: right; margin-left:  .5em; margin-top: -.5em; display: flex;" align="end">
          <button mat-icon-button (click)="clickClose()"> <i class="material-icons" style="font-size: x-large;">close</i> </button>
        </div> 
      </div> 
    </div>

    <form  [formGroup]="template_edit_form" style="background: white; padding: 0.5em;">
      <div style="display: flex; "> 
        <div > 
          <div > 
            <div style="display: flex;margin-bottom:0.25em">
              <div style="margin-right:0.5em; width: 7em;text-align: right">Template Name</div>
              <div>
                <input type="text" formControlName="template_name" placeholder="Enter Template Name here"  class="form-control"  style="font-size: small; padding: 0.25em;width:70em;height:2em;border-radius: 0.25em; background-color:#eaf0fd" />
              </div>
            </div> 
            <div style="display: flex;margin-bottom:0.25em">
              <div style="display:flex;  margin-right: 0.25em">
                <div style="margin-right:0.5em; width: 7em;text-align: right">Subject Line</div>
                <div>
                  <input type="text"  class="form-control"  formControlName="subject"  placeholder="Enter subject Line here"   style="font-size: small; padding: 0.25em;width:70em;height:2em;background-color:#eaf0fd; border-radius: 0.25em" />
                  <!-- <textarea  formControlName="subject"  class="form-control"   
                     style="width:66em; height:3.5em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">
                  </textarea> -->
                </div>
              </div>
              <div *ngIf="submitted && f.subject.errors" class="invalid-feedback" style="margin-top:0em">
                <div *ngIf="f.subject.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:9em">Subject is required</div>
              </div>
            </div>

            <div style="display: flex;margin-bottom:0.25em">
              <div style="display:flex;   margin-right: 0.25em; margin-left: 1em;">
                <div style="margin-right:0.5em; width: 6em;">
                  <button type="button" mat-button class="btn-low" style="margin-left: 1em;" [ngClass]="selectedServicesCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickServiceFilter()">Services{{selectedServicesCountText}}</button>
                </div>
                  
                <div>
                  <input type="text" readonly formControlName="service_list" class="form-control" style="font-size: small; padding: 0.25em;width:70em;height:2em;border-radius: 0.25em; background-color:#f0f0f0"   />
                  <!-- <textarea readonly disabled  tabindex="-1" formControlName="service_list"  class="form-control"   
                     style="width:70em; height:3.5em; font-size:small; padding:0em 0em 0em 0em;background-color:#f0f0f0">
                  </textarea> -->

                </div>
              </div> 
            </div>
  
            <!--service list-->
            <div *ngIf="showServiceList" style="position: absolute;float: left; margin-top: 0.25em; margin-left: 6.25em; background-color: #FAFAFD;; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000 ">
              <div style="display: flex">
                <div style="margin-right:2em">

  
                  <div>                  
                    <div style="font-weight: bold; text-decoration:underline; white-space: nowrap"> Service Class </div>
                </div>
                  <div *ngIf="  serviceClassList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                    <div *ngFor="let p of serviceClassList; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                      <div #tripPersonRefs [attr.serviceClassID]="p.serviceClassID" style="display: flex; font-size: small; margin-top:-0.5em">
                        <div>
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="checkClassChange($event, p)" [checked]="p.selected">{{p.serviceClassDescription}}</mat-checkbox>
                        </div>

                      </div>
                    </div>
                  </div>



                </div>
                <div style="min-width: 14em">
                  <div style="display:flex; justify-content:space-between">
                    <div style="font-weight: bold; text-decoration:underline; white-space: nowrap">{{service=='service_type'?'Service Type':'Service Class'}}</div>
                    <div style="float:right" (click)="showServiceList=!showServiceList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                  </div>
                  <div style="font-size: small; margin-left:0em">
                    <div style="margin-right:0.5em;">
                      <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                        <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                        <input type="text" formControlName="service_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameService($event,service)" />
                      </div>
                    </div>
                  </div>
                  <!--type-->
                  <div *ngIf="  serviceTypeList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                    <div *ngFor="let p of filteredServiceList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                      <div #tripPersonRefs [attr.serviceTypeID]="p.serviceTypeID" style="display: flex; font-size: small; margin-top:-0.5em">
                        <div>
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="checkServiceChange($event, p)" [checked]="p.selected">{{p.serviceTypeDescription}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--type end-->
                  <!--class-->
                  <div *ngIf="service=='service_class' && serviceClassList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                    <div *ngFor="let p of filteredServiceClassList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                      <div #tripPersonRefs [attr.serviceClassID]="p.serviceClassID" style="display: flex; font-size: small; margin-top:-0.5em">
                        <div>
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="checkServiceChange($event, p)" [checked]="p.selected">{{p.serviceClassDescription}}</mat-checkbox>
                        </div>

                      </div>
                    </div>
                  </div>
                  <!--class end-->
                </div>

              </div>
              <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
              <div style="display: flex; float: right">
                <div style="margin-right:0.5em">
                  <button type="button" mat-button class="btn-low" (click)="clickResetService()" style="height:2em">RESET</button>
                </div>
                <div>
                  <button type="button" mat-button class="btn-high" (click)="clickDoneService()" style="height:2em">DONE</button>
                </div>
              </div>
            </div>
            <!--service list end-->

            <!--  Legend starts   -->
            <div *ngIf="showLegend" style="width: 47em; position: absolute;float: left; margin-top: 2.5em; margin-left: 53.25em; background-color: #FAFAFD; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000 ">
              <div style="display:flex; justify-content:space-between">
                <div style="font-weight: bold; text-decoration:underline; white-space: nowrap">Valid Keywords</div>
                <div style="float:right" (click)="showLegend=!showLegend"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
              </div>
              <div style="display: flex; flex-wrap: wrap;">
                <div *ngFor="let key of templateData.validKeywords; let i = index" style="margin-right: 0.45em; margin-bottom: 0.25em;">
                  <div class="mat-checkbox-smaller" style="display: flex; width: 11em;">
                    <span style="display: flex; justify-content: center; align-items: center;cursor: pointer;font-size: small;" 
                    [matTooltip]="key.length > 18 ? key : ''"  matTooltipClass="tooltipLineBreak"
                     (click)="insertText('%'+ key +'%') "> {{ key.length <= 18 ? key : (key | slice:0:18) + '...' }}</span>
                  </div>
                </div>
              </div>
              <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
              <div style="display: flex;  ">
       
                <div>
                  <button type="button" mat-button class="btn-high"  (click)="showLegend=!showLegend" style="height:2em">Close</button>
                  <!-- <button mat-button  (click)="insertText('hello') ">copy</button> -->

                </div>
              </div>
            </div> 
            <!--Legend ends -->

            <div  style="display: flex;margin-left: 1em;margin: 0.5em 0; ">
              <div style="display: flex;"> 
                <label for="isMultipleLegs" style="font-size:small">Multiple Legs</label>
                <div class="form-group" style="margin-left: 1.6em; margin-top:-0.35em; margin-bottom:0em">
                  <mat-slide-toggle style="transform:scale(.8); height:2em; " color="primary" [checked]="isMultipleLegs" formControlName="isMultipleLegs"></mat-slide-toggle>
                </div>
              </div>
              <div   >
                <!-- <button mat-button class="btn-low" (click)="copyToClipboard() " >Copy To Clipboard</button> -->
                <button mat-button class="btn-low" (click)="clickLegend() " style="margin-left: 2em;">Keywords</button>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div>
        <div style="display: flex;">
          <!-- <div *ngIf="showSuccessMsg">
            <app-success-message [successMsg]="successMsg" ></app-success-message>
          </div> -->
          <div></div>
        </div>
        <!-- <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; min-width:20em;font-size: small ">{{errMsg}}</div> -->
      </div>
      <div>
        <ckeditor #emailCKEditor formControlName="emailCKEditor" id="emailCKEditor" name="emailCKEditor" [editor]="Editor" [config]="CKEConfig" (ready)="onReady($event)" [disabled]="isDisabled" style="line-height:1.2em; background-color:#eaf0fd !important;"></ckeditor>
      </div>
    </form> 
  </div>
  <div style="display: flex; margin-top: 0.5em; justify-content: space-between;">
    <div>
      <div>
        <button *ngIf="isAdmin"  mat-button class="btn-high"  [disabled]="loading" (click)="validateTemplateDetails()" style="padding-left:0.5em !important; padding-right: 0.5em !important; margin-right: 1em;" >Save</button>
        <button mat-button class="btn-low" style="margin-right: 1em;" (click)="clickClose()"> Close </button>        
      </div>
    </div>

    <div style="display: flex;" >
      <div>

        <div *ngIf="errMsg!=''"  class="alert d-flex align-items-center" role="alert" style=" background-color:#f9d2de; min-width:25em; padding-left:0.25em; padding-right:0.25em; font-size:small;height:2.5em">
          <i class="material-icons">cancel</i>
          <div>{{errMsg}}</div>
        </div>
        <div  *ngIf="errMsg2!=''" class="alert d-flex align-items-center" role="alert" style=" background-color:#f9d2de; min-width:25em; padding-left:0.25em; padding-right:0.25em; font-size:small;height:2.5em">
          <i class="material-icons">cancel</i>
          <div>{{errMsg2}}</div>
        </div>
        <div *ngIf="showSuccessMsg">
          <app-success-message [successMsg]="successMsg" ></app-success-message>
        </div>
        <!-- <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-left:2em; background-color:#f9d2de; padding-left:0.25em; min-width:20em;font-size: small ">{{errMsg}}</div>
        <div *ngIf="errMsg2!=''" class="d-block" style="margin:0; display:flex;margin-left:2em; background-color:#f9d2de; padding-left:0.25em; min-width:20em;font-size: small ">{{errMsg2}}</div> -->
      </div> 
    </div> 

    <form [formGroup]="template_edit_form">
      <div style="font-size: small; float: right; margin-right: 3em; margin-top: -0.5em; display: inline-flex;">
        <div style="margin-right: 2em; margin-top: -0.25em;">
          <label for="record_status" style="font-size:small">Disable Record</label>
          <div style="margin-top:-0.5em;">
            <select *ngIf="f" formControlName="record_status"
              style="font-size: small;background-color:#eaf0fd;border-radius:0.25em;width: 7em; height:1.5625em; padding: 0;"
              class="form-control" (change)="record_statusChange($event)">
              <option value="1" selected>No</option>
              <option value="0">Yes</option>
            </select>
          </div>
        </div>
        <div>
          <div>
            Modified By: {{modifiedBy}}
          </div>
          <div>
            Modified Date: {{modifiedDate}}
          </div>
        </div>
      </div>
    </form>
  </div>
  <app-spin *ngIf="showSpin"></app-spin>
</div>
