<div *ngIf="upPII" style="background-color:#f8f8f8;overflow-y:hidden;" >
  <div style="display: flex; justify-content:space-between">
    <div style="display: flex;padding-left:1em; padding-top:0.25em; float:left; width:100%" cdkDrag
         cdkDragRootElement=".cdk-overlay-pane"
         cdkDragHandle>

      <div style="margin-right:1em">
        <div style="font-size: small">Registration</div>
        <div style="font-weight: bold; font-size: small">{{registration}}</div>
      </div>
      <div style="margin-right:1em">
        <div style="font-size: small">Trip Code</div>
        <div style="font-weight: bold; font-size: small">{{tripCode}}</div>
      </div>
      <div style="margin-right:1em">
        <div style="font-size: small">Business Stop</div>
        <div style="font-size: small; display: flex"><div>{{prevICAO!=''?prevICAO+'-':''}}</div><div style="font-weight: bold">{{icao}}</div><div>{{nextICAO!=''?'-'+nextICAO:''}}</div></div>
      </div>
      <div style="margin-right:1em" *ngIf="arrivalDateTime!=''">
        <div style="font-size: small">Arrival({{icao}})</div>
        <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{arrivalDateTime}}</div>
        <div style="font-size: small; margin-top:-0.5em"> {{arrivalDateTimeLocal!=''?arrivalDateTimeLocal:''}}</div>
      </div>
      <div style="margin-right:1em" *ngIf="departureDateTime!=''">
        <div style="font-size: small">Departure({{icao}})</div>
        <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{departureDateTime}}</div>
        <div style="font-size: small;margin-top:-0.5em"> {{departureDateTimeLocal!=''?departureDateTimeLocal:''}}</div>
      </div>
      <div>
        <div style="font-size: small;">{{aircraftManufacturer}} {{aircraftModel}}</div>
        <div style="font-size: small;">{{aircraftInfo}}</div>
      </div>
    </div>
    <div *ngIf="onHoldText!=''" style="margin-top:1em;border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
      <div style="margin-top: -0.1em; text-align: center; font-weight: normal; font-size: small;">On Hold</div>
    </div>
    <div style="float: right;padding-top:0.75em; padding-right:1em">
      <i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium" title="Copy note to clipboard" [cdkCopyToClipboard]="clickCopyHeader() ">content_copy</i>
    </div>
  </div>
  <div>
    <form *ngIf="fuel_task_edit_form" [formGroup]="fuel_task_edit_form">
      <div style="display:flex; margin-top:0em; padding-left: 1em; padding-right:1em">
        <div style="margin-top: 0.5em; background-color: white; padding-left: 1em; padding-right: 1em; padding-bottom: 0.75em; min-width:80em; max-width:90em">
          <div style="display:flex; justify-content: space-between">
            <div class="d-inline-flex">
              <div style="border-radius: 50%; width: 1em; height: 1em; border: black solid 0.0625em; margin-right:.25em;margin-top:.5em" [style.background-color]="taskColor"></div>
              <div  style="margin-top:0.25em;"><h5  [ngClass]="immediateAction == 1?'divImmediatePriority':''" >{{serviceTypeDesc}}</h5></div>
              <div class="ml-2 alert alert-danger small px-1 py-0 link" style="margin-top:.55em;" *ngIf="avoidLocationMsg!=''" (click)="openBusinessRule()">{{avoidLocationMsg}}</div>
            </div>
            <div *ngIf="isRevisionRequiredValue==1">
              <div [ngClass]="f.isRevisionRequired.value==true?'alert alert-danger d-flex align-items-center':'alert alert-success d-flex align-items-center'" style="height:2.5em; margin-top:0.25em; margin-right:2em;padding-left:0.25em; padding-right:0.25em; font-size:small; display: flex; border-radius:0.25em;">
                <div>
                  <mat-slide-toggle formControlName="isRevisionRequired" style="transform: scale(0.9) " [checked]="isRevisionRequired">
                  </mat-slide-toggle>
                </div>
                <div *ngIf="f.isRevisionRequired.value==true">
                  <div>Revision Required - </div>
                  <div style="margin-top:-0.5em">Turn off to Acknowledge</div>
                </div>
                <div *ngIf="f.isRevisionRequired.value==false">
                  <div style="margin-top:0em;">Revision Acknowledged</div>
                </div>
                <div>
                  <div *ngIf="f.isRevisionRequired.value==true" style="margin-top:0.5em">
                    <i class="material-icons-two-tone icon_orange" style="font-size: x-large;">notifications_active</i>
                  </div>
                  <div *ngIf="f.isRevisionRequired.value==false" style="margin-top:0.5em">
                    <i class="material-icons" style="font-size: x-large; background-color: transparent; color: black; border-color: #5e5e5e">task_alt</i>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="isRevisionRequiredValue==2">
              <div [ngClass]="f.isRevisionRequired.value==true?'alert alert-danger d-flex align-items-center':'alert alert-success d-flex align-items-center'" style="height:2.5em; margin-top:0.25em; margin-right:2em;padding-left:0.25em; padding-right:0.25em; font-size:small; display: flex; border-radius:0.25em;">
                <div>
                  <mat-slide-toggle formControlName="isRevisionRequired" style="transform: scale(0.9) " [checked]="isRevisionRequired">
                  </mat-slide-toggle>
                </div>
                <div *ngIf="f.isRevisionRequired.value==true">
                  <div>Revision Required - </div>
                  <div style="margin-top:-0.5em">Turn off to Acknowledge</div>
                </div>
                <div *ngIf="f.isRevisionRequired.value==false">
                  <div style="margin-top:0em">Revision Acknowledged</div>
                </div>
                <div>
                  <div *ngIf="f.isRevisionRequired.value==true" style="margin-top:0.5em">
                    <i class="material-icons-two-tone icon_red" style="font-size: x-large;">notifications_active</i>
                  </div>
                  <div *ngIf="f.isRevisionRequired.value==false" style="margin-top:0.5em">
                    <i class="material-icons" style="font-size: x-large; background-color: transparent; color: black; border-color: #5e5e5e">task_alt</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div style="min-width:42em">
              <!--fuel-->
              <div style="display: flex;margin-top:-0.75em; ">
                <div style="margin-right: 2em" *ngIf="fuelQuoteList.length>0">
                  <label for="fuel_supplier_select" style="font-size:small">Fuel Supplier</label>
                  <div style="margin-top: -0.5em;">
                    <label style="color: #337dff">{{fuelQuoteList.length>0? fuelQuoteList[0].fuelSupplierName : 'n/a'}}</label>

                  </div>

                </div>
                <div style="margin-right:2em" *ngIf="fuelQuoteList.length>0">
                  <div>
                    <label for="intoplane_agent_select" style="font-size:small">Into Plane Agent</label>
                    <div style="margin-top:-0.5em;">
                      <label style="color: #337dff"> {{fuelQuoteList.length>0? fuelQuoteList[0].intoPlaneAgent : 'n/a'}}</label>
                    </div>

                  </div>
                </div>
                <div *ngIf="selectedTabName=='quote'" style="margin-right:2em">
                  <div>
                    <label for="intoplane_agent_select" style="font-size:small">Expiry Date</label>
                    <div style="margin-top:-0.5em;">
                      <label style="color: #337dff"> {{fuelQuoteList.length>0? fuelQuoteList[0].expiryDate!=null? fuelQuoteList[0].expiryDate:'n/a' : 'n/a'}}</label>
                    </div>

                  </div>
                </div>

                <div *ngFor="let vendor of vendorList" style="margin-right:2em">
                  <div class="mr-1" *ngIf="vendor.vendorGUID!=''">

                    <label style="font-size:small;">{{vendor.vendorRoleDescription}}</label>
                    <div style="margin-top:-0.5em;">
                      <a (click)="openVendorDetails(vendor.vendorGUID)" style="color: #337dff;" class="link"> {{vendor.vendorName}}</a>
                    </div>
                  </div>

                </div>

              </div>
              <!--fuel end-->
              <div style="display: flex">
                <div>
                  <div style="display:flex; margin-top:-0.25em; height:3em; ">
                    <div style="margin-left:0em">
                      <label for="task_status_select" style="font-size:small">Operational Status</label>
                      <div style="margin-top:-0.5em;">
                        <select *ngIf="f" formControlName="task_status_select" style="font-size: small;font-weight: 500" class="form-control form-control-sm dropdown" (change)="taskStatusChange($event)">
                          <option *ngFor="let t of taskStatusList" [ngValue]="t.taskStatusGUID">{{t.taskStatusDescription}}</option>
                        </select>
                      </div>
                    </div>
                    <div *ngIf="due_datetime==''" style="margin-left:0.5em;margin-top:0em">
                      <div style="cursor: pointer; font-size: small; margin-top:0.25em" (click)="showOrginalLeadTimeDueDate=true" class="divHover">Lead Time</div>
                      <div style="display:flex;margin-top:0.25em; font-size:small">
                        <input type="text" formControlName="lead_time" maxlength="9" placeholder="?d?h?m" class="form-control" [ngClass]="{ 'is-invalid': (submitted || lead_timeChanged) && f.lead_time.errors }" style="width:5em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; background-color:#eaf0fd" (change)="leadTimeChange($event)" />
                      </div>
                      <div *ngIf="(submitted || lead_timeChanged) && f.lead_time.errors">
                        <div *ngIf="f.lead_time.errors.pattern" style="background-color:#f9d2de; margin-left:-10em; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Invalid lead time. format: ?d?h?m</div>
                      </div>
                    </div>

                    <div *ngIf="due_datetime==''" style="margin-left:0.5em;width:4.25em ">
                      <div style="cursor: pointer; font-size: small; margin-top:0.25em" (click)="showOrginalLeadTimeDueDate=true" class="divHover">Due Date</div>
                      <div style="display:flex;margin-top:0.25em">
                        <input type="text" formControlName="due_date" maxlength="9" placeholder="?d?h?m" class="form-control" [ngClass]="{ 'is-invalid': (submitted || due_dateChanged) && f.due_date.errors }" style="width:5em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; background-color:#eaf0fd" (change)="dueDateChange($event)" />
                      </div>
                      <div *ngIf="(submitted || due_dateChanged) && f.due_date.errors">
                        <div *ngIf="f.due_date.errors.pattern" style="background-color:#f9d2de; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Invalid due date. format: ?d?h?m</div>
                      </div>
                    </div>
                    <div *ngIf="due_datetime!=''" style="margin-left:0.5em;margin-top:0em;width:10.25em">
                      <div style="font-size: small; margin-top:0.25em">Delivery</div>
                      <div style="display:flex;margin-top:0.25em; font-size:small">
                        {{due_datetime}} (L)
                      </div>
                    </div>
                    <!--<div style="margin-left:0.5em">
              <label for="acct_status_select" style="font-size:small">GTS Accounting status</label>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="acct_status_select" style="width:17em; font-size: small; font-weight:500; border-radius:0.25em;border-color:lightgrey;background-color:#eaf0fd">
                  <option *ngFor="let a of acctStatusList" [ngValue]="a.accountingStatusGUID">{{a.accountingStatusDescription}}</option>
                </select>
              </div>
            </div>-->
                    <div *ngIf="showOrginalLeadTimeDueDate" style="font-size:small; background-color:#f8f8f8; margin-left:-27em; margin-top:-4.5em; display: flex; max-height: 5em; width: 35em; border: solid gray 0.0625em; border-radius:0.25em; padding:0.5em 1em 0em 1em; z-index: 1000">
                      <div style="margin-right:1em">
                        <div style="white-space: nowrap">Lead Time</div>
                        <div style="margin-top:1em">{{serviceType_LeadTime}}</div>
                      </div>
                      <div style="margin-right: 1em">
                        <div style="white-space: nowrap">Lead Time</div>
                        <div style="white-space: nowrap;margin-top:-0.5em">in Minutes</div>
                        <div>{{serviceType_LeadTime_Min}}</div>
                      </div>
                      <div style="margin-right: 1em">
                        <div style="white-space: nowrap">Due Date</div>
                        <div style="margin-top:1em">{{serviceType_DueDate}}</div>
                      </div>
                      <div style="margin-right: 1em">
                        <div style="white-space: nowrap">Due Date</div>
                        <div style="white-space: nowrap;margin-top:-0.5em">in Minutes</div>
                        <div>{{serviceType_DueDate_Min}}</div>
                      </div>
                      <div style="margin-right: 1em">
                        <div style="white-space: nowrap">Calculate From</div>
                        <div style="margin-top:1em">{{serviceType_CalculateFrom}}</div>
                      </div>
                      <div>
                        <i class="material-icons" style="cursor: pointer; color:black;font-size: medium" (click)="showOrginalLeadTimeDueDate=!showOrginalLeadTimeDueDate">close</i>
                      </div>
                    </div>
                    <div class="ml-4 mt-3">
                      <button type="button" mat-button class="btn-high" *ngIf="!lockdownTrip && !showSpin && selectedTabName=='quote' && upFuel" [disabled]="loadingDoc" (click)="generateQuote()"><div style="display:flex"><span *ngIf="loadingDoc" class="spinner-border spinner-border-sm mr-1"></span><div>Create Quote</div></div></button>&nbsp;
                    </div>
                    <div class="ml-3 mt-4 d-flex">
                      <i *ngIf="taskDocumentList.length>0" class="material-icons" style="font-size:large">attach_file</i>
                      <div *ngFor="let item of taskDocumentList; let i=index" style="margin-top:-0.25em">
                        <div style="display:flex; padding:0em;">
                          <div><a style="cursor: pointer; color: #337dff; text-decoration: underline" title="{{item.documentName}}" (click)="clickPreviewDocument($event, item)">{{item.documentName}}</a></div>
                          <div *ngIf="item.groundStopTaskDocumentGUID=='' && hasExistingDocument" style="margin-top:0.5em; font-size:x-small"><i class="material-icons" style="color:green; font-size: small; ">fiber_new</i></div>
                          <div style="margin-top:0.25em; margin-left: 0.25em" *ngIf="!lockdownTrip && upFuel"><i class="material-symbols-outlined" style=" font-size: large; cursor: pointer;" title="click to remove the document" (click)="clickRemoveDocument($event, item)">delete</i></div>
                        </div>
                      </div>


                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div style="float:right">
              <button type="button" mat-button class="btn-med" style="padding-left: 0.25em !important; padding-right: 0.25em !important" (click)="clickEmail()" [disabled]="lockdownTrip"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em; width:1.125em">send</i><div>Email</div></div></button>&nbsp;
              <button type="button" mat-button class="btn-med" style="padding-left: 0.25em !important; padding-right: 0.25em !important" (click)="openTripMessages()" [disabled]="lockdownTrip"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em; width:1.125em">email</i><div>Trip Messages</div></div></button>&nbsp;
            </div>



          </div>
          <div style="display: flex; justify-content: space-between;">
            <div class="form-inline" style="margin-top:0.5em; float:left; height:1.5em">
              <div>
                <mat-tab></mat-tab>
                <ul class="tabul">
                  <li *ngIf="upFuel" [ngClass]="{ 'btn-high':selectedTabName=='pricing' && fuelQuoteList.length>0,'btn-low pointer':selectedTabName!='pricing' && fuelQuoteList.length>0  }" (click)="clickTab('pricing')"><div style="width: 7em">Fuel Pricing</div></li>
                  <li *ngIf="fuelQuoteList.length>0" [ngClass]="{ 'btn-high':selectedTabName=='quote','btn-low pointer':selectedTabName!='quote'  }" style="border-left: black 0.0625em solid;" (click)="clickTab('quote')"><div style="width: 7em">Quote</div></li>
                  <!--<li [ngClass]="{ 'btn-high':selectedTabName=='usage','btn-low pointer':selectedTabName!='usage'  }" (click)="clickTab('usage')"><div style="width: 7em">Usage Metrics</div></li>-->
                  <!--<li [ngClass]="{ 'btn-high':selectedTabName=='history','btn-low pointer':selectedTabName!='history' }" (click)="clickTab('history')"><div style="width: 7em">Quote History</div></li>-->
                </ul>

              </div>
              <mat-slide-toggle *ngIf="selectedTabName=='pricing'" style="transform:scale(.8); " color="primary" [(ngModel)]="showExpired" [ngModelOptions]="{standalone: true}"
                                (change)="getFuelPrices('expired')">
                Include Expired Prices (-4 Days)
              </mat-slide-toggle>
              <div *ngIf="upFuel && selectedTabName=='pricing'">
                <button type="button" (click)="managePrices()" class="btn btn-med" style="font-size:small;margin-right:.5em;margin-top:-.5em">Manage Prices</button>
              </div>
            </div>
           


          </div>
          <div *ngIf="selectedTabName=='pricing'">
            <div style="max-height:15em;min-height:10em;overflow-y:auto">
              <label *ngIf="priceMsg!=''">{{priceMsg}}</label>
              <table *ngIf="fuelPricingList.length>0" #table mat-table [dataSource]="fuelPricingList" class="price-table">
                <tr>
                  <ng-container matColumnDef="fuel_supplier">
                    <th mat-header-cell *matHeaderCellDef style="flex:0 0 10%;width:10%;">
                      Fuel Supplier
                    </th>
                    <td mat-cell *matCellDef="let element" style="flex:0 0 10%;width:10%;">{{element.fuelSupplierName}}</td>
                  </ng-container>
                  <ng-container matColumnDef="into_plane">
                    <th mat-header-cell *matHeaderCellDef style="flex:0 0 15%;width:15%;">
                      Into Plane Agent
                    </th>
                    <td mat-cell *matCellDef="let element" style="flex:0 0 15%;width:15%;">{{element.intoPlaneAgent}}</td>
                  </ng-container>
                  <ng-container matColumnDef="effective_date">
                    <th mat-header-cell *matHeaderCellDef style="flex:0 0 7%;width:7%;">
                      Effective Date (Z)
                    </th>
                    <td mat-cell *matCellDef="let element" style="flex:0 0 7%;width:7%;">{{element.effectiveDate | date: 'dd-MMM'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="expiry_date">
                    <th mat-header-cell *matHeaderCellDef style="flex:0 0 7%;width:7%;">
                      Expiry Date (Z)
                    </th>
                    <td mat-cell *matCellDef="let element" [ngClass]="{ 'alert-danger':element.isExpired }" style="flex:0 0 7%;width:7%;">{{element.expiryDate | date: 'dd-MMM'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef style="flex:0 0 5%;width:5%;">
                      Quantity
                    </th>
                    <td mat-cell *matCellDef="let element" style="flex:0 0 5%;width:5%;">{{element.qty}}</td>
                  </ng-container>

                  <ng-container matColumnDef="currency">
                    <th mat-header-cell *matHeaderCellDef style="flex:0 0 5%;width:5%;">
                      Currency
                    </th>
                    <td mat-cell *matCellDef="let element" style="flex:0 0 5%;width:5%;">{{element.currencyID}}</td>
                  </ng-container>
                  <ng-container matColumnDef="base_fuel">
                    <th mat-header-cell *matHeaderCellDef style="flex:0 0 5%;width:5%;">
                      FPI Cost<br />(USD/USG)
                    </th>
                    <td mat-cell *matCellDef="let element" style="flex:0 0 5%;width:5%;" matTooltip="{{element.clientNotes}}" matTooltipClass="tooltipLineBreak">{{element.priceInUSD.toFixed(4)}}</td>
                  </ng-container>
                  <ng-container matColumnDef="taxes">
                    <th mat-header-cell *matHeaderCellDef style="flex:0 0 5%;width:5%;">
                      Taxes<br />Included
                    </th>
                    <td mat-cell *matCellDef="let element" style="flex:0 0 5%;width:5%;">{{element.taxesIncluded==true?'Yes':'No'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="markup">
                    <th mat-header-cell *matHeaderCellDef style="flex:0 0 5%;width:5%;">
                      Client<br />Markup
                    </th>
                    <td mat-cell *matCellDef="let element; let i=index" style="text-align:center !important;flex:0 0 5%;width:5%;">
                      <input style="width:3em;margin-top:.25em;margin-left:.75em" class="form-control form-control-sm inputBox" id="markup{{i}}" (change)="updateMarkup($event, element, false)" value="{{element.markupAmount | number: '0.2-2'}}" />
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="exchange_rate_markup">
                    <th mat-header-cell *matHeaderCellDef style="flex:0 0 6%;width:6%;">
                      Exchange Rate<br />Markup
                    </th>
                    <td mat-cell *matCellDef="let element; let i=index" style="align-content:center !important;flex:0 0 6%;width:6%;">
                      {{element.exchangeRateMarkupAmount | percent: '0.0-2' }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="commissions">
                    <th mat-header-cell *matHeaderCellDef [hidden]="!hasCommissions" style="flex:0 0 5%;width:5%;">
                      Commissions
                    </th>
                    <td mat-cell *matCellDef="let element; let i=index" style="align-content:center !important;flex:0 0 5%;width:5%;" [hidden]="!hasCommissions">
                      {{element.fuelCommissionAmount}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="total_cost">
                    <th mat-header-cell *matHeaderCellDef style="flex:0 0 5%;width:5%;">
                      Client Price
                    </th>
                    <td mat-cell *matCellDef="let element" style="flex:0 0 5%;width:5%;" matTooltip="{{element.clientNotes}}" matTooltipClass="tooltipLineBreak">{{element.markupAmount + element.fuelCommissionAmount + element.priceInUSD |  number: '0.4-4'}}</td>
                  </ng-container>

                  <ng-container matColumnDef="total_all">
                    <th mat-header-cell *matHeaderCellDef style="flex:0 0 5%;width:5%;">
                      <div>Total</div>
                      <div *ngIf="hasDomestic">International</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="flex:0 0 5%;width:5%;" matTooltip="{{element.clientNotes}}" matTooltipClass="tooltipLineBreak">{{element.totalPrice | number: '0.4-4'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="total_dom">
                    <th mat-header-cell *matHeaderCellDef [hidden]="!hasDomestic" style="flex:0 0 5%;width:5%;">
                      Total<br />Domestic
                    </th>
                    <td mat-cell *matCellDef="let element" [hidden]="!hasDomestic" style="flex:0 0 5%;width:5%;" matTooltip="{{element.clientNotes}}" matTooltipClass="tooltipLineBreak">{{element.totalPriceDomestic | number: '0.4-4'}}</td>
                  </ng-container>
                  <!--<ng-container matColumnDef="internal_notes">
          <th mat-header-cell *matHeaderCellDef style="flex:0 0 5%;width:5%;">
            Internal<br />Notes
          </th>
          <td mat-cell *matCellDef="let element" style="flex:0 0 5%;width:5%;" matTooltip="{{element.internalNotes}}" matTooltipClass="tooltipLineBreak">{{element.internalNotes!=''?'Yes':''}}</td>
        </ng-container>
        <ng-container matColumnDef="client_notes">
          <th mat-header-cell *matHeaderCellDef style="flex:0 0 5%;width:5%;">
            Supplier<br />Notes
          </th>
          <td mat-cell *matCellDef="let element" style="flex:0 0 5%;width:5%;" matTooltip="{{element.clientNotes}}" matTooltipClass="tooltipLineBreak">{{element.clientNotes!=''?'Yes':''}}</td>
        </ng-container>-->
                  <ng-container matColumnDef="option">
                    <th mat-header-cell *matHeaderCellDef style="flex:0 0 5%;width:5%;">
                      Option
                    </th>
                    <td mat-cell *matCellDef="let element" style="flex:0 0 5%;width:5%;"><button mat-button class="btn-high grid" (click)="createFuelQuote(element)">Quote</button></td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumnPrice; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnPrice" (click)="clickRow(row)"
[class.row-is-clicked]="clickedRows.has(row)"></tr>
              </table>
            </div>
            <div class="d-inline-flex mt-1">

              <div class="form-group mr-3">
                <label style="font-size: small; margin-right: .5em">Internal Notes</label>
                <textarea formControlName="internal_note" class="form-control" style="height:12em;width:30em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd"></textarea>

              </div>
              <div>
                <div class="form-group mr-2">
                  <label style="font-size:small;margin-right:.5em">Default Markup Amount</label>
                  <div class="rounded p-1 border" style="font-size: small; width: 32em">
                    <div *ngIf="fuelClientMarkup!= undefined">
                      <div>{{fuelClientMarkup.markupAmt}} {{fuelClientMarkup.customerName}} / {{fuelClientMarkup.countryName}} {{fuelClientMarkup.icao!=''? ' / ' + fuelClientMarkup.icao : ''}}</div>
                      <div *ngIf="fuelClientMarkup.internalNotes!=''">Notes: {{fuelClientMarkup.internalNotes}}</div>
                    </div>
                    <div *ngIf="fuelClientMarkup== undefined">
                      None
                    </div>
                  </div>
                </div>


                <div class="form-group mr-3">
                  <div class="d-flex justify-content-start">
                    <label style="font-size:small;margin-right:.5em;justify-content:start">Airport - Fuel Notes</label>
                    <i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium" title="Copy note to clipboard" [cdkCopyToClipboard]="airportNotes">content_copy</i>
                  </div>
                  <textarea readonly class="form-control" style=" height: 6.35em; width: 25em; font-size: small; padding: 0em 0em 0em 0.25em;">{{airportNotes}} </textarea>

                </div>
              </div>
              
              <div class="form-group" *ngIf="showPriceTax">
                <div *ngIf="upFuel">
                  <button type="button" (click)="addNewTax('price')" class="btn btn-med" style="font-size:small;margin-right:.5em;">Manage Taxes</button>
                </div>
                <div>
                  <div class=" rounded p-1 mt-2" style="font-size: small; width: 42em; background-color: #f8f8f8; max-height: 8.5em;overflow:auto">
                    <label *ngIf="priceTaxesAndFeesXML" style="font-weight:600;text-decoration:underline">Estimated Duty, Taxes & Fees Detail:</label>
                    <ng-template ngFor let-tax [ngForOf]="priceTaxesAndFeesXML" let-i="index">
                      <div>
                        {{tax.Description}}: {{tax.Amount[0].Type}}  {{tax.Amount[0]}} {{tax.Note!='' ? ' - ' + tax.Note :''}}  {{tax.Domestic =='true' ? ' - Domestic Flights' :''}}
                      </div>
                    </ng-template>
                    <div *ngIf="priceTaxesAndFeesXML==null">
                      {{taxesIncluded? 'Taxes Included':'None'}}
                    </div>
                  </div>
                </div>

              </div>
            </div>

            
            <div class="d-flex justify-content-end" style="margin-top:-4em">

              <div style="width:7.75em">
                <label for="record_status" style="font-size:small">Disable Record</label><!--<div style="font-size:small; margin-top:-1em">Record</div>-->
                <div style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="record_status" style="width: 7em" class="form-control form-control-sm dropdown" (change)="record_statusChange($event)">
                    <option value="1" selected>No</option>
                    <option value="0">Yes</option>
                  </select>
                </div>
              </div>
              <div style="margin-right:0.5em">
                <label for="immediateAction" style="font-size:small">Immediate Action</label>
                <div style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="immediateAction" style="width: 7em" class="form-control form-control-sm dropdown">
                    <option value="0" selected>No</option>
                    <option value="1">Yes</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="selectedTabName=='quote'" class="d-inline-flex border rounded" style="padding-right:1em">
            <div class="d-flex">
              <div>
                <div style="max-height:15em;overflow-y:auto;overflow-x:hidden">
                  <table #table mat-table [dataSource]="fuelQuoteList" class="example-container">
                    <tr>
                      <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef>
                          Quantity
                        </th>
                        <td mat-cell *matCellDef="let element" style="align-content:center !important">{{element.qty}}</td>
                      </ng-container>

                      <ng-container matColumnDef="base_fuel">
                        <th mat-header-cell *matHeaderCellDef>
                          FPI Cost<br />(USD/USG)
                        </th>
                        <td mat-cell *matCellDef="let element" style="align-content:center !important">{{element.priceInUSD.toFixed(4)}}</td>
                      </ng-container>
                      <ng-container matColumnDef="taxes">
                        <th mat-header-cell *matHeaderCellDef>
                          Taxes<br />Included
                        </th>
                        <td mat-cell *matCellDef="let element" style="align-content:center !important">{{element.taxesIncluded==true?'Yes':'No'}}</td>
                      </ng-container>
                      <ng-container matColumnDef="commissions">
                        <th mat-header-cell *matHeaderCellDef [hidden]="!hasCommissions">
                          Commissions
                        </th>
                        <td mat-cell *matCellDef="let element; let i=index" style="align-content:center !important" [hidden]="!hasCommissions">
                          {{element.fuelCommissionAmount}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="markup">
                        <th mat-header-cell *matHeaderCellDef>
                          Client<br />Markup
                        </th>
                        <td mat-cell *matCellDef="let element; let i=index" style="align-content:center !important">
                          <input [disabled]="!upFuel" style="width:3em;margin-top:.25em;margin-left:.75em" class="form-control form-control-sm inputBox" id="qmarkup{{i}}" (change)="updateMarkup($event, element, true)" value="{{element.markupAmount | number: '0.2-2'}}" />
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="exchange_rate_markup">
                        <th mat-header-cell *matHeaderCellDef>
                          Exchange Rate<br />Markup
                        </th>
                        <td mat-cell *matCellDef="let element; let i=index" style="align-content:center !important">
                          {{element.exchangeRateMarkupAmount | percent: '0.0-2' }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="total_cost">
                        <th mat-header-cell *matHeaderCellDef>
                          Client Price
                        </th>
                        <td mat-cell *matCellDef="let element" style="align-content:center !important">{{element.priceInUSD + element.markupAmount + element.fuelCommissionAmount | number: '0.4-4'}}</td>
                      </ng-container>
                      <ng-container matColumnDef="total_all">
                        <th mat-header-cell *matHeaderCellDef>
                          <div>Total</div>
                          <div *ngIf="hasDomestic">International</div>
                        </th>
                        <td mat-cell *matCellDef="let element" style="align-content:center !important">{{element.totalPrice | number: '0.4-4'}}</td>
                      </ng-container>
                      <ng-container matColumnDef="total_dom">
                        <th mat-header-cell *matHeaderCellDef [hidden]="!hasDomestic">
                          Total<br />Domestic
                        </th>
                        <td mat-cell *matCellDef="let element" style="align-content:center !important" [hidden]="!hasDomestic">{{element.totalPriceDomestic | number: '0.4-4'}}</td>
                      </ng-container>
                      <ng-container matColumnDef="remove">
                        <th mat-header-cell *matHeaderCellDef [hidden]="fuelQuoteList.length<2"></th>
                        <td mat-cell *matCellDef="let element"  [hidden]="fuelQuoteList.length<2" style="align-content:center !important" ><mat-icon style="color:red;font-size:large;margin-top:.25em;cursor:pointer" (click)="removePrice(element)">cancel</mat-icon> </td>
                      </ng-container>
                    </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnQuote; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnQuote"></tr>
                  </table>
                </div>
                <div class="d-inline-flex mt-2 ml-2">
                  <div>
                    <div class="form-group">
                      <div *ngIf="upFuel">
                        <button (click)="addNewTax('quote')" class="btn btn-med" style="font-size:small;margin-right:.5em;">Manage Taxes</button>
                      </div>
                      <div *ngIf="fuelQuoteList.length>0">
                        <div class=" rounded p-1 mt-2" style="font-size: small;width:42em;background-color:#f8f8f8">
                          <label *ngIf="taxesAndFeesXML" style="font-weight:600;text-decoration:underline">Estimated Duty, Taxes & Fees Detail:</label>
                          <ng-template ngFor let-tax [ngForOf]="taxesAndFeesXML" let-i="index">
                            <div>
                              {{tax.Description}}: {{tax.Amount[0].Type}}  {{tax.Amount[0]}} {{tax.Note!='' ? ' - ' + tax.Note :''}}  {{tax.Domestic =='true' ? ' - Domestic Flights' :''}}
                            </div>
                          </ng-template>
                          <div *ngIf="taxesAndFeesXML==null">
                            {{taxesIncluded? 'Taxes Included':'None'}}
                          </div>
                        </div>
                      </div>

                    </div>
                    <div>
                      <div class="form-group">
                        <label style="font-size:small;margin-right:.5em">Default Markup Amount:</label>
                        <div class=" rounded p-1" style="font-size: small; width: 42em;background-color:#f8f8f8">
                          <div *ngIf="fuelClientMarkup!= undefined">
                            <div>{{fuelClientMarkup.markupAmt}} {{fuelClientMarkup.customerName}} / {{fuelClientMarkup.countryName}} {{fuelClientMarkup.icao!=''? ' / ' + fuelClientMarkup.icao : ''}}</div>
                            <div *ngIf="fuelClientMarkup.internalNotes!=''">Notes: {{fuelClientMarkup.internalNotes}}</div>
                          </div>
                          <div *ngIf="fuelClientMarkup== undefined">
                            None
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div class=" mx-3">
              <div>
                <div class="form-group">
                  <label style="font-size: small; margin-right: .5em">Internal Notes</label>
                  <textarea formControlName="internal_note" class="form-control" style="height:5em;width:29em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd"></textarea>

                </div>
              </div>
              <div>
                <div [hidden]="selectedTabName=='pricing'" class="form-group">
                  <label style="font-size:small;margin-right:.5em;justify-content:start">Quote Notes</label>
                  <textarea formControlName="quote_note" class="form-control" style=" height: 19em; width: 29em; font-size: small; padding: 0em 0em 0em 0.25em; background-color: #eaf0fd"></textarea>
                </div>
              </div>
            </div>
            <div style="margin-top:.25em">
              <div class="form-group">
                <div class="d-flex justify-content-start">
                  <label style="font-size:small;margin-right:.5em;justify-content:start">Airport - Fuel Notes</label>
                  <i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium" title="Copy note to clipboard" [cdkCopyToClipboard]="airportNotes">content_copy</i>
                </div>
                <textarea readonly class="form-control" style=" height: 5em; width: 25em; font-size: small; padding: 0em 0em 0em 0.25em;">{{airportNotes}} </textarea>

              </div>
              <div class="form-group">
                <div class="d-flex justify-content-start">
                  <label style="font-size:small;margin-right:.5em;justify-content:start">Supplier Notes</label>
                  <i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium" title="Copy note to clipboard" [cdkCopyToClipboard]="supplier_note">content_copy</i>
                </div>
                <textarea readonly formControlName="supplier_note" class="form-control" style=" height: 19em; width: 25em; font-size: small; padding: 0em 0em 0em 0.25em;">

</textarea>
              </div>
            </div>

          </div>
          <div *ngIf="selectedTabName=='history'">
            <div style="height:20em">
              <table #table mat-table [dataSource]="pastFuelQuoteList" class="price-table">
                <tr>
                  <ng-container matColumnDef="modified">
                    <th mat-header-cell *matHeaderCellDef>
                      Created By
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.modifiedBy}} {{element.modifiedDate}}</td>
                  </ng-container>

                  <ng-container matColumnDef="fuel_supplier">
                    <th mat-header-cell *matHeaderCellDef>
                      Fuel Supplier
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.fuelSupplierName}}</td>
                  </ng-container>
                  <ng-container matColumnDef="into_plane">
                    <th mat-header-cell *matHeaderCellDef>
                      Into Plane Agent
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.intoPlaneAgent}}</td>
                  </ng-container>
                  <ng-container matColumnDef="expiry_date">
                    <th mat-header-cell *matHeaderCellDef>
                      Expiry Date
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.expiryDate}}</td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                      Status
                    </th>
                    <td mat-cell *matCellDef="let element; let i=index" style="align-content:center !important">

                      {{element.isActive? 'Active': ''}}
                      <button *ngIf="!element.isActive" mat-button class="btn-med grid" (click)="clickPreviewDocument($event, null, element.groundStopTaskDocumentGUID)">Make Active</button>
                      <!--<select [ngValue]="element.isActive" style="margin-top:.25em;margin-left:.75em" class="form-control form-control-sm inputBox" id="status{{i}}" (change)="updateQuoteStatus($event, element, true)" value="{{element.isActive}}">
    <option value="true">Active</option>
    <option value="false">Deactivated</option>
  </select>-->
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="option">
                    <th mat-header-cell *matHeaderCellDef>
                      <div>Option</div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div><button mat-button class="btn-high grid" (click)="clickPreviewDocument($event, null, element.groundStopTaskDocumentGUID)">Open</button></div>
                     
                    </td>
                  </ng-container>


                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumnPastQuote; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnPastQuote"></tr>
              </table>
            </div>
          </div>

        </div>
      </div>
      
    </form>
  </div>
  <div class="d-inline-flex justify-content-between" style="width: 100%; padding-left: 1em; padding-right: 1em; ">
    <div>
      <div style="justify-content:flex-start;padding-top:0.5em; padding-bottom:0.5em;display:flex; height:2.75em">
        <div>
          <button type="button" mat-button class="btn-high" *ngIf="!lockdownTrip && !showSpin" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>Save</div></div></button>&nbsp;
          <button type="button" mat-button class="btn-low" (click)="clickClose()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>&nbsp;
          <!--<button *ngIf="businessRuleID==0 && fuel_task_edit_form" class="btn-low" mat-button (click)="clickPromoteBusinessRule()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">grading</i><div>Promote Business Rule</div></div></button>&nbsp;-->
          <button type="button" mat-button class="btn-low" *ngIf="hasTaskAudit==true" (click)="clickAudit()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">manage_search</i><div>Audit</div></div></button>&nbsp;
          <button type="button" mat-button class="btn-low" *ngIf="hasPastQuote" (click)="clickQuoteHistory()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">history</i><div>Quote History</div></div></button>&nbsp;
          <button type="button" mat-button class="btn-low" (click)="clickLocationMetrics()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">near_me</i><div>Location Metrics</div></div></button>&nbsp;
          <button type="button" mat-button class="btn-low" (click)="manageFuelQuotes()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">request_quote</i><div>Manage Fuel Quotes</div></div></button>&nbsp;

        </div>
        <div *ngIf="errMsg2!=''" class="d-block" style="margin-top:0em; margin-left:0.25em; display:flex; background-color:#f9d2de; padding-left:0.25em; width:24em;font-size: small ">{{errMsg2}}</div>
        <div *ngIf="showSuccessMsg" style="margin-top: 0em;">
          <app-success-message [successMsg]=""></app-success-message>
        </div>
      </div>
    </div>
      <div>
        <div class="flex-column">
          <div>
            <label for="modifiedBy" style="font-size:small">Modified By: {{modifiedBy}}</label>
          </div>
          <div style="margin-top:-.5em">
            <label for="modifiedDate" style="font-size:small">Modified Date: {{modifiedDate}}</label>
          </div>

        </div>
      </div>
    </div>
</div>
<app-spin *ngIf="showSpin || showSpinFuel"></app-spin>
