<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
<div class="divCenter">
  <div style="text-align: center;">
    <div style="border-style: solid; border-width: 0.0625em; border-radius: 0.3125em; border-color: lightgray; padding: 3.5em; margin-top:0.625em">
      <img src="../../assets/images/FlightProLogoWhiteBG.png" style="border:0em" />
      <form *ngIf="get_username_form" [formGroup]="get_username_form" (ngSubmit)="onSubmit()">
        <div style="text-align: left;">
          <h3>Username assistance</h3>
          <div class="d-block" style="height:1.875em"></div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" formControlName="email" #emailRef class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" style="width:18.75em; background-color:#eaf0fd" maxlength="50" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">email is required</div>
              <div *ngIf="f.email.errors.pattern">Invalid email</div>
            </div>
          </div>
          <div *ngIf="errMsg!=''" class="d-block" style="width:18.75em;"><label style="color:red">{{errMsg}}</label></div>
          <div *ngIf="msg!=''" class="d-block" style="width:18.75em;"><label style="color:forestgreen">{{msg}}</label></div>
          <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary" style="width:18.75em">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Submit
            </button>
          </div>
          <div class="form-group" style="display:inline">
            <a routerLink="" (click)="clickBackToSignIn()">Back to Sign In</a>
          </div>

        </div>
      </form>
    </div>
    <div style="display:flex;margin-top:1em;">
      <div style="font-size:small;"><a routerLink="" (click)="openTerm()" style="text-decoration: none">Conditions of Use</a></div>
      <div style="font-size:small; margin-left: 6em;"><a routerLink="" style="text-decoration: none">Privacy Notice</a></div><div style="font-size:small; margin-left:10em;"><a routerLink="" style="text-decoration: none">Help</a></div>
    </div>
  </div>
</div>
