import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { UserModel } from '../models/user.model';
//import { AuthenticateService } from '../services/authenticate.service';
import { IdentityService } from '../services/identity.service';
import { ResponseModel } from '../models/response.model';

@Component({
  selector: 'app-get-username',
  templateUrl: './get-username.component.html',
  styleUrls: ['./get-username.component.css']
})

export class GetUsernameComponent implements OnInit, AfterViewInit {
  get_username_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  user: UserModel;
  errMsg: string;
  msg: string;
  @ViewChild('emailRef') emailRef: ElementRef;

  constructor(private _router: Router, private _route: ActivatedRoute,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _identityService: IdentityService,) {

  }

  ngOnInit() {
    this.errMsg = "";
    this.msg = "";
    this.get_username_form = this._formBuilder.group({
      //first_name: ['', Validators.required],
      //last_name: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,100}$")]
      )]
      
    });
  }

  ngAfterViewInit() {
    this.emailRef.nativeElement.focus();
  }

  get f() { return this.get_username_form.controls };

  onSubmit() {
    this.errMsg = "";
    this.submitted = true;

    if (this.get_username_form.invalid || this.errMsg != "") {
      return;
    }

    this.loading = true;
    this.user = new UserModel();
    //this.user.firstName = this.f.first_name.value;
    //this.user.lastName = this.f.last_name.value;
    this.user.email = this.f.email.value;

    this._identityService.getUsername<ResponseModel<boolean>>(this.user).subscribe(response => {
      if (response != null && response.code == "200") {
        this.msg = response.message;

      }
      else {
        this.errMsg = "Failed to get username. Please try again later.";
      }

      this.loading = false;
    });
    
  }

  openTerm() {
    window.open("../../assets/term.html", "_blank");
  }

  clickBackToSignIn() {
    this._router.navigate(['']);
  }

}
