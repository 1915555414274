import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router, Event, NavigationStart, NavigationEnd, ActivatedRoute, RouterEvent, NavigationExtras } from '@angular/router';
import { AuthenticateService } from '../services/authenticate.service';
import { TripQuickSearchControlClientComponent } from '../ground-stops-client/trip-quick-search-control-client.component';
import { TripLeglistClientComponent } from '../trip-legs-client/trip-leglist-client.component';
import { GroundStopCreatorClientDialogComponent } from '../ground-stops-client/ground-stop-creator-client-dialog.component';
import { TripSummaryReportDialogComponent } from '../ground-stops/trip-summary-report-dialog.component';
import { TripPersonlistEditorClientDialogComponent } from '../trip-legs-client/trip-personlist-editor-client-dialog.component';
import { TripModel } from '../models/trip.model';

@Component({
  selector: 'app-schedules-client',
  templateUrl: './schedules-client.component.html',
  styleUrls: ['./schedules-client.component.css']
})

export class SchedulesClientComponent implements OnInit {
  // userType: string; 
  schedules_client_form: UntypedFormGroup;
  loading = false;
  // v: number = this._authService.getCurrentTimeNumber();
  // upPII: boolean = false;
  username: string;
  isDialog: boolean = false;
  


  constructor(
    @Optional() private readonly _dialogRef: MatDialogRef<SchedulesClientComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly dialog: MatDialog, private readonly _authService: AuthenticateService, private _router: Router
  ) {

    // this.tripRevisionID = _data.tripRevisionID;
    // this.userComments = _data.userComments;
    if (this.data) {
      this.username = this.data.username;
      this.isDialog = true;
    }

  }

  ngOnInit() {

    // this._authService.updateAccessTime();



  }

  initControls() {
    this.schedules_client_form = this._formBuilder.group({
      // userComments: [this.userComments]
    });


  }

  get f() { return this.schedules_client_form.controls; }




  clickClose(val) {
    this._dialogRef.close(val);
  }


  openTripQuickSearchControlClientComponent() {
    // if (this.isDialog) {
    //   this.clickClose(true);
    // }
    this.clickClose(true);

    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4";
    config.width = "93vw"; 
    config.height = "82vh"; 
    config.maxWidth = "100vw"; 
    config.maxHeight = "100vh"; 
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "20px", top: "138px" }; 
    config.data = {
      username: this.username,
      isDialog: true,
      v: this._authService.getCurrentTimeNumber(),
    };
    this.dialog.open(TripQuickSearchControlClientComponent, config);
  }



  openTripLeglistClientComponent() {
    // if (this.isDialog) {
    //   this.clickClose(true);
    // }
    this.clickClose(true);

    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4";
    config.width = "94em";
    config.height = "82vh";
    config.maxWidth = "95vw";
   // config.maxHeight = "100vh"; 
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "20px", top: "138px" };
    config.data = { username: this.username, isDialog: true, v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(TripLeglistClientComponent, config);
  }




 /* openGroundStopCreatorClientDialogComponent() {
    if (this.isDialog) {
      this.clickClose(true);
    }
    const config = new MatDialogConfig();
    Config.autoFocus = true;
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
  //  config.maxWidth = "92em";//"1000px";
   // config.height = "40em";//"1000px";
   // config.width = "max-content";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "20px", top: "138px" };
    config.data = { username: this.username, isDialog: true, v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(GroundStopCreatorClientDialogComponent, config);
  }*/

  openGroundStopCreatorClientDialog() {
    // if (this.isDialog) {
    //   this.clickClose(true);
    // }
    this.clickClose(true);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "66em";//"1000px";
    //dialogConfig.minHeight = "39em";//"500px";
    //dialogConfig.minHeight = "40em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { tripType: '', v: this._authService.getCurrentTimeNumber() };
    dialogConfig.position = { left: "20px", top: "138px" };
    const dialogRef = this.dialog.open(GroundStopCreatorClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        let trip: TripModel;
        trip = new TripModel();
        trip.tripCodeGUID = result.newId;
        trip.aircraftGUID = result.aircraftGUID;
        trip.customerGUID = result.customerGUID;
        this.openTripPersonListEditorClient(trip.tripCodeGUID, trip.aircraftGUID, trip.customerGUID);
      }
    });
  }
  openTripPersonListEditorClient(tripCodeGUID: string, aircraftGUID: string, customerGUID: string) {
    this.clickClose(true);
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, tripCodeGUID: tripCodeGUID, aircraftGUID: aircraftGUID, customerGUID: customerGUID };

    const dialogRef = this.dialog.open(TripPersonlistEditorClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {

        let trip: TripModel;
        trip = new TripModel();
        trip.tripCodeGUID = result.newId;
        trip.aircraftGUID = result.aircraftGUID;
        trip.customerGUID = result.customerGUID;
        this.clickOpenTripDetailClient(trip);
        //this.getSearchResults();
      }

    });

  }
  clickOpenTripDetailClient(p: TripModel) {

    this.clickClose(true);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "tripId": p.tripCodeGUID,
        "aircraftId": p.aircraftGUID,
        "customerId": p.customerGUID,
        "pf": "",
        tType: p.tripCodeType,
        v: this._authService.getCurrentTimeNumber()
      }
    };
    this._router.navigate(['/ground-stops-client/trip-details-client'], navigationExtras);

  }

  openTripSummaryReportDialogComponent() {
    this.clickClose(true);

    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    config.height = "32em";
    config.width = "45em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "20px", top: "138px" };
    config.data = {tripCodeGUID: "", username: this.username, isDialog: true, v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(TripSummaryReportDialogComponent, config);
  }



}
