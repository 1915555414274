import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, NavigationExtras, Route, Router } from '@angular/router';
import { AircraftModel } from '../models/aircraft.model';
import { ResponseModel } from '../models/response.model';
import { AircraftService } from '../services/aircraft.service';
import { AuthenticateService } from '../services/authenticate.service';
import { AircraftMainEditDialogComponent } from './aircraft-main-edit-dialog.component';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { GlobalConstant } from '../common-utility/global-constant';
import { FileModel } from '../models/file.model';
import { AircraftProfileDetailsComponent } from './aircraft-profile-details.component';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { AircraftFlightPlanningComponent } from './aircraft-flight-planning.component';
import { AircraftEquipmentComponent } from './aircraft-equipment.component';
import { Title } from '@angular/platform-browser';
import { AircraftProfileComponent } from './aircraft-profile.component';

@Component({
  selector: 'app-aircraft-profile-edit',
  templateUrl: './aircraft-profile-edit.component.html',
  styleUrls: ['./aircraft-profile-edit.component.css']
})
/** aricraft-profile-details component*/
export class AircraftProfileEditComponent implements OnInit {
  aircraft_profile_edit_form: UntypedFormGroup;
  aircraftGuid: string;
  errMsg: string;
  showSpin: boolean;
  msg: string;
  userType: string;
  selectedTabName: string;
  registration: string;
  callSign: string;
  ac_manufacturer: string;
  ac_model: string;
  ac_type: string;
  ac_status: string;
  modifiedBy: string;
  modifiedDate: string;
  customerGuid: string;
  textSearch: string;
  filterBy: string;
  includeDisabledRecords: boolean;
  customerName: string;
  uploader: FileUploader;
  fileList: FileModel[];
  finishAllUploads: boolean = true;
  photoName: string;
  photoFilePath: string = "";
  imageToShow: any;
  isPopup: boolean = false;
  @ViewChild('aircraftDetails') aircraftDetailsRef: AircraftProfileDetailsComponent;
  @ViewChild('flightPlanning') flightPlanningRef: AircraftFlightPlanningComponent;
  @ViewChild('aircraftEquipment') aircraftEquipmentRef: AircraftEquipmentComponent;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  constructor(private _router: Router, private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog, private readonly _aircraftService: AircraftService,
    private readonly _formBuilder: UntypedFormBuilder, private _route: ActivatedRoute, private readonly _dialogService: DialogService,
    @Optional() @Inject(MAT_DIALOG_DATA) private _data: any,
    @Optional() private readonly _dialogRef: MatDialogRef<AircraftProfileDetailsComponent>,
    private _titleService: Title) {

  }

  ngOnInit() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    if (this._data) {
      this.isPopup = true;

      this.aircraftGuid = this._data.aircraftGUID;
      this.customerGuid = this._data.customerGUID;
      this.textSearch = this._data.textSearch;
      this.filterBy = this._data.filterBy;
    }
    else {
      this._route.queryParams.subscribe(params => {
        this.aircraftGuid = params["aircraftGuid"];
        this.customerGuid = params["customerGuid"];
        this.textSearch = params["textSearch"];
        this.filterBy = params["filterBy"];
        this.selectedTabName = params["tab"];
      });
    }

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    this.photoFilePath = "";
    if (this.selectedTabName == undefined || this.selectedTabName == "" || this.selectedTabName == null)
      this.selectedTabName = "details";
    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "aircraft/uploadAircraftPhoto",
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false
    });

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('aId', this.aircraftGuid); //note comma separating key and value
      //form.append('Field2', this.filedValue2);
    };

    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
    };

    this.getData();
    this.initControls()

  }

  getData() {
    this._aircraftService.getAircraftProfileByaId<ResponseModel<AircraftModel>>(this.aircraftGuid).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let aircraft = response.model;
          this.customerName = aircraft.customerName;
          this.registration = aircraft.registration;
          this.callSign = aircraft.callSign;
          this.ac_manufacturer = aircraft.aircraftManufacturer;
          this.ac_model = aircraft.aircraftModel;
          this.ac_type = aircraft.aircraftTypeDesignator;
          this.modifiedBy = aircraft.modifiedBy;
          this.modifiedDate = aircraft.modifiedDate;
          if (aircraft.isActive == true) {
            this.ac_status = "No";
          }
          else {
            this.ac_status = "Yes";
          }
          if (!this.isPopup) {
            this._titleService.setTitle(this.registration);
          }
          this.photoName = aircraft.aircraftPhotoName;
          this.photoFilePath = aircraft.aircraftPhotoPath;
          this.initControls()

        }
      }
    });
  }

  initControls() {
    this.aircraft_profile_edit_form = this._formBuilder.group({
      registration: this.registration,
      manufacturer: this.ac_manufacturer,
      aircraft_model: this.ac_model,
      aircraft_type: this.ac_type,
      disable_record: this.ac_status,
      modifiedBy: this.modifiedBy,
      modifiedDate: this.modifiedDate,
      includeDisabledRecords: this.includeDisabledRecords,
      client_name: this.customerName,
      photo: [null],
      call_sign: this.callSign

    });
  }

  get f() { return this.aircraft_profile_edit_form.controls; }

  clickEditMain() {
    let s = "";
    let aircraftGUID = "";

    s = "EDIT Record";
    aircraftGUID = this.aircraftGuid;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //dialogConfig.width = "37.5em";//"1000px";
    //dialogConfig.height = "15em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, aircraftGuid: aircraftGUID, customerGUID: this.customerGuid, customerName: this.customerName };

    const dialogRef = this._dialog.open(AircraftMainEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.actionName == "search") {
        this.getData();
        this.aircraftDetailsRef.getData();
      }
    });

  }

  includeDisabledRecordsOnChange(event: any) {
    this.includeDisabledRecords = event.checked;
  }

  clickTab(s: string) {

    switch (this.selectedTabName) {
      case "details":
        if (this.aircraftDetailsRef.hasChanges) {
          this.confirmDialogRef = this._dialogService.openConfirmDialog("You have unsaved changes. Would you like to save before continuing?");
          this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
            this.eventSubscription.unsubscribe();
            if (result) {
              this.aircraftDetailsRef.clickSave();
              if (this.selectedTabName != s)
                this.selectedTabName = s;
            }
            else {
              if (this.selectedTabName != s) {
                this.selectedTabName = s;
              }
            }
          });
        }
        else {
          if (this.selectedTabName != s) {
            this.selectedTabName = s;
          }
        }
        break;
      case "fp":
        if (this.flightPlanningRef.controlValueChanged) {
          this.confirmDialogRef = this._dialogService.openConfirmDialog("You have unsaved changes. Would you like to save before continuing?");
          this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
            this.eventSubscription.unsubscribe();
            if (result) {
              this.flightPlanningRef.clickSave();
              if (this.selectedTabName != s)
                this.selectedTabName = s;
            }
            else {
              if (this.selectedTabName != s) {
                this.selectedTabName = s;
              }
            }
          });
        }
        else {
          if (this.selectedTabName != s) {
            this.selectedTabName = s;
          }
        }
        break;
      case "equip":
        if (this.aircraftEquipmentRef.controlValueChanged) {
          this.confirmDialogRef = this._dialogService.openConfirmDialog("You have unsaved changes. Would you like to save before continuing?");
          this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
            this.eventSubscription.unsubscribe();
            if (result) {
              this.aircraftEquipmentRef.clickSave();
              if (this.selectedTabName != s)
                this.selectedTabName = s;
            }
            else {
              if (this.selectedTabName != s) {
                this.selectedTabName = s;
              }
            }
          });
        }
        else {
          if (this.selectedTabName != s) {
            this.selectedTabName = s;
          }
        }
        break;
      default:
        if (this.selectedTabName != s) {
          this.selectedTabName = s;
        }
    }


  }


  clickClose() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "customerGuid": this.customerGuid,
        "textSearch": this.textSearch,
        "filterBy": this.filterBy

      }
    };
    this._router.navigate(['/aircraft/manage-aircraft-profile'], navigationExtras);

  }

  // upload photo
  importPhoto(event: any) {
    this.errMsg = "";
    this.finishAllUploads = false;
    this.fileList = [];
    var files = [];
    var maxFileSize = 1024 * 1024;
    this.errMsg = "";
    this.photoFilePath = "";

    if (event.target.files.length > 1) {
      this.errMsg = 'Only upload one file.';
      return;
    }
    else {
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;
        //var a = this.checkValidFileType(filename);
        var k = filename.lastIndexOf('.');
        var ext = filename.substring(k + 1).toLocaleLowerCase();
        if (ext != "png" && ext != "jpg" && ext != "gif") {
          //this.errMsg += 'The file type of "' + filename + '" is not allowed to upload.';
          this.errMsg = 'The file type is not\nallowed to upload.';
          //this.uploader.removeFromQueue(event.target.files[i]);
          this.uploader.clearQueue();
          return;
        }
        else {
          if (event.target.files[i].size >= maxFileSize) {
            //if (this.errMsg != "") {
            //  this.errMsg += "\n";
            //}
            //this.errMsg += 'The file size "' + filename + '" is too big to upload.';
            this.errMsg = 'The file size is too\nbig to upload.';
            //this.uploader.removeFromQueue(event.target.files[i]);
            this.uploader.clearQueue();
            return;
          }
          else {
            files.push(event.target.files[i]);
            //this.f.document_name.setValue(filename);
          }
        }
        break;
      }

    }

    if (this.errMsg != "")
      return;

    //if (files.length > 0) {
    //  this.uploader.addToQueue(files);
    //}

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      return;
    }

    this.uploadSubmit();

  }


  uploadSubmit() {
    let x = this.uploader.queue.length;
    this.finishAllUploads = false;
    this.uploader.uploadAll();
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {
          result.model.forEach((item, index) => {
            this.photoFilePath = item.filePath;
            this.photoName = item.fileName;
            //let obj = new FileModel();
            //obj.fileName = item.fileName;
            //obj.filePath = item.filePath;
            //obj.fileSize = item.fileSize;
            //obj.bFile = item.bFile;
            //obj.mimeType = item.mimeType;
            //obj.fileType = item.fileType;
            // this.f.size.setValue(obj.fileSize.toString() + " KB");
            //this.fileList.push(obj)
            //this._commonService.getImageById<ResponseModel<Blob>>(this.personGUID).subscribe(res => {

            //    this.createImageFromBlob(res);

            //});

          });
        }
      }
      else {
        this.errMsg = result.message;
      }
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }

  //createImageFromBlob(image: Blob) {
  //  let reader = new FileReader();
  //  reader.addEventListener("load", () => {
  //    this.imageToShow = reader.result;
  //  }, false);

  //  if (image) {
  //    reader.readAsDataURL(image);
  //  }
  //}
  //clickPreviewDocument() {
  //  let item = this.fileList[0];
  //  let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
  //  let file = new Blob([byteArray], { type: item.mimeType });
  //  var fileURL = URL.createObjectURL(file);
  //  window.open(fileURL);
  //}

  clickRemovePhoto() {
    //this.confirmDialogRef = this._dialogService.openConfirmDialog("Are you sure you want remove the document?");
    //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
    //  this.eventSubscription.unsubscribe();
    //  if (result) {
    //    this.fileList = [];
    //    this.document_name = '';
    //    this.f.document_name.setValue(this.document_name);
    //  }
    //})
    let request = new AircraftModel();
    request.aircraftGuid = this.aircraftGuid;
    request.aircraftPhotoName = this.photoName;
    request.actionName = 'removePhoto';
    this._aircraftService.updateAircraftPhoto<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model == true) {
          this.photoFilePath = "";
          this.photoName = "";
        }
      }
    })

  }
  // upload file end

  openAircraftProfileComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.width = "93em";
    //config.height = "65vh";
    config.maxWidth = "95vw";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: this.customerGuid, customerName: this.customerName };
    config.position = { left: "10px", top: "140px" };

    if (this.isPopup) {
      this._dialogRef.close(true);
    }

    this._dialog.open(AircraftProfileComponent, config);
  }

  clickRouteNew(route: string) {
    this._dialogRef.close(true);
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber() + "&customerGuid=" + this.customerGuid + "&aircraftGuid=" + this.aircraftGuid
      + "&textSearch=" + this.textSearch + "&filterBy=" + this.filterBy);
  }
}
