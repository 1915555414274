import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { UserModel } from '../models/user.model';
import { AuthenticateService } from '../services/authenticate.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { CustomerModel } from '../models/customer.model';
import { VendorModel } from '../models/vendor.model';
import { Observable, forkJoin } from 'rxjs';
import { IdentityService } from '../services/identity.service';
import { ResponseModel } from '../models/response.model';
import { SelectionModel } from '@angular/cdk/collections';
import { DialogService } from '../services/dialog.service';
import { UserPermissionAdminEditDialogComponent } from './user-permission-admin-edit-dialog.component';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CustomerGroupModel } from '../models/customer-group.model';
import { CustomerGroupService } from '../services/customer-group-service';
import { GlobalConstant } from '../common-utility/global-constant';

@Component({
  selector: 'app-user-permission-restrict-client-edit-dialog',
  templateUrl: './user-permission-restrict-client-edit-dialog.component.html',
  styleUrls: ['./user-permission-restrict-client-edit-dialog.component.css']
})

export class UserPermissionRestrictClientEditDialogComponent implements OnInit, AfterViewInit, OnChanges {
  user_permission_restrict_client_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  user: UserModel;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;

  @Input() userId: string;
  @Input() customerGuid: string;
  @Input() tabName: string;
  @Input() userType: string;
  isModified: boolean=false;
  userList: UserModel[];
  //isActive: boolean; 
  displayedColumn: string[] = ['username', 'name', 'email'];
  displayedColumnRes: string[] = ['crew_trip_detail', 'pax_trip_detail', 'trip_document', 'create_event', 'create_alert', 'company_document', 'aircraft_preference', 'crew_pax_data_maintenance', 'trip_messages', 'vendor_invoiceupload'];
  displayedColumnRes2: string[] = ['restrict_access'];

  //@Output() tabChanged = new EventEmitter();
  @Input() admin_access_select: boolean;
  @Input() account_status_select: boolean;
  @Input() effectiveDateText: string;
  @Input() expirationDateText: string;
  @Input() isValidateEffectiveDate: boolean;
  @Input() isValidateExpirationDate: boolean;
  @Input() locked: boolean;
  showSuccessMsg: boolean = false;
  ua: number;
  ut: string;
  grantAccessToManageManifestProfiles: boolean;
  grantAccessCompanyProfiles: boolean;
  grantAccessAircraftProfiles: boolean;
  grantAccessSubmitTripsandRequestQuotes: boolean;
  grantAccessToMessages: boolean;
  grantAccessToAllMessages: boolean;
  grantAccessToManageCompanyEvents: boolean;
  grantAccessToInvoices: boolean;
  grantAccessToPIIData: boolean;
  grantAccessToAccountGrouping: boolean;
  
  dataChanged: boolean = false;
  @Output() public sendDataToParent = new EventEmitter();
  customerGroupList: CustomerGroupModel[] = [];
  customerGroupGUID: string = "";
  constructor(private _router: Router, private _route: ActivatedRoute,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _identityService: IdentityService, private readonly _customerGroupService: CustomerGroupService,
    private readonly _authService: AuthenticateService, private readonly _dialogService: DialogService, private readonly _dialogRef: MatDialogRef<UserPermissionAdminEditDialogComponent>) {

  }

  getAllData(): Observable<any[]> {
    let reqUser = new UserModel();
    reqUser.userId = this.userId;
    reqUser.customerGuid = this.customerGuid;
    let reqUserResponse = this._identityService.getUserWithAnyStatusByUserId(reqUser);
    let req = new CustomerGroupModel();
    req.customerGUID = this.customerGuid;
    let getCusResponse = this._customerGroupService.getCustomerGroupListBycId(req);
    return forkJoin([reqUserResponse,getCusResponse]);
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.ua = 0;// Number(localStorage.getItem('ua'));
    this.ut = localStorage.getItem('ut').toLowerCase();
    if (localStorage.getItem('up.isA') != null && localStorage.getItem('up.isA') != "") {
      if (localStorage.getItem('up.isA').toLowerCase() == localStorage.getItem('up.isAB').toLowerCase()) {
        this.ua = 1;
      }
    }
    if (this.ua != 1) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.showSpin = true;
    
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.user = responses[0].model;
          this.grantAccessToManageManifestProfiles = this.user.grantAccessToManageManifestProfiles;
          this.grantAccessCompanyProfiles = this.user.grantAccessCompanyProfiles;
          this.grantAccessAircraftProfiles = this.user.grantAccessAircraftProfiles;
          this.grantAccessSubmitTripsandRequestQuotes = this.user.grantAccessSubmitTripsandRequestQuotes;
          this.grantAccessToMessages = this.user.grantAccessToMessages;
          this.grantAccessToAllMessages = this.user.grantAccessToAllMessages;
          //this.grantAccessToManageCompanyEvents = this.user.grantAccessToManageCompanyEvents;
          this.grantAccessToInvoices = this.user.grantAccessToInvoices;
          this.grantAccessToPIIData = this.user.grantAccessToPIIData;
          this.grantAccessToAccountGrouping = this.user.grantAccessToAccountGrouping;
          this.customerGroupGUID = "";
          if (this.user.customerGroupGUID!="")
            this.customerGroupGUID = this.user.customerGroupGUID.toLowerCase();
          
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.customerGroupList = responses[1].model;
          
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }     

      this.initControls();
      if (this.f.grantAccessToPIIData.value == false) {
        this.grantAccessToPIIData = false;
        this.grantAccessToManageManifestProfiles = false;
        this.grantAccessCompanyProfiles = false;
        this.grantAccessAircraftProfiles = false;
        this.grantAccessSubmitTripsandRequestQuotes = false;
        this.grantAccessToMessages = false;
        this.grantAccessToAllMessages = false;
        this.grantAccessToManageCompanyEvents = false;
        this.grantAccessToInvoices = false;
        this.grantAccessToAccountGrouping = false;

        this.user_permission_restrict_client_form.get('grantAccessToManageManifestProfiles').disable();
        this.user_permission_restrict_client_form.get('grantAccessCompanyProfiles').disable();
        this.user_permission_restrict_client_form.get('grantAccessAircraftProfiles').disable();
        this.user_permission_restrict_client_form.get('grantAccessSubmitTripsandRequestQuotes').disable();
        this.user_permission_restrict_client_form.get('grantAccessToMessages').disable();
        this.user_permission_restrict_client_form.get('grantAccessToAllMessages').disable();
       // this.user_permission_restrict_client_form.get('grantAccessToManageCompanyEvents').disable();
        this.user_permission_restrict_client_form.get('grantAccessToInvoices').disable();
        this.user_permission_restrict_client_form.get('grantAccessToAccountGrouping').disable();


      }
      else {
        this.user_permission_restrict_client_form.get('grantAccessToManageManifestProfiles').enable();
        this.user_permission_restrict_client_form.get('grantAccessCompanyProfiles').enable();
        this.user_permission_restrict_client_form.get('grantAccessAircraftProfiles').enable();
        this.user_permission_restrict_client_form.get('grantAccessSubmitTripsandRequestQuotes').enable();
        this.user_permission_restrict_client_form.get('grantAccessToMessages').enable();
        this.user_permission_restrict_client_form.get('grantAccessToAllMessages').enable();
        //this.user_permission_restrict_client_form.get('grantAccessToManageCompanyEvents').enable();
        this.user_permission_restrict_client_form.get('grantAccessToInvoices').enable();
        this.user_permission_restrict_client_form.get('grantAccessToAccountGrouping').enable();
      }

      this.showSpin = false;

    });
   

  }

  initControls() {
    this.user_permission_restrict_client_form = this._formBuilder.group({
      grantAccessToManageManifestProfiles: this.grantAccessToManageManifestProfiles,
      grantAccessCompanyProfiles: this.grantAccessCompanyProfiles,
      grantAccessAircraftProfiles: this.grantAccessAircraftProfiles,
      grantAccessSubmitTripsandRequestQuotes: this.grantAccessSubmitTripsandRequestQuotes,
      grantAccessToMessages: this.grantAccessToMessages,
      grantAccessToAllMessages: this.grantAccessToAllMessages,
      //grantAccessToManageCompanyEvents: this.grantAccessToManageCompanyEvents,
      grantAccessToInvoices: this.grantAccessToInvoices,
      grantAccessToPIIData: this.grantAccessToPIIData,
      grantAccessToAccountGrouping: this.grantAccessToAccountGrouping,
      customer_group_select: this.customerGroupGUID

    });
  }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.admin_access_select && changes.admin_access_select.currentValue)
      this.admin_access_select = changes.admin_access_select.currentValue;
    if (changes.account_status_select && changes.account_status_select.currentValue)
      this.account_status_select = changes.account_status_select.currentValue;
    if (changes.effectiveDateText && changes.effectiveDateText.currentValue)
      this.effectiveDateText = changes.effectiveDateText.currentValue;
    if (changes.expirationDateText && changes.expirationDateText.currentValue)
      this.expirationDateText = changes.expirationDateText.currentValue;
    if (changes.isValidateEffectiveDate && changes.isValidateEffectiveDate.currentValue)
      this.isValidateEffectiveDate = changes.isValidateEffectiveDate.currentValue;
    if (changes.isValidateExpirationDate && changes.isValidateExpirationDate.currentValue)
      this.isValidateExpirationDate = changes.isValidateExpirationDate.currentValue;
    if (changes.locked)
      this.locked = changes.locked.currentValue;
    this.showSuccessMsg = false;
  }

  get f() { return this.user_permission_restrict_client_form.controls; }

  restrictChange(e: any) {
    this.dataChanged = true;    
    this.sendDataToParentEndPoint();
  }

  restrictPIIChange(e: any) {
    this.dataChanged = true;
    if (this.f.grantAccessToPIIData.value == false) {
      this.grantAccessToPIIData = false;
      this.grantAccessToManageManifestProfiles = false;
      this.grantAccessCompanyProfiles = false;
      this.grantAccessAircraftProfiles = false;
      this.grantAccessSubmitTripsandRequestQuotes = false;
      this.grantAccessToMessages = false;
      this.grantAccessToAllMessages = false;
      //this.grantAccessToManageCompanyEvents = false;
      this.grantAccessToInvoices = false;
      this.grantAccessToAccountGrouping = false;
      this.initControls();

      this.user_permission_restrict_client_form.get('grantAccessToManageManifestProfiles').disable();
      this.user_permission_restrict_client_form.get('grantAccessCompanyProfiles').disable();
      this.user_permission_restrict_client_form.get('grantAccessAircraftProfiles').disable();
      this.user_permission_restrict_client_form.get('grantAccessSubmitTripsandRequestQuotes').disable();
      this.user_permission_restrict_client_form.get('grantAccessToMessages').disable();
      this.user_permission_restrict_client_form.get('grantAccessToAllMessages').disable();
      //this.user_permission_restrict_client_form.get('grantAccessToManageCompanyEvents').disable();
      this.user_permission_restrict_client_form.get('grantAccessToInvoices').disable();
      this.user_permission_restrict_client_form.get('grantAccessToAccountGrouping').disable();
      this.customerGroupGUID = "";
     

    }
    else {
      this.user_permission_restrict_client_form.get('grantAccessToManageManifestProfiles').enable();
      this.user_permission_restrict_client_form.get('grantAccessCompanyProfiles').enable();
      this.user_permission_restrict_client_form.get('grantAccessAircraftProfiles').enable();
      this.user_permission_restrict_client_form.get('grantAccessSubmitTripsandRequestQuotes').enable();
      this.user_permission_restrict_client_form.get('grantAccessToMessages').enable();
      this.user_permission_restrict_client_form.get('grantAccessToAllMessages').enable();
      //this.user_permission_restrict_client_form.get('grantAccessToManageCompanyEvents').enable();
      this.user_permission_restrict_client_form.get('grantAccessToInvoices').enable();
      this.user_permission_restrict_client_form.get('grantAccessToAccountGrouping').enable();
    }

    this.sendDataToParentEndPoint();
  }
  restrictMessageChange(e: any) {
    this.dataChanged = true;
    if (this.f.grantAccessToMessages.value == false) {
      this.grantAccessToAllMessages = false;
      this.initControls();
      this.user_permission_restrict_client_form.get('grantAccessToAllMessages').disable();
     

    }
    else {
      this.user_permission_restrict_client_form.get('grantAccessToAllMessages').enable();
    }

    this.sendDataToParentEndPoint();
  }

  restrictAccountGroupingChange(e: any) {
    this.grantAccessToAccountGrouping = this.f.grantAccessToAccountGrouping.value;
    if (this.grantAccessToAccountGrouping) {
      //this.getCustomerGroupList();

    }
    else {
      this.customerGroupGUID = "";
      
    }
    
    this.f.customer_group_select.setValue(this.customerGroupGUID);
    this.dataChanged = true;
    this.sendDataToParentEndPoint();
  }

  sendDataToParentEndPoint() {
    this.sendDataToParent.emit({ dataChanged: this.dataChanged });
  }

  getCustomerGroupList() {
    let req = new CustomerGroupModel();
    req.customerGUID = this.customerGuid;
    var root = this;
    this._customerGroupService.getCustomerGroupListBycId<ResponseModel<CustomerGroupModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        this.customerGroupList = [];
        this.customerGroupList = response.model;
        this.f.grantAccessToAccountGrouping.setValue(this.customerGroupGUID);
           //setTimeout(function () {
           //  this.f.grantAccessToAccountGrouping.setValue(root.customerGroupGUID);
           // }, 500);     
      }
      else {
        //this.errMsg = "Failed to save to our database.";
      }
      //this.loading = false;
      //this.showSpin = false;

    })
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.submitted = true;
    if (!this.isValidateEffectiveDate || !this.isValidateExpirationDate) {
      return;
    }

    if (this.f.grantAccessToAccountGrouping.value == true && this.f.customer_group_select.value=="") {
      this.f.customer_group_select.setErrors({ required: true });
      return;
    }
    if (this.loading == true) {
      return;
    }
    this.loading = true;
    this.showSpin = true;

    var request = new UserModel();
    request.isActive = this.account_status_select;
    //request.isAdmin = this.admin_access_select;
    if (this.admin_access_select) {
      request.isAB = localStorage.getItem('up.isA');
    }
    else {
      request.isAB = localStorage.getItem('up.isB');
    }
    request.effectiveDate = this.effectiveDateText;
    request.expirationDate = this.expirationDateText;
    request.locked = this.locked;
    request.grantAccessToManageManifestProfiles = this.f.grantAccessToManageManifestProfiles.value;
    request.grantAccessCompanyProfiles = this.f.grantAccessCompanyProfiles.value;
    request.grantAccessAircraftProfiles = this.f.grantAccessAircraftProfiles.value;
    request.grantAccessSubmitTripsandRequestQuotes = this.f.grantAccessSubmitTripsandRequestQuotes.value;
    request.grantAccessToMessages = this.f.grantAccessToMessages.value;
    request.grantAccessToAllMessages = this.f.grantAccessToAllMessages.value;
    //request.grantAccessToManageCompanyEvents = this.f.grantAccessToManageCompanyEvents.value;
    request.grantAccessToInvoices = false; // this.f.grantAccessToInvoices.value;
    request.grantAccessToPIIData = this.f.grantAccessToPIIData.value;
    request.grantAccessToAccountGrouping = this.f.grantAccessToAccountGrouping.value;
    request.customerGroupGUID = this.f.customer_group_select.value; 
    request.customerGuid = this.customerGuid;
    request.userId = this.userId;
    
    this._identityService.saveUserRestrictionAccessByUserId<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model == true) {
          var s = "The user info and restriction access selections have been saved.";
          //this._dialogService.openAlertDialog(s);
          this.dataChanged = false;
          this.isModified = true;
          setTimeout(() => {
            this.sendDataToParentEndPoint()
          }, 500);
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
        }
        else {
          this.errMsg = "Failed to save to our database.";
        }
      }
      else {
        this.errMsg = "Failed to save to our database.";
      }
      this.loading = false;
      this.showSpin = false;

    })

  }

  clickClose() {
    //this._dialogRef.close(this.isModified);
    this._dialogRef.close(true);
  }


}
