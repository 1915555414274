<div style="max-width: 100%; max-height: 100%; overflow: hidden;">
<div class="divLeft" *ngIf="ua==1" style=" margin-top: 0em; margin-right: 1.25em;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [cdkDragDisabled]="!isPopup">
  <div>
    <div style="display: inline-flex; justify-content: space-between; width: 100%;">
      <!--<div>
        <h5 style="display: inline-flex;">Register New User <h5 *ngIf="selectedUserType === 'internal'">&nbsp;- FPC Operations</h5></h5>
        <h5 style="color: #1976e6;">{{selectedClientName}}</h5>
      </div>-->
      <div style="display: flex; align-items: center; gap: 1em; padding: 0.5em;">
        <span class="material-symbols-outlined" style="font-size: 1.5em; line-height: 1;">Settings_account_box</span>
        <h5 style="display: inline-flex;">Register New User <h5 *ngIf="selectedUserType === 'internal'">&nbsp;- FPC Operations</h5></h5>
        <h5 style="color: #1976e6;">{{selectedClientName}}</h5>
       
      </div>

      <div *ngIf="isPopup" style="display: flex">
        <div style="z-index:100; top: 10em ; left: 20em; position: absolute; max-width: 5em">
          <app-spin *ngIf="showSpin" ></app-spin>
        </div>
        <button *ngIf="userType === 'internal'" mat-icon-button (click)="openAdminServicesComponent()">
          <i class="material-icons-outlined"
              style="font-size:xx-large;">arrow_circle_left</i>
        </button>
        <button *ngIf="userType === 'customer'" mat-icon-button (click)="openAdminServicesClientDashboard()">
          <i class="material-icons-outlined"
              style="font-size:xx-large;">arrow_circle_left</i>
        </button>
        <button mat-icon-button mat-dialog-close>
          <i class="material-icons" style="font-size: x-large;">
            close
          </i>
        </button>
      </div>
    </div>

      <div style="background-color: white; padding-left: 0.5em; padding-right: 0.5em; padding-bottom: 0.5em;">
        <form *ngIf="register_user_form" [formGroup]="register_user_form" (ngSubmit)="onSubmit()">
          <!--<div *ngIf="userType=='internal'" class="form-group form-inline">
        <ul class="tabul">
          <li [ngClass]="{ 'btn-high':selectedUserType=='internal','btn-low pointer':selectedUserType!='internal'  }" style="border-left: black 0.0625em solid;" (click)="clickUserType('internal')"><div style="margin-top:0.25em">FPI Operations</div></li>
          <li [ngClass]="{ 'btn-high':selectedUserType=='customer','btn-low pointer':selectedUserType!='customer' }" (click)="clickUserType('customer')"><div style="margin-top:0.25em">Client</div></li>
          <li [ngClass]="{ 'btn-high':selectedUserType=='vendor','btn-low pointer':selectedUserType!='vendor' }" ><div style="margin-top:0.25em">Vendor</div></li>
        </ul>
      </div>-->
          <div class="form-group">
            <div class="row">
              <!-- <div class="col-12" *ngIf="userType=='internal'">
            <select *ngIf="f && selectedUserType=='customer'" (change)="changeCustomer($event)" formControlName="customer_select" style="font-size: small;width:29.5em;border-radius:0.25em; border-color:lightgrey">
              <option value="">Select Client</option>
              <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
            </select>
            <select *ngIf="f && selectedUserType=='vendor'" formControlName="vendor_select" (change)="changeVendor($event)" style="font-size:small;width:29.5em; border-radius:0.25em; border-color:lightgrey">
              <option value="">Select Vendor</option>
              <option *ngFor="let vdr of vendorList" value={{vdr.vendorGUID}}>{{vdr.vendorNameCom}}</option>
            </select>
          </div> -->
              <div class="col-12" *ngIf="userType=='customer' && grantAccessToAccountGrouping && customerList.length>0" style="display: flex; margin-top: 0.5em;">
                <div style="font-size: small; margin-right:0.5em">Select Client</div>
                <div>
                  <select *ngIf="f" (change)="changeCustomer($event)" formControlName="customer_select"  class="form-control form-control-sm dropdown"
                  style="font-size: small;min-width:20em;border-radius:0.25em; border-color:lightgrey;height: 2em; margin-top: -0.3em;">
                    <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div style="display: inline-flex;">
            <div class="form-group">
              <div style="display: inline-flex;">
                <i class="material-icons-outlined" style="float: left;">assignment_ind</i>
                <h5>Basic Info</h5>
              </div>
              <div style="display:flex">
                <div class="form-group">
                  <label for="first_name" style="font-size:small">First Name</label>
                  <input type="text" formControlName="first_name" class="form-control"
                         (mousedown)="$event.stopPropagation()" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"
                         style="width:15em; height:1.5625em;background-color:#eaf0fd" maxlength="50"
                         (change)="firstNameChange($event)" />
                  <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                    <div *ngIf="f.first_name.errors.required">First name is required</div>
                    <div *ngIf="f.first_name.errors.pattern">Invalid first name</div>
                  </div>
                </div>
                <div class="form-group" style="margin-left:1em">
                  <label for="last_name" style="font-size:small">Last Name</label>
                  <input type="text" formControlName="last_name" class="form-control" (mousedown)="$event.stopPropagation()"
                         [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }"
                         style="width: 15em; height: 1.5625em;background-color:#eaf0fd" maxlength="50"
                         (change)="lastNameChange($event)" />
                  <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                    <div *ngIf="f.last_name.errors.required">Last name is required</div>
                    <div *ngIf="f.last_name.errors.pattern">Invalid last name</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="form-group">
                  <label for="job_title" style="font-size:small">Job Title</label>
                  <input type="text" formControlName="job_title" class="form-control" (mousedown)="$event.stopPropagation()"
                         [ngClass]="{ 'is-invalid': submitted && f.job_title.errors }"
                         style="width: 31em; height: 1.5625em;background-color:#eaf0fd" maxlength="50"
                         (change)="jobTitleChange($event)" />
                  <div *ngIf="submitted && f.job_title.errors" class="invalid-feedback">
                    <div *ngIf="f.job_title.errors.required">Job title is required</div>
                    <div *ngIf="f.job_title.errors.pattern">Invalid job title</div>
                  </div>
                </div>
              </div>
              <div style="display: flex;">
                <div class="form-group" style="margin-top:-0.5em">
                  <label for="contact_phone_number" style="font-size:small">Phone</label>
                  <input type="text" formControlName="contact_phone_number" class="form-control"
                         (mousedown)="$event.stopPropagation()" [ngClass]="{ 'is-invalid': submitted && f.contact_phone_number.errors }"
                         style="width: 15em; height: 1.5625em;background-color:#eaf0fd" maxlength="50" />
                  <div *ngIf="submitted && f.contact_phone_number.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_phone_number.errors.required">Phone number is required</div>
                    <div *ngIf="f.contact_phone_number.errors.pattern">Invalid phone number</div>
                  </div>
                </div>
                <div class="form-group" style="margin-top:-0.5em; margin-left:1em">
                  <label for="contact_email" style="font-size:small">Email</label>
                  <input type="text" formControlName="contact_email" class="form-control" (mousedown)="$event.stopPropagation()"
                         [ngClass]="{ 'is-invalid': submitted && f.contact_email.errors }"
                         style="width: 15em; height: 1.5625em;background-color:#eaf0fd" maxlength="50" />
                  <div *ngIf="submitted && f.contact_email.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_email.errors.required">Email address is required</div>
                    <div *ngIf="f.contact_email.errors.pattern">Invalid email address</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="form-group" style="padding:0;margin-bottom:0em; margin-top:-0.5em">
                  <label for="username" style="font-size:small">Username</label><br />
                  <div style="display:inline-flex; padding:0em;margin:0em">
                    <input type="text" formControlName="username" readonly class="form-control" style="width: 15em; height: 1.5625em;" maxlength="50" />&nbsp;
                    <!-- <button type="button" class="btn btn-light" (click)="clickCheckUsername()">Check Availability</button>&nbsp;&nbsp;&nbsp;<label style="font-size:small">** Username min requirements, 8 characters Alphanumeric not case sensitive.<br />No email addresses allowed</label> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group" style="margin-left: 5em;">
              <div style="display: inline-flex;">
                <i class="material-icons-outlined" style="float: left;">lock_open</i>
                <h5>Password Recovery</h5>
              </div>
              <div style="display: flex; flex-direction: column;">
                <div class="form-group">
                  <label for="phone_number" style="font-size:small">Phone</label>
                  <input type="text" formControlName="phone_number" class="form-control"
                         (mousedown)="$event.stopPropagation()"
                         [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }"
                         style="width: 20em; height: 1.5625em;background-color:#eaf0fd" maxlength="50" />
                  <div *ngIf="submitted && f.phone_number.errors" class="invalid-feedback">
                    <div *ngIf="f.phone_number.errors.required">Phone number is required</div>
                    <div *ngIf="f.phone_number.errors.pattern">Invalid phone number</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="email" style="font-size:small">Email</label>
                  <input type="text" formControlName="email" class="form-control"
                         (mousedown)="$event.stopPropagation()" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                         style="width: 20em; height: 1.5625em;background-color:#eaf0fd" maxlength="50" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email address is required</div>
                    <div *ngIf="f.email.errors.pattern">Invalid email address</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <!--<input type="checkbox" formControlName="isAdmin" /><label for="isAdmin" style="font-size:small">&nbsp;Admin Access</label>&nbsp;&nbsp;&nbsp;&nbsp;<label style="font-size: small">Basic limited access to trip views when Admin access is not selected.</label>-->
            <div style="display: inline-flex;">
              <i class="material-icons-outlined" style="float: left;">tune</i>
              <h5>Manage Permissions</h5>
            </div>
            <div style="display: flex; font-size: small; font-weight: bold; margin-top:0.25em">
              <div style="display: flex; margin-right:0.5em">
                <div style="margin-right:0.25em">
                  <input formControlName="user_role" type="radio" value="s" (click)="userRoleChange($event)" />
                </div>
                <div style="white-space: nowrap;margin-top:-0.15em">
                  <label>Standard Access</label>
                </div>
              </div>
              <div style="display: flex">
                <div style="margin-right:0.25em">
                  <input formControlName="user_role" type="radio" value="a" (click)="userRoleChange($event)" />
                </div>
                <div style="white-space: nowrap;margin-top:-0.15em">
                  <label>Admin Access</label>
                </div>
              </div>
            </div>
            <div *ngIf="selectedUserType=='customer'" style="display: flex; margin-left:-1em; margin-top:0em">
              <div class="d-flex flex-column" style="font-size: medium;   ">
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="c_grantAccessToPIIData" formControlName="c_grantAccessToPIIData"
                                  (change)="c_restrictChange($event, 'c_grantAccessToPIIData')">
                  Grant Access To PII
                  Data
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="c_grantAccessToManageManifestProfiles"
                                  formControlName="c_grantAccessToManageManifestProfiles"
                                  (change)="c_restrictChange($event, 'c_grantAccessToManageManifestProfiles')">
                  Grant Access To Manage
                  Manifest Profiles
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="c_grantAccessCompanyProfiles" formControlName="c_grantAccessCompanyProfiles"
                                  (change)="c_restrictChange($event,'c_grantAccessCompanyProfiles')">
                  Grant Access Company
                  Profiles
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="c_grantAccessAircraftProfiles" formControlName="c_grantAccessAircraftProfiles"
                                  (change)="c_restrictChange($event,'c_grantAccessAircraftProfiles')">
                  Grant Access Aircraft
                  Profiles
                </mat-slide-toggle>
              </div>
              <div class="d-flex flex-column" style=" font-size: medium;align-content: flex-start">
                <div>
                  <mat-slide-toggle style="transform:scale(.8); height:2em; width:25em;" color="primary"
                                    [checked]="c_grantAccessSubmitTripsandRequestQuotes"
                                    formControlName="c_grantAccessSubmitTripsandRequestQuotes"
                                    (change)="c_restrictChange($event,'c_grantAccessSubmitTripsandRequestQuotes')">
                    Grant Access Submit
                    Trips and Request Quotes
                  </mat-slide-toggle>
                </div>
                <div>
                  <mat-slide-toggle style="transform: scale(.8); height: 2em; width:25em;" color="primary"
                                    [checked]="c_grantAccessToMessages" formControlName="c_grantAccessToMessages"
                                    (change)="c_restrictChange($event,'c_grantAccessToMessages')">
                    Grant Access To Messages and
                    Inbox
                  </mat-slide-toggle>
                </div>
                <div>
                  <mat-slide-toggle style="transform: scale(.8); height: 2em; width:25em;" color="primary"
                                    [checked]="c_grantAccessToAllMessages" formControlName="c_grantAccessToAllMessages"
                                    (change)="c_restrictChange($event,'c_grantAccessToAllMessages')">
                    Grant Access To All Messages and
                    Inbox
                  </mat-slide-toggle>
                </div>
                <!--<div>
                  <mat-slide-toggle style="transform: scale(.8); height: 2em; width: 25em;" color="primary"
                                    [checked]="c_grantAccessToManageCompanyEvents" formControlName="c_grantAccessToManageCompanyEvents"
                                    (change)="c_restrictChange($event,'c_grantAccessToManageCompanyEvents')">
                    Grant Access To Manage
                    Company Events
                  </mat-slide-toggle>
                </div>-->
                <div style="display: flex; text-align: left">
                  <div>
                    <mat-slide-toggle style="transform: scale(.8); height: 2em; width: 25em;" color="primary"
                                      [checked]="c_grantAccessToAccountGrouping" formControlName="c_grantAccessToAccountGrouping"
                                      (change)="c_restrictChange($event,'c_grantAccessToAccountGrouping')">
                      Grant Access To Account
                      Grouping
                    </mat-slide-toggle>
                  </div>
                  <div *ngIf="c_grantAccessToAccountGrouping" style="z-index: 100;">
                    <div style="margin-top:0.5em; margin-left: -6em;">
                      <select *ngIf="f" formControlName="customer_group_select"
                              style="font-size: small;min-width:10em;border-radius:0.25em; border-color:lightgrey; background-color: #eaf0fd;"
                              [ngClass]="{ 'is-invalid': submitted && f.customer_group_select.errors, 'is-valid': !f.customer_group_select.errors}">
                        <option value="">Select a Group</option>
                        <option *ngFor="let grp of customerGroupList" [ngValue]="grp.customerGroupGUID">
                          {{grp.customerGroupName}}
                        </option>
                      </select>
                    </div>
                    <div *ngIf="submitted  && f.customer_group_select.errors" class="invalid-feedback"
                         style="margin-top:-2em">
                      <div *ngIf="f.customer_group_select.errors.required"
                           style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:16em">
                        customer group is
                        a required field.
                      </div>
                    </div>
                    <div *ngIf="customerGroupList.length==0"
                         style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:22.5em; font-size:small">
                      There is no group account for the current client.
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div *ngIf="selectedUserType=='internal'" style="display: flex; margin-left:-1em; margin-top:0em">
              <div class="d-flex flex-column" style="font-size: medium; min-width:22em;  ">
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessToPIIData" formControlName="i_grantAccessToPIIData"
                                  (change)="i_restrictChange($event, 'i_grantAccessToPIIData')">
                  Grant Access To PII
                  Data
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessToManageManifestProfiles"
                                  formControlName="i_grantAccessToManageManifestProfiles"
                                  (change)="i_restrictChange($event, 'i_grantAccessToManageManifestProfiles')">
                  Grant Access To Manage
                  Manifest Profiles
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessCompanyProfiles" formControlName="i_grantAccessCompanyProfiles"
                                  (change)="i_restrictChange($event, 'i_grantAccessCompanyProfiles')">
                  Grant Access Client
                  Profiles
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessAircraftProfiles" formControlName="i_grantAccessAircraftProfiles"
                                  (change)="i_restrictChange($event, 'i_grantAccessAircraftProfiles')">
                  Grant Access Aircraft
                  Profiles
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessToMessages" formControlName="i_grantAccessToMessages"
                                  (change)="i_restrictChange($event, 'i_grantAccessToMessages')">
                  Grant Access To
                  Messages
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessToLockedTrips" formControlName="i_grantAccessToLockedTrips"
                                  (change)="i_restrictChange($event, 'i_grantAccessToLockedTrips')">
                  Grant Access To Locked
                  Trips
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="i_grantAccessToFuel"
                                  formControlName="i_grantAccessToFuel" (change)="i_restrictChange($event, 'i_grantAccessToFuel')">
                  Grant
                  Access To Fuel Management
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessToAirport" formControlName="i_grantAccessToAirport"
                                  (change)="i_restrictChange($event, 'i_grantAccessToAirport')">
                  Grant Access To Manage Airport & Country
                  Profiles
                </mat-slide-toggle>
              </div>
              <div class="d-flex flex-column" style="min-width:22em; font-size: medium">
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessToInvoices" formControlName="i_grantAccessToInvoices"
                                  (change)="i_restrictChange($event, 'i_grantAccessToInvoices')">
                  Grant Access To
                  Invoices
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessToCompanyBusinessRules" formControlName="i_grantAccessToCompanyBusinessRules"
                                  (change)="i_restrictChange($event, 'i_grantAccessToCompanyBusinessRules')">
                  Grant Access To Company
                  Business Rules
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessToVendorProfiles" formControlName="i_grantAccessToVendorProfiles"
                                  (change)="i_restrictChange($event, 'i_grantAccessToVendorProfiles')">
                  Grant Access To Vendor
                  Profiles
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessCreateTripAlerts" formControlName="i_grantAccessCreateTripAlerts"
                                  (change)="i_restrictChange($event, 'i_grantAccessCreateTripAlerts')">
                  Grant Access To Trip
                  Alerts
                </mat-slide-toggle>
                <!--<mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessToManageCompanyEvents" formControlName="i_grantAccessToManageCompanyEvents"
                                  (change)="i_restrictChange($event, 'i_grantAccessToManageCompanyEvents')">
                  Grant Access To Manage Company
                  Events
                </mat-slide-toggle>-->
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessToHeadlineNews" formControlName="i_grantAccessToHeadlineNews"
                                  (change)="i_restrictChange($event, 'i_grantAccessToHeadlineNews')">
                  Grant Access To Headline
                  News
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessToManageAccountGrouping" formControlName="i_grantAccessToManageAccountGrouping"
                                  (change)="i_restrictChange($event, 'i_grantAccessToManageAccountGrouping')">
                  Grant Access To Manage
                  Account Grouping
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="i_grantAccessToAPIS"
                                  formControlName="i_grantAccessToAPIS" (change)="i_restrictChange($event, 'i_grantAccessToAPIS')">
                  Grant
                  Access To APIS Credentials
                </mat-slide-toggle>
              </div>
              <div class="d-flex flex-column" style="min-width:22em; font-size: medium">
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessToTripCloseout" formControlName="i_grantAccessToTripCloseout"
                                  (change)="i_restrictChange($event, 'i_grantAccessToTripCloseout')">
                  Grant Access To Trip
                  Closeout
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessSubmitToBilling" formControlName="i_grantAccessSubmitToBilling"
                                  (change)="i_restrictChange($event, 'i_grantAccessSubmitToBilling')">
                  Grant Access To Submit to
                  Billing
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessToVendorProfiles" formControlName="i_grantAccessToMessageTemplates"
                                  (change)="i_restrictChange($event, 'i_grantAccessToMessageTemplates')">
                  Grant Access To Message
                  Templates
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessCreateTripAlerts" formControlName="i_grantAccessToHelpGuides"
                                  (change)="i_restrictChange($event, 'i_grantAccessToHelpGuides')">
                  Grant Access To Help
                  Guides
                </mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary"
                                  [checked]="i_grantAccessToAccountingDashboard" formControlName="i_grantAccessToAccountingDashboard"
                                  (change)="i_restrictChange($event, 'i_grantAccessToAccountingDashboard')">
                  Grant Access To Accounting
                  Dashboard
                </mat-slide-toggle>
              </div>
            </div>
          </div>
          <!-- <div style="display:flex; margin-top:-0.5em">
          <div class="form-group">
            <label for="email" style="font-size:small">Email</label>
            <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" style="width: 15em; height: 1.5625em;background-color:#eaf0fd" maxlength="50" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.pattern">Invalid email</div>
              <div *ngIf="userType=='internal' && selectedUserType=='internal' && f.email.errors.internalEmailValidator==true">Company email address required.</div>
              <div *ngIf="selectedUserType!='internal' && f.email.errors.internalEmailValidator==true">Flight Pro email address cannot be used.</div>
            </div>
          </div>
          <div class="form-group" style="margin-left:1em">
            <label for="confirm_email" style="font-size:small">Confirm Email</label>
            <input type="email" formControlName="confirm_email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirm_email.errors }" style="width: 15em; height: 1.5625em;background-color:#eaf0fd" maxlength="50" />
            <div *ngIf="submitted && f.confirm_email.errors" class="invalid-feedback">
              <div *ngIf="f.confirm_email.errors.required">Confirm email is required</div>
              <div *ngIf="f.confirm_email.errors.textMatchValidator">Email addresses do not match.</div>
            </div>
          </div>
        </div> -->
          <!-- <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.sameEmailValidator==true" style="margin-top:-0.5em">The email address has already been used in the company</div>
        </div> -->
          <!--<div >
      <div >-->
          <!-- </div>-->
          <!--<div class="col-9" >
        <div style="padding-left:0em; margin-left:0em;display: flex;">
          <div>
            <i *ngIf="passUsername==1" class="material-icons" style="color:green; margin-left:-0.75em; margin-top:1.25em;">done</i>
          </div>
          <div style="margin-top: 1.8125em;">
            <button type="button" class="btn btn-light" (click)="clickCheckUsername()">Check Availability</button>&nbsp;
            <label style="font-size:small">** Username min requirements, 8 characters Alphanumeric not case sensitive. No email addresses allowed</label>
          </div>
        </div>
      </div>-->
          <!-- </div> -->
          <!--<div *ngIf="f.username.errors" class="invalid-feedback d-inline">
                <div *ngIf="(submitted || passUsername==-2) && f.username.errors.required">Username is required</div>
              <div *ngIf="(submitted || passUsername==-2) && (f.username.errors.minlength || f.username.errors.pattern)">Username must be at least 8 characters long and only allow letters, digits, dot and _ with no empty space</div>
        <div *ngIf="(submitted || passUsername==-2) && f.username.errors.cannotContainSpace">Username cannot contain space</div>
        <div *ngIf="(submitted || passUsername==-2) && f.username.errors.cannotContainEmailSign">Username cannot contain "@"</div>
        <div *ngIf="f.username.value!='' && f.username.errors && f.username.errors.checkExistingUsername==true">The username has been used in our system. Please enter a different username.</div>
      </div>-->



        </form>
      </div>
      <div class="form-group" style="margin-top:0.625em;">
        <button [disabled]="loading" mat-button class="btn-high" (click)="onSubmit()">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          <i *ngIf="!loading" class="material-icons">add</i>
          Save
        </button>&nbsp;
        <button   mat-button class="btn-low" mat-dialog-close> Close </button>&nbsp;
        <button *ngIf="userSaved" mat-button class="btn-med" (click)="addNewUser()">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Add a new User
        </button>&nbsp;
        <!--<button type="button" mat-button mat-dialog-close class="btn-low">
      <i class="material-icons">close</i>
      Close
    </button>-->
        <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;">
          <label style="color:red">{{errMsg}}</label>
        </div>
        <div *ngIf="msg!=''" class="d-block" style="margin:0"><label style="color:forestgreen">{{msg}}</label></div>
      </div>
    </div>
</div>
  </div>

