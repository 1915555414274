<div *ngIf="upPII && grantAccessToManageManifestProfiles" [ngClass]="{ 'p-1' : isOpenedNew }"
          [cdkDragDisabled]="isOpenedNew" cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle>
  <div>
    <div *ngIf="isOpenedNew">
     
      <div class="d-flex justify-content-between" style="width:70em">
        <div class="d-inline-flex">
          <h5>Personal Details</h5>
          <div *ngIf="customer" class="d-flex justify-content-start">            
            <h4 style="color: #146ec3;margin-top:-.25em;margin-left:.5em">{{customer.customerName}}</h4>
          </div>
        </div>
        <div>
          <div class="d-inline-flex">
            <div *ngIf="customer && customer.customerGroup" class="alert-success px-2" style="font-size:small !important; margin-right:1em">
              <ng-template ngFor let-element [ngForOf]="customer.customerGroup" let-i="index">
                <div matTooltip="{{element.tooltip}}">Account Grouping - {{element.customerGroupName}} ({{element.customerList.length}})</div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isOpenedNew" cdkDrag
         cdkDragRootElement=".cdk-overlay-pane"
         cdkDragHandle >
      <div class="d-flex justify-content-between">
        <div class="d-inline-flex">
          <h5>Personal Details</h5>
          <div *ngIf="customer" class="d-flex justify-content-start">           
            <h4 style="color: #146ec3; margin-top: -.25em; margin-left: .5em ">{{customer.customerName}}</h4>
          </div>
        </div>
        <div>
          <div class="d-inline-flex">
            <div *ngIf="customer && customer.customerGroup" class="alert-success px-2" style="font-size:small !important; margin-right:1em">
              <ng-template ngFor let-element [ngForOf]="customer.customerGroup" let-i="index">
                <div matTooltip="{{element.tooltip}}">Account Grouping - {{element.customerGroupName}} ({{element.customerList.length}})</div>
              </ng-template>
            </div>
            <span class="material-icons-outlined pointer" (click)="openContacts()" *ngIf="userType=='internal' && fromSearch" style="margin-right:.5em">arrow_circle_left</span>
            <!-- <mat-icon (click)="openInNew()" style="margin-right:.5em;cursor:pointer">open_in_new</mat-icon> -->
            <mat-icon class="pointer" (click)="close()" style="">close</mat-icon>
          </div>
        </div>
        </div>
        
      </div>

    <form *ngIf="person_details_form" [formGroup]="person_details_form">
      <div style="margin-left: 0em; padding: 0em;background-color:white">
        <div class="d-inline-flex">
          <div style="margin-right:0.25em;padding-left:.5em;padding-top:.5em">
            <div style="width:6em; height:7em; border: 0.0625em black solid ;border-radius: 0.25em">
              <a href="{{photoFilePath}}" target="_blank" rel="noopener noreferrer">
                <img *ngIf="photoFilePath!=''" [src]="photoFilePath" style="width:6em;height:7em; border:0" />
                <img *ngIf="photoFilePath==''" src="../../assets/images/empty_profile.png" style="width:5.85em;height:6.85em; border:0" />
              </a>
            </div>
            <div>
              <label *ngIf="photoFilePath==''" style="font-size: small; text-decoration:underline; cursor: pointer; color: blue">
                <input #photo formControlName="photo" id="photo" type="file" ng2FileSelect [uploader]="uploader" style="display:none" accept="*" (change)="importPhoto($event)" maxFileSize="28000000" />
                Load photo
              </label>
              <a *ngIf="photoFilePath!='' && userType=='internal'" (click)="clickRemovePhoto()" style="font-size: small; text-decoration:underline; cursor: pointer; color: blue">Remove photo</a>
            </div>

            <div *ngIf="errMsg!=''" class="inline-block" style="margin-top:-0.5em; white-space:pre-wrap;background-color: #f9d2de"><label style="color: black; font-size:small">{{errMsg}}</label></div>
          </div>
          <div style="padding:0.25em; padding-top:0em; display:flex;">
            <div>
              <div style="display:flex">
                <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; font-size: small;width:11em" >
                  <label for="firstName" style="font-weight:bold">First Name</label>
                  <div style="margin-left: 0em; margin-top: -0.5em; margin-bottom: 0em; height: 1.5625em">
                    {{firstName}}
                    <!--<input type="text" formControlName="firstName" class="form-control" readonly style="padding: 0em;width:11em;height:1.5625em;font-size:small;background-color:white" />-->
                  </div>
                </div>
                <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; font-size: small; width: 11em" >
                  <label for="middleName" style="font-weight: bold">Middle Name</label>
                  <div style="margin-left: 0em; margin-top: -0.5em; margin-bottom: 0em; height: 1.5625em">
                    {{middleName}}
                    <!--<input type="text" formControlName="middleName" class="form-control" readonly style="padding: 0em;width:11em;height:1.5625em;font-size:small;background-color:white" />-->
                  </div>
                </div>
              </div>
              <div>
                <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; font-size: small; width: 22.75em" >
                  <label for="lastName" style="font-weight: bold">Last Name</label>
                  <div style="margin-left: 0em; margin-top: -0.5em; margin-bottom: 0em; height: 1.5625em">
                    {{lastName}}
                    <!--<input type="text" formControlName="lastName" class="form-control" readonly style="padding: 0em;width:22.75em;height:1.5625em;font-size:small;background-color:white" />-->
                  </div>
                </div>
              </div>
              <div style="display:flex">
                <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; font-size: small; width: 11em" >
                  <label for="lastName" style="font-weight: bold">eu-LISA Out Of Scope</label>
                  <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                    {{eesetiasOutOfScope}}
                    <!--<input type="text" formControlName="eesetiasOutOfScope" class="form-control" readonly style="padding: 0em;width:11em;height:1.5625em;font-size:small;background-color:white" />-->
                  </div>
                </div>

                <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; font-size: small; width: 11em" >
                  <label for="lastName" style="font-weight: bold">Expiration Date</label>
                  <div style="margin-left: 0em; margin-top: -0.5em; margin-bottom: 0em; height: 1.5625em">
                    {{eesetiasOutOfScopeExpirationDate}}
                    <!--<input type="text" formControlName="eesetiasOutOfScopeExpirationDate" class="form-control" readonly style="padding: 0em;width:11em;height:1.5625em;font-size:small;background-color:white" />-->
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; font-size: small; width: 15em" >
                <label for="title" style="font-weight: bold">Title</label>
                <div style="margin-left: 0em; margin-top: -0.5em; margin-bottom: 0em; height: 1.5625em ">
                  {{title}}
                  <!--<input type="text" formControlName="title" class="form-control" readonly style="padding: 0em;width:15em;height:1.5625em;font-size:small;background-color:white" />-->
                </div>
              </div>
              <div >
                <div style="font-weight: bold;font-size:small">Role</div>
                <div style="width:15em; height:5em; font-size: small; background-color:white; overflow:auto; white-space:pre-wrap;">
                  <div *ngFor="let r of rolelist">
                    {{r}}
                  </div>
                </div>
              </div>
              <div *ngIf="dataEntryStatusID != 2"  >
                <div *ngIf="dataEntryStatusID != 2" style="padding: 0.3em; margin-top: -1.5em; background-color: #f2d2d2; width: 15em; margin-left: -0.5em; font-size:small">Data Entry Status: Not Approved</div>
                
              </div>
            </div>
            <div>
              <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; font-size: small; width: 7em" >
                <label for="dob" style="font-weight: bold">Date of Birth</label>
                <div style="margin-left: 0em; margin-top: -0.5em; margin-bottom: 0em; height: 1.5625em">
                  {{dob}}
                  <!--<input type="text" formControlName="dob" class="form-control" readonly style="padding: 0em;width:7em;height:1.5625em;font-size:small;background-color:white" />-->
                </div>
              </div>
              <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; font-size: small; width: 7em" >
                <label for="gender" style="font-weight: bold">Gender</label>
                <div style="margin-left: 0em; margin-top: -0.5em; margin-bottom: 0em; height: 1.5625em">
                  {{gender}}
                  <!--<input type="text" formControlName="gender" class="form-control" readonly style="padding: 0em;width:7em;height:1.5625em;font-size:small;background-color:white" />-->
                </div>
              </div>
              <div style="margin-top: 0em; margin-right: 0.625em; font-size: small; width: 7em " >
                <label for="person_status" style="font-weight: bold">Disable Record</label>
                <div style="margin-left: 0em; margin-top: -0.5em; margin-bottom: 0em; height: 1.5625em">
                  {{person_status}}
                  <!--<input type="text" formControlName="person_status" class="form-control" readonly style="padding: 0em;width:7em;height:1.5625em;font-size:small;background-color:white" />-->
                </div>
              </div>
            </div>
            <div>
              <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; font-size: small; width: 13em" >
                <label for="countryofbirth" style="font-weight: bold">Country of Birth</label>
                <div style="margin-left: 0em; margin-top: -0.5em; margin-bottom: 0em; height: 1.5625em">
                  {{countryofbirth}}
                  <!--<input type="text" formControlName="countryofbirth" class="form-control" readonly style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:white" />-->
                </div>
              </div>
              <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; font-size: small; width: 13em" >
                <label for="countryofresidence" style="font-weight: bold">Country of Residence</label>
                <div style="margin-left: 0em; margin-top: -0.5em; margin-bottom: 0em; height: 1.5625em">
                  {{countryofresidence}}
                  <!--<input type="text" formControlName="countryofresidence" class="form-control" readonly style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:white" />-->
                </div>
              </div>
              <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; font-size: small; width: 13em" >
                <label for="countryofnationality" style="font-weight: bold">Country of Nationality</label>
                <div style="margin-left: 0em; margin-top: -0.5em; margin-bottom: 0em; height: 1.5625em">
                  {{countryofnationality}}
                  <!--<input type="text" formControlName="countryofnationality" class="form-control" readonly style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:white" />-->
                </div>
              </div>

            </div>
            <div>
              <div style="display:flex">
                <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; font-size: small; width: 11em" >
                  <label for="state_provinceofbirth" style="font-weight: bold">State/Province of Birth</label>
                  <div style="margin-left: 0em; margin-top: -0.5em; margin-bottom: 0em; height: 1.5625em">
                    {{state_provinceofbirth}}
                    <!--<input type="text" formControlName="state_provinceofbirth" class="form-control" readonly style="padding: 0em;width:9em;height:1.5625em;font-size:small;background-color:white" />-->
                  </div>
                </div>
                <div style="margin-left:1em">
                  <button type="button" mat-button class="btn-high" *ngIf="userType=='internal'" style="width: 4em;" (click)="clickEditPerson()">Edit</button>
                </div>
              </div>
              <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; font-size: small; width: 13em" >
                <label for="cityofbirth" style="font-weight: bold">City of Birth</label>
                <div style="margin-left: 0em; margin-top: -0.5em; margin-bottom: 0em; height: 1.5625em">
                  {{cityofbirth}}
                  <!--<input type="text" formControlName="cityofbirth" class="form-control" readonly style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:white" />-->
                </div>
              </div>
              <div class="d-inline-flex">
                <div style="margin-right: .5em; font-size: small; width: 7em" >
                  <label for="modifiedBy" style="font-weight: bold ">Modified By</label>
                  <div style="margin-left: 0em; margin-top:-0.5em;height:1.5625em; width: 7em;overflow:clip;text-overflow:ellipsis" >
                    {{modifiedBy}}
                    <!--<input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:7em;height:1.5625em;font-size:small;padding: 0em;" />-->
                  </div>
                </div>
                <div  style="font-size: small; " >
                  <label for="modifiedDate" style="font-weight: bold; width: 8.5em ">Modified Date</label>
                  <div style="margin-left: 0em; margin-top:-0.5em;">
                    {{modifiedDate}}
                    <!--<input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:8.5em;height:1.5625em;font-size:small;padding: 0em;" />-->
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="ml-2 pb-2 d-flex justify-content-end" style="width:70em">
          
          <div >
            <button mat-button *ngIf="customer && customer.customerGroup" [ngClass]="{ 'btn-lightyellow' : syncCount!=totalClientCount, 'btn-lightgreen' : syncCount==totalClientCount }" style="font-size:small !important;margin-right:2em" (click)="openAccountList()">
              <span class="material-icons-outlined mr-1">share</span>Share Profile With Account Grouping ({{syncCount}})
            </button>
          </div>
        </div>
      </div>
    </form>

    <div class="form-inline mb-2 mt-2">
      <mat-radio-group *ngIf="userType=='internal'" class="form-control" [(ngModel)]="filterOption" [ngModelOptions]="{standalone: true}" style="display: flex; flex-direction: row; transform: scale(.8); align-items: flex-start;border:none!important;background-color:transparent;margin-left:-3.5em">
        <mat-radio-button class="mr-2" [checked]="filterOption==1" [value]="1">Show Active Records</mat-radio-button>
        <mat-radio-button class="mr-2" [checked]="filterOption==2" [value]="2">Data Entry Status</mat-radio-button>
        <mat-radio-button class="mr-2" [checked]="filterOption==3" [value]="3">Show Disabled Records</mat-radio-button>
      </mat-radio-group>

      <button mat-button class="btn btn-high mr-1" (click)="addNew('documents')"><span style="font-size: small !important; margin-top:-.25em" class="material-icons-outlined mr-1">add</span>Add Documents</button>
      <button mat-button class="btn btn-high mr-1" (click)="addNew('comms')"><span style="font-size: small !important; margin-top: -.25em" class="material-icons-outlined mr-1">add</span>Add Phone & Emails</button>
      <button mat-button class="btn btn-high mr-1" (click)="addNew('mail')"><span style="font-size: small !important; margin-top: -.25em" class="material-icons-outlined mr-1">add</span>Add Mail Address</button>
      <button mat-button class="btn btn-high mr-1" (click)="addNew('rewards')"><span style="font-size: small !important; margin-top: -.25em" class="material-icons-outlined mr-1">add</span>Add Travel Memberships</button>


      <!--<div>
    <ul class="tabul">
      <li [ngClass]="{ 'btn-high':selectedTabName=='mailAddress','btn-low pointer':selectedTabName!='mailAddress'  }" style="border-left: black 0.0625em solid;" (click)="clickTab('mailAddress')"><div style="margin-top:0.25em">Mail Address</div></li>
      <li *ngIf="isCrewPax" [ngClass]="{ 'btn-high':selectedTabName=='travelDocument','btn-low pointer':selectedTabName!='travelDocument' }" (click)="clickTab('travelDocument')"><div style="margin-top:0.25em">Documents</div></li>
      <li *ngIf="isCrewPax" [ngClass]="{ 'btn-high':selectedTabName=='rewards','btn-low pointer':selectedTabName!='rewards' }" (click)="clickTab('rewards')"><div style="margin-top:0.25em">Travel Memberships</div></li>
      <li [ngClass]="{ 'btn-high':selectedTabName=='comms','btn-low pointer':selectedTabName!='comms' }" (click)="clickTab('comms')"><div style="margin-top:0.25em">Phone & Emails</div></li>
    </ul>
  </div>-->
      <!--<div style="display:flex; margin-left: 2em; margin-top:0.5em" *ngIf="userType=='internal'">
    <input type="checkbox" formControlName="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)" /><label for="includeDisabledRecords" style="font-size:small;margin-top:-0.25em">&nbsp;Include disabled records</label>
  </div>-->


    </div>
  </div>

  <div style="background-color:white; " [ngClass]="{'popup':!isOpenedNew }">

    <div class="mb-2" [hidden]="docCount==0">
      <app-customer-person-travel-doclist #travelDocs *ngIf="isCrewPax" [personId]="personGUID" [customerGUID]="customerGUID"    [filterOption]="filterOption" (updateParentPage)="onUpdateParentPage($event)"></app-customer-person-travel-doclist>
    </div>
    <div class="mb-2" [hidden]="commCount==0">
      <app-customer-person-commlist #comms [personId]="personGUID" [customerGUID]="customerGUID" [filterOption]="filterOption" (updateParentPage)="onUpdateParentPage($event)"></app-customer-person-commlist>
    </div>
    <div class="mb-2" [hidden]="mailCount==0">
      <app-customer-person-maillist #mail [personId]="personGUID" [customerGUID]="customerGUID"  [filterOption]="filterOption" (updateParentPage)="onUpdateParentPage($event)"></app-customer-person-maillist>
    </div>
    <div class="mb-2" [hidden]="rewardCount==0">
      <app-customer-person-rewardlist #rewards *ngIf="isCrewPax" [personId]="personGUID" [customerGUID]="customerGUID"  [filterOption]="filterOption" (updateParentPage)="onUpdateParentPage($event)"></app-customer-person-rewardlist>
    </div>
  </div>

  <div style="display: flex; margin: 0.5em 0 -0.2em 0;">

    <div *ngIf="userType=='customer' && grantAccessToMessages"  >
      <!--<button mat-button class="btn-low" (click)="clickGoToPersonListPage()">Manage Contact Details Page</button>&nbsp;-->
      <button type="button" *ngIf="userType=='customer' && grantAccessToMessages" mat-button class="btn-low" (click)="clickSendEmail($event)">
        <span class="material-symbols-outlined"  style="font-size: large;  ">send</span>
        Email</button> 
    </div>
    <button class="btn-low" mat-button (click)="close()"
      style="background-color:grey; color: white; margin-left: 1em; height: 1.9em;"> Close</button>
  </div>
      
</div>
