<div *ngIf="upPII" style="background-color:#f8f8f8;overflow-y:hidden " >
  <div style="display: flex; justify-content:space-between">
    <div style="display: flex;padding-left:1em; padding-top:0.25em; float:left; min-width:50em" cdkDrag
         cdkDragRootElement=".cdk-overlay-pane"
         cdkDragHandle>

      <div style="margin-right:1em">
        <div style="font-size: small">Registration</div>
        <div style="font-weight: bold; font-size: small">{{registration}}</div>
      </div>
      <div style="margin-right:1em">
        <div style="font-size: small">Trip Code</div>
        <div style="font-weight: bold; font-size: small">{{tripCode}}</div>
      </div>
      <div style="margin-right:1em">
        <div style="font-size: small">Business Stop</div>
        <div style="font-size: small; display: flex"><div>{{prevICAO!=''?prevICAO+'-':''}}</div><div style="font-weight: bold">{{icao}}</div><div>{{nextICAO!=''?'-'+nextICAO:''}}</div></div>
      </div>
      <div style="margin-right:1em" *ngIf="arrivalDateTime!=''">
        <div style="font-size: small">Arrival({{icao}})</div>
        <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{arrivalDateTime}}</div>
        <div style="font-size: small; margin-top:-0.5em"> {{arrivalDateTimeLocal!=''?arrivalDateTimeLocal:''}}</div>
      </div>
      <div style="margin-right:1em" *ngIf="departureDateTime!=''">
        <div style="font-size: small">Departure({{icao}})</div>
        <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{departureDateTime}}</div>
        <div style="font-size: small;margin-top:-0.5em"> {{departureDateTimeLocal!=''?departureDateTimeLocal:''}}</div>
      </div>
      <div>
        <div style="font-size: small;">{{aircraftManufacturer}} {{aircraftModel}}</div>
        <div style="font-size: small;">{{aircraftInfo}}</div>
      </div>
    </div>
    <div *ngIf="onHoldText!=''" style="margin-top:1em;border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
      <div style="margin-top: -0.1em; text-align: center; font-weight: normal; font-size: small;">On Hold</div>
    </div>
    <div style="float: right;padding-top:0.75em; padding-right:1em">
      <i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium" title="Copy note to clipboard" [cdkCopyToClipboard]="clickCopyHeader() ">content_copy</i>
    </div>
  </div>
  <div>
    <form *ngIf="ground_stop_task_edit_form" [formGroup]="ground_stop_task_edit_form">
      <div style="display:flex; margin-top:0em; padding-left: 1em; padding-right:1em">
        <div style="margin-top:0.5em;background-color:white; padding-left:1em; padding-bottom: 0.75em; width: 57em">
          <div style="display:flex; justify-content: space-between">
            <div class="d-inline-flex">
              <div style="border-radius: 50%; width: 1em; height: 1em; border: black solid 0.0625em; margin-right:.25em;margin-top:.5em" [style.background-color]="taskColor"></div>
              <div style="margin-top:0.25em;"><h5  [ngClass]="immediateAction == 1?'divImmediatePriority':''" >{{serviceTypeDesc}}</h5></div>
              <div class="ml-2 alert alert-danger small px-1 py-0 link" style="margin-top:.55em;" *ngIf="avoidLocationMsg!=''" (click)="openBusinessRule()">{{avoidLocationMsg}}</div>
            </div>
            <div *ngIf="isRevisionRequiredValue==1">
              <div [ngClass]="f.isRevisionRequired.value==true?'alert alert-danger d-flex align-items-center':'alert alert-success d-flex align-items-center'" style="height:2.5em; margin-top:0.25em; margin-right:2em;padding-left:0.25em; padding-right:0.25em; font-size:small; display: flex; border-radius:0.25em;">
                <div>
                  <mat-slide-toggle formControlName="isRevisionRequired" style="transform: scale(0.9) " [checked]="isRevisionRequired">
                  </mat-slide-toggle>
                </div>
                <div *ngIf="f.isRevisionRequired.value==true">
                  <div>Revision Required - </div>
                  <div style="margin-top:-0.5em">Turn off to Acknowledge</div>
                </div>
                <div *ngIf="f.isRevisionRequired.value==false">
                  <div style="margin-top:0em;">Revision Acknowledged</div>
                </div>
                <div>
                  <div *ngIf="f.isRevisionRequired.value==true" style="margin-top:0.5em">
                    <i class="material-icons-two-tone icon_orange" style="font-size: x-large;">notifications_active</i>
                  </div>
                  <div *ngIf="f.isRevisionRequired.value==false" style="margin-top:0.5em">
                    <i class="material-icons" style="font-size: x-large; background-color: transparent; color: black; border-color: #5e5e5e">task_alt</i>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="isRevisionRequiredValue==2">
              <div [ngClass]="f.isRevisionRequired.value==true?'alert alert-danger d-flex align-items-center':'alert alert-success d-flex align-items-center'" style="height:2.5em; margin-top:0.25em; margin-right:2em;padding-left:0.25em; padding-right:0.25em; font-size:small; display: flex; border-radius:0.25em;">
                <div>
                  <mat-slide-toggle formControlName="isRevisionRequired" style="transform: scale(0.9) " [checked]="isRevisionRequired">
                  </mat-slide-toggle>
                </div>
                <div *ngIf="f.isRevisionRequired.value==true">
                  <div>Revision Required - </div>
                  <div style="margin-top:-0.5em">Turn off to Acknowledge</div>
                </div>
                <div *ngIf="f.isRevisionRequired.value==false">
                  <div style="margin-top:0em">Revision Acknowledged</div>
                </div>
                <div>
                  <div *ngIf="f.isRevisionRequired.value==true" style="margin-top:0.5em">
                    <i class="material-icons-two-tone icon_red" style="font-size: x-large;">notifications_active</i>
                  </div>
                  <div *ngIf="f.isRevisionRequired.value==false" style="margin-top:0.5em">
                    <i class="material-icons" style="font-size: x-large; background-color: transparent; color: black; border-color: #5e5e5e">task_alt</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--handling-->
          <div *ngIf="serviceTypeID!=3 && serviceTypeID!=5 && serviceTypeID!=26 && serviceTypeID!=54 && serviceTypeID!=55 && serviceTypeID!=88 && serviceTypeID != 98 && this.serviceTypeID != 73 && vendorReq==true" style="display: flex;margin-top:-0.75em">
            <div style="margin-right:0.5em">
              <div class="d-flex justify-content-between"><label for="fbo_select" style="font-size:small">{{serviceSubTitle}}</label><label style="font-size:small" class="mr-2">Method Of Payment: <span class="font-weight-bold">{{methodOfPayment}}</span></label></div>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="fbo_select" [ngClass]="{ 'is-invalid': submitted && f.fbo_select.errors }" style="font-size: small; width:28.5em; border-radius:0.25em; background-color:#eaf0fd" (change)="vendorChange($event,'fbo_select')">
                  <option value="" selected></option>
                  <option *ngFor="let b of fboList" [ngValue]="b.vendorGUID">{{b.vendorName}}</option>
                </select>
              </div>
              <div *ngIf="submitted && f.fbo_select.errors">
                <div *ngIf="f.fbo_select.errors.required" style="background-color:#f9d2de; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">FBO is required</div>
              </div>
            </div>
            <div style="margin-right:0.5em;">
              <div class="d-flex justify-content-start"><label for="service_arrangement_handling_select" style="font-size:small">Service Arrangement</label></div>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="service_arrangement_handling_select" style="font-size: small;border-radius:0.25em; border-color:lightgrey;background-color:#eaf0fd">
                  <option *ngFor="let s of serviceArrangementList" [ngValue]="s.serviceArrangementID">{{s.serviceArrangementDescription}}</option>
                </select>
              </div>
            </div>
            <div style="margin-top:.5em;background-color:#E9ECEF;border-radius:.25em" class="p-1" *ngIf="serviceTypeID==1 &&  f.fbo_select.value != null && f.fbo_select.value != '' && f.fbo_select.value !='4383ada6-2161-49e6-965f-47fc67fa5cd4'">
              <div class="d-flex justify-content-center">
                <label style="font-size:x-small">Handling Notes</label>
              </div>
              <div style="margin-top:-.5em" class="d-flex justify-content-center">
                <button mat-button class="btn-high" style="font-size:x-small !important; padding-left:0.25em !important; padding-right:0.25em !important" (click)="generateHandlingNotes('pdf')"><span *ngIf="loadingPDF" class="spinner-border spinner-border-sm mr-1"></span>PDF</button>
                <button mat-button class="btn-high ml-1" style="font-size:x-small !important; padding-left:0.25em !important; padding-right:0.25em !important" (click)="generateHandlingNotes('word')"><span *ngIf="loadingWord" class="spinner-border spinner-border-sm mr-1"></span>Word</button>
                <button mat-button class="btn-high ml-1" style="font-size:x-small !important; padding-left:0.25em !important; padding-right:0.25em !important" (click)="generateHandlingNotes('notes')">Add to Notes</button>
              </div>
            </div>
            <!--<div style="margin-top:1.25em;">
              <button mat-button class="btn-high" (click)="openVendorDetails()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">info</i><div>Details</div></div></button>&nbsp;
            </div>
            <div style="margin-top:1.25em">
              <button mat-button class="btn-high" (click)="addNewVendor()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">library_add</i><div>Add New FBO / Handler</div></div></button>
            </div>-->
          </div>
          <!--handling end-->
          <!--flight plan-->
          <div *ngIf="serviceTypeID==35" style="display: flex;margin-top:-0.75em ">
            <div style="margin-right:0.5em">
              <div style="margin-left:0em;margin-top:0em">
                <label for="recall_number" style="font-size:small">Recall Number</label>
                <div style="display:flex;margin-top:-0.5em">
                  <input type="text" formControlName="recall_number" class="form-control" style="width:10em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; background-color:#eaf0fd" (change)="recallNumberChange($event)" />
                </div>
              </div>
            </div>
            <div *ngIf="overflightCountryList && overflightCountryList.length>0" style="width:41.5em; margin-right: 0.5em; height:4em; background-color: #E9ECEF; border-radius:0.25em; font-size: small; padding-left: 0.25em; ">
              <div style="font-weight:500">Overflight Permit Task Associated to This Recall number:</div>
              <div style="height: 2.5em; overflow-y:auto">
                <div *ngFor="let item of overflightCountryList; let i=index">
                  <div *ngIf="i % 6==0" style="display: flex;">
                    <div style="font-size: small; display:flex;width:7em; padding:0em;">
                      <div style="font-size: small;border-radius:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{item.publicNote}}" [style.background-color]="item.taskStatusBackgroundColor">{{item.countryName.length>12?item.countryName.substring(0,12)+'...': item.countryName}}</div>
                    </div>
                    <div *ngIf="i + 1 < overflightCountryList.length" style="font-size: small; display:flex; margin-left:0.5em;width:7em; padding:0em" [style.background-color]="item.TaskStatusBackgroundColor">
                      <div style="font-size: small;border-radius:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{overflightCountryList[i+1].publicNote}}" [style.background-color]="overflightCountryList[i+1].taskStatusBackgroundColor">{{overflightCountryList[i+1].countryName.length>12?overflightCountryList[i+1].countryName.substring(0,12)+'...':overflightCountryList[i+1].countryName}}</div>
                    </div>
                    <div *ngIf="i + 2 < overflightCountryList.length" style="font-size: small;display: flex; margin-left:0.5em;width:7em; padding:0em ">
                      <div style="font-size: small;border-radius:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{overflightCountryList[i+2].publicNote}}" [style.background-color]="overflightCountryList[i+2].taskStatusBackgroundColor">{{overflightCountryList[i+2].countryName.length>12?overflightCountryList[i+2].countryName.substring(0,12)+'...':overflightCountryList[i+2].countryName}}</div>
                    </div>
                    <div *ngIf="i + 3 < overflightCountryList.length" style="font-size: small;display: flex; margin-left:0.5em;width:7em; padding:0em ">
                      <div style="font-size: small;border-radius:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{overflightCountryList[i+3].publicNote}}" [style.background-color]="overflightCountryList[i+3].taskStatusBackgroundColor">{{overflightCountryList[i+3].countryName.length>12?overflightCountryList[i+3].countryName.substring(0,12)+'...':overflightCountryList[i+3].countryName}}</div>
                    </div>
                    <div *ngIf="i + 4 < overflightCountryList.length" style="font-size: small;display: flex; margin-left:0.5em;width:7em; padding:0em ">
                      <div style="font-size: small;border-radius:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{overflightCountryList[i+4].publicNote}}" [style.background-color]="overflightCountryList[i+4].taskStatusBackgroundColor">{{overflightCountryList[i+4].countryName.length>12?overflightCountryList[i+4].countryName.substring(0,12)+'...':overflightCountryList[i+4].countryName}}</div>
                    </div>
                    <div *ngIf="i + 5 < overflightCountryList.length" style="font-size: small;display: flex; margin-left:0.5em;width:7em; padding:0em ">
                      <div style="font-size: small;border-radius:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{overflightCountryList[i+5].publicNote}}" [style.background-color]="overflightCountryList[i+5].taskStatusBackgroundColor">{{overflightCountryList[i+5].countryName.length>12?overflightCountryList[i+5].countryName.substring(0,12)+'...':overflightCountryList[i+5].countryName}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="margin-top:1.25em">
                <button mat-button class="btn-high" (click)="openAlternatPage()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">edit_location_alt</i><div>Alternate Airport</div></div></button>
              </div>

            </div>
          </div>
          <!--flight plan-->
          <!--over flight permit-->
          <div *ngIf="(serviceTypeID==3 || serviceTypeID==88) && vendorReq==true" style="display: flex;margin-top:-0.75em">
            <div style="margin-right:0.5em;">
              <label for="overflight_country_select" style="font-size:small">Overflight Country Name</label>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="overflight_country_select" [ngClass]="{ 'is-invalid': submitted && f.overflight_country_select.errors }" style="font-size: small;background-color:#eaf0fd; width:17em; border-radius:0.25em;" (change)="countryChange($event, 'overflight')">
                  <option value="" selected></option>
                  <option *ngFor="let cty of countryList" [ngValue]="cty">{{cty.countryName}}</option>
                </select>
               
              </div>
              <div *ngIf="submitted && f.overflight_country_select.errors">
                <div *ngIf="f.overflight_country_select.errors.required" style="background-color:#f9d2de; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Country is required</div>
              </div>
            </div>
            <div style="margin-right:0.5em; width: 12.25em">
              <div *ngIf="f.overflight_country_select.value!=''">
                <div class="d-flex justify-content-between" style="margin-top:.2em"><label for="vendor_select" style="font-size:small">{{serviceSubTitle}}</label><label class="ml-1" style="font-size:small; font-weight:bold; margin-right:.5em">{{methodOfPayment}}</label></div>
                <div style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="vendor_select" [ngClass]="{ 'is-invalid': submitted && f.overflight_country_select.errors }" style="font-size: small;border-radius:0.25em; width:15em; background-color:#eaf0fd" (change)="vendorChange($event,'vendor_select')">
                    <option value="" selected></option>
                    <option *ngFor="let v of vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                  </select>
                </div>
                <div *ngIf="submitted && f.vendor_select.errors">
                  <div *ngIf="f.vendor_select.errors.required" style="background-color:#f9d2de; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Vendor is required</div>
                </div>
              </div>
            </div>
            <div style="margin-right:0.5em">
              <div style="display:flex">
                <div>
                  <div *ngIf="serviceTypeID==3" style="margin-right:0.5em;margin-top:0em">
                    <label for="permit_order_number" style="font-size:small; white-space:nowrap">Order #</label>
                    <div style="display:flex;margin-top:-0.35em">
                      <input type="number" min="1" formControlName="permit_order_number" class="form-control" style="width:3em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; background-color:#eaf0fd" [ngClass]="{ 'is-invalid': submitted && f.permit_order_number.errors }" />
                    </div>
                  </div>
                </div>
                <div>
                  <div style="margin-right:0.5em;margin-top:0em" *ngIf="fpRecallNumberList && fpRecallNumberList.length>0">
                    <div style="margin-top:-0.85em"><label for="fp_recall_number_select" style="font-size:small;">Recall Number</label><div style="margin-top:-1em; font-size: small">(Link to Flight Plan)</div></div>
                    <div style="margin-top:0em;">
                      <select *ngIf="f" formControlName="fp_recall_number_select" style="font-size: small;background-color:#eaf0fd; width: 8.5em; border-radius:0.25em; border-color: lightgrey">
                        <option value="" selected></option>
                        <option *ngFor="let fp of fpRecallNumberList" [ngValue]="fp.flightPlanRecallNo">{{fp.flightPlanRecallNo}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <label for="service_arrangement_overflight_select" style="font-size:small">Service Arrangement</label>
                  <div style="margin-top:-0.5em;">
                    <select *ngIf="f" formControlName="service_arrangement_overflight_select" style="font-size: small;border-radius:0.25em; border-color:lightgrey;background-color:#eaf0fd">
                      <option *ngFor="let s of serviceArrangementList" [ngValue]="s.serviceArrangementID">{{s.serviceArrangementDescription}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--over flight permit end-->
          <!--landing permit and charter-->
          <div>
            <div *ngIf="(serviceTypeID==5 || serviceTypeID==26) && vendorReq==true" style="display: flex;margin-top:-0.75em">
              <div style="margin-right:0.5em">                
                <div class="d-flex justify-content-between" style="margin-top:.2em"><label for="landing_vendor_select" style="font-size:small">{{serviceSubTitle}}</label><label class="ml-1" style="font-size:small; font-weight:bold; margin-right:.5em">{{methodOfPayment}}</label></div>
                <div style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="landing_vendor_select" [ngClass]="{ 'is-invalid': submitted && f.landing_vendor_select.errors }" style="font-size: small;border-radius:0.25em; width:15em; background-color:#eaf0fd" (change)="vendorChange($event,'landing_vendor_select')">
                    <option value="" selected></option>
                    <option *ngFor="let v of vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                  </select>
                </div>
                <div *ngIf="submitted && f.landing_vendor_select.errors">
                  <div *ngIf="f.landing_vendor_select.errors.required" style="background-color:#f9d2de; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Vendor is required</div>
                </div>
              </div>
              <div style="margin-right:0.5em">
                <label for="service_arrangement_landing_select" style="font-size:small">Service Arrangement</label>
                <div style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="service_arrangement_landing_select" style="font-size: small;border-radius:0.25em; border-color:lightgrey;background-color:#eaf0fd">
                    <option *ngFor="let s of serviceArrangementList" [ngValue]="s.serviceArrangementID">{{s.serviceArrangementDescription}}</option>
                  </select>
                </div>
              </div>
              <div>
                <div style="margin-right:0.5em;">
                  <label for="landing_country_select" style="font-size:small">{{serviceTypeID==5?'Landing Country Name':'Charter Country Name'}}</label>
                  <div style="margin-top:-0.5em;">
                    <select *ngIf="f" formControlName="landing_country_select" [ngClass]="{ 'is-invalid': submitted && f.landing_country_select.errors }" style="font-size: small;background-color:#eaf0fd; width:17em; border-radius:0.25em; " (change)="countryChange($event, 'landing')">
                      <option value="" selected></option>
                      <option *ngFor="let cty of countryList" [ngValue]="cty">{{cty.countryName}}</option>
                    </select>
                  </div>
                  <div *ngIf="submitted && f.landing_country_select.errors">
                    <div *ngIf="f.landing_country_select.errors.required" style="background-color:#f9d2de; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Country is required</div>
                  </div>
                </div>
              </div>

              <!--<div style="margin-top:1.25em">
                <button mat-button class="btn-high" (click)="addNewVendor()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">library_add</i><div>Add New Vendor</div></div></button>
              </div>
              <div style="margin-top:1.25em;">
                <button mat-button class="btn-high" (click)="openVendorDetails()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">info</i><div>Details</div></div></button>&nbsp;
              </div>-->
            </div>
          </div>
          <!--landing permit end-->
          <!--hotel-->
          <div *ngIf="serviceTypeID==7 || serviceTypeID==20" style="display: flex;margin-top:-0.75em">
            <div>
              <div>
                <label for="hotel_select" style="font-size:small">Hotel Property Name</label>
                <div style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="hotel_select" style="font-size: small;border-radius:0.25em; width:31em; border-color:lightgrey;background-color:#eaf0fd" (change)="vendorChange($event,'hotel_select')">
                    <option value="" selected></option>
                    <option *ngFor="let h of hotelList" [ngValue]="h.hotelGUID">{{h.hotelName+' - '+h.icao }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div style="margin-left:0.5em; margin-top:1.25em">
              <button [hidden]="f.hotel_select.value==''" mat-button class="btn-high" style="padding-left:0.25em !important; padding-right:0.25em !important" (click)="clickEditHotel('E')"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.15em">hotel</i><div style="margin-left:0.25em">Details</div></div></button>&nbsp;
            </div>
            <div style="margin-left:0.5em; margin-top:1.25em">
              <button mat-button class="btn-high" style="padding-left:0.25em !important; padding-right:0.25em !important" (click)="clickEditHotel('')"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">library_add</i><div style="margin-left:0.25em">Add Hotel Property</div></div></button>
            </div>
          </div>
          <!--hotel end-->
          <!--customs-->
          <div *ngIf="serviceTypeID==54 || serviceTypeID==55 || serviceTypeID == 98 || this.serviceTypeID == 73" style="display: flex;margin-top:-0.75em">
            <div style="margin-right:0.5em;">
              <div>
                <label for="customs_vendor_select" style="font-size:small">{{serviceSubTitle}}</label>
                <div style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="customs_vendor_select" style="font-size: small;border-radius:0.25em; width:31em; border-color:lightgrey;background-color:#eaf0fd" (change)="vendorChange($event,'customs_vendor_select')">
                    <option value="" selected></option>
                    <option *ngFor="let v of customsVendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                  </select>
                </div>
                <div *ngIf="submitted && f.customs_vendor_select.errors">
                  <div *ngIf="f.customs_vendor_select.errors.required" style="background-color:#f9d2de; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Vendor is required</div>
                </div>
              </div>
            </div>
            <div style="margin-top:1.25em;">
              <button mat-button class="btn-high" (click)="openVendorDetails()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">info</i><div>Details</div></div></button>&nbsp;
            </div>

          </div>
          <!--customs end-->
          <!--fuel-->
          <div *ngIf="serviceTypeID==56" style="display: flex;margin-top:-0.75em; ">
            <div style="margin-right: 0.5em">
              <label for="fuel_supplier_select" style="font-size:small">Fuel Supplier</label>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="fuel_supplier_select" [ngClass]="{ 'is-invalid': submitted && f.fuel_supplier_select.errors }" style="font-size: small;border-radius:0.25em; width:15em; background-color:#eaf0fd" (change)="fuelSupplierChange($event)">
                  <option value="" selected></option>
                  <option *ngFor="let fu of fuelSupplierList" [ngValue]="fu.vendorName">{{fu.vendorName}}</option>
                </select>
              </div>
              <div *ngIf="submitted && f.fuel_supplier_select.errors">
                <div *ngIf="f.fuel_supplier_select.errors.required" style="background-color:#f9d2de; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Fuel supplier is required</div>
              </div>
            </div>
            <div style="width: 12.25em; margin-right:0.5em">
              <div *ngIf="f.fuel_supplier_select.value!=''">
                <label for="intoplane_agent_select" style="font-size:small">Into Plane Agent</label>
                <div style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="intoplane_agent_select" [ngClass]="{ 'is-invalid': submitted && f.intoplane_agent_select.errors }" style="font-size: small;width:15em; border-radius:0.25em; background-color:#eaf0fd">
                    <option value="" selected></option>
                    <option *ngFor="let i of intoPlaneAgentList" [ngValue]="i.vendorGUID">{{i.intoPlaneAgent}}</option>
                  </select>
                </div>
                <div *ngIf="submitted && f.intoplane_agent_select.errors">
                  <div *ngIf="f.intoplane_agent_select.errors.required" style="background-color:#f9d2de; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Into plane agent is required</div>
                </div>
              </div>
            </div>
            <div style="margin-right:0.5em">
              <label for="service_arrangement_fuel_select" style="font-size:small">Service Arrangement</label>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="service_arrangement_fuel_select" style="font-size: small;border-radius:0.25em; border-color:lightgrey;background-color:#eaf0fd">
                  <option *ngFor="let s of serviceArrangementList" [ngValue]="s.serviceArrangementID">{{s.serviceArrangementDescription}}</option>
                </select>
              </div>
            </div>
            <!--<div style="margin-top:1.25em">
              <button mat-button class="btn-high"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">library_add</i><div>Add New Vendor</div></div></button>
            </div>-->
          </div>
          <!--fuel end-->
          <div style="display: flex">
            <div>
              <div style="display:flex; margin-top:-0.25em; height:3em; width: 34em">
                <div style="margin-left:0em">
                  <label for="task_status_select" style="font-size:small">Operational Status</label>
                  <div style="margin-top:-0.5em;">
                    <select *ngIf="f" formControlName="task_status_select" style="font-size: small;font-weight: 500; border-radius:0.25em;border-color:lightgrey;background-color:#eaf0fd" (change)="taskStatusChange()">
                      <option *ngFor="let t of taskStatusList" [ngValue]="t.taskStatusGUID">{{t.taskStatusDescription}}</option>
                    </select>
                  </div>
                </div>
                <div *ngIf="due_datetime==''" style="margin-left:0.5em;margin-top:0em">
                  <div style="cursor: pointer; font-size: small; margin-top:0.25em" (click)="showOrginalLeadTimeDueDate=true" class="divHover">Lead Time</div>
                  <div style="display:flex;margin-top:0.25em; font-size:small">
                    <input type="text" formControlName="lead_time" maxlength="9" placeholder="?d?h?m" class="form-control" [ngClass]="{ 'is-invalid': (submitted || lead_timeChanged) && f.lead_time.errors }" style="width:5em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; background-color:#eaf0fd" (change)="leadTimeChange($event)" />
                  </div>
                  <div *ngIf="(submitted || lead_timeChanged) && f.lead_time.errors">
                    <div *ngIf="f.lead_time.errors.pattern" style="background-color:#f9d2de; margin-left:-10em; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Invalid lead time. format: ?d?h?m</div>
                  </div>
                </div>

                <div *ngIf="due_datetime==''" style="margin-left:0.5em;width:4.25em ">
                  <div style="cursor: pointer; font-size: small; margin-top:0.25em" (click)="showOrginalLeadTimeDueDate=true" class="divHover">Due Date</div>
                  <div style="display:flex;margin-top:0.25em">
                    <input type="text" formControlName="due_date" maxlength="9" placeholder="?d?h?m" class="form-control" [ngClass]="{ 'is-invalid': (submitted || due_dateChanged) && f.due_date.errors }" style="width:5em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; background-color:#eaf0fd" (change)="dueDateChange($event)" />
                  </div>
                  <div *ngIf="(submitted || due_dateChanged) && f.due_date.errors">
                    <div *ngIf="f.due_date.errors.pattern" style="background-color:#f9d2de; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Invalid due date. format: ?d?h?m</div>
                  </div>
                </div>
                <div *ngIf="due_datetime!=''" style="margin-left:0.5em;margin-top:0em;width:10.25em">
                  <div style="font-size: small; margin-top:0.25em">Delivery</div>
                  <div style="display:flex;margin-top:0.25em; font-size:small">
                    {{due_datetime}} (L)
                  </div>
                </div>
                <div style="margin-left:0.5em">
                  <label for="acct_status_select" style="font-size:small">GTS Accounting status</label>
                  <div style="margin-top:-0.5em;">
                    <select *ngIf="f" formControlName="acct_status_select" style="width:17em; font-size: small; font-weight:500; border-radius:0.25em;border-color:lightgrey;background-color:#eaf0fd">
                      <option *ngFor="let a of acctStatusList" [ngValue]="a.accountingStatusGUID">{{a.accountingStatusDescription}}</option>
                    </select>
                  </div>
                </div>
                <div *ngIf="showOrginalLeadTimeDueDate" style="font-size:small; background-color:#f8f8f8; margin-left:-27em; margin-top:-4.5em; display: flex; max-height: 5em; width: 35em; border: solid gray 0.0625em; border-radius:0.25em; padding:0.5em 1em 0em 1em; z-index: 1000">
                  <div style="margin-right:1em">
                    <div style="white-space: nowrap">Lead Time</div>
                    <div style="margin-top:1em">{{serviceType_LeadTime}}</div>
                  </div>
                  <div style="margin-right: 1em">
                    <div style="white-space: nowrap">Lead Time</div>
                    <div style="white-space: nowrap;margin-top:-0.5em">in Minutes</div>
                    <div>{{serviceType_LeadTime_Min}}</div>
                  </div>
                  <div style="margin-right: 1em">
                    <div style="white-space: nowrap">Due Date</div>
                    <div style="margin-top:1em">{{serviceType_DueDate}}</div>
                  </div>
                  <div style="margin-right: 1em">
                    <div style="white-space: nowrap">Due Date</div>
                    <div style="white-space: nowrap;margin-top:-0.5em">in Minutes</div>
                    <div>{{serviceType_DueDate_Min}}</div>
                  </div>
                  <div style="margin-right: 1em">
                    <div style="white-space: nowrap">Calculate From</div>
                    <div style="margin-top:1em">{{serviceType_CalculateFrom}}</div>
                  </div>
                  <div>
                    <i class="material-icons" style="cursor: pointer; color:black;font-size: medium" (click)="showOrginalLeadTimeDueDate=!showOrginalLeadTimeDueDate">close</i>
                  </div>
                </div>
                <!--<div>
              <div style="display:flex">

                <div style="margin-left:0.5em;margin-top:0em">
    <label for="lead_datetime" style="font-size:small">Request</label>
    <div style="display:flex;margin-top:-0.35em">
      <input type="text" formControlName="lead_datetime" maxlength="16" placeholder="mm/dd/yyyy HH:MM" class="form-control" readonly style="width:10em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; " />
    </div>
  </div>
              </div>

            </div>-->
              </div>
                <div style="display:flex">
                  <div>
                    <div style="display:flex" *ngIf="serviceTypeID!=35">
                      <div style="margin-left:0em;margin-top:0em">
                        <label for="confirmation_number" style="font-size:small">{{serviceTypeID!=58?serviceTypeID!=40 && serviceTypeID!=66 && serviceTypeID!=68? taskLabel!='' && taskLabel!=null? taskLabel + ' Number': 'Confirmation Number':'Recall Number':'Misc Description'}}<!--{{serviceTypeID!=58?'Confirmation Number':'Misc Description'}}--></label>
                        <div style="display:flex;margin-top:-0.5em">
                          <input type="text" formControlName="confirmation_number" class="form-control" style="width:13.25em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; background-color:#eaf0fd" />
                        </div>
                      </div>
                      <div style="margin-left:0.5em;" class="d-inline-flex" *ngIf="requiresServiceDate">
                        <div>
                          <label for="service_date" style="font-size:small">{{taskLabel!='' && taskLabel!=null? taskLabel: 'Service'}} Date (UTC)</label>
                          <div style="display:flex;margin-top:-0.5em">
                            <input type="text" [matDatepicker]="servicedatepicker" placeholder="mm/dd/yyyy" formControlName="service_date" #service_date class="form-control" [ngClass]="{ 'is-invalid': submitted && f.service_date.errors }" style="padding: 0.25em; width: 8.5em; height: 1.5625em; font-size: small; background-color:#eaf0fd" maxlength="10" />
                            <mat-datepicker-toggle matSuffix [for]="servicedatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                            <mat-datepicker #servicedatepicker></mat-datepicker>
                          </div>
                        </div>
                        <div>
                          <label for="service_date" style="font-size:small">{{taskLabel!='' && taskLabel!=null? taskLabel: 'Service'}} Time (UTC)</label>
                          <div style="display:flex;margin-top:-0.5em">
                            <input type="text" matInput placeholder="hh:mm" maxlength="5" formControlName="service_time" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.service_time.errors }" style="padding: 0.25em; width: 8.5em; height: 1.5625em; font-size: small; background-color:#eaf0fd" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange($event)" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="submitted && f.service_date.errors">
                      <div *ngIf="f.service_date.errors.isValidDate==false" style="background-color:#f9d2de; padding-left:0.25em; width:10em;margin-top:-0.5em; margin-left:13.5em ; font-size:small;">Invalid service date</div>
                    </div>
                  </div>
                  <!--flight brief-->
                  <div style="margin-left:0.6em" *ngIf="serviceTypeID==34 || serviceTypeID==79 || serviceTypeID==32 || serviceTypeID == 85 || serviceTypeID == 87|| this.serviceTypeID == 83 || this.serviceTypeID == 84 || this.serviceTypeID ==91">
                    <label for="checkFlightBriefStops" style="font-size:small">Included Stops</label>
                    <div style="margin-top:-0.6em;">
                      <mat-select #matFlightBriefStops placeholder="None" panelClass="custom-mat-select-panel4 placeholder1" [formControl]="checkFlightBriefStops" multiple style="border: solid lightgray 0.0625em; font-size: small; color:black; border-radius: 0.25em; padding-left:0.25em; height:1.65em;width:17em; background-color: #eaf0fd;">
                        <mat-select-trigger>
                          <div style="display:flex;">
                            <div *ngFor="let g of selectedGroundStopList; let i=index" style="margin-top:0.4em;margin-left:0em; height:1.35em; padding:0em;display:flex "><div style="margin-top:-0.5em; margin-left:0em; white-space:nowrap;">{{i==0? g.groundStopBriefInfo:', '+g.groundStopBriefInfo}}</div></div>
                          </div>
                        </mat-select-trigger>
                        <div>
                          <mat-option *ngFor="let g of groundStopList" [value]="g" (onSelectionChange)="onGroundStopListChange($event, g)" style="height:1.5em; line-height:1.5em !important;background-color: #eaf0fd;">{{g.groundStopBriefInfo}} </mat-option>
                        </div>
                      </mat-select>
                    </div>
                  </div>
                  <!--flight brief end-->
                  <div style="margin-left:0.6em" *ngIf="vendorReq==true">
                    <label for="acct_status_tp_select" style="font-size:small">Third Party Accounting status</label>
                    <div style="margin-top:-0.5em;">
                      <select *ngIf="f" formControlName="acct_status_tp_select" style="width:17em; font-size: small; font-weight:500; border-radius:0.25em;border-color:lightgrey;background-color:#eaf0fd">
                        <option *ngFor="let a of acctStatus_TPList" [ngValue]="a.accountingStatusGUID">{{a.accountingStatusDescription}}</option>
                      </select>
                    </div>
                  </div>
                  <!--<div style="margin-left:5.85em">
      <div style="display: flex">

        <div style="margin-left:0.5em;margin-top:0em">
          <label for="due_datetime" style="font-size:small">&nbsp;</label>
          <div style="display:flex;margin-top:-0.5em">
            <input type="text" formControlName="due_datetime" maxlength="16" placeholder="mm/dd/yyyy HH:MM" class="form-control" readonly style="width:10em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; " />
          </div>
        </div>
      </div>

    </div>-->

                </div>

              </div>
            <div class="ml-3">
              <div class="d-inline-flex">
                <div style="margin-top:1em; margin-left:-0.55em; white-space: nowrap; display:flex">
                  <!--<button mat-button class="btn-high" style="padding-left:0.25em !important; padding-right:0.25em !important" (click)="clickContact()" *ngIf="selectedTaskStatusGUID!='6aaa0374-7279-4edb-84cb-927918a85584'"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em; width:1.125em">contact_page</i><div>Contacts</div></div></button>&nbsp;-->
                  <button mat-button class="btn-high" style="margin-right: 1em;"
                  (click)="clickEmail()">
                  <i class="material-symbols-outlined" style="font-size: medium;">
                    send
                  </i>
                  Email
                </button>
                  <button *ngIf="serviceTypeID==34" mat-button class="btn-high" style="padding-left: 0.25em !important; padding-right: 0.25em !important;" (click)="clickEmailSelection()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em;width:1.5em ">contact_mail</i><div>Pre Selected Comms</div></div></button>&nbsp;
                  <!--<button mat-button class="btn-high" style="padding-left: 0.25em !important; padding-right: 0.25em !important" (click)="generateMessageTemplate()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">description</i><div>Templates</div></div></button>&nbsp;-->

                </div>
                <!--<div style="margin-left:0.5em"  *ngIf="!(serviceTypeID==34 || serviceTypeID==79 || serviceTypeID==32 || serviceTypeID == 85 || serviceTypeID == 87|| this.serviceTypeID == 83 || this.serviceTypeID == 84 || this.serviceTypeID ==91)">
      <label for="acct_status_tp_select" style="font-size:small">Trip Leg</label>
      <div style="margin-top:-0.5em;">
        <select *ngIf="f" formControlName="trip_leg" style="font-size: small;background-color:#eaf0fd; width:17em; border-radius:0.25em;">
          <option *ngFor="let g of groundStopList" [ngValue]="g">{{g.groundStopBriefInfo}}</option>
        </select>
      </div>
    </div>-->
              </div>
              <div style="font-size:small"><!--message count--></div>
              <div *ngIf="serviceTypeID==3 || serviceTypeID==88 || serviceTypeID==5 || serviceTypeID==26" style="margin-top:1.4em; margin-left:-0.5em">
                <button mat-button class="btn-high" (click)="openVendorDetails()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">info</i><div>Details</div></div></button>&nbsp;
                <!--<button mat-button class="btn-high" (click)="addNewVendor()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">library_add</i><div>Add New Vendor</div></div></button>-->


              </div>
              <div *ngIf="serviceTypeID==54 || serviceTypeID==59 || serviceTypeID==67" style="margin-left: -0.55em; margin-top: .5em">
                <button mat-button class="btn-high" (click)="generateGenDec()">Create Gen Dec</button>
              </div>
              <div *ngIf="serviceTypeID==50" style="margin-left: -0.55em;margin-top:.5em">
                <button mat-button class="btn-high" (click)="generateApis91()">Create APIS</button>
              </div>
              <div *ngIf="serviceTypeID==51" style="margin-left: -0.55em; margin-top: .5em">
                <button mat-button class="btn-high" (click)="generateApis135()">Create APIS</button>
              </div>
              <div *ngIf="serviceTypeID==53" style="margin-left: -0.55em; margin-top: .5em">
                <button mat-button class="btn-high" (click)="generateApisCaricom()">Create APIS</button>
              </div>
              <div *ngIf="serviceTypeID==52 || serviceTypeID==102" style="margin-left: -0.55em; margin-top: .5em">
                <button mat-button class="btn-high" [disabled]="isGARLoading" (click)="generateMexicoAPIS()"><span *ngIf="isGARLoading" class="spinner-border spinner-border-sm mr-1"></span>Create APIS</button>
              </div>
              <div *ngIf="serviceTypeID==101" style="margin-left: -0.55em; margin-top: .5em">
                <button mat-button class="btn-high" [disabled]="isGARLoading" (click)="generateFuelQuote()"><span *ngIf="isGARLoading" class="spinner-border spinner-border-sm mr-1"></span>Create Quote Template</button>
              </div>
              <div style="display: flex; margin-left: -0.55em" *ngIf="serviceTypeID==1 || serviceTypeID==71">
                <div style="margin-top:1.25em;">
                  <button mat-button class="btn-high" (click)="openVendorDetails()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">info</i><div>Details</div></div></button>&nbsp;
                </div>
                <!--<div style="margin-top:1.25em" *ngIf="serviceTypeID==1">
      <button mat-button class="btn-high" (click)="addNewVendor()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">library_add</i><div>Add New FBO / Handler</div></div></button>
    </div>-->
              </div>
              <!--<div style="margin-top:.5em;margin-left:-0.55em;background-color:#E9ECEF;border-radius:.25em" class="p-1" *ngIf="serviceTypeID==1 &&  f.fbo_select.value != null && f.fbo_select.value != '' && f.fbo_select.value !='4383ada6-2161-49e6-965f-47fc67fa5cd4'">
    <div class="d-flex justify-content-center">
      <label style="font-size:x-small">Handling Notes</label>
    </div>
    <div style="margin-top:-.5em" class="d-flex justify-content-center">
      <button mat-button class="btn-high" style="font-size:x-small !important; padding-left:0.25em !important; padding-right:0.25em !important" (click)="generateHandlingNotes('pdf')"><span *ngIf="loadingPDF" class="spinner-border spinner-border-sm mr-1"></span>PDF</button>
      <button mat-button class="btn-high ml-1" style="font-size:x-small !important; padding-left:0.25em !important; padding-right:0.25em !important" (click)="generateHandlingNotes('word')"><span *ngIf="loadingWord" class="spinner-border spinner-border-sm mr-1"></span>Word</button>
      <button mat-button class="btn-high ml-1" style="font-size:x-small !important; padding-left:0.25em !important; padding-right:0.25em !important" (click)="generateHandlingNotes('notes')">Add to Notes</button>
    </div>
  </div>-->
              <div style="margin-top:1em;margin-left:-0.55em;" class="p-1" *ngIf="serviceTypeID==34 || serviceTypeID==79 || serviceTypeID==32 || serviceTypeID == 85 || serviceTypeID == 87">

                <div class="d-flex justify-content-center">
                  <button mat-button class="btn-high" (click)="setupFlightBrief()"><span *ngIf="serviceTypeID==34">Build Flight Brief</span><span *ngIf="serviceTypeID==79">Build Trip Brief</span><span *ngIf="serviceTypeID==32 || serviceTypeID == 85 || serviceTypeID == 87">Build Wx Outlook</span></button>
                </div>
                <!--<div style="margin-top:-.5em" class="d-flex justify-content-around">-->
                <!--<button class="btn btn-primary pl-3 pr-2" style="font-size:x-small" (click)="selectWeatherCharts()" matBadge="{{weatherChartIds.length}}" matBadgePosition="before" matBadgeColor="warn">Wx Charts</button>-->
                <!--<button [disabled]="loadingFlightBrief" class="btn btn-primary ml-1 px-2" style="font-size:x-small" (click)="generateFlightBrief('pdf')"><span *ngIf="loadingPDF" class="spinner-border spinner-border-sm mr-1"></span>PDF</button>-->
                <!--<button [disabled]="loadingFlightBrief" class="btn btn-primary ml-1 px-2" style="font-size:x-small" (click)="generateFlightBrief('word')"><span *ngIf="loadingWord" class="spinner-border spinner-border-sm mr-1"></span>Word</button>-->
                <!--<button [disabled]="loadingFlightBrief" class="btn btn-primary ml-1 px-2" style="font-size:x-small" (click)="setupFlightBrief()">Setup/Merge</button>-->
                <!--<button [disabled]="loadingFlightBrief" class="btn btn-primary ml-1 px-2" style="font-size:x-small" (click)="compileFlightBrief()">Build Brief</button>-->
                <!--</div>
    <mat-progress-bar class="mt-1" *ngIf="loadingFlightBrief" mode="indeterminate"></mat-progress-bar>-->
              </div>
              <div *ngIf="serviceTypeID==80 || serviceTypeID==81" style="margin-left: -0.55em">
                <button mat-button class="btn-high" [disabled]="isGARLoading" (click)="generateUKGAR()"><span *ngIf="isGARLoading" class="spinner-border spinner-border-sm mr-1"></span>Create UK GAR</button>
              </div>
              <div style="margin-top:.5em;margin-left:-0.55em;background-color:#E9ECEF;border-radius:.25em; width:10.5em" class="p-1" *ngIf="serviceTypeID==82">
                <div class="d-flex justify-content-center">
                  <label style="font-size:x-small">Remote Clearance Form</label>
                </div>
                <div style="margin-top:-.5em" class="d-flex justify-content-around">
                  <button mat-button class="btn-high ml-1 pl-1 pr-1" style="font-size:x-small" [disabled]="loadingWord" (click)="generateRemoteClearance('word')"><span *ngIf="loadingWord" class="spinner-border spinner-border-sm mr-1"></span>Word</button>
                  <button mat-button class="btn-high pl-1 pr-1" style="font-size:x-small" [disabled]="loadingPDF" (click)="generateRemoteClearance('pdf')"><span *ngIf="loadingPDF" class="spinner-border spinner-border-sm mr-1"></span>PDF</button>
                </div>
              </div>
              <div style="margin-top:.5em;margin-left:-0.55em;background-color:#E9ECEF;border-radius:.25em; width:10.5em" class="p-1" *ngIf="serviceTypeID==73">
                <div class="d-flex justify-content-center">
                  <label style="font-size:x-small">Canpass Form</label>
                </div>
                <div style="margin-top:-.5em" class="d-flex justify-content-around">
                  <button mat-button class="btn-high ml-1 pl-1 pr-1" style="font-size:x-small" [disabled]="loadingWord" (click)="generateCanpass('word')"><span *ngIf="loadingWord" class="spinner-border spinner-border-sm mr-1"></span>Word</button>
                  <button mat-button class="btn-high pl-1 pr-1" style="font-size:x-small" [disabled]="loadingPDF" (click)="generateCanpass('pdf')"><span *ngIf="loadingPDF" class="spinner-border spinner-border-sm mr-1"></span>PDF</button>
                </div>
              </div>
            </div>
          </div>
          <div style="width:95%">
            <app-flight-plan-preference-display *ngIf="serviceTypeID==35" [tripCodeGUID]="tripCodeGUID" [groundStopTaskGUID]="groundStopTaskGUID" [groundStopGUID]="groundStopGUID" [nextGroundStopGUID]="nextGroundStopGUID"></app-flight-plan-preference-display>
          </div>
          <div style="display: flex; justify-content: space-between;">
            <div class="form-inline" style="margin-top:0.5em; float:left; height:1.5em">
              <div>
                <ul class="tabul">
                  <li [ngClass]="{ 'btn-high':selectedTabName=='public','btn-low pointer':selectedTabName!='public'  }" style="border-left: black 0.0625em solid;" (click)="clickTab('public')"><div style="display: flex; "><div style="width: 6.25em">Public</div><div *ngIf="f.public_note.value!=''" style="width:1em"><i class="material-icons" style="font-size: small;cursor: pointer">notes</i></div></div></li>
                  <!--<li *ngIf="vendorReq==true" [ngClass]="{ 'btn-high':selectedTabName=='vendor','btn-low pointer':selectedTabName!='vendor'  }" (click)="clickTab('vendor')"><div style="display: flex; "><div style="width: 6.25em">Vendor</div><div *ngIf="f.vendor_note.value!=''" style="width:1em"><i class="material-icons" style="font-size: small;cursor: pointer">notes</i></div></div></li>-->
                  <li [ngClass]="{ 'btn-high':selectedTabName=='internal','btn-low pointer':selectedTabName!='internal' }" (click)="clickTab('internal')"><div style="display: flex; "><div style="width: 6.25em">Internal</div><div *ngIf="f.internal_note.value!=''" style="width:1em"><i class="material-icons" style="font-size: small;cursor: pointer">notes</i></div></div></li>
                  <li *ngIf="serviceTypeID==34 || serviceTypeID==79" [ngClass]="{ 'btn-high':selectedTabName=='important_note','btn-low pointer':selectedTabName!='important_note' }" (click)="clickTab('important_note')"><div style="display: flex; "><div style="width: 7.25em">Important Note</div><div *ngIf="f.important_note.value!=''" style="width:1em"><i class="material-icons" style="font-size: small;cursor: pointer">notes</i></div></div></li>
                  <li *ngIf="serviceTypeID==34 || serviceTypeID==32 || serviceTypeID == 85 || serviceTypeID == 87" [ngClass]="{ 'btn-high':selectedTabName=='weather','btn-low pointer':selectedTabName!='weather' }" (click)="clickTab('weather')"><div style="display: flex; "><div style="width: 6.25em">Weather</div><div *ngIf="f.synopsis_note.value!='' || f.turbulence_note.value!='' || f.terminal_note.value!='' || f.metars_note.value!='' || f.sigmets_note.value!='' || f.airmets_note.value!='' || f.pireps_note.value!='' || f.tropical_note.value!=''|| f.va_note.value!=''" style="width:1em"><i class="material-icons" style="font-size: small;cursor: pointer">notes</i></div></div></li>
                  <li *ngIf="serviceTypeID==34 || serviceTypeID==32 || serviceTypeID == 85 || serviceTypeID == 87" [ngClass]="{ 'btn-high':selectedTabName=='notams','btn-low pointer':selectedTabName!='notams' }" (click)="clickTab('notams')"><div style="display: flex; "><div style="width: 6.25em">Notams</div><div *ngIf="f.departure_note.value!='' || f.destination_note.value!='' || f.etp_note.value!='' || f.fdc_note.value!='' || f.track_note.value!='' || f.tfr_notams.value!=''" style="width:1em"><i class="material-icons" style="font-size: small;cursor: pointer">notes</i></div></div></li>
                  <li *ngIf="serviceTypeID==35" [ngClass]="{ 'btn-high':selectedTabName=='route','btn-low pointer':selectedTabName!='route' }" (click)="clickTab('route')"><div style="display: flex; "><div style="width: 7.25em">Route</div><div *ngIf="f.route_note.value!=''" style="width:1em"><i class="material-icons" style="font-size: small;cursor: pointer">notes</i></div></div></li>
                </ul>
              </div>

            </div>
            <div *ngIf="(serviceTypeID==3 && hasOverflightPermitDocument) || ((serviceTypeID==5 || serviceTypeID==26) && hasLandingPermitDocument)" style="margin-left:10.25em">
              <button mat-button class="btn-high" (click)="generateOverflight()">Generate Permit Request</button>
            </div>
            <div style="float: right; margin-right:2.5em;">
              <div class="d-inline-flex">

                <div *ngIf="selectedTabName=='public' && serviceTypeID==35" (click)="clearFPNote()"><button mat-button class="btn-high">Clear Note</button></div>
                <button type="button" mat-button color="primary" style="outline:none; font-size: small" [matMenuTriggerFor]="menu">Export</button>
                <mat-menu #menu="matMenu">
                  <div class="example-box" (click)="exportNote('word')">Word</div>
                  <div class="example-box" (click)="exportNote('pdf')">PDF</div>
                  <div class="example-box" (click)="exportNote('text')">Text</div>
                  <div class="example-box" [cdkCopyToClipboard]="clickCopyNote()">Copy to Clipboard</div>
                </mat-menu>
              </div>
            </div>
            <!--<div style="margin-top: 0.75em;float: right; margin-right:3em; height:1em; font-size: small; display: flex"><i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium" title="Copy note to clipboard" [cdkCopyToClipboard]="clickCopyNote()">content_copy</i><div [cdkCopyToClipboard]="clickCopyNote()" style="cursor: pointer">Copy Note to Clipboard</div></div>-->
          </div>

          <div>
            <textarea [hidden]="selectedTabName!='public'" formControlName="public_note" class="form-control" style="width:65em; height:23.5em;font-family:Courier New, Courier, monospace;font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd"></textarea>
            <textarea [hidden]="selectedTabName!='vendor'" formControlName="vendor_note" class="form-control" style="width:65em; height:23.5em;font-family:Courier New, Courier, monospace; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd"></textarea>
            <textarea [hidden]="selectedTabName!='internal'" formControlName="internal_note" class="form-control" style="width:65em; height:23.5em;font-family:Courier New, Courier, monospace; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd"></textarea>
            <textarea [hidden]="selectedTabName!='important_note'" formControlName="important_note" class="form-control" style="width:65em; height:23.5em;font-family:Courier New, Courier, monospace; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd"></textarea>
            <div *ngIf="selectedTabName=='weather'" class="border" style="width:52.9em">
              <mat-tab-group animationDuration="0ms" [selectedIndex]="selected.value"
                             (selectedIndexChange)="selected.setValue($event)" [color]="primary" mat-align-tabs="start">
                <mat-tab *ngIf="serviceTypeID==32 || serviceTypeID == 85 || serviceTypeID == 87">
                  <ng-template mat-tab-label>
                    Synopsis
                    <mat-icon *ngIf="f.synopsis_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" formControlName="synopsis_note" class="form-control" style="width:64.8em;font-family:Courier New, Courier, monospace;font-size:small; padding:0em 14em 0em .25em;background-color:#eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
                <mat-tab *ngIf="serviceTypeID==32 || serviceTypeID == 85 || serviceTypeID == 87">
                  <ng-template mat-tab-label>
                    Turbulence
                    <mat-icon *ngIf="f.turbulence_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" formControlName="turbulence_note" class="form-control" style="width: 64.8em;font-family: Courier New, Courier, monospace; font-size: small; padding: 0em 14em 0em 0.25em; background-color: #eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    Terminal Forecasts
                    <mat-icon *ngIf="f.terminal_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" formControlName="terminal_note" class="form-control" style="width: 64.8em; font-family: Courier New, Courier, monospace; font-size: small; padding: 0em 14em 0em 0.25em; background-color: #eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
                <mat-tab *ngIf="serviceTypeID==34">
                  <ng-template mat-tab-label>
                    METARs
                    <mat-icon *ngIf="f.metars_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" formControlName="metars_note" class="form-control" style="width: 64.8em; font-family: Courier New, Courier, monospace; font-size: small; padding: 0em 14em 0em 0.25em; background-color: #eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
                <mat-tab *ngIf="serviceTypeID==34">
                  <ng-template mat-tab-label>
                    SIGMETs
                    <mat-icon *ngIf="f.sigmets_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" formControlName="sigmets_note" class="form-control" style="width: 64.8em;  font-family: Courier New, Courier, monospace; font-size: small; padding: 0em 14em 0em 0.25em; background-color: #eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
                <mat-tab *ngIf="serviceTypeID==34">
                  <ng-template mat-tab-label>
                    AIRMETs
                    <mat-icon *ngIf="f.airmets_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" formControlName="airmets_note" class="form-control" style="width: 64.8em;  font-family: Courier New, Courier, monospace; font-size: small; padding: 0em 14em 0em 0.25em; background-color: #eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
                <mat-tab *ngIf="serviceTypeID==34">
                  <ng-template mat-tab-label>
                    PIREPs
                    <mat-icon *ngIf="f.pireps_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" formControlName="pireps_note" class="form-control" style="width: 64.8em; font-family: Courier New, Courier, monospace; font-size: small; padding: 0em 14em 0em 0.25em; background-color: #eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    Tropical Advisory
                    <mat-icon *ngIf="f.tropical_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" formControlName="tropical_note" class="form-control" style="width: 64.8em;font-family: Courier New, Courier, monospace; font-size: small; padding: 0em 14em 0em 0.25em; background-color: #eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    VA Advisory
                    <mat-icon *ngIf="f.va_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" formControlName="va_note" class="form-control" style="width: 64.8em;  font-family: Courier New, Courier, monospace; font-size: small; padding: 0em 14em 0em 0.25em; background-color: #eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
                <mat-tab *ngIf="f.weather_note.value!=''">
                  <ng-template mat-tab-label>
                    Weather Note
                    <mat-icon *ngIf="f.weather_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" readonly formControlName="weather_note" class="form-control" style="width: 64.8em;  font-family: Courier New, Courier, monospace; font-size: small; padding: 0em 14em 0em 0.25em; background-color: #eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </div>
            <div *ngIf="selectedTabName=='notams'" class="border" style="width: 52.9em">
              <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedNotams.value"
                             (selectedIndexChange)="selectedNotams.setValue($event)" [color]="primary" mat-align-tabs="start">
                <mat-tab>
                  <ng-template mat-tab-label>
                    Departure Airport
                    <mat-icon *ngIf="f.departure_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" formControlName="departure_note" class="form-control" style="width: 64.8em; font-family: Courier New, Courier, monospace; font-size: small; padding: 0em 14em 0em 0.25em; background-color: #eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    Destination/Alternate Airports
                    <mat-icon *ngIf="f.destination_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" formControlName="destination_note" class="form-control" style="width: 64.8em;  font-family: Courier New, Courier, monospace; font-size: small; padding: 0em 14em 0em 0.25em; background-color: #eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    ETP Airports
                    <mat-icon *ngIf="f.etp_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" formControlName="etp_note" class="form-control" style="width:64.8em;font-family:Courier New, Courier, monospace; font-size:small; padding:0em 14em 0em 0.25em;background-color:#eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    FDC Notams
                    <mat-icon *ngIf="f.fdc_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" formControlName="fdc_note" class="form-control" style="width:64.8em;font-family:Courier New, Courier, monospace; font-size:small; padding:0em 14em 0em 0.25em;background-color:#eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    TFR Notams
                    <mat-icon *ngIf="f.tfr_notams.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" formControlName="tfr_notams" class="form-control" style="width:64.8em; font-family:Courier New, Courier, monospace; font-size:small; padding:0em 14em 0em 0.25em;background-color:#eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
                <mat-tab *ngIf="serviceTypeID==34">
                  <ng-template mat-tab-label>
                    Track Message
                    <mat-icon *ngIf="f.track_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" formControlName="track_note" class="form-control" style="width:64.8em;font-family:Courier New, Courier, monospace; font-size:small; padding:0em 14em 0em 0.25em;background-color:#eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
                <mat-tab *ngIf="f.notams_note.value!=''">
                  <ng-template mat-tab-label>
                    Notams Note
                    <mat-icon *ngIf="f.notams_note.value!=''" class="example-tab-icon">notes</mat-icon>
                  </ng-template>
                  <ng-template matTabContent>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="14"
                              cdkAutosizeMaxRows="{{maxSize}}" readonly formControlName="notams_note" class="form-control" style="width:64.8em; font-family:Courier New, Courier, monospace; font-size:small; padding:0em 14em 0em 0.25em;background-color:#eaf0fd"></textarea>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>

            </div>
            <textarea [hidden]="selectedTabName!='route'" formControlName="route_note" class="form-control" style="width:65em; height:23.5em;font-family:Courier New, Courier, monospace; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd"></textarea>
          </div>
          <div style="display: flex; margin-top:0.5em">
            <div>
              <div style="width:45em; min-height:2em; background-color: #E9ECEF; border-radius:0.25em; font-size: small; padding-left: 0.25em ">
                <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; max-width:40em; "><label style="color:black; font-size: small">{{errMsg}}</label></div>
                <div style="width:100%" class="d-inline-flex justify-content-between">
                  <div style="display: flex; margin-top:0.25em;">
                    Documents: <div style="margin-left:0.25em;margin-top:0.125em" *ngIf="!lockdownTrip">
                      <label class="btn-high" mat-button style="border: 0.0625em solid #7095c8; height:1.75em; border-radius: 0.25em; cursor:pointer">
                        <input #document formControlName="document" id="document" type="file" ng2FileDrop [uploader]="uploader" multiple style="display:none" accept="*" (change)="importFile($event)" maxFileSize="28000000" (click)="onFileClick($event)" />
                        <div style="display: flex">
                          <i class="material-icons" style="font-size: medium; margin-top:0.25em; color: white; transform: rotate(-90deg);">attachment</i>
                        </div><span *ngIf="!finishAllUploads" class="spinner-border spinner-border-sm mr-1"></span>
                      </label>
                    </div>
                    <mat-slide-toggle style="margin-left:.5em;transform:scale(.8);font-size:medium" (change)="toggleDeleted()"
                                      color="primary">Show Deleted
                    </mat-slide-toggle>

                    <!--<div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; width:28em; "><label style="color:black; font-size: small">{{errMsg}}</label></div>-->
                  </div>
                  <div style="font-size:x-small; margin-left:1.5em; margin-right:0.5em;margin-top:.25em">
                    Allowed file types: {{allowedFileType}}
                  </div>
                </div>
                <!--document list-->
                <div style="height:5em;overflow-y:auto" ng2FileDrop [uploader]="uploader" (onFileDrop)="dropFile($event)">
                  <!--<div *ngFor="let item of taskDocumentList; let i=index" style="margin-top:-0.25em">
                    <div style="font-size: small; display:flex; padding:0em;">
                      <div><a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline" title="{{item.documentName}}" (click)="clickPreviewDocument($event, item)">{{item.documentName}}</a></div>
                      <div *ngIf="item.groundStopTaskDocumentGUID=='' && hasExistingDocument" style="margin-top:0.5em; font-size:x-small"><i class="material-icons" style="color:green; font-size: small; ">fiber_new</i></div>
                      <div style="margin-top:0.25em; margin-left: 0.25em" *ngIf="!lockdownTrip"><i class="material-icons" style="color:red; font-size: small; cursor: pointer;" title="click to remove the document" (click)="clickRemoveDocument($event, item)">cancel</i></div>
                    </div>
                  </div>-->
                  <div *ngFor="let item of taskDocumentList; let i=index" style="margin-top:-0.25em">
                    <div style="font-size: small; display:flex; padding:0em; height: 2em;">
                      <div>
                        <a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline"
                           title="{{item.documentName}}"
                           (click)="clickPreviewDocument($event, item)">{{item.documentName}}</a>
                      </div>
                      <div *ngIf="item.groundStopTaskDocumentGUID=='' && hasExistingDocument"
                           style="margin-top:0.5em; font-size:x-small">
                        <i class="material-icons" style="color:green; font-size: small; ">fiber_new</i>
                      </div>
                      <div style="margin-top:0.25em; margin-right: 0.25em" *ngIf="!lockdownTrip && item.isActive">
                        <i class="material-icons" style="color:red; font-size: small; cursor: pointer;"
                           title="Delete file"
                           (click)="clickRemoveDocument(item)">cancel</i>
                      </div>
                      <div *ngIf="!item.isActive" title="Undelete file"
                           style="cursor: pointer; border-radius:0.25em; margin-left:.25em; height: 1.5em; user-select: none; padding: 0 .25em 0 .25em"
                           class="d-block alert-danger" (click)="clickRemoveDocument(item)">Deleted</div>
                      <div *ngIf="!item.isInternal && item.isActive" title="Toggle Public/Internal"
                           style="cursor: pointer; user-select: none;" (click)="toggleInternalFile(item)">
                        <i class="material-symbols-outlined">more_horiz</i>
                      </div>
                      <div *ngIf="item.isInternal && item.isActive" title="Toggle Public/Internal"
                           style="cursor: pointer; height: 1.5em; border-radius: 0.25em; user-select: none; padding: 0 .25em 0 .25em "
                           class="d-block alert-warning" (click)="toggleInternalFile(item)">Internal</div>
                    </div>
                  </div>
                </div>
                <!--document list end-->
              </div>
            </div>
            <div>
              <div style="padding-left: 0.5em; ">
                <div style="margin-top:0em; margin-right:0.625em; display: flex">
                  <!--<div>
                    <label for="revision_counter" style="font-size:small">Revision Counter</label>
                    <div style="margin-top:-0.5em;">
                      <select *ngIf="f" formControlName="revision_counter" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em;width: 7em">
                        <option value="0" selected>No Revision</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                  </div>-->
                  <div style="width:7.75em">
                    <label for="record_status" style="font-size:small">Disable Record</label><!--<div style="font-size:small; margin-top:-1em">Record</div>-->
                    <div style="margin-top:-0.5em;">
                      <select *ngIf="f" formControlName="record_status" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em;width: 7em" (change)="record_statusChange($event)">
                        <option value="1" selected>No</option>
                        <option value="0">Yes</option>
                      </select>
                    </div>
                  </div>
                  <div style="margin-right:0.5em">
                    <label for="immediateAction" style="font-size:small">Immediate Action</label>
                    <div style="margin-top:-0.5em;">
                      <select *ngIf="f" formControlName="immediateAction" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em;width: 7em" >
                        <option value="0" selected>No</option>
                        <option value="1">Yes</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="margin-right:0.5em">
                    <label for="modifiedBy" style="font-size:small">Modified By</label>
                    <div style="margin-top:-0.35em;">
                      <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:9em;height:1.5625em;font-size:small;padding:0" />
                    </div>
                  </div>
                  <div>
                    <label for="modifiedDate" style="font-size:small">Modified Date</label>
                    <div style="margin-top:-0.35em;">
                      <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:9em;height:1.5625em;font-size:small;padding:0" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        </div>
    </form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display:flex; height:2.75em">
    <div>
      <button mat-button class="btn-high" *ngIf="(!lockdownTrip && !showSpin) || (lockdownTrip && !showSpin &&  grantAccessToTripCloseout)" [disabled]="loading" (click)="clickSave()"><div style="display:flex"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>Save</div></div></button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white;"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>&nbsp;
      <button *ngIf="businessRuleID==0 && ground_stop_task_edit_form" class="btn-low" mat-button (click)="clickPromoteBusinessRule()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">grading</i><div>Promote Business Rule</div></div></button>&nbsp;
      <button mat-button class="btn-low" *ngIf="hasTaskAudit==true" (click)="clickAudit()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">manage_search</i><div>Audit</div></div></button>&nbsp;      
      <!-- <button *ngIf="hasFeedback && selectedTaskStatusGUID.toLowerCase() == 'ae2ee6b7-437a-4f5a-9bdb-0e0a810de2a8'" mat-button class="btn-high" (click)="clickFeedback()" style="background-color:grey; color: white;"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">message</i><div>Send Feedback</div></div></button>&nbsp; -->
      
    </div>    
    <div *ngIf="errMsg2!=''" class="d-block" style="margin-top:0em; margin-left:0.25em; display:flex; background-color:#f9d2de; padding-left:0.25em; width:24em;font-size: small ">{{errMsg2}}</div>
    <div *ngIf="showSuccessMsg" style="margin-top: 0em;">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
  </div>

</div>
<app-spin *ngIf="showSpin"></app-spin>
