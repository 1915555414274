import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Component, Inject, Injector, OnInit, QueryList, ViewChild, ViewChildren, Optional } from '@angular/core';
import { CategoryModel } from '../models/category.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CONTAINER_DATA, HelpGuideService } from '../services/help-guide.service';
import { HelpGuideTopicsComponent } from './help-guide-topics.component';
import { HelpGuidesComponent } from './help-guides.component';
import { MessageService } from '../services/message.service';
import { MessageModel } from '../models/message.model';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgbAccordion, NgbPanel } from '@ng-bootstrap/ng-bootstrap';
import { HelpDashboardClientComponent } from '../help-guides/help-dashboard-client.component';

@Component({
  selector: 'app-help-guide-categories',
  templateUrl: './help-guide-categories.component.html',
  styleUrls: ['./help-guide-categories.component.css']
})

export class HelpGuideCategoriesComponent implements OnInit {
  categoryList: CategoryModel[];
  topicPortal: ComponentPortal<HelpGuideTopicsComponent>;
  type: string;
  feedbackMsg: string;
  activePanels: string = "custom-panel-1,fbPanel";
  showSuccessMsg: boolean = false;
  isDialog: boolean = false;

  @ViewChild(NgbAccordion) accordion: NgbAccordion;

  constructor(private readonly _authService: AuthenticateService, private readonly _helpGuideService: HelpGuideService,
    private readonly _helpGuide: HelpGuidesComponent, private _injector: Injector, @Inject(CONTAINER_DATA) public componentData: any,
    private readonly _messageService: MessageService, private readonly _dialogRef: MatDialogRef<HelpGuideCategoriesComponent>, private readonly _dialog: MatDialog, @Optional() @Inject(MAT_DIALOG_DATA) private data: any) {
    this.type = componentData;
    if (this.data) {
      this.isDialog = true;
    }
  }
  ngOnInit() {
    this._dialogRef.afterClosed().subscribe(() => {
      // Perform any cleanup or state reset
      this.isDialog = false; // Example: Reset dialog state
    });
    this._authService.updateAccessTime();
    let audienceID;
    if (localStorage.getItem('ut').toLowerCase() == 'i')
      audienceID = 1;
    else
      audienceID = 2;
    if (this.type == 'client')
      audienceID = 2;
    this._helpGuideService.getCategoryListByAudienceID<ResponseModel<CategoryModel[]>>(audienceID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.categoryList = response.model;
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }

        }
      }
    });
  }

  showDetails(category: CategoryModel) {
    this.topicPortal = new ComponentPortal(HelpGuideTopicsComponent, null, this.createInjector(category));
    this._helpGuide.selectedPortal = this.topicPortal;

  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

  submitFeedback() {
    if (!this.feedbackMsg) return;
    let email: MessageModel = new MessageModel();
    email.body = this.feedbackMsg;
    email.subject = "Feedback from FPC";

    this._messageService.sendFeedbackEmail<ResponseModel<boolean>>(email).subscribe(resp => {

      if (resp.code == "200" && resp.model) {
        this.showSuccessMsg = true;
        setTimeout(() => {
          this.showSuccessMsg = false;
          this.feedbackMsg = '';
          this.accordion.collapse("fbPanel");
        }, 2000);
      }
      else
        alert("feedback not sent");
    });
  }


  openHelpDashboardClientComponent() {
    // Close the current Help Guides dialog
    this._dialogRef.close();

    // Close any existing Help Dashboard dialogs
    this._dialog.openDialogs.forEach(dialog => {
      if (dialog.componentInstance instanceof HelpDashboardClientComponent) {
        dialog.close();
      }
    });

    // Open a new Help Dashboard
    setTimeout(() => {
      const config = new MatDialogConfig();
      config.panelClass = "custom-dialog-container3";
      config.height = "18.5em";
      config.width = "45em";
      config.restoreFocus = false;
      config.hasBackdrop = false;
      config.data = { v: this._authService.getCurrentTimeNumber(), isDialog: true };
      config.position = { left: "20px", top: "138px" };
      this._dialog.open(HelpDashboardClientComponent, config);
    }, 200); // Add delay to ensure dialogs are closed before opening a new one
  }

  closeHelpGuidesAndGoBack() {
    this._dialogRef.close();
  }

  

}
