<div class="divLeft" style=" margin-top: 0em;" [ngClass]="isDialog? 'greyBack':''" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div>
    <div>
      <div style="border-radius: 0.25em; padding: 0.1em 1em 0em 0.1em; width: 100%; display: inline-block; max-height: 5em; ">
        <div style="display: flex; justify-content: space-between;">
          <div style="display: flex; flex-direction: column; margin-left: -1em;">
            <div style="display: inline-flex;">
              <i class="material-icons-outlined" style=" margin-top: 0.2em; margin-left: 1em; margin-right: 1em;">admin_panel_settings</i>
              <h4>Admin Services</h4>
            </div>

          </div>
          <div style="display: flex; justify-content: flex-end; margin-right: -1em;">
            <button mat-icon-button mat-dialog-close style="background: none; box-shadow: none;">
              <i class="material-icons" style="font-size: x-large;">close</i>
            </button>
          </div>
        </div>
        <div>
        </div>

      </div>
    </div>


    <div style="display: flex; flex-direction: column; background-color: white; margin: 0em 0.4em 0.3em 0.3em;width:41em;">
      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 21em; background-color: #f2faff !important; color: Black !important; text-align: left; padding: 0.5em 1em;"
                (click)="openRegisterUserComponent()">
          <i class="material-symbols-outlined" style="float: left;">Settings_account_box</i>
          <span style="margin-left: 1.0em; font-weight: 500; font-size: 1.2em;">Add New User</span>
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;margin-top:0.5em;">
          Create a new user with administrative or standard access
        </div>
      </div>



      <div style="display: inline-flex; align-items: center; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 21em; background-color: #f2faff !important; color: Black !important; display: flex; align-items: center; padding-left: 1em;" (click)="
  openResetPasswordDialog()">
          <i class="material-symbols-outlined" style="margin-right: 0.5em;">vpn_key</i>
          <span style="font-weight: 500; font-size: 1.2em;">Reset Password</span>
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Full access to resetting user accounts
        </div>
      </div>

      <div style="display: inline-flex; align-items: center; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 21em; background-color: #f2faff !important; color: Black !important; display: flex; align-items: center; padding-left: 1em;" (click)="openUserPermissionClientComponent()">
          <i class="material-symbols-outlined" style="margin-right: 0.5em;">folder_supervised</i>
          <span style="font-weight: 500; font-size: 1.2em;">User Access & Permissions</span>
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Manage user permissions and deactivate accounts
        </div>
      </div>

    </div>

  </div>
</div>
