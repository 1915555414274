import { Component, Input, OnInit, Output, Optional, Inject } from '@angular/core';
import { AuthenticateService } from '../services/authenticate.service';
import { MessageFolderModel } from '../models/message-folder.model'
import { MessageService } from '../services/message.service';
import { ResponseModel } from '../models/response.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { DialogService } from '../services/dialog.service';
import { EmailClientDialogComponent } from './email-client-dialog.component';


@Component({
  selector: 'app-message-center-client',
  templateUrl: './message-center-client.component.html',
  styleUrls: ['./message-center-client.component.css']
})
export class MessageCenterClientComponent implements OnInit {
  messageFolderList: MessageFolderModel[];
  hasChanges: boolean = false;
  showSearch: boolean = false;
  timeFrame: number = 2;
  grantAccessToMessages: boolean;
  showNewFolderInput: boolean;
  folderName: string;
  folderExist: boolean;
  isDialog: boolean = false;
  constructor(private readonly _authService: AuthenticateService, private readonly _messageService: MessageService, private readonly _dialog: MatDialog, private readonly _dialogService: DialogService, @Optional() @Inject(MAT_DIALOG_DATA) private data: any, @Optional() private readonly _dialogRef: MatDialogRef<MessageCenterClientComponent>) {
    if (this.data) {
      this.isDialog = true;
    }

  }

  ngOnInit(): void {
    this._authService.updateAccessTime();
    this.grantAccessToMessages = false;
    if (localStorage.getItem('up.msg') == 'true') {
      this.grantAccessToMessages = true;
    }


    if (!this.grantAccessToMessages) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.messageFolderList = [];
    this._messageService.getMessageFolderList<ResponseModel<MessageFolderModel[]>>().subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.messageFolderList = response.model;
          this.messageFolderList[0].selected = true;
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
  }
  filterMessageList(mf: MessageFolderModel) {
    
    this.messageFolderList.forEach(v => {
      if (v.messageFolderID == mf.messageFolderID)
        v.selected = true;
      else
        v.selected = false;
    });
    this.hasChanges = !this.hasChanges;
  }

  newMessage() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", messageGUID: "", isDraft: false, v: this._authService.getCurrentTimeNumber()
    };
    dialogConfig.position = { left: "20px", top: "138px" };
    const dialogRef = this._dialog.open(EmailClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.isModified = true;
      }

    });
  }

  clickRouteNew(route: string, display: string) {
    this._dialogRef.close(true);
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }

  clickClose(val) {
    this._dialogRef.close(val);
  }

}
