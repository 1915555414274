
<div class="divLeft" *ngIf="ua == 1 && userType=='internal'" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [cdkDragDisabled]="!isPopup"> 
    <div>
      <div>
        <div style="display: inline-flex; width: 100%; justify-content: space-between;">
          <div>
            <h5>ADMIN SERVICES \ User Access & Permissions - FPC Operations</h5>
          </div>
          <div *ngIf="isPopup" style="display: flex">
            <button mat-icon-button (click)="openAdminServicesComponent()"> <i class="material-icons-outlined"
                style="font-size:xx-large;  ">arrow_circle_left</i> </button>
            <button mat-icon-button mat-dialog-close><i class="material-icons" style="font-size: x-large;">close
              </i></button>
          </div>
        </div>
        
        <div style="background-color: white;">
          <form *ngIf="user_permission_admin_form" [formGroup]="user_permission_admin_form" (ngSubmit)="onSubmit()">
            <div style="margin-left:0em;padding:0em; display: flex">
              <div style="margin-top:0.5em; padding:0em; display: flex">
                <!--<div *ngIf="userType=='internal'" class="form-group form-inline" style="margin-bottom:0em; margin-right:0.5em">
                  <ul class="tabul">
                    <li [ngClass]="{ 'btn-high':selectedUserType=='internal','btn-low pointer':selectedUserType!='internal'  }" style="border-left: black 0.0625em solid;" (click)="clickUserType('internal')"><div style="margin-top:0.25em">FPI Operations</div></li>
                    <li [ngClass]="{ 'btn-high':selectedUserType=='customer','btn-low pointer':selectedUserType!='customer' }" (click)="clickUserType('customer')"><div style="margin-top:0.25em">Client</div></li>
                    <li [ngClass]="{ 'btn-high':selectedUserType=='vendor','btn-low pointer':selectedUserType!='vendor' }" ><div style="margin-top:0.25em">Vendor</div></li>
                  </ul>
                </div>-->
                <!--<div *ngIf="userType=='internal'  && selectedUserType=='customer'" style="margin-top:0em; margin-bottom:0em">
                  <label for="customer_select" style="font-size:small">Select Client</label>
                  <div style="margin-top:-0.5em;">
                    <select *ngIf="f" formControlName="customer_select" style="font-size: small;width:19.5em;border-radius:0.25em; border-color:lightgrey" (change)="customerChange($event)">
                      <option value="">Client Name</option>
                      <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
                    </select>
                  </div>
                </div>-->
                <div *ngIf="userType=='internal'  && selectedUserType=='vendor'" style="margin-top:-0.65em; margin-bottom:0em">
                  <label for="vendor_select" style="font-size:small">Select Vendor</label>
                  <div style="margin-top:-0.65em;">
                    <select *ngIf="f" formControlName="vendor_select" style="font-size: small;width:19.5em;border-radius:0.25em; border-color:lightgrey" (change)="vendorChange($event)">
                      <option value="">Vendor Name</option>
                      <option *ngFor="let vdr of vendorList" value={{vdr.vendorGUID}}>{{vdr.vendorNameCom}}</option>
                    </select>
                  </div>
                </div>
              </div>

            </div>
            <div style="display:flex; margin-top:0.5em; padding-left: 0.5em;">
              <div style="margin-left: 0em;margin-top:0em; padding:0em">
                <label for="text_search" style="font-size:small">Search by {{textSearchBy}}</label>
                <div class="form-group" style="margin-left: 0em">
                  <input type="text" formControlName="text_search" class="form-control" style="width:9.75em;height:1.4em" />
                </div>
              </div>
              <div class="form-group" style="margin-left: 0.3125em; margin-top:1.75em">
                <select *ngIf="f" formControlName="permission_type" style="font-size: small;border-radius:0.25em; border-color:lightgrey" (change)="permission_typeChange($event)">
                  <option value="Username" selected>Username</option>
                  <option value="Email">Email</option>
                  <option value="Registration">Registration</option>
                  <option value="PIC/SIC">PIC/SIC</option>
                  <option value="PAX">PAX</option>
                </select>
              </div>
              <div class="form-group" style="margin-left:1em">
                <label style="font-size:small; white-space:nowrap" for="admin_access_select">Account Access</label>
                <div style="margin-top:-0.15em">
                  <select *ngIf="f" formControlName="admin_access" style="font-size: small;border-radius:0.25em; border-color:lightgrey" (change)="clickShowAllUsers($event)">
                    <option value="1">Admin</option>
                    <option value="0">Standard</option>
                  </select>
                </div>
              </div>
              <div class="form-group" style="margin-left: 0.3125em;margin-top:1.65em">
                <button [disabled]="loading" mat-button class="btn-high" style="width: 5em; font-size: small;height:1.875em;padding-top:0em">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Search
                </button>&nbsp;
                <button type="button" mat-button class="btn-low" style="font-size: small;height:1.875em;padding-top:0em" (click)="clickShowAllUsers()">Show All Records</button>&nbsp;
                <button type="button" mat-button class="btn-low" (click)="clickReset()" style="width: 5em">Reset</button>
              </div>
              <div class="form-group" style="margin-left: 0.5em;margin-top:1.65em">
                <input type="checkbox" formControlName="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)" />
                <label for="includeDisabledRecords" style="font-size:small">&nbsp;Include disabled records</label>
                <label style="font-size:small;margin-left:.5em">{{totalRecordsText}} </label>
              </div>
            </div>
            <div class="row" style="margin-left:0em">
              <div *ngIf="msg!=''" class="inline-block"><label style="color:forestgreen">&nbsp;{{msg}}</label></div>
            </div>
            <div class="row" style="margin-left:0em">
              <div *ngIf="errMsg!=''" class="inline-block"><label style="color: #c00000">{{errMsg}}</label></div>
            </div>
          </form>
        </div>
      </div>
      <div style="height:36em;overflow-x:auto">
        <table mat-table matTableResponsive *ngIf="userList && userList.length>0" [dataSource]="userList" class="example-container">
          <!--<tr>
            <ng-container matColumnDef="members_list">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="7" style="border-bottom:none;">&nbsp;</th>
            </ng-container>
            <ng-container matColumnDef="restrict_access">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="22" style="border-bottom: none;">Permissions</th>
            </ng-container>
            <ng-container matColumnDef="blank">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" style="border-bottom: none;">&nbsp;</th>
            </ng-container>
          </tr>-->
          <tr>
            <ng-container matColumnDef="username">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Username
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left; padding-left: 0.125em;">{{element.username}}</td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Name
              </th>
              <td mat-cell *matCellDef="let element" style="white-space: nowrap; text-align: left;">{{element.flName}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Email
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left ">{{element.email}}</td>
            </ng-container>
            <ng-container matColumnDef="account_status">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Account Status
              </th>
              <td mat-cell *matCellDef="let element">{{element.isActive? 'Active': 'Not Active'}}</td>
            </ng-container>
            <ng-container matColumnDef="admin_access">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Account Access
              </th>
              <td mat-cell *matCellDef="let element">{{element.isAdmin? 'Admin': 'Standard'}}</td>
            </ng-container>
            <ng-container matColumnDef="effectiveDate">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Effective Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.effectiveDate}}</td>
            </ng-container>
            <ng-container matColumnDef="expirationDate">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Expiration Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.expirationDate}}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="grantAdmin">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Admin Serivces
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.isAdmin" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantManifest">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Manifest Profiles
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToManageManifestProfiles" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantCompany">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Company Profiles
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessCompanyProfiles" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantAircraft">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Aircraft Profiles
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessAircraftProfiles" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantMessage">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Messages
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToMessages" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantLockTrip">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Locked Trips
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToLockedTrips" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantCompanyEvent">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Manage Company Events
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToManageCompanyEvents" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantInvoice">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Invoices
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToInvoices" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantBusinessRule">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Business Rules
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToCompanyBusinessRules" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantVendor">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Vendor Profiles
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToVendorProfiles" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantTripAlert">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Create Trip Alerts
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessCreateTripAlerts" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantNews">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Headline News
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToHeadlineNews" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantPII">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                <div>Grant</div><div style="margin-top:-0.5em">Access to</div><div style="margin-top:-0.5em">PII Data</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToPIIData" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="manageGrp">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Manage Grouping
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToManageAccountGrouping" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="apis">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Manage APIS Credentials
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToAPISCredentials" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="fuel">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Fuel
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToFuel" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="airportDocs">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Manage Airport & Country Profiles
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToAirportDocuments" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container> 
            <ng-container matColumnDef="tripCloseout">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Trip Closeout
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToTripCloseout" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="submitToBilling">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Submit To Billing
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessSubmitToBilling" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="messageTemplates">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Message Templates
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToMessageTemplates" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="helpGuides">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
               Help Guides
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToHelpGuides" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="accountingDashboard">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Accounting Dashboard
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToAccountingDashboard" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="locked">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Locked
              </th>
              <td mat-cell *matCellDef="let element">{{element.locked? 'Yes': ''}}</td>
            </ng-container>
            <ng-container matColumnDef="disabled">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                Disabled
              </th>
              <td mat-cell *matCellDef="let element">{{element.isActive? '': 'Yes'}}</td>
            </ng-container> -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">Action</th>
              <td mat-cell *matCellDef="let element; let i=index ">
                <a mat-button class="btn-high grid">
                  Edit
                </a>
              </td>
            </ng-container> 
          </tr>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"  (click)="clickEdit($event, row);"></tr>
        </table>

      </div>
      <!--<div>
        <mat-paginator #paginator *ngIf="userList && totalPageNumber>1" [length]="totalRecords" [pageSizeOptions]="[10, 15, 20, 25, 30]" [pageSize]="pageSize"
                       [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
        </mat-paginator>
      </div>-->
    </div>
  
</div>
<app-spin *ngIf="showSpin"></app-spin>
