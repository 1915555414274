import { ContentDocumentModel } from "./content-document.model";

export class HeadlineModel {
  headlineID: number;
  customerGUID: string;
  isUTC: boolean;
  headlineTypeID: number;
  effectiveDate: string;
  expirationDate: string;
  validTimeStart: string;
  validTimeEnd: string;
  headlineTitle: string;
  headlineStory: string;
  headlineStoryText: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  documentWeblink: string;
  displayDateStart: string;
  displayDateEnd: string;
  attachmentCount: number;
  isDisplayOnly: boolean;
  includePast: boolean;
  customerGUIDList: string;
  customerNameLIst: string;
  isEventReleased: boolean;
  isInternalOnly: boolean;
  isDistributionRestricted: boolean;
  contentDocuments: ContentDocumentModel[];
  headlineTypeDescription: string;
  eventSortOrderNo: number;
  showOnLoginPage: boolean;
  isFpc: boolean;
  notReleased: boolean; 
  disabled: boolean;
  isMaintenance: boolean;
  sortOrder: number;
  isPublic: boolean;
  
}