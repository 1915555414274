import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { RegisterUserComponent } from '../user-accounts/register-user.component';
import { ResetPasswordAdminComponent } from '../user-accounts/reset-password-admin.component';
import { UserPermissionClientComponent } from '../user-accounts/user-permission-client.component';
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";

@Component({
  selector: 'app-admin-services-client-dashboard',
  templateUrl: './admin-services-client-dashboard.component.html',
  styleUrls: ['./admin-services-client-dashboard.component.css']
})

export class AdminServicesClientDashboardComponent implements OnInit {
  // userType: string; 
  admin_services_client_dashboard_form: UntypedFormGroup;
  loading = false;
  // v: number = this._authService.getCurrentTimeNumber();
  // upPII: boolean = false;
  username: string;
  isDialog: boolean = false;
  customerGUID: string = "";
  selectedCustomerName: string = "";
  userType: string = "";


  constructor(
    @Optional() private readonly _dialogRef: MatDialogRef<AdminServicesClientDashboardComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly dialog: MatDialog, private readonly _authService: AuthenticateService, private _router: Router,
    private _route: ActivatedRoute,
  ) {

    // this.tripRevisionID = _data.tripRevisionID;
    // this.userComments = _data.userComments;
    if (this.data) {
      this.username = this.data.username;
      this.isDialog = true;
    }

  }

  ngOnInit() {

    // this._authService.updateAccessTime();
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }


  }

  initControls() {
    this.admin_services_client_dashboard_form = this._formBuilder.group({
      // userComments: [this.userComments]
    });


  }

  get f() { return this.admin_services_client_dashboard_form.controls; }




  clickClose(val) {
    this._dialogRef.close(val);
  }



  openRegisterUserComponent() {
    if (this.isDialog) {
      this.clickClose(true);
    }
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    config.width = "61em";
    config.height = "auto";
   config.minHeight = "36.5em"; 
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "20px", top: "138px" };
    config.data = {isDialog: true, v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(RegisterUserComponent, config);
  }



  openUserPermissionClientComponent() {
    if (this.isDialog) {
      this.clickClose(true);
    }
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.maxWidth = "93em";//"1000px";
    config.height = "37em";//"1000px";
    config.width = "98em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "20px", top: "138px" };
    config.data = { isDialog: true, v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(UserPermissionClientComponent, config);
  }

  openResetPasswordDialog() {
    if (this.isDialog) {
      this.clickClose(true);
    }

    const config = new MatDialogConfig();
    config.panelClass = 'custom-dialog-container4';
    config.width = '52em';
    config.height = "40em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: '20px', top: '138px' };
    config.data = {
      gsId: '',
      tripCodeId: '',
      isDialog: true,
      v: this._authService.getCurrentTimeNumber(),
      customerGUID: this.customerGUID,
      customerName: this.selectedCustomerName,
      userType: this.userType,
    };

    const dialogRef = this.dialog.open(ResetPasswordAdminComponent, config);

    // Handle navigation logic after dialog interaction
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Call your navigation logic here
        this.clickRoute('user-accounts/reset-password-admin','');
      }
    });
  }


  clickRoute(route: string, display: string) {
    this.gotoRoutePage(route, display);
  }

  gotoRoutePage(routePage: string, display: string) {
    let navigationExtras: NavigationExtras;
    navigationExtras = {
      queryParams: {
        v: this._authService.getCurrentTimeNumber()
      }
    }
    this._router.navigate([routePage], navigationExtras);
  }

}
