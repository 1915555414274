import { GroundStopAlternateAirportModel } from "./ground-stop-alternate-airport.model";
import { GroundStopManifestModel } from "./ground-stop-manifest.model";
import { GroundStopTaskModel } from "./ground-stop-task.model";
import { ServiceClassModel } from "./service-class.model";
import { TripRevisionModel } from "./trip-revision.model";
import { VendorModel } from "./vendor.model";

export class GroundStopModel {
  groundStopGUID: string;
  groundStopTypeGUID: string;
  groundStopTypeDescription: string;
  icao: string;
  iata: string;
  arrivalDateUTC: Date;
  arrivalDateTextUTC: string;
  arrivalTimeUTC: string;
  departureDateUTC: Date;
  departureDateTextUTC: string;
  departureTimeUTC: string;
  arrivalDateLocal: Date;
  arrivalDateTextLocal: string;
  arrivalTimeLocal: string;
  departureDateLocal: Date;
  departureDateTextLocal: string;
  departureTimeLocal: string;
  arrStartAt: Date;
  depStartAt: Date;
  groundStopSequenceID: number; 
  customerGUID: string;
  customerName: string;
  aircraftGUID: string;        
  registration: string;
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string;
  speed: number;
  callSign: string;
  isTechStop: boolean;
  isTechStopNumber: number;
  ete: string;
  tripCodeGUID: string;
  tripCodeType: string;
  tripCodeDesc: string;
  tripCode: string;
  tripEstimate: string;
  tripConsultation: string;
  icaO_IATA: string;
  airportName: string;
  airportState_CountryCode: string;
  runwayMaxLength: number;
  elevation: number;
  latitude: number;
  longitude: number;
  maxGroundStopSequenceID: number;
  groundStopBriefInfo: string;
  arrivalFARTypeDescription: string;
  arrivalCallSign: string;
  departureFARTypeDescription: string;
  departureCallSign: string;
  departureFARTypeID: number;
  departureStatusID: number;
  arrivalStatusID: number;
  isActive: string;
  nextICAO_IATA: string;
  nextAirportName: string;
  nextAirportCity_CountryCode: string;
  callLog: string;
  isValidArrDate: boolean;
  isValidArrTime: boolean;
  isValidDeptDate: boolean;
  isValidDeptTime: boolean;
  dateTimePassed: boolean;
  moveUp: boolean;
  moveDown: boolean;
  arrReadOnly: boolean;
  depReadOnly: boolean;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
  groundStopAlternateAirportList: GroundStopAlternateAirportModel[];
  arrivalCrewCount: number
  arrivalPAXCount: number;
  arrivalPIC: GroundStopManifestModel;
  arrivalSIC: GroundStopManifestModel;
  arrivalCrewList:GroundStopManifestModel[];
  arrivalPAXList: GroundStopManifestModel[];
  departureCrewCount: number;
  departurePAXCount: number;
  departurePIC: GroundStopManifestModel;
  departureSIC: GroundStopManifestModel;
  departureCrewList: GroundStopManifestModel[];
  departurePAXList: GroundStopManifestModel[];
  fboHandlerList: GroundStopTaskModel[];
  airportServiceTaskList: GroundStopTaskModel[]; 
  flightPlanServiceTaskList: GroundStopTaskModel[];
  preflightServiceTaskList: GroundStopTaskModel[];
  flightFollowingTaskList: GroundStopTaskModel[];
  hotelTaskList: GroundStopTaskModel[];
  serviceClassTaskStatusList: GroundStopTaskModel[];
  groundStopTypeColor: string;
  cancelAllServices: boolean;
  cancelled: boolean;
  nextGroundStopGUID: string;
  departureICAO: string;
  arrivalICAO: string;
  countryGUID: string;
  arrivalPetsOnBoard: boolean;
  departurePetsOnBoard: boolean;
  arrivalWeaponsOnBoard: boolean;
  departureWeaponsOnBoard: boolean;
  nextArrivalDateUTC: string;
  nextArrivalTimeUTC: string;
  nextArrivalDateLocal: string;
  nextArrivalTimeLocal: string;
  nextCountryGUID: string;
  faR_CallSign: string;
  isFirstGroundStop: boolean;
  isLastGroundStop: boolean;
  nextICAO: string;
  prevICAO: string;
  hasAlternateAirport: boolean;
  fARTypeID: number;
  arrivalStatusDescription: string;
  departureStatusDescription: string;
  arrivalStatusColorCode: string;
  departureStatusColorCode: string;
  nextLatitude: number;
  nextLongitude: number;
  hasTask: boolean;
  tripCloseOutDescription: string;
  tripCloseOutID: number;
  lockdownTrip: boolean;
  arrivalGroundStopAlternateAirportList: GroundStopAlternateAirportModel[];
  departureGroundStopAlternateAirportList: GroundStopAlternateAirportModel[];
  etopGroundStopAlternateAirportList: GroundStopAlternateAirportModel[];
  etpGroundStopAlternateAirportList: GroundStopAlternateAirportModel[];
  isHomeBaseAirport: boolean;
  departureICAO_IATA: string;
  arrivalICAO_IATA: string;
  departureLatitude: number;
  departureLongitude: number;
  arrivalLatitude: number;
  arrivalLongitude: number;
  cityPairOrder: number;
  tripRevisionList: TripRevisionModel[];
  pointOfContactList: GroundStopTaskModel[];
  serviceTypeDescription: string;
  groundStopGUIDList: string;
  allCrewPaxList: GroundStopManifestModel[];
  serviceClassList: ServiceClassModel[];
  selected: boolean;
  tripTaskStatusGUIDList: string;
  includeDisabledTask: boolean;
  accountingStatusGUIDList: string;
  utcTimeConversion: string;
  departureDateTimeUTCDisplay: string;
  arrivalDateTimeUTCDisplay: string;
  airportCountryName: string;
  groundStopTaskList: GroundStopTaskModel[];
  departureUTCTimeConversion: string;
  arrivalUTCTimeConversion: string;
  oldGroundStopTypeGUID: string;
  arrivalDateTimePassed: boolean;
  departureDateTimePassed: boolean;
  isDeptFerryFlight: boolean;
  isArrFerryFlight: boolean;
  isPICChanged: boolean;
  isSICChanged: boolean;
  arrAirportServiceTaskList: GroundStopTaskModel[];
  arrFlightPlanServiceTaskList: GroundStopTaskModel[];
  arrPreflightServiceTaskList: GroundStopTaskModel[];
  arrFlightFollowingTaskList: GroundStopTaskModel[];
  depAirportServiceTaskList: GroundStopTaskModel[];
  depFlightPlanServiceTaskList: GroundStopTaskModel[];
  depPreflightServiceTaskList: GroundStopTaskModel[];
  depFlightFollowingTaskList: GroundStopTaskModel[];
  eteChanged: boolean;
  hasHaltServices: boolean;
  taskType: string;
  serviceTypeIDList: string;
  vendorList: VendorModel[];
  airportInfo: string;
  prevGroundStopGUID: string;
  farTypeID: number;
  prevCountryGUID: string;
  isDateTimeConflict: boolean;
  hasNextGroundStop: boolean;
  nextGroundStopTypeGUID: string;
  nextGroundStopTypeColor: string;
  arrivalOnHold: boolean;
  departureOnHold: boolean;
  contingencyGroundStopGUID: string;
  isRepositioning: boolean;
  contingencySequence: number;
  contingencyStopList: GroundStopModel[];
  hasContingencyStops: boolean;
  contingencyCount: number;
  contingencyCountText: string;
  hidePastStop: boolean;
  cityName: string;
  tripRoute: string;
  airportID: number;
  countryID: number;
  defaultEmail: string;
  nextAirportID: number;
  prevDepartureDateUTC: string;
  prevDepartureTimeUTC:string
  prevDepartureDateLocal: string;
  prevDepartureTimeLocal: string
  prevDepartureDateTimeUTC: string;
  nextArrivalDateTimeUTC: string
  prevDepartureDateTimeLocal: string;
  nextArrivalDateTimeLocal: string;
  prevAirportID: number;
}
