<div style="background-color:#f8f8f8;" >
  <div style="display: inline-flex; justify-content: space-between;" cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle>
    <div>
      <div style="padding-left:1em;padding-right:1em;margin-top:0.3125em;">
        <h5 class="mat-h2">{{dialogTitle}}</h5>
      </div>
    </div>
    <div>
      <div *ngIf="errMsg!=''" class="alert alert-danger p-0 small mt-3">{{errMsg}}</div>
    </div>
    <!--<div style="font-weight: 700; margin-top:0.3125em; cursor: pointer;padding-right:0.5em;">
    <i class="material-icons" style="color:black;" (click)="clickClose()">close</i>
    </div>-->
  </div>
  <div>
    <form *ngIf="person_edit_form" [formGroup]="person_edit_form" (ngSubmit)="clickSave()">
      <div>
        <div style=" margin-top:0em; padding-left: 1em;padding-right:1em">
          <div style="margin-top:0em;background-color:white; padding-left:0.5em;padding-bottom:0.5em">
            <div>
              <div style="display:flex">
                <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                  <label for="lastName" style="font-size:small">Last Name</label>
                  <div style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                    <input type="text" formControlName="lastName" class="form-control" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" style="padding: 0em;width:11em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                  </div>
                  <div *ngIf="submitted && f.lastName.errors" class="alert alert-danger p-0 small">
                    <div *ngIf="f.lastName.errors.required">Last Name is required.</div>
                    <div *ngIf="f.lastName.errors.pattern">Invalid last name</div>
                  </div>
                </div>
                <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                  <label for="firstName" style="font-size:small">First Name</label>
                  <div style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                    <input type="text" formControlName="firstName" class="form-control" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" style="padding: 0em;width:11em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                  </div>
                  <div *ngIf="submitted && f.firstName.errors" class="alert alert-danger p-0 small">
                    <div *ngIf="f.firstName.errors.required">First Name is required.</div>
                    <div *ngIf="f.firstName.errors.pattern">Invalid first name</div>
                  </div>
                </div>
                <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                  <label for="middleName" style="font-size:small">Middle Name</label>
                  <div style="margin-left: 0em; margin-top:-.35em; margin-bottom:0em">
                    <input type="text" formControlName="middleName" class="form-control" maxlength="255" style="padding: 0em;width:11em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                  </div>

                </div>
              </div>
              <div style="display:flex">
                <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                  <label for="title" style="font-size:small">Title</label>
                  <div style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                    <input type="text" formControlName="title" class="form-control" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" style="padding: 0em;width:29em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                  </div>
                </div>
                <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                  <label for="title" style="font-size:small">Sort Order</label>
                  <div style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                    <input type="number" formControlName="sort_order" class="form-control" style="padding: 0em;width:4em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                  </div>
                </div>
              </div>
              <div style="margin-left:-.5em;">
                <mat-checkbox formControlName="includeInBrief">Include in Brief</mat-checkbox>
                
              </div>
              <!--<div style="display:flex">
          <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback" style="width:14em">
            <div *ngIf="f.lastName.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:13em">Last name is a required field.</div>
          </div>
          <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback" >
            <div *ngIf="f.firstName.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:13em;">First name is a required field.</div>
          </div>
        </div>-->

            </div>
          </div>
        </div>
        <div style="margin-top:0em; padding-left: 1em;padding-right:1em">
          <div style="margin-top:.5em;background-color:white; padding-left:0.5em;padding-bottom:0.5em">
            <div style="display:flex; margin-top:1em ">
              <div style="margin-top:0em; margin-right:1em">
                <label for="person_status" style="font-size:small">Disable Record</label>
                <div style="margin-top:-0.35em;">
                  <select *ngIf="f" formControlName="person_status" class="form-control form-control-sm dropdown">
                    <option value="1" selected>No</option>
                    <option value="0">Yes</option>
                  </select>
                </div>
              </div>
              <div style="margin-right:1em" class="form-group">
                <label for="modifiedBy" style="font-size:small">Modified By<!--{{personGUID!=''? 'Modified by': 'Created by' }}--></label>
                <div style="margin-top:-0.35em;">
                  <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:9em;height:1.5625em;font-size:small;padding:0" />
                </div>
              </div>
              <div class="form-group">
                <label for="modifiedDate" style="font-size:small">Modified Date<!--{{personGUID!=''? 'Modified Date': 'Date' }}--></label>
                <div style="margin-top:-0.35em;">
                  <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:9em;height:1.5625em;font-size:small;padding:0" />
                </div>
              </div>
            </div>
            <!--<div *ngIf="errMsg!=''" class="d-flex" style="padding-left:0.25em; display:flex;margin-top:0.25em;margin-bottom:-1em; white-space:pre-wrap;background-color: #f9d2de;width:17em;height:1.5em"><label style="color:black; font-size: small; margin-top:0em">{{errMsg}}</label></div>-->



          </div>
        </div>
      </div>
    </form>
  </div>
  <div style="padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em; display: flex; height:3em">
    <div style="margin-right: 1em">
      <button mat-raised-button class="btn-high" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;Save&nbsp;</button>&nbsp;
      <button mat-raised-button class="btn-med" (click)="clickClose()" style="background-color:grey; color: white">&nbsp;Close&nbsp;</button>&nbsp;
      <button mat-raised-button *ngIf="showContactButton" class="btn-high" (click)="clickOpenContactDetails()">Open Contact Details</button>&nbsp;
    </div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
  </div>
</div>
  <app-spin *ngIf="showSpin"></app-spin>

