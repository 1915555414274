<div *ngIf="upPII" style="margin-top:0em; padding-top:0em; display:flex">
  <form *ngIf="trip_code_viewlist_client_form" [formGroup]="trip_code_viewlist_client_form">
    <div>
      <div class="table-wrapper">
        <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container mat-elevation-z8">
          <tr>
            <ng-container matColumnDef="tripcode">
              <th mat-header-cell *matHeaderCellDef style="width:10%; text-align:left !important">
                <div style="margin-top:-0.25em;">Trip Codes</div>
              </th>
              <td mat-cell *matCellDef="let element" style="white-space:nowrap; text-align:left !important" [style.background-color]="element.tripCodeType=='Consultation'? 'lightgreen': '' || element.tripCodeType=='Estimate'? 'lightblue':'' ">
                <div style="font-weight: bold">{{element.tripCodeDesc}}</div>
                <div style="display: flex">
                  <div style="font-size:x-small;margin-top:-0.25em;">{{element.isActive && element.tripCode!=''? 'Active': '' || element.tripCodeType!=''? element.tripCodeType: ''}}</div>
                  <div *ngIf="element.isNewTrip" style="margin-top:-0.25em; margin-left:0.25em"><i class="material-icons-two-tone icon_orange" title="New Trip" style="font-size: medium;">new_releases</i></div>
                </div>
                <div style="font-size:x-small;margin-top:-0.25em;color: darkgreen">{{element.tripCloseOutID>1 && element.tripCodeType==''? element.tripCloseOutDescription:''}}</div>
                <div style="font-size:x-small;margin-top:-0.25em;color: darkgreen">{{element.tripCloseOutID>1 && element.tripCodeType==''? 'Date '+ element.tripCloseOutDate:''}}</div>
                <div style="font-size:x-small;margin-top:-0.25em;color: darkgreen">{{element.tripCloseOutID>1 && element.tripCodeType==''? element.tripCloseOutSubmittedBy:''}}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="registration">
              <th mat-header-cell *matHeaderCellDef style="width:10%; text-align:left !important">
                <div style="margin-top:-0.25em;">Registration</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important;">
                <div style="font-weight:bold">{{element.registration}}</div>
                <div style="font-size:x-small;margin-top:-0.25em; white-space:nowrap">{{element.aircraftManufacturer + " "+element.aircraftModel}}</div>
                <!--<div style="font-size:x-small;margin-top:0em;padding:0em">{{element.aircraftInfo}}</div>-->
              </td>
            </ng-container>
            <ng-container matColumnDef="crew">
              <th mat-header-cell *matHeaderCellDef style="width:10%;">
                <div style="margin-top:-0.25em;">Crew</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.crewCount>0? element.crewCount.toString():''}}</td>
            </ng-container>
            <ng-container matColumnDef="pax">
              <th mat-header-cell *matHeaderCellDef style="width:10%;">
                <div style="margin-top:-0.25em;">Passengers</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.paxCount>0? element.paxCount.toString():''}}</td>
            </ng-container>
            <ng-container matColumnDef="dateRange">
              <th mat-header-cell *matHeaderCellDef style="width:15%; ">
                <div style="margin-top:-0.25em;">Date Range</div>
                <div style="margin-top:-0.25em;font-size:x-small;">(First Departure - Last Arrival)</div>
              </th>
              <td mat-cell *matCellDef="let element" style="font-weight:bold; text-align: left !important"><div style="margin-left:1em">{{element.dateRange}}</div></td>
            </ng-container>
            <ng-container matColumnDef="route">
              <th mat-header-cell *matHeaderCellDef style="width:20%; text-align:left !important">
                <div style="margin-top:-0.25em;">Route</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important">
                <!--{{element.route}}-->
                <!--<div style="display:flex;"><div *ngFor="let gs of element.groundStopList; let i=index" style="display:flex">
            <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==element.groundStopList.length-1? '': '-'}}</div>
            </div>
          </div>-->
                <div *ngFor="let gs of element.groundStopList; let i=index">
                  <div *ngIf="i % 10==0" style="display: flex">
                    <div style="display: flex">
                      <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 1 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+1].groundStopTypeColor">{{element.groundStopList[i+1].icao}}</div><div>{{(i+1)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 2 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+2].groundStopTypeColor">{{element.groundStopList[i+2].icao}}</div><div>{{(i+2)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 3 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+3].groundStopTypeColor">{{element.groundStopList[i+3].icao}}</div><div>{{(i+3)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 4 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+4].groundStopTypeColor">{{element.groundStopList[i+4].icao}}</div><div>{{(i+4)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 5 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+5].groundStopTypeColor">{{element.groundStopList[i+5].icao}}</div><div>{{(i+5)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 6 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+6].groundStopTypeColor">{{element.groundStopList[i+6].icao}}</div><div>{{(i+6)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 7 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+7].groundStopTypeColor">{{element.groundStopList[i+7].icao}}</div><div>{{(i+7)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 8 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+8].groundStopTypeColor">{{element.groundStopList[i+8].icao}}</div><div>{{(i+8)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 9 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+9].groundStopTypeColor">{{element.groundStopList[i+9].icao}}</div><div>{{(i+9)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="clientInstruction">
              <th mat-header-cell *matHeaderCellDef style="width:10%">
                <div style="margin-top:-0.25em;">Client<br />Instructions</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: center !important">
                <!--<div style="cursor:pointer; white-space:pre-wrap" matTooltipClass="tooltipLineBreak" matTooltip="{{element.clientInstructions}}">{{ element.clientInstructions.substring(0,19) }}</div>-->
                <button *ngIf="element.clientInstructions!=''" mat-button class="btn-low grid" style="align-self:center" (click)="viewNotes(element)">View</button>
              </td>
            </ng-container>
            <ng-container matColumnDef="clientRef">
              <th mat-header-cell *matHeaderCellDef style="width:10%">
                <div style="margin-top:-0.25em;">Client Trip#</div>
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.customerTripID }}
              </td>
            </ng-container>
            <!--<ng-container matColumnDef="additionalRequirement">
        <th mat-header-cell *matHeaderCellDef style="width:10%">
          <div style="margin-top:-0.25em;">Additional<br />Requirements</div>
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center !important">
          <div style="cursor:pointer; white-space:pre-wrap" matTooltipClass="tooltipLineBreak" matTooltip="{{element.additinalReqTooltip}}">{{element.additinalReqTooltip!=''?'YES':'' }}</div>
        </td>
      </ng-container>-->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="width:5%">
                <div>Action</div><div *ngIf="grantAccessSubmitTripsandRequestQuotes && isActiveClient" style="margin-top:0em;padding-bottom:0.25em"><a mat-button class="btn-high grid" style="align-self:center" (click)="clickAdd($event, null);">Add</a></div>
              </th>
              <td mat-cell *matCellDef="let element">
                <a mat-button class="btn-low grid" style="align-self:center">
                  Open
                </a>
              </td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="clickOpen($event, row)"></tr>
        </table>
        </div>
      </div>

</form>
</div>

<app-spin *ngIf="showSpin"  style="width: 6em; display: block; margin-top: 5em;margin-left: 45em;" ></app-spin>

