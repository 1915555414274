<div style="background-color:#f8f8f8;overflow-y:hidden " cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div style="display: flex; justify-content:space-between">
    <div style="display: flex;padding-left:1em; padding-top:0.25em; float:left; min-width:50em">

      <div style="margin-right:1em">
        <div style="font-size: small">Registration</div>
        <div style="font-weight: bold; font-size: small">{{registration}}</div>
      </div>
      <div style="margin-right:1em">
        <div style="font-size: small">Trip Code</div>
        <div style="font-weight: bold; font-size: small">{{tripCode}}</div>
      </div>
      <div style="margin-right:1em">
        <div style="font-size: small">Business Stop</div>
        <div style="font-size: small; display: flex">
          <div>{{prevICAO!=''?prevICAO+'-':''}}</div>
          <div style="font-weight: bold">{{icao}}</div>
          <div>{{nextICAO!=''?'-'+nextICAO:''}}</div>
        </div>
      </div>
      <div style="margin-right:1em; margin-top: -0.175em;" *ngIf="serviceLocation=='D'">
        <div style="font-size: small; display: inline-flex;">
          Departure<div style="font-weight: bold; margin-left: 0.5em;">
            <div style="margin-left: 0.5em; font-weight: bold;">{{icao}}</div>
          </div>
        </div>
        <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{departureDateTime==""? "TBA" : departureDateTime}}</div>
        <div style="font-size: small;margin-top:-0.5em">
          {{departureDateTimeLocal}}
        </div>
      </div>
      <div style="margin-right:1em; margin-top: -0.175em;" *ngIf="serviceLocation=='A'">
        <div style="font-size: small; display: inline-flex;">
          Departure<div style="font-weight: bold; margin-left: 0.5em;">
            <div style="margin-left: 0.5em; font-weight: bold;">{{prevICAO}}</div>
          </div>
        </div>
        <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{prevDepartureDateTime==""? "TBA" : prevDepartureDateTime}}</div>
        <div style="font-size: small;margin-top:-0.5em">
          {{prevDepartureDateTimeLocal}}
        </div>
      </div>
      <div style="margin-right:1em; margin-top: -0.175em;" *ngIf="serviceLocation=='D'">
        <div style="font-size: small; display: inline-flex;">
          Arrival<div style="margin-left: 0.5em; font-weight: bold;">{{nextICAO}}</div>
        </div>
        <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{nextArrivalDateTime==""? "TBA" : nextArrivalDateTime}}</div>
        <div style="font-size: small; margin-top:-0.5em">
          {{nextArrivalDateTimeLocal}}
        </div>
      </div>
      <div style="margin-right:1em; margin-top: -0.175em;" *ngIf="serviceLocation=='A'">
        <div style="font-size: small; display: inline-flex;">
          Arrival<div style="margin-left: 0.5em; font-weight: bold;">{{icao}}</div>
        </div>
        <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{arrivalDateTime==""? "TBA" : arrivalDateTime}}</div>
        <div style="font-size: small; margin-top:-0.5em">
          {{arrivalDateTimeLocal}}
        </div>
      </div>
      <div>
        <div style="font-size: small;">{{aircraftManufacturer}} {{aircraftModel}}</div>
        <div style="font-size: small;">{{aircraftInfo}}</div>
      </div>
      <form [formGroup]="landing_permit_task_edit_form" *ngIf="landing_permit_task_edit_form">
        <div *ngIf="isRevisionRequiredValue==1">
          <div [ngClass]="f.isRevisionRequired.value==true?'alert alert-danger d-flex align-items-center':'alert alert-success d-flex align-items-center'"
               style="height:2.5em; margin-bottom:0em; margin-right:2em;padding-left:0.25em; padding-right:0.25em; font-size:small; display: flex; border-radius:0.25em; margin-left: 3em; margin-top: 0.75em;">
            <div>
              <mat-slide-toggle formControlName="isRevisionRequired" style="transform: scale(0.9) " [checked]="isRevisionRequired">
              </mat-slide-toggle>
            </div>
            <div *ngIf="f.isRevisionRequired.value==true">
              <div>Revision Required - </div>
              <div style="margin-top:-0.5em">Turn off to Acknowledge</div>
            </div>
            <div *ngIf="f.isRevisionRequired.value==false">
              <div style="margin-top:0em;">Revision Acknowledged</div>
            </div>
            <div>
              <div *ngIf="f.isRevisionRequired.value==true" style="margin-top:0.5em">
                <i class="material-icons-two-tone icon_orange" style="font-size: x-large;">notifications_active</i>
              </div>
              <div *ngIf="f.isRevisionRequired.value==false" style="margin-top:0.5em">
                <i class="material-icons" style="font-size: x-large; background-color: transparent; color: black; border-color: #5e5e5e">task_alt</i>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isRevisionRequiredValue==2">
          <div [ngClass]="f.isRevisionRequired.value==true?'alert alert-danger d-flex align-items-center':'alert alert-success d-flex align-items-center'"
               style="height:2.5em; margin-bottom:0em; margin-right:2em;padding-left:0.25em; padding-right:0.25em; font-size:small; display: flex; border-radius:0.25em; margin-left: 3em; margin-top: 0.75em;">
            <div>
              <mat-slide-toggle formControlName="isRevisionRequired" style="transform: scale(0.9) " [checked]="isRevisionRequired">
              </mat-slide-toggle>
            </div>
            <div *ngIf="f.isRevisionRequired.value==true">
              <div>Revision Required - </div>
              <div style="margin-top:-0.5em">Turn off to Acknowledge</div>
            </div>
            <div *ngIf="f.isRevisionRequired.value==false">
              <div style="margin-top:0em">Revision Acknowledged</div>
            </div>
            <div>
              <div *ngIf="f.isRevisionRequired.value==true" style="margin-top:0.5em">
                <i class="material-icons-two-tone icon_red" style="font-size: x-large;">notifications_active</i>
              </div>
              <div *ngIf="f.isRevisionRequired.value==false" style="margin-top:0.5em">
                <i class="material-icons" style="font-size: x-large; background-color: transparent; color: black; border-color: #5e5e5e">task_alt</i>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="onHoldText !=''"
      style="margin-top:1em;border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
      <div style="margin-top: -0.1em; text-align: center; font-weight: normal; font-size: small;">On Hold</div>
    </div>
    <div style="float: right; display: inline-flex; margin-left: -1em;">
      <button mat-icon-button>
        <i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium" title="Copy note to clipboard"
          [cdkCopyToClipboard]="clickCopyHeader() ">content_copy</i>
      </button>
      <!-- <button mat-icon-button>
        <i class="material-symbols-outlined" style="cursor: pointer;" title="Previous Task">expand_less</i>
      </button>
      <button mat-icon-button>
        <i class="material-symbols-outlined" style="cursor: pointer;" title="Next Task">expand_more</i>
      </button> -->
      <button mat-icon-button mat-dialog-close>
        <i class="material-icons" style="cursor: pointer;" title="Close">close </i>
      </button>
    </div>

  </div>
  <div>
    <app-spin style="width: 15em;" *ngIf="showSpin"></app-spin>
    <form [formGroup]="landing_permit_task_edit_form" *ngIf="landing_permit_task_edit_form">
      <div style="display:flex; margin-top:0em; padding-left: 1em; padding-right:1em">
        <div style="margin-top:0.5em;background-color:white; padding-left:1em; padding-bottom: 0.75em; width: 65em">
          <!-- <div style="display:flex; justify-content: space-between"> -->
          <div class="d-inline-flex" style="width: 100%;">
            <div style="border-radius: 50%; width: 1em; height: 1em; border: black solid 0.0625em; margin-right:.25em;margin-top:.5em" [style.background-color]="taskColor">
            </div>
            <div style="display: flex; justify-content: space-between; width: 100%;">
              <div style="margin-top:0.25em;">
                <h5  [ngClass]="immediateAction == 1?'divImmediatePriority':''"  style="margin-bottom: 0em;">{{serviceTypeDesc}}</h5>
                <div>{{selectedLandingCountryName}}</div>
              </div>

              <div>
                <button *ngIf="hasLandingPermitDocument" mat-button class="btn-high"
                        style="height: 2em; margin-top: 0.5em; margin-right: 1em;" (click)="generateLanding()">
                  Generate
                  Permit Request
                </button>
                <button mat-button class="btn-high" style="height: 2em; margin-top: 0.5em; margin-right: 1em;"
                        (click)="clickEmail()">
                  <span class="material-symbols-outlined">
                    send
                  </span>
                  Email
                </button>
                <button mat-button class="btn-high" style="height: 2em; margin-top: 0.5em; margin-right: 1em;"
                        (click)="clickTripMessages()">
                  <span class="material-symbols-outlined">
                    mail
                  </span>
                  Trip Messages
                </button>
              </div>
            </div>
          </div>
          <div style="display: flex">
            <!-- <div style="margin-right:0.5em;">
            <label for="landing_country_select" style="font-size:small">Landing Country Name</label>
            <div style="margin-top:-0.35em;">
              <select formControlName="landing_country_select"
                [ngClass]="{ 'is-invalid': submitted && f.landing_country_select.errors }"
                (change)="countryChange($event, 'landing')"
                style="font-size: small; height:1.5625em; padding: 0; background-color:#eaf0fd; width:17em; border-radius:0.25em;"
                class="form-control">
                <option value="" selected></option>
                <option *ngFor="let cty of countryList" [ngValue]="cty">{{cty.countryName}}</option>
              </select>
            </div>
            <div *ngIf="submitted && f.landing_country_select.errors">
              <div *ngIf="f.landing_country_select.errors.required"
                style="background-color:#f9d2de; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">
                Country is required</div>
            </div>
          </div> -->
            <div style="margin-right:0.5em; width: 28.25em">
              <div *ngIf="f.landing_country_select.value!=''">
                <div class="d-flex justify-content-between" style="margin-top:.2em">
                  <label for="vendor_select" style="font-size:small">Vendor</label>
                </div>
                <div style="margin-top:-0.35em;">
                  <select *ngIf="f" formControlName="vendor_select"
                          [ngClass]="{ 'is-invalid': submitted && f.landing_country_select.errors }"
                          style="font-size: small; height:1.5625em; padding: 0; border-radius:0.25em; width:100%; background-color:#eaf0fd;"
                          class="form-control" (change)="vendorChange($event,'vendor_select')">
                    <option value="" selected></option>
                    <option *ngFor="let v of vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                  </select>
                </div>
                <div *ngIf="submitted && f.vendor_select.errors">
                  <div *ngIf="f.vendor_select.errors.required"
                       style="background-color:#f9d2de; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">
                    Vendor is required
                  </div>
                </div>
              </div>
            </div>
            <!-- <div style="margin-right: 0.5em;">
            <label style="font-size:small; width: 10em">Method of Payment</label>
            <div class="alert-success" style="text-align: center; margin-top: -0.5em;">{{methodOfPayment}}</div>
          </div> -->
            <!-- <div>
            <button mat-button class="btn-high" style="margin-top: 1.8em;" (click)="openVendorDetails()"><span
                *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              <div>Details</div>
            </button>
          </div> -->
            <div style="margin-right:0.5em">
              <div style="display:flex">
                <div style="display: flex">
                  <div>
                    <!-- <div style="display:flex">
                    <div>
                      <div *ngIf="submitted && f.service_date.errors">
                        <div *ngIf="f.service_date.errors.isValidDate==false"
                          style="background-color:#f9d2de; padding-left:0.25em; width:10em;margin-top:-0.5em; margin-left:13.5em ; font-size:small;">
                          Invalid service date</div>
                      </div>
                    </div>
                  </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="display: flex;">
            <div style="display: inline-flex;">
              <div style="margin-left:0em; padding-right: 0.5em;">
                <label for="task_status_select" style="font-size:small">Operational Status</label>
                <div style="margin-top:-0.35em;">
                  <select *ngIf="f" formControlName="task_status_select"
                          style="width: 17em; height:1.5625em; padding: 0; font-size: small;border-radius:0.25em; background-color:#eaf0fd"
                          class="form-control" (change)="taskStatusChange()">
                    <option *ngFor="let t of taskStatusList" [ngValue]="t.taskStatusGUID">
                      {{t.taskStatusDescription}}
                    </option>
                  </select>
                </div>
              </div>
              <div style="margin-right:0.5em;">
                <label for="service_arrangement_landing_select" style="font-size:small">Service Arrangement</label>
                <div style="margin-top:-0.35em;">
                  <select *ngIf="f" formControlName="service_arrangement_landing_select"
                          style="width: 17em; height:1.5625em; padding: 0; font-size: small;border-radius:0.25em; background-color:#eaf0fd"
                          class="form-control">
                    <option *ngFor="let s of serviceArrangementList" [ngValue]="s.serviceArrangementID">
                      {{s.serviceArrangementDescription}}
                    </option>
                  </select>
                </div>
              </div>
              <!-- <div style="margin-right:0.5em;margin-top:0em">
              <label for="permit_order_number" style="font-size:small; white-space:nowrap">Order #</label>
              <div style="display:flex;margin-top:-0.35em">
                <input type="number" min="1" formControlName="permit_order_number" class="form-control"
                  style="width:4.75em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; background-color:#eaf0fd"
                  [ngClass]="{ 'is-invalid': submitted && f.permit_order_number.errors }" />
              </div>
            </div> -->
            </div>
          </div>

          <div style="display:inline-flex">
            <div style="margin-left:0em;margin-top:0em;">
              <label for="confirmation_number" style="font-size:small">Confirmation Number</label>
              <div style="display:flex;margin-top:-0.5em">
                <input type="text" formControlName="confirmation_number" class="form-control"
                       style="width:17em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; background-color:#eaf0fd" (mousedown)="$event.stopPropagation()" />
              </div>
            </div>
            <div style="display: flex; flex-direction: row; width: 9em; margin-bottom: 1em;">
              <div *ngIf="due_datetime==''" style="margin-left:0.5em;margin-top:0em">
                <div style="font-size: small; margin-top:0.25em" (click)="showOrginalLeadTimeDueDate=true">
                  Lead
                  Time
                </div>
                <div style="display:flex;margin-top:0.05em; font-size:small">
                  <input type="text" formControlName="lead_time" maxlength="9" placeholder="?d?h?m" class="form-control"
                         [ngClass]="{ 'is-invalid': (submitted || lead_timeChanged) && f.lead_time.errors }"
                         style="width:5em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; background-color:#eaf0fd"
                         (change)="leadTimeChange($event)" (mousedown)="$event.stopPropagation()" />
                </div>
                <div *ngIf="(submitted || lead_timeChanged) && f.lead_time.errors">
                  <div *ngIf="f.lead_time.errors.pattern"
                       style="background-color:#f9d2de; margin-left:-10em; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">
                    Invalid lead time. format: ?d?h?m
                  </div>
                </div>
              </div>

              <div *ngIf="due_datetime==''" style="margin-left:3em;width:4.25em ">
                <div style="font-size: small; margin-top:0.25em" (click)="showOrginalLeadTimeDueDate=true">
                  Due
                  Date
                </div>
                <div style="display:flex;margin-top:0.05em">
                  <input type="text" formControlName="due_date" maxlength="9" placeholder="?d?h?m" class="form-control"
                         [ngClass]="{ 'is-invalid': (submitted || due_dateChanged) && f.due_date.errors }"
                         style="width:5em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; background-color:#eaf0fd"
                         (change)="dueDateChange($event)" (mousedown)="$event.stopPropagation()" />
                </div>
                <div *ngIf="(submitted || due_dateChanged) && f.due_date.errors">
                  <div *ngIf="f.due_date.errors.pattern"
                       style="background-color:#f9d2de; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">
                    Invalid due date. format: ?d?h?m
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="display: inline-flex;">
            <div>
              <div style="justify-content: space-between; width: 100%">
                <div style="display: inline-flex; max-height: 1em;">
                  <label style="width: 26em; margin-bottom: 0em;">Public Notes</label>
                  <button type="button" mat-button color="primary" style="outline:none; font-size: small"
                          [matMenuTriggerFor]="menu1">
                    Export
                  </button>
                  <mat-menu #menu1="matMenu">
                    <div class="example-box" (click)="exportNote('word', true)">Word</div>
                    <div class="example-box" (click)="exportNote('pdf', true)">PDF</div>
                    <div class="example-box" (click)="exportNote('text', true)">Text</div>
                    <div class="example-box" [cdkCopyToClipboard]="clickCopyNote(true)">Copy to Clipboard</div>
                  </mat-menu>
                  <button mat-icon-button (click)="clickOpenInNew(1)" style="cursor: pointer; margin-top: -0.75em;">
                    <i class="material-icons" style="font-size:x-large; color: gray">open_in_full</i>
                  </button>
                </div>

                <textarea formControlName="public_note" class="form-control" style="width:40em; height:10.85em;font-family:Courier New, Courier, monospace;font-size:small;
                padding:0em 0em 0em 0.25em;background-color:#eaf0fd" (ngModelChange)="updateNotes($event, true)"
                          (mousedown)="$event.stopPropagation()">
                {{public_notes}}
              </textarea>
              </div>
              <ng-template #publicNotesWrapper>
                <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="background-color:white;">
                  <div id="public_notes" class="form-control landing-notes" style="height: max-content; font-size:small; padding:1em 1em 1em 1em;background-color: white;
                    white-space: pre-wrap; overflow: auto;" readonly>
                    <div style="display: inline-flex; justify-content: space-between; width: 100%;">
                      Public Notes
                      <button mat-icon-button mat-dialog-close style="margin-top: -0.5em" (click)="closeNotesPopup(0)">
                        <i class="material-icons" style="cursor: pointer;" title="Close">close </i>
                      </button>
                    </div>
                    <textarea formControlName="public_note" class="form-control" style="width:45em; height:45em;font-family:Courier New, Courier, monospace;font-size:small;
                      padding:0em 0em 0em 0.25em;background-color:#eaf0fd" (mousedown)="$event.stopPropagation()"
                              (ngModelChange)="updateNotes($event, true)">
                      {{public_notes}}
                  </textarea>
                    <button mat-button mat-dialog-close class="btn-low"
                            style="background-color:grey; color: white; margin-top: 1em;" (click)="closeNotesPopup(0)">
                      <div style="display:flex">
                        <i class="material-icons"
                           style="font-size: medium; margin-top:0.25em">close</i>
                        <div>Close</div>
                      </div>
                    </button>&nbsp;
                  </div>
                </div>
              </ng-template>

              <div style="justify-content: space-between; width: 100%; padding-top: 0.5em;">
                <div style="display: inline-flex; max-height: 1em;">
                  <label style="width: 26em; margin-bottom: 0em;">Internal Notes</label>
                  <button type="button" mat-button color="primary" style="outline:none; font-size: small"
                          [matMenuTriggerFor]="menu2">
                    Export
                  </button>
                  <mat-menu #menu2="matMenu">
                    <div class="example-box" (click)="exportNote('word', false)">Word</div>
                    <div class="example-box" (click)="exportNote('pdf', false)">PDF</div>
                    <div class="example-box" (click)="exportNote('text', false)">Text</div>
                    <div class="example-box" [cdkCopyToClipboard]="clickCopyNote(false)">Copy to Clipboard</div>
                  </mat-menu>
                  <button mat-icon-button (click)="clickOpenInNew(2)" style="cursor: pointer; margin-top: -0.75em;">
                    <i class="material-icons" style="font-size:x-large; color: gray">open_in_full</i>
                  </button>
                </div>
                <textarea formControlName="internal_note" class="form-control" style="width:40em; height: 12.155em;font-family:Courier New, Courier, monospace; font-size:small;
                  padding:0em 0em 0em 0.25em;background-color:#eaf0fd" (ngModelChange)="updateNotes($event, false)"
                          (mousedown)="$event.stopPropagation()">
                  {{internal_notes}}
                </textarea>
              </div>
              <ng-template #internalNotesWrapper>
                <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="background-color:white;">
                  <div id="internal_notes" class="form-control landing-notes" style="height: max-content; font-size:small; padding:1em 1em 1em 1em;background-color: white;
                    white-space: pre-wrap; overflow: auto;" readonly>
                    <div style="display: inline-flex; justify-content: space-between; width: 100%;">
                      Internal Notes
                      <button mat-icon-button mat-dialog-close style="margin-top: -0.5em" (click)="closeNotesPopup(1)">
                        <i class="material-icons" style="cursor: pointer;" title="Close">close </i>
                      </button>
                    </div>
                    <textarea formControlName="internal_note" class="form-control" style="width:45em; height:45em;font-family:Courier New, Courier, monospace;font-size:small;
                      padding:0em 0em 0em 0.25em;background-color:#eaf0fd" (mousedown)="$event.stopPropagation()"
                              (ngModelChange)="updateNotes($event, false)">
                      {{internal_notes}}
                  </textarea>
                    <button mat-button mat-dialog-close class="btn-low"
                            style="background-color:grey; color: white; margin-top: 1em;" (click)="closeNotesPopup(1)">
                      <div style="display:flex">
                        <i class="material-icons"
                           style="font-size: medium; margin-top:0.25em">close</i>
                        <div>Close</div>
                      </div>
                    </button>&nbsp;
                  </div>
                </div>
              </ng-template>

              <div>
                <div style="display: inline-flex; width: 32.625em;  padding-top: 0.5em;">
                  <div style="width: 28em; display: inline-flex;">
                    <label style="margin-bottom: 0em;">Documents</label>
                    <label mat-button class="btn-high"
                           style="border: 0.0625em solid transparent; background-color: transparent; height:1.75em; border-radius: 0.25em; cursor:pointer; margin-left: 0.5em; margin-right: 0.5em;">
                      <input #document formControlName="document" id="document" type="file" ng2FileDrop
                             [uploader]="uploader" multiple style="display:none" accept="*" (change)="importFile($event)"
                             maxFileSize="28000000" (click)="onFileClick($event)" />
                      <div style="display: flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: white; transform: rotate(-90deg);">attachment</i></div><span *ngIf="!finishAllUploads" class="spinner-border spinner-border-sm mr-1"></span>
                    </label>
                    <mat-slide-toggle style="transform:scale(.8)" (change)="toggleDeleted()" color="primary">Show Deleted</mat-slide-toggle>
                  </div>

                  <mat-slide-toggle (change)="showDocuments = !showDocuments" style="margin-left: -7em;" color="primary">
                    <div style="text-wrap: wrap; font-size: small; line-height: normal">Show Accounting Status</div>
                  </mat-slide-toggle>
                </div>

                <div *ngIf="showDocuments" style="border-radius: 0.25em;border: 1px solid rgb(206, 212, 218);
                  height: 6em; width: 32.5em;" ng2FileDrop [uploader]="uploader" (onFileDrop)="dropFile($event)"
                     accept="*" multiple>
                  <div style="height: 100%;">
                    <div style="overflow-y:auto; height: 100%;">
                      <div *ngFor="let item of taskDocumentList; let i=index" style="margin-top:-0.25em">
                        <div style="font-size: small; display:flex; padding:0em; height: 2em;">
                          <div>
                            <a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline"
                               title="{{item.documentName}}"
                               (click)="clickPreviewDocument($event, item)">{{item.documentName}}</a>
                          </div>
                          <div *ngIf="item.groundStopTaskDocumentGUID=='' && hasExistingDocument"
                               style="margin-top:0.5em; font-size:x-small">
                            <i class="material-icons" style="color:green; font-size: small; ">fiber_new</i>
                          </div>
                          <div style="margin-top:0.25em; margin-right: 0.25em" *ngIf="!lockdownTrip && item.isActive">
                            <i class="material-icons" style="color:red; font-size: small; cursor: pointer;"
                               title="Delete file"
                               (click)="clickRemoveDocument(item)">cancel</i>
                          </div>
                          <div *ngIf="!item.isActive" title="Undelete file"
                               style="cursor: pointer; border-radius:0.25em; margin-left:.25em; height: 1.5em; user-select: none; padding: 0 .25em 0 .25em"
                               class="d-block alert-danger" (click)="clickRemoveDocument(item)">Deleted</div>
                          <div *ngIf="!item.isInternal && item.isActive" title="Toggle Public/Internal"
                               style="cursor: pointer; user-select: none;" (click)="toggleInternalFile(item)">
                            <i class="material-symbols-outlined">more_horiz</i>
                          </div>
                          <div *ngIf="item.isInternal && item.isActive" title="Toggle Public/Internal"
                               style="cursor: pointer; height: 1.5em; border-radius: 0.25em; user-select: none; padding: 0 .25em 0 .25em "
                               class="d-block alert-warning" (click)="toggleInternalFile(item)">Internal</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="!showDocuments" style="height: 6em;
                  border: 1px solid rgb(206, 212, 218);
                  border-radius: 0.25rem">
                  <div style="font-weight: bold; font-size: small; margin-left: 0.5em; margin-right: 0.5em; border-bottom: 1px solid #aaa;">
                    Accounting Status
                  </div>
                  <div style="display: flex; flex-direction: row; width: 15em;">
                    <div style="margin-left:0.5em;">
                      <label for="acct_status_select" style="font-size:small">GTS - Status</label>
                      <div *ngIf="!canChangeBilling" style="margin-top:-0.5em; color: #155724">
                        {{selectedAcctStatusDescription}}
                      </div>
                      <div *ngIf="canChangeBilling">
                        <select *ngIf="f" formControlName="acct_status_select"
                                style="width:10em; font-size: small; font-weight:500; border-radius:0.25em;border-color:lightgrey;background-color:#eaf0fd">
                          <option *ngFor="let a of acctStatusList" [ngValue]="a.accountingStatusGUID">
                            {{a.accountingStatusDescription}}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div style="margin-left:3em">
                      <label for="acct_status_tp_select" style="font-size:small">Third Party - Status</label>
                      <div *ngIf="!canChangeBilling" style="margin-top:-0.5em; color: #155724">
                        {{selectedTaskStatusDescription}}
                      </div>
                      <div *ngIf="canChangeBilling">
                        <select *ngIf="f" formControlName="acct_status_tp_select"
                                style="width:10em; font-size: small; font-weight:500; border-radius:0.25em;border-color:lightgrey;background-color:#eaf0fd">
                          <option *ngFor="let a of acctStatus_TPList" [ngValue]="a.accountingStatusGUID">
                            {{a.accountingStatusDescription}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin-left: 0.5em">
              <div style="width:37em; height:5.5em; font-size:small; padding:0em 0em 0em 0em;
                white-space: pre-wrap; overflow: auto; border-radius: 0.25em;border: 1px solid rgb(206, 212, 218);
                margin-top: -10em; display: flex; margin-bottom: 0.5em;"
                   [ngStyle]="{'background-color': permitHexColor}">
                <div style="font-size:small; text-align: center; width: 8em; display: flex; flex-direction: column; justify-content: space-between;">
                  <label style="text-align: center; height: 3em;">Permit Required</label>
                  <div style="font-weight: 800; text-align: center; margin-bottom: 0.25em;">
                    {{permitRequired}}
                  </div>
                </div>

                <div style="margin-left:1em;font-size:small; text-align: center; width: 8em; display: flex; flex-direction: column; justify-content: space-between;">
                  <label style="text-align: center; height: 3em;">Required Documents</label>
                  <div style="font-weight: 800; text-align: center; margin-bottom: 0.25em;">
                    {{requiredDocumentCount}}
                  </div>
                </div>

                <div style="margin-left:1em;font-size:small; text-align: center; width: 8em; display: flex; flex-direction: column; justify-content: space-between;">
                  <label style="text-align: center; height: 3em;">Validity</label>
                  <div style="font-weight: 800; text-align: center; margin-bottom: 0.25em;">{{validityTime}}</div>
                </div>

                <div style="margin-left:3em;">
                  <label for="immediateAction" style="font-size:small; height: 3em;">Immediate Priority</label>
                  <div style="margin-top:-0.5em;">
                    <select *ngIf="f" formControlName="immediateAction"
                            style="width:7em;height:1.5625em; font-size: small;padding:0em; border-radius:0.25em; background-color:#eaf0fd"
                            class="form-control">
                      <option value="0" selected>No</option>
                      <option value="1">Yes</option>
                    </select>
                  </div>
                </div>
              </div>

              <div>
                <div style="display: inline-flex; height: 1em; padding-top: 0.5em;">
                  <div>
                    <label class="country-notes-label"
                           (click)="openCountryNotesDetailed(selectedLandingCountry, false)">Landing Country Notes</label>
                    <button mat-button class="btn-med"
                            style="height: 2em; margin-left: 1em; position: relative; top: -0.35em"
                            (click)="openCountryNotesDetailed(selectedLandingCountry)">
                      <div style="display: inline-flex;">
                        <span class="material-symbols-outlined" style="font-size: 22px; margin-right: 0.25em;">
                          open_in_new
                        </span>
                        Details
                      </div>
                    </button>
                  </div>

                  <div style="margin-top: -0.75em;">
                    <button mat-icon-button style="margin-left: 9.5em;">
                      <i class="material-icons" style="cursor:pointer;font-size:large; color: gray"
                         title="Copy notes to clipboard" [cdkCopyToClipboard]="clickCopyNotes() ">content_copy</i>
                    </button>
                    <button mat-icon-button *ngIf="country_landing_notes !== ''" (click)="clickOpenInNew(3)"
                            style="cursor: pointer; margin-right: -1em;">
                      <i class="material-icons"
                         style="font-size:x-large; color: gray">open_in_full</i>
                    </button>
                  </div>
                </div>
                <div id="country_landing_notes" class="form-control landing-notes" style="width:37em; height: 39em; font-size:small; padding:0em 0em 0em 0em;background-color: #E9ECEF;
                  white-space: pre-wrap; overflow: auto;" readonly [innerHTML]="country_landing_notes | safeHtml"
                     (click)="innerHtmlClick($event)"></div>
                <ng-template #countryNotesWrapper>
                  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="background-color:white;">
                    <div id="country_landing_notes" class="form-control landing-notes" style="width:45em; height:45em; font-size:small; padding:1em 1em 1em 1em;background-color: white;
                      white-space: pre-wrap; overflow: auto; display: inline-flex; justify-content: space-between;"
                         readonly>
                      <div style="max-width: 45em;" [innerHTML]="country_landing_notes | safeHtml"
                           (click)="innerHtmlClick($event)">
                      </div>
                      <button mat-icon-button mat-dialog-close style="margin-top: -0.5em" (click)="closeNotesPopup(2)">
                        <i class="material-icons" style="cursor: pointer;" title="Close">close </i>
                      </button>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </form>
  </div>
  <div
    style="justify-content:space-between;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display:flex; height:2.75em">
    <div style="display: inline-flex;">
      <button *ngIf="(!lockdownTrip && !showSpin) || (lockdownTrip && !showSpin &&  grantAccessToTripCloseout)" mat-button class="btn-high" (click)="clickSave()"><span *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"></span>
        <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i>
          <div>Save</div>
        </div>
      </button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white;">
        <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
          <div>Close</div>
        </div>
      </button>&nbsp;
      <!-- <button *ngIf="businessRulesID==0 && landing_permit_task_edit_form" class="btn-low" mat-button
        (click)="clickPromoteBusinessRule()">
        <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">grading</i>
          <div>Promote Business Rule</div>
        </div>
      </button>&nbsp; -->
      <button mat-button class="btn-low" *ngIf="hasTaskAudit==true" (click)="clickAudit()">
        <div style="display:flex"><i class="material-icons"
            style="font-size: medium; margin-top:0.25em">manage_search</i>
          <div>Audit</div>
        </div>
      </button>&nbsp;
      <div *ngIf="showSuccessMsg" style="margin-top: 0em;">
        <app-success-message [successMsg]=""></app-success-message>
      </div>
      <div *ngIf="errMsg!=''" class="d-block"
        style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; max-width:40em; ">
        <label style="color:black; font-size: small">{{errMsg}}</label>
      </div>
    </div>

    <form [formGroup]="landing_permit_task_edit_form" *ngIf="landing_permit_task_edit_form">
      <div style="font-size: small; float: right; margin-right: 3em; margin-top: -0.5em; display: inline-flex;">
        <div style="margin-right: 2em; margin-top: -0.25em;">
          <label for="record_status" style="font-size:small">Disable Record</label>
          <div style="margin-top:-0.5em;">
            <select *ngIf="f" formControlName="record_status"
              style="font-size: small;background-color:#eaf0fd;border-radius:0.25em;width: 7em; height:1.5625em; padding: 0;"
              class="form-control"
              (change)="record_statusChange($event)">
              <option value="1" selected>No</option>
              <option value="0">Yes</option>
            </select>
          </div>
        </div>
        <div>
          <div>
            Modified By: {{modifiedBy}}
          </div>
          <div>
            Modified Date: {{modifiedDate}}
          </div>
        </div>
        <div *ngIf="businessRulesID != 0" style="margin-left: 1em; font-size: small;">
          BrID
          <div>
            {{businessRulesID}}
          </div>
        </div>
      </div>
    </form>

    <div *ngIf="errMsg2!=''" class="d-block"
      style="margin-top:0em; margin-left:0.25em; display:flex; background-color:#f9d2de; padding-left:0.25em; width:24em;font-size: small ">
      {{errMsg2}}</div>
  </div>
</div>

