<div class="divLeft" style=" margin-top: 0em;" [ngClass]="isDialog? 'greyBack':''" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div>
    <div>
      <div style="border-radius: 0.25em; padding: 0.1em 1em 0em 0.1em; width: 100%; display: inline-block; max-height: 5em; ">
        <div style="display: flex; justify-content: space-between;">
          <div style="display: flex; flex-direction: column; margin-left: -1em;">
            <div style="display: inline-flex;">
              <i class="material-icons-outlined" style=" margin-top: 0.2em; margin-left: 1em; margin-right: 1em;">engineering</i>
              <h4>Data Maintenance</h4>
            </div>

          </div>
          <div style="display: flex; justify-content: flex-end; margin-right: -1em;">
            <button mat-icon-button mat-dialog-close style="background: none; box-shadow: none;">
              <i class="material-icons" style="font-size: x-large;">close</i>
            </button>

          </div>
        

        </div>
    </div>


      <div style="display: flex; flex-direction: column; background-color: white; margin: 0em 0.4em 0.3em 0.3em;">
        <div style="display: inline-flex; padding: 1em;">
          <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 26em; background-color: #f2faff !important; color: Black !important; text-align: left; padding: 0.5em 1em;"
                  (click)="openManageContactDetailsComponent()">
            <i class="material-symbols-outlined" style="float: left;">Settings_account_box</i>
            <span style="margin-left: 1.0em; font-weight: 500; font-size: 1.2em;">Manage Crew, PAX & Support Profiles</span>
          </button>
          <div style="font-weight: 300; font-size: small; width: 30em;">
            Add and view person records, including documents, phone numbers, emails, addresses, and travel memberships
          </div>
        </div>



        <div style="display: inline-flex; align-items: center; padding: 1em;">
          <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 26em; background-color: #f2faff !important; color: Black !important; display: flex; align-items: center; padding-left: 1em;" (click)="openCustomerCommlistComponent()">
            <i class="material-symbols-outlined" style="margin-right: 0.5em;">business</i>
            <span style="font-weight: 500; font-size: 1.2em;">Company Profile</span>
          </button>
          <div style="font-weight: 300; font-size: small; width: 30em;">
            Add and view company profile phone numbers, emails, addresses and documents
          </div>
        </div>

        <div style="display: inline-flex; align-items: center; padding: 1em;">
          <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 26em; background-color: #f2faff !important; color: Black !important; display: flex; align-items: center; padding-left: 1em;" (click)="openAircraftProfileComponent()">
            <i class="material-symbols-outlined" style="margin-right: 0.5em;">flight</i>
            <span style="font-weight: 500; font-size: 1.2em;">Aircraft Profile</span>
          </button>
          <div style="font-weight: 300; font-size: small; width: 30em;">
            View all aircraft profiles
          </div>
        </div>


        <!-- <div style="display: inline-flex; padding: 1em;">
          <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 26em; background-color: #f2faff !important; color: Black !important; text-align: left; padding: 0.5em 1em;"
                  (click)="openCompanyEventListComponent()">
            <i class="material-symbols-outlined" style="float: left;">event</i>
            <span style="margin-left: 1.0em; font-weight: 500; font-size: 1.2em;">Manage Company Events</span>
          </button>
          <div style="font-weight: 300; font-size: small; width: 30em;margin-top:.5em;">
            Add or disable a company events announcement
          </div>
        </div> -->


      </div>

  </div>
  </div> 
