<div *ngIf="upPII && grantAccessCompanyProfiles" class="divLeft">
  <div>
   <!-- <h5 *ngIf="userType=='customer'">Company Profile  </h5>-->
    <h5 *ngIf="userType=='internal'">Client Office Addresses, Comms and Documents</h5>
    <div>
      <form *ngIf="customer_documentlist_form" [formGroup]="customer_documentlist_form" (ngSubmit)="onSubmit()">
        <div *ngIf="userType=='internal'" style="margin-left:0em;padding:0em; display: flex">
          <div *ngIf="userType=='internal'" class="form-group" style="margin-top:0em; margin-right:0.625em">
            <label for="customer_select" style="font-size:small">Select Client</label>
            <div style="margin-top:-0.5em;">
              <select *ngIf="f" formControlName="customer_select" style="font-size: small;width:19.5em;border-radius:0.25em; border-color:lightgrey" (change)="customerChange($event)">
                <option value="" selected>Client Name</option>
                <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
              </select>
            </div>
          </div>
          <div style="margin-left: 0em;margin-top:0em; ">
            <label for="text_search" style="font-size:small">Search by {{textSearchBy}}</label>
            <div class="form-group" style="margin-left: 0em; margin-top:-0.35em">
              <input type="text" formControlName="text_search" class="form-control" style="width:9.75em;height:1.4em" />
            </div>
          </div>
          <div style="margin-left: 0.3125em; margin-top:0em">
            <label for="filter_by" style="font-size:small">Filter by</label>
            <div class="form-group" style="margin-left: 0em; margin-top:-0.5em">
              <select *ngIf="f" formControlName="filter_by" style="font-size: small;border-radius:0.25em; border-color:lightgrey">
                <option value="DocumentName" selected>Document Name</option>
                <option value="DocumentType">Document Type</option>
                <!--<option value="VendorServiceDocRequirement">Vendor Service Doc Requirement</option>-->
              </select>
            </div>
          </div>
          <div class="form-group" style="margin-left: 0.3125em;margin-top:1.25em">
            <button [disabled]="loading" mat-button class="btn-high" style="width: 5em;">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Search
            </button>&nbsp;
            <button type="button" mat-button class="btn-high" (click)="clickShowAllRecords()" style="width: 10em">Show All Records</button>&nbsp;
            <button type="button" mat-button class="btn-low" (click)="clickReset()" style="width: 5em">Reset</button>&nbsp;

          </div>
          <div class="form-group" style="margin-left: 0.3125em;margin-top:1.25em">
            <input type="checkbox" style="background-color: white" formControlName="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)" /><label for="includeDisabledRecords" style="font-size:small">&nbsp;Include disabled records</label>
          </div>
        </div>
        <!--<div *ngIf="userType=='customer' && grantAccessToAccountGrouping && customerList.length>0" style="margin-left:0em;padding:0em; display: flex">
          <div class="form-group" style="margin-top:0em; margin-right:0.625em">
            <label for="customer_select" style="font-size:small">Select Client</label>
            <div style="margin-top:-0.5em;">
              <select *ngIf="f" formControlName="customer_select" style="font-size: small;width:19.5em;border-radius:0.25em; border-color:lightgrey" (change)="customerChange($event)">
              
                <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
              </select>
            </div>
          </div>
        </div>-->
        <div *ngIf="msg!=''" class="inline-block"><label style="color:forestgreen">&nbsp;{{msg}}</label></div>
        <div *ngIf="errMsg!=''" class="inline-block"><label style="color: #c00000">{{errMsg}}</label></div>
        

        <div style="display: flex; justify-content: space-between; align-items: center;">
          <div *ngIf="userType=='internal'" class="form-group form-inline">
            <ul class="tabul">
              <li [ngClass]="{ 'btn-high':selectedTabName=='comm','btn-low pointer':selectedTabName!='comm'  }" style="border-left: black 0.0625em solid;" (click)="clickTab('comm')"><div style="margin-top:0.25em">Comms Phones & Emails</div></li>
              <li [ngClass]="{ 'btn-high':selectedTabName=='mail','btn-low pointer':selectedTabName!='mail' }" (click)="clickTab('mail')"><div style="margin-top:0.25em">Mail Address</div></li>
              <li [ngClass]="{ 'btn-high':selectedTabName=='document','btn-low pointer':selectedTabName!='document' }" (click)="clickTab('document')"><div style="margin-top:0.25em">Documents</div></li>
            </ul>
          </div>
          <div style="display: flex; font-size: small; justify-content: flex-end; gap: 0.5em;margin-left:68.5em;margin-top:-8em;">
            Total Record Count: {{recordList.length}}
          </div>
        </div>


        <div style="margin-top: -2.3em; margin-left: -1.2em;">
          <table mat-table *ngIf="recordList && recordList.length>0" [dataSource]="recordList" class="example-container mat-elevation-z8">
            <tr>
              <ng-container matColumnDef="document_name">
                <th mat-header-cell *matHeaderCellDef style="width: 20%">
                  Document Name
                </th>
                <td mat-cell *matCellDef="let element"><a *ngIf="element.hasDocument" (click)="clickPreviewDoc($event, element);">{{element.documentName}}</a></td>
              </ng-container>
              <!--<ng-container matColumnDef="preview">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">
                  &nbsp;
                </th>
                <td mat-cell *matCellDef="let element">
                  <a class="a1" *ngIf="element.hasDocument" (click)="clickPreviewDoc($event, element);">Preview</a>
                </td>
              </ng-container>-->
              <ng-container matColumnDef="doc_type">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">
                  Type
                </th>
                <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.documentTypeList}}</td>
              </ng-container>
              <ng-container matColumnDef="effective_date">
                <th mat-header-cell *matHeaderCellDef style="width: 8%">
                  Effective Date
                </th>
                <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.effectiveDate}}</td>
              </ng-container>
              <ng-container matColumnDef="expiration_date">
                <th mat-header-cell *matHeaderCellDef style="width: 8%">
                  Expiration Date
                </th>
                <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger': element.isExpired}" >{{element.expirationDate}}</td>
              </ng-container>
              <ng-container matColumnDef="data_entry_status">
                <th mat-header-cell *matHeaderCellDef style="width:8%">
                  <div style="margin-top:-0.25em;">Data Entry Status</div>
                </th>
                <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger': element.dataEntryStatusID!=2}">{{ element.dataEntryStatusDescription }}</td>
              </ng-container>
              <ng-container matColumnDef="disabled" style="width: 5%">
                <th mat-header-cell *matHeaderCellDef [hidden]="hideColumn">
                  Disabled
                </th>
                <td mat-cell *matCellDef="let element" [hidden]="hideColumn">{{element.isActive? '': 'Yes'}}</td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width: 5%">Action</th>
                <td mat-cell *matCellDef="let element; let i=index ">
                  <a mat-button class="btn-low grid" *ngIf="userType=='internal'" (click)="clickEdit($event, element);">
                    Edit
                  </a>
                  <a mat-button class="btn-low grid" *ngIf="userType=='customer'" (click)="clickEdit($event, element);">
                    View
                  </a>
                </td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
        </div>
        <!--<div>
    <mat-paginator style="border: none; width:86.75em" #paginator *ngIf="recordList && totalPageNumber>1" [length]="totalRecords" [pageSize]="pageSize"
                   [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
    </mat-paginator>
  </div>-->
        <div style="margin-top:0.5em">
          <button type="button" mat-button class="btn-high" (click)="clickEdit($event, null)">Add new Record</button>&nbsp;
          <button type="button" *ngIf="userType=='customer' && grantAccessToMessages" mat-button class="btn-low" (click)="clickSendEmail($event)"><span class="material-symbols-outlined" style="font-size: large;  ">send</span> Email</button>
        </div>
      </form>
    </div>
  </div>
</div>
<app-spin *ngIf="showSpin"  style="width: 6em; display: block; margin-left: 27em;"></app-spin>
