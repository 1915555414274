<div [ngClass]="isDialog? 'greyBack':''" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div *ngIf="hasPermission" class="mt-1 ml-1" style="width:max-content">
    <form *ngIf="company_event_form" [formGroup]="company_event_form">
      <div class="d-flex justify-content-between">

        <div style="display: flex; align-items: center; gap: 1em; padding: 0.5em;">
          <span class="material-icons-outlined" style="font-size: 1.5em; line-height: 1;">event</span>
          <h4 style="font-size: 1.2em;   margin: 0;">{{customerName}} Events</h4>

          <div *ngIf="userType=='customer' && grantAccessToAccountGrouping && customerList.length>0"
               style="margin-left: auto; display: flex; align-items: center;">
            <div class="form-group form-inline" style="display: flex; align-items: center; margin: 0;">
              <label for="customer_select" style="font-size: small; margin-right: 0.5em;">Select Client</label>
              <select *ngIf="f"
                      formControlName="customer_select"
                      class="form-control form_control-sm dropdown"
                      (change)="customerChange($event)"
                      style="margin-left: 0.5em; height: auto;">
                <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">
                  {{cus.customerName}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="d-inline-flex">
          <div><mat-checkbox [(ngModel)]="includeDisabled" (change)="getData()" [ngModelOptions]="{standalone: true}" style="transform:scale(.8)">Include Disabled</mat-checkbox></div>
          <div><mat-checkbox [(ngModel)]="includeExpired" (change)="getData()" [ngModelOptions]="{standalone: true}" style="transform:scale(.8)">Include Past Events</mat-checkbox></div>
        </div>

        <div>
          <button mat-icon-button *ngIf="isDialog" (click)="openDataMaintenanceComponent()">
            <i class="material-icons-outlined" style="font-size:xx-large;  ">arrow_circle_left</i>
          </button>
         <!-- <button mat-icon-button *ngIf="isDialog" (click)="clickRouteNew('current-events/company-events-list')">
            <i class="material-icons" style="font-size:x-large;  ">open_in_new</i>
          </button>-->
          <button mat-icon-button *ngIf="isDialog" mat-dialog-close>
            <i class="material-icons"
               style="font-size: x-large;">close </i>
          </button>
        </div>
      </div>
      <div class="mt-2">

        <table mat-table [dataSource]="companyEventList" class="example-container">
          <tr>
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef width="10%">
                Title
              </th>
              <td mat-cell *matCellDef="let element">{{element.companyEventTitle}}</td>
            </ng-container>
            <ng-container matColumnDef="story">
              <th mat-header-cell *matHeaderCellDef width="25%">
                Story
              </th>
              <td style="text-align:left !important;" mat-cell *matCellDef="let element">{{element.companyEventStoryText.length>500? element.companyEventStoryText.substring(0,496) + '...' : element.companyEventStoryText}}</td>
            </ng-container>
            <ng-container matColumnDef="attachment_count">
              <th mat-header-cell *matHeaderCellDef width="5%">
                Attachments
              </th>
              <td mat-cell *matCellDef="let element">{{element.attachmentCount>0?element.attachmentCount:''}}</td>
            </ng-container>
            <ng-container matColumnDef="weblink">
              <th mat-header-cell *matHeaderCellDef width="5%">
                Weblink
              </th>
              <td mat-cell *matCellDef="let element">{{element.documentWeblink!=null?'Yes':''}}</td>
            </ng-container>
            <ng-container matColumnDef="display_start">
              <th mat-header-cell *matHeaderCellDef width="8%">
                Display Date Start
              </th>
              <td mat-cell *matCellDef="let element">{{element.displayDateStart}}</td>
            </ng-container>
            <ng-container matColumnDef="display_end">
              <th mat-header-cell *matHeaderCellDef width="8%">
                Display Date End
              </th>
              <td mat-cell *matCellDef="let element">{{element.displayDateEnd}}</td>
            </ng-container>
            <ng-container matColumnDef="effective_date">
              <th mat-header-cell *matHeaderCellDef width="10%">
                Effective Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.effectiveDate}} {{element.validTimeStart}} {{element.validTimeStart==null? '': element.isUTC? 'UTC': 'Local'}}</td>
            </ng-container>
            <ng-container matColumnDef="expiration_date">
              <th mat-header-cell *matHeaderCellDef width="10%">
                Expiration Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.expirationDate}} {{element.validTimeEnd}} {{element.validTimeEnd==null? '' : element.isUTC? 'UTC': 'Local'}}</td>
            </ng-container>
            <ng-container matColumnDef="disabled">
              <th mat-header-cell *matHeaderCellDef width="5%">
                Disabled
              </th>
              <td mat-cell *matCellDef="let element">{{element.isActive? '': 'Yes'}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="th" width="5%">Action<br /><a mat-button class="btn-high grid" (click)="clickEdit($event, null)">ADD</a></th>
              <td mat-cell *matCellDef="let element; let i=index ">
                <a mat-button class="btn-low grid">
                  EDIT
                </a>
              </td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="clickEdit($event, row);"></tr>
        </table>
        <app-spin *ngIf="showSpin"></app-spin>
      </div>
    </form>
  </div>
  </div>
