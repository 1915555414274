<div class="divLeft" style=" margin-top: 0em;" [ngClass]="isDialog? 'greyBack':''" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div>
    <div>
      <div style="border-radius: 0.25em; padding: 0.1em 1em 0em 0.1em; width: 100%; display: inline-block; max-height: 5em; ">
        <div style="display: flex; justify-content: space-between;">
          <div style="display: flex; flex-direction: column; margin-left: -1em;">
            <div style="display: inline-flex;">
              <i class="material-icons-outlined" style=" margin-top: 0.2em; margin-left: 1em; margin-right: 1em;">event_note</i>
              <h4>Schedules</h4>
            </div>

          </div>
          <div style="display: flex; justify-content: flex-end; margin-right: -1em">
            <button mat-icon-button mat-dialog-close style="background: none; box-shadow: none;">
              <i class="material-icons" style="font-size: x-large;">close</i>
            </button>

          </div>
        </div>
        <div>
        </div>

      </div>
    </div>


    <!--top right bottom left-->
    <div style="display: flex; flex-direction: column; background-color: white; margin: 0em 0.4em 0.3em 0.3em; width:48.5em;">
      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 17em; background-color: #f2faff !important; color: Black !important; text-align: left; padding: 0.5em 1em;"
                (click)="openTripQuickSearchControlClientComponent()">
          <i class="material-symbols-outlined" style="float: left;">event_available</i>
          <span style="margin-left: 1.0em; font-weight: 500; font-size: 1.2em;">Trip Schedules</span>
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          View all current and future trip
        </div>
      </div>



      <div style="display: inline-flex; align-items: center; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 17em; background-color: #f2faff !important; color: Black !important; display: flex; align-items: center; padding-left: 1em;" (click)="openTripLeglistClientComponent()">
          <i class="material-symbols-outlined" style="margin-right: 0.5em;">today</i>
          <span style="font-weight: 500; font-size: 1.2em;">Daily View</span>
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          View trip legs within 24-hour, 48-hour, 72-hour, 7-day, or 14-day windows
        </div>
      </div>

      <div style="display: inline-flex; align-items: center; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 17em; background-color: #f2faff !important; color: Black !important; display: flex; align-items: center; padding-left: 1em;" (click)="openGroundStopCreatorClientDialog()">
          <i class="material-symbols-outlined" style="margin-right: 0.5em;">airline_stops</i>
          <span style="font-weight: 500; font-size: 1.2em;">Submit a Trip</span>
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Create a new trip
        </div>
      </div>


      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 17em; background-color: #f2faff !important; color: Black !important; text-align: left; padding: 0.5em 1em;"
                (click)="openTripSummaryReportDialogComponent()">
          <i class="material-symbols-outlined" style="float: left;">summarize</i>
          <span style="margin-left: 1.0em; font-weight: 500; font-size: 1.2em;">Trip Summary Report</span>
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Generate a trip leg report that includes crew and passenger passport details, services, and handling briefs
        </div>
      </div>


    </div>

  </div>
</div>
