import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CommAddressTypeModel } from '../models/comm-address-type.model';
import { AddressRatingModel } from '../models/address-rating.model';
import { AddressUseModel } from '../models/address-use.model';
import { VendorService } from '../services/vendor.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { PersonClassModel } from '../models/person-class.model';
import { PersonService } from '../services/person.service';
import { CountryModel } from '../models/country.model';
import { PersonRoleModel } from '../models/person-role.model';
import { PersonModel } from '../models/person.model';
import { ReturnObjModel } from '../models/return-obj.model';

@Component({
  selector: 'app-vendor-person-edit-dialog',
  templateUrl: './vendor-person-edit-dialog.component.html',
  styleUrls: ['./vendor-person-edit-dialog.component.css']
})

export class VendorPersonEditDialogComponent implements OnInit, AfterViewInit {
  @Input() dialogTitle: string;
  @Input() personGUID: string;
  @Input() vendorGUID: string;
  
  person_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  personClassList: PersonClassModel[];
  countryList: CountryModel[];
  personRoleList: PersonRoleModel[]; 
  avaiPersonRoleList: PersonRoleModel[];
  selPersonRoleList: PersonRoleModel[];
  selectedPersonClass: PersonClassModel;
  firstName: string;
  lastName: string;
  middleName: string;
  title: string;
  dob: Date;
  gender: string;
  city: string;
  state_province: string;
  selectedBirthCountryGUID: string;
  selectedResidenceCountryGUID: string;
  selectedNationalityCountryGUID: string;
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;
  userType: string;
  isModified: number = 0;
  returnObj: ReturnObjModel;
  showContactButton: boolean = false;
  showSuccessMsg: boolean = false;
  includeInBrief: boolean = false;
  sortOrder: number;
  //@Output() pageChanged = new EventEmitter();
  //@Output() pId: string;

  constructor(private readonly _dialogRef: MatDialogRef<VendorPersonEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _personService: PersonService,
    private readonly _dialogService: DialogService
  ) {
    this.personGUID = _data.personGUID;
    if (_data.vendorGUID == null) {
      this.vendorGUID = "";
    }
    else {
      this.vendorGUID = _data.vendorGUID;
    }
    this.dialogTitle = _data.dialogTitle;

  }

  getAllData(): Observable<any[]> {
     if (this.personGUID != null && this.personGUID != "") {
      let getPersonResponse = this._personService.getPersonBypId(this.personGUID);
      return forkJoin([ getPersonResponse]);
    }
    else
      return forkJoin([]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.personClassList = [];
    this.countryList = [];
    this.personRoleList = [];
    this.avaiPersonRoleList = [];
    this.selPersonRoleList = [];
    this.firstName = "";
    this.lastName = "";
    this.middleName = "";
    this.title = "";
    this.gender = "";
    this.city = "";
    this.state_province = "";
    this.selectedBirthCountryGUID = "";
    this.selectedResidenceCountryGUID = "";
    this.selectedNationalityCountryGUID = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.showContactButton = false;
    this.returnObj = new ReturnObjModel();
    this.showSuccessMsg = false;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "vendor";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    if (this.personGUID != null && this.personGUID != "") {
      this.getAllData().subscribe(responses => {


        if (responses[0] != null) {
          if (responses[0].code == "200" && responses[0].message == "") {
            let person = new PersonModel();
            person = responses[0].model;
            this.firstName = person.firstName;
            this.lastName = person.lastName;
            this.middleName = person.middleName;
            this.gender = person.gender;
            this.title = person.jobTitle;
            this.includeInBrief = person.includeInBrief;
            this.modifiedBy = person.modifiedBy;
            this.modifiedDate = person.modifiedDate;
            this.includeInBrief = person.includeInBrief;
            if (person.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;
            this.sortOrder = person.sortOrderNo;
          }
          else {
            if (responses[0].code == "401") {
              this._authService.signOut();
            }
          }
        }
        this.initControls();

      });
  }
    this.initControls();
      this.showSpin = false;


  }

  initControls() {
    this.person_edit_form = this._formBuilder.group({
      //comm_address_type_select: this.selectedCommAddressTypeId, //this.commAddressTypeList[0].commAddressTypeGUID,
      //address_rating_select: this.selectedAddressRatingId, //[this.addressRatingList[0].addressRatingGUID],
      //comm_address_status: this.isActive,
      firstName: [this.firstName, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z ',-.]*$")]
      )],
      lastName: [this.lastName, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z ',-.]*$")]
      )],
      middleName: [this.middleName],
     title: this.title,
      person_status: this.isActive,
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],
      includeInBrief: this.includeInBrief,
      sort_order:this.sortOrder
    });
  }


  get f() { return this.person_edit_form.controls; }

  ngAfterViewInit() {
    
  }

 
  clickSave() {
    this.errMsg = "";
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }
   
    if (this.f.firstName.value.trim() == "") {
      this.f.firstName.setErrors({ required: true });
      return;
    }
    if (this.f.lastName.value.trim() == "") {
      this.f.lastName.setErrors({ required: true });
      return;
    }
    //if (this.selPersonRoleList.length == 0) {
    //  this.errMsg = "You must select at least one person role.";
    //}

    if (this.person_edit_form.invalid || this.errMsg != "") {
      return;
    }

    this.loading = true;
    let obj = new PersonModel();
    obj.vendorGUID = this.vendorGUID;
    obj.personGUID = "";
    if (this.personGUID != "" && this.personGUID != null)
      obj.personGUID = this.personGUID;
    obj.firstName = this.f.firstName.value;
    obj.lastName = this.f.lastName.value;
    obj.middleName = this.f.middleName.value;
    obj.jobTitle = "";
    if (this.f.title.value != "")
      obj.jobTitle = this.f.title.value;
    obj.isActive = true;
    if (obj.personGUID != "") {
      if (this.f.person_status.value == "1")
        obj.isActive = true;
      else
        obj.isActive = false;
    }
    obj.includeInBrief = this.f.includeInBrief.value;
    obj.sortOrderNo = this.f.sort_order.value;
    this._personService.saveVendorPerson<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != "") {
          this.personGUID = response.model;
          this.returnObj.refresh = true;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
          //if (obj.personGUID == "") {
          //  //this.showContactButton = true;
          //  this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          //}
          //else {
          //  this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          //  //this.clickClose();
          //}
        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })


  }

  clickOpenContactDetails() {
    this.returnObj.refresh = false;
    this.returnObj.newId = this.personGUID;   
    this._dialogRef.close(this.returnObj);
  }

  clickClose() {
    this._dialogRef.close(this.returnObj);
  }

}
