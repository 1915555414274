<div style="margin-top:0em; padding-top:0em; display:flex">
  <div [ngClass]="{ 'divLeft':!isPopup}" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle [cdkDragDisabled]="!isPopup">
    <div>
      <div class="d-flex justify-content-between">
        <h5>Personal Details</h5>
        <div>
          <mat-icon class="mr-2 pointer" *ngIf="isPopup" (click)="openInNew()">open_in_new</mat-icon>
          <mat-icon *ngIf="isPopup" (click)="clickClose()" mat-dialog-close>close</mat-icon>
        </div>
      </div>
      <div>
        <form *ngIf="person_details_form" [formGroup]="person_details_form">
          <div [ngClass]="{ 'divPopup':isPopup, 'divFull':!isPopup}">
            <div style="margin-right:0.25em">
              <div style="width:6em; height:7em; border: 0.0625em black solid ;border-radius: 0.25em">
                <a href="{{photoFilePath}}" target="_blank" rel="noopener noreferrer">
                  <img *ngIf="photoFilePath!=''" [src]="photoFilePath" style="width:6em;height:7em; border:0" />
                  <img *ngIf="photoFilePath==''" src="../../assets/images/empty_profile.png" style="width:5.85em;height:6.85em; border:0" />
                </a>
              </div>
              <div>
                <label *ngIf="photoFilePath==''" style="font-size: small; text-decoration:underline; cursor: pointer; color: blue">
                  <input #photo formControlName="photo" id="photo" type="file" ng2FileSelect [uploader]="uploader" style="display:none" accept="*" (change)="importPhoto($event)" maxFileSize="28000000" />
                  Load photo
                </label>
                <a *ngIf="photoFilePath!=''" (click)="clickRemovePhoto()" style="font-size: small; text-decoration:underline; cursor: pointer; color: blue">Remove photo</a>
              </div>

              <div *ngIf="errMsg!=''" class="inline-block" style="margin-top:-0.5em; white-space:pre-wrap;background-color: #f9d2de"><label style="color: black; font-size:small">{{errMsg}}</label></div>
            </div>
            <div style="background-color:#f8f8f8; padding:0.25em; padding-top:0em; display:flex">
              <div>
                <div style="display:flex">
                  <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                    <label for="firstName" style="font-size:small">First Name</label>
                    <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                      <input type="text" formControlName="firstName" class="form-control" readonly style="padding: 0em;width:11em;height:1.5625em;font-size:small;background-color:white" />
                    </div>
                  </div>
                  <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                    <label for="middleName" style="font-size:small">Middle Name</label>
                    <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                      <input type="text" formControlName="middleName" class="form-control" readonly style="padding: 0em;width:11em;height:1.5625em;font-size:small;background-color:white" />
                    </div>
                  </div>
                </div>
                <div>
                  <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                    <label for="lastName" style="font-size:small">Last Name</label>
                    <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                      <input type="text" formControlName="lastName" class="form-control" readonly style="padding: 0em;width:22.75em;height:1.5625em;font-size:small;background-color:white" />
                    </div>
                  </div>

                </div>
                <div style="display:flex">
                  <div style="margin-left: -.5em;margin-top:.5em;margin-right:0.625em;">
                    <mat-checkbox [checked]="includeInBrief">Include In Brief</mat-checkbox>
                  </div>
                </div>

              </div>
              <div>
                <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                  <label for="title" style="font-size:small">Title</label>
                  <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                    <input type="text" formControlName="title" class="form-control" readonly style="padding: 0em;width:15em;height:1.5625em;font-size:small;background-color:white" />
                  </div>
                </div>
                <div style="margin-top:0em; margin-right:0.625em">
                  <label for="sort_order" style="font-size:small">Sort Order</label>
                  <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                    <input type="text" formControlName="sort_order" class="form-control" readonly style="padding: 0em;width:4em;height:1.5625em;font-size:small;background-color:white" />
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div style="margin-top:0em; margin-right:0.625em">
                    <label for="person_status" style="font-size:small">Disable Record</label>
                    <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                      <input type="text" formControlName="person_status" class="form-control" readonly style="padding: 0em;width:7em;height:1.5625em;font-size:small;background-color:white" />
                    </div>
                  </div>

                </div>
                <div>
                  <div style="margin-right:1em">
                    <label for="modifiedBy" style="font-size:small">Modified By</label>
                    <div style="margin-left: 0em; margin-top:-0.5em;">
                      <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:13em;height:1.5625em;font-size:small;padding: 0em;" />
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <label for="modifiedDate" style="font-size:small">Modified Date</label>
                    <div style="margin-left: 0em; margin-top:-0.5em;">
                      <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:13em;height:1.5625em;font-size:small;padding: 0em;" />
                    </div>
                  </div>
                </div>
              </div>
              <div style="margin-top:1em; margin-right:1em">
                <button mat-raised-button type="button" class="btn-high" (click)="clickEditPerson()">Edit</button>
              </div>
            </div>
            <div class="ml-1" style="position:relative;width:10em">
              <div style="position:absolute;bottom:0">
                <mat-checkbox formControlName="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)">Include disabled records</mat-checkbox>
              </div>
            </div>

          </div>
          <!--<div class="form-inline" style="margin-top:0.5em">-->
          <!--<div>-->
          <!--<div class="btn-group btn-group-sm d-flex" role="group">
    <button class="btn btn-primary group-btn"  [ngClass]="{ 'group-btn-selected':selectedTabName=='mailAddress' }" (click)="clickTab('mailAddress')">Mail Address</button>
    <button class="btn btn-primary group-btn"  *ngIf="isCrewPax" [ngClass]="{ 'group-btn-selected':selectedTabName=='travelDocument'}" (click)="clickTab('travelDocument')">Document</button>
    <button class="btn btn-primary group-btn"  *ngIf="isCrewPax" [ngClass]="{ 'group-btn-selected':selectedTabName=='rewards'}" (click)="clickTab('rewards')">Travel Memberships</button>
    <button class="btn btn-primary group-btn"  [ngClass]="{ 'group-btn-selected':selectedTabName=='comms'}" (click)="clickTab('comms')">Communications</button>

  </div>-->
          <!--<ul class="tabul">
      <li [ngClass]="{ 'tabliSelected':selectedTabName=='mailAddress','tabli':selectedTabName!='mailAddress'  }" style="border-left: black 0.0625em solid;" (click)="clickTab('mailAddress')"><div style="margin-top:0.25em">Mail Address</div></li>
      <li *ngIf="isCrewPax" [ngClass]="{ 'tabliSelected':selectedTabName=='travelDocument','tabli':selectedTabName!='travelDocument' }" (click)="clickTab('travelDocument')"><div style="margin-top:0.25em">Documents</div></li>
      <li *ngIf="isCrewPax" [ngClass]="{ 'tabliSelected':selectedTabName=='rewards','tabli':selectedTabName!='rewards' }" (click)="clickTab('rewards')"><div style="margin-top:0.25em">Travel Memberships</div></li>
      <li [ngClass]="{ 'tabliSelected':selectedTabName=='comms','tabli':selectedTabName!='comms' }" (click)="clickTab('comms')"><div style="margin-top:0.25em">Phone & Emails</div></li>
    </ul>
  </div>-->
          <!--</div>-->
        </form>
      </div>

      <div class="mt-2">
        <app-vendor-person-commlist [personId]="personGUID" [includeDisabledRecords]="includeDisabledRecords"></app-vendor-person-commlist>
      </div>

      <div *ngIf="!isPopup" style="margin-top:2em">
        <button mat-button class="btn-low" (click)="clickBack()"><mat-icon style="margin-top:-.2em">arrow_back</mat-icon> Vendor Details</button>
      </div>
    </div>
  </div>
</div>
