<div>
  <!--<div>
    <h5>Current Events Dashboard</h5>
  </div>-->

    <div class="d-inline-flex mt-1 ml-3 p-1 border rounded" style="height:23em;width:94em;  box-shadow: 0 2px 5px 0 #f8f8f8;">

      <div>
  

          <div class="d-flex position-relative" style="background: linear-gradient(to bottom, #A1DCFF,#A1BEFF);">
            <mat-icon style="color:#cc0000">airline_stops</mat-icon>
            <h5 style="color:#cc0000">Upcoming Schedules</h5>
            <label class="small ml-5 mt-1" style="font-size: small;">Filter Schedules - Next</label>
            <mat-radio-group id="queryHour" aria-label="Select an option" [(ngModel)]="queryHour" (change)="getData()" [ngModelOptions]="{standalone: true}">
              <mat-radio-button style="transform:scale(.8)" color="primary" value="168">7 Days</mat-radio-button>
              <mat-radio-button style="transform:scale(.8)" color="primary" value="720">30 Days</mat-radio-button>
            </mat-radio-group>
            <div>
              <app-spin *ngIf="showSpin"></app-spin>
            </div>
            <div *ngIf="userType=='internal'">
              <div>
                <button type="button" mat-button class="btn-low" [ngClass]="selectedClientCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickClientFilter()">Client{{selectedClientCountText}}</button>
              </div>
              <div *ngIf="showClientList" style="position:absolute;float: left; min-width: 12em; margin-top: 0.25em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline;">Clients</div>
                  <div style="float:right" (click)="showClientList=!showClientList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="clientSearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayName($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="clientList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let c of filteredClientList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
                    <div style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox style="transform: scale(0.75);" (click)="$event.stopPropagation()" (change)="checkClientChange($event, c)" [checked]="c.selected"></mat-checkbox>
                      </div>
                      <div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{c.customerName}}</div></div>
                    </div>
                  </div>
                </div>
                <div style="margin-top:-0.5em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetClient()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneClient()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="small mt-1" style="font-size: small;">
              <label *ngIf="tripCount>0">{{tripCount}} {{tripCount>1? 'Trips' : 'Trip'}} /&nbsp;</label>
              <label *ngIf="recordList && recordList.length>0">{{recordList.length}} Legs</label>
            </div>
            <!--<div class="small mt-1" style="position: absolute; right: .5em">
              <a [routerLink]="" style="color:black" (click)="openLegend()">Legend</a>
            </div>-->
          </div>
      
          <div style="max-height: 20em; overflow-y: auto;overflow-x:hidden">

            <table mat-table [dataSource]="sortedData" matSort (matSortChange)="sortData($event)" class="example-container">
              <tr>
                <ng-container matColumnDef="trip_code">
                  <th mat-sort-header="trip_code" mat-header-cell *matHeaderCellDef width="5%">
                    Trip Code
                  </th>
                  <td mat-cell *matCellDef="let element"><a *ngIf="upPII" [routerLink]="" (click)="openTrip(element)">{{element.tripCode}}</a><span *ngIf="upPII==false">{{element.tripCode}}</span></td>
                </ng-container>
                <ng-container matColumnDef="registration">
                  <th mat-sort-header="registration" mat-header-cell *matHeaderCellDef width="4%">
                    Registration
                  </th>
                  <td mat-cell *matCellDef="let element" style="font-weight:bold">{{element.registration}}</td>
                </ng-container>
                <ng-container matColumnDef="departure_date">
                  <th mat-sort-header="departure_date" mat-header-cell *matHeaderCellDef width="6%">
                    Departure (Z)
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.departureDateTimeUTCText}}</td>
                </ng-container>
                <ng-container matColumnDef="crew_count">
                  <th mat-sort-header="crew_count" mat-header-cell *matHeaderCellDef width="2%">
                    Crew
                  </th>
                  <td mat-cell *matCellDef="let element" matTooltip="{{upPII? element.departureCrewList: ''}}">{{element.departureCrewCount}}</td>
                </ng-container>
                <ng-container matColumnDef="pax_count">
                  <th mat-sort-header="pax_count" mat-header-cell *matHeaderCellDef width="2%">
                    Pax
                  </th>
                  <td mat-cell *matCellDef="let element" matTooltip="{{upPII? element.departurePaxList: ''}}">{{element.departurePaxCount}}</td>
                </ng-container>
                <ng-container matColumnDef="dep_vendor_name">
                  <th mat-header-cell *matHeaderCellDef width="13%">
                    Handler/SSN
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.departureVendorList[0].vendorName!=null && element.departureVendorList[0].vendorName!=''? 'H: ' + element.departureVendorList[0].vendorName : ''}}
                    <br />{{element.departureVendorList[1].vendorName!=null && element.departureVendorList[1].vendorName!=''? 'S: ' + element.departureVendorList[1].vendorName : ''}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="ete">
                  <th mat-sort-header="ete" mat-header-cell *matHeaderCellDef width="3%">
                    ETE
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.ete}}</td>
                </ng-container>
                <ng-container matColumnDef="leg">
                  <th mat-sort-header="leg" mat-header-cell *matHeaderCellDef width="7%">
                    Leg
                  </th>
                  <td mat-cell *matCellDef="let element" style="font-weight:bold">
                    <div class="d-inline-flex">
                      <div>
                        <span>{{element.departureICAO}} - {{element.nextArrivalICAO}}</span>
                      </div>
                      <div>
                        <span matTooltip="Leg is On Hold" *ngIf="element.departureOnHold || element.arrivalOnHold" style="margin-left:.25em;" class="material-icons-two-tone pink">pause_circle</span>
                      </div>
                    </div>
                  </td>                  
                </ng-container>
                <ng-container matColumnDef="arrival_date">
                  <th mat-sort-header="arrival_date" mat-header-cell *matHeaderCellDef width="6%">
                    Arrival (Z)
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.nextArrivalDateTimeUTCText}}</td>
                </ng-container>
                <ng-container matColumnDef="arr_vendor_name">
                  <th mat-header-cell *matHeaderCellDef width="12%">
                    Handler/SSN
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.nextArrivalVendorList[0].vendorName!=null && element.nextArrivalVendorList[0].vendorName!=''? 'H: ' + element.nextArrivalVendorList[0].vendorName : ''}}
                    <br />{{element.nextArrivalVendorList[1].vendorName!=null && element.nextArrivalVendorList[1].vendorName!=''? 'S: ' + element.nextArrivalVendorList[1].vendorName : ''}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="icon">
                  <th mat-header-cell *matHeaderCellDef width="3%">
                    Leg Details
                  </th>
                  <td style="text-align:left !important" mat-cell *matCellDef="let element"><span style="cursor:pointer" (click)="openChecklist(element)" class="material-icons-outlined blue">fact_check</span></td>
                </ng-container>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn"
                  (click)="clickRow(row)"
                  [class.row-is-clicked]="clickedRows.has(row)"></tr>
            </table>
          </div>
      </div>


      <div class="ml-2">
        <!--<div class="d-flex justify-content-between" style="width:100%">
          <div>
            <h5>Trip Legs</h5>
          </div>
          <div *ngIf="map">
            <span style="font-size:xx-small"> *Great Circle Route</span>
          </div>
        </div>-->
        <div style="width:34.6em;height:100%;border-radius:.25em" id="map"></div>
      </div>

    </div>
    <div class="d-flex">
      
      
      
      
      <!-- <div class="mt-3 ml-3 p-1 border rounded" style="    width: 30.5em;
    height: 15.5em;
    background-color: white;
    background: linear-gradient(to bottom, #E1E1FF,#efefff);
    box-shadow: 0 2px 5px 0 #efefff;">
        <form *ngIf="currents_event_dashboard_form" [formGroup]="currents_event_dashboard_form">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <span style="color:#cc0000" class="material-icons-outlined">
                campaign
              </span>
              <h5 style="color:#cc0000">{{customerName}} Events</h5>
              <div *ngIf="userType=='customer' && grantAccessToAccountGrouping && customerList.length>0" style="margin-left:0em;padding:0em; display: flex">
                <div class="form-group form-inline mr-2 ml-2" style="margin-top:0em; margin-right:0.625em">
                  <label for="customer_select" style="font-size:small">Select Client</label>
                  <div>
                    <select *ngIf="f" formControlName="customer_select" class="form-control form_control-sm dropdown ml-2" (change)="customerChange($event)">

                      <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="grantAccessCompanyEvents">
              <span style="color:#cc0000" class="material-icons-outlined pointer" (click)="addCompanyEvent()">
                add
              </span>
            </div>
          </div>
            <div>
              <ng-template ngFor let-event [ngForOf]="eventList" let-i="index">
                <div class="eventDiv">
                  <div>
                    <a style="color:black;font-weight:bold" [routerLink]="" (click)="openCompanyEvent(event.companyEventID)">{{event.companyEventTitle}}</a>
                    <span *ngIf="event.attachmentCount>0" class="material-icons-outlined" style="transform:rotate(-45deg);font-size:medium">attachment</span>
                    <span *ngIf="event.documentWeblink!=null && event.documentWeblink!=''" class="material-icons-outlined" style="font-size:medium;cursor:pointer" (click)="openLink(event.documentWeblink)">language</span>
                  </div>
                </div>
              </ng-template>
            </div>
        </form>
      </div> -->
      
      
      
      
      <div class="mt-3 ml-3 p-1 border rounded" style="width:30.5em;height:15.5em;background-color:white;background: linear-gradient(to bottom, #E1E1FF,#efefff);   box-shadow: 0 2px 5px 0 #efefff;">
        <div class="d-flex">
          <span style="color:#cc0000" class="material-icons-outlined">
            campaign
          </span>
          <h5 style="color:#cc0000">Flight Pro Connect News</h5>
        </div>
        <div style="max-height:12em;overflow-y:auto">
          <ng-template ngFor let-headlineGroup [ngForOf]="fpcNews" let-i="index">
            <div class="eventDiv">
              <!-- <div><label style="font-weight:bold">{{headlineGroup.headlineType}}</label></div> -->
              <ng-template ngFor let-headline [ngForOf]="headlineGroup.headlines" let-i="index">
                <div>
                    <a [ngClass]="{ 'bg-warning': headlineGroup.headlineType=='FPC Maintenance' }" style="color:black;" [routerLink]="" (click)="openFpcNewsItem(headline.headlineID)">{{headline.headlineTitle}} {{headline.effectiveDate}} {{headline.expirationDate!=null? ' - ' + headline.expirationDate : ''}}</a>
                  <span *ngIf="headline.attachmentCount>0" class="material-icons-outlined" style="transform:rotate(-45deg);font-size:medium">attachment</span>
                  <span *ngIf="headline.documentWeblink!=null && headline.documentWeblink!=''" class="material-icons-outlined" style="font-size:medium;cursor:pointer" (click)="openLink(headline.documentWeblink)">language</span>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </div>
      </div>
      
      <div class="mt-3 ml-3 p-1 border rounded" style="width:30.5em;height:15.5em;background-color:white;background: linear-gradient(to bottom, #E1E1FF,#efefff);   box-shadow: 0 2px 5px 0 #efefff;">
        <div class="d-flex">
          <span style="color:#cc0000" class="material-icons-outlined">
            feed
          </span>
          <h5 style="color:#cc0000">Headlines</h5>
        </div>
        <div style="max-height:12em;overflow-y:auto">
          <ng-template ngFor let-headlineGroup [ngForOf]="headlines" let-i="index">
            <div class="eventDiv">
              <div><label style="font-weight:bold">{{headlineGroup.headlineType}}</label></div>
              <ng-template ngFor let-headline [ngForOf]="headlineGroup.headlines" let-i="index">
                <div>
                    <a [ngClass]="{ 'bg-warning': headlineGroup.headlineType=='FPC Maintenance' }" style="color:black;" [routerLink]="" (click)="openHeadline(headline.headlineID)">{{headline.headlineTitle}} {{headline.effectiveDate}} {{headline.expirationDate!=null? ' - ' + headline.expirationDate : ''}}</a>
                  <span *ngIf="headline.attachmentCount>0" class="material-icons-outlined" style="transform:rotate(-45deg);font-size:medium">attachment</span>
                  <span *ngIf="headline.documentWeblink!=null && headline.documentWeblink!=''" class="material-icons-outlined" style="font-size:medium;cursor:pointer" (click)="openLink(headline.documentWeblink)">language</span>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </div>
      </div>




      <div class="mt-3 ml-3 p-1 border rounded" style="width:30.5em;height:15.5em;background: linear-gradient(to bottom,#fff0f0, #ffe4e4);   box-shadow: 0 2px 5px 0 #ffe4e4;">
        <div class="d-flex">
          <span style="color:#cc0000" class="material-icons-outlined">
            info
          </span>
          <h5 style="color:#cc0000">Alerts</h5>
        </div>
        <div class="alertDiv" *ngIf="missingCrewList && missingCrewList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            people
          </span>
          <div><a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(missingCrewList,'missingCrew')">{{missingCrewList.length}} {{missingCrewList.length==1? 'Leg':'Legs'}} Missing Crew</a></div>
        </div>
        <div class="alertDiv" *ngIf="missingPaxList && missingPaxList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            airline_seat_recline_normal
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(missingPaxList,'missingPax')">{{missingPaxList.length}} {{missingPaxList.length==1? 'Leg':'Legs'}} Missing PAX</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="expiredTravelDocList && expiredTravelDocList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            badge
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(expiredTravelDocList,'expiredManifest')">{{expiredTravelDocList.length}} Expired Manifest {{expiredTravelDocList.length==1? 'Document':'Documents'}}</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="missingTravelDocuments && missingTravelDocuments.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            description
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(missingTravelDocuments,'missingManifest')">{{missingTravelDocuments.length}} Missing Manifest {{missingTravelDocuments.length==1? 'Document':'Documents'}}</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="waitingTaskList && waitingTaskList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            assignment
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(waitingTaskList,'task')">{{waitingTaskList.length}} {{waitingTaskList.length==1? 'Task':'Tasks'}} Waiting for Client Reply</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="onHoldList && onHoldList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            pause_circle
          </span>
          <div><a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(onHoldList,'onHold')">{{holdTripCount}} {{holdTripCount==1? 'Trip':'Trips'}} / {{onHoldList.length}} {{onHoldList.length==1? 'Leg':'Legs'}} / {{holdTaskCount}} {{holdTaskCount==1? 'Task':'Tasks'}} On Hold</a></div>
        </div>
        <div class="alertDiv" *ngIf="expiredACDocList && expiredACDocList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            airplane_ticket
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(expiredACDocList,'aircraft')">{{expiredACDocList.length}} Expired Aircraft {{expiredACDocList.length==1? 'Document':'Documents'}}</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="expiredCustomerDocList && expiredCustomerDocList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            business
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(expiredCustomerDocList,'customer')">{{expiredCustomerDocList.length}} Expired Client {{expiredCustomerDocList.length==1? 'Document':'Documents'}}</a>
          </div>
        </div>

      </div>
      <!--<div class="mt-1 ml-1 p-1 border rounded" style="width:23.2em;height:15em;background-color:white;background: linear-gradient(to bottom, white,lightgray);   box-shadow: 0 2px 5px 0 lightgray;">
        <div class="d-flex">
          <span class="material-icons-outlined">
            notifications_active
          </span>
          <h5>FPC Latest Trip Updates - Last 24 hrs</h5>
        </div>
        <div *ngIf="legRevisions && legRevisions.length>0">
          <ng-template ngFor let-revision [ngForOf]="legRevisions">
            <div>
              <a [routerLink]=""  (click)="openAlert(legRevisions,'revisions')" class="small">{{revision.tripCode}} - {{revision.revisions.length}} {{revision.revisions.length==1? 'Change':'Changes'}}</a>
            </div>
          </ng-template>
        </div>
      </div>-->

    </div>
</div>  
