<div *ngIf="grantAccessToMessages">
  <div><h5 style="padding-left: 0.9em; width: 13em; font-size: 23px !important;">Message Center</h5></div>
  <div class="container-fluid">

    <div class="d-flex">
      <div>
        <div class="row" style="padding-left: 0.4em; padding-bottom: 2.5em;">
          <div class="col-2">
            <button mat-button class="btn-high larger" style="width: 13em; text-align: left; " (click)="newMessage()">
              <mat-icon>email</mat-icon>
              New Message
            </button>
          </div>

        </div>



        <!--    <mat-selection-list [multiple]="false">
            <mat-list-option *ngFor="let mf of messageFolderList" [selected]="mf.selected" (click)="filterMessageList(mf)" [ngClass]="{'ml-2': mf.isSubfolder}">
                {{mf.messageFolderName}} ({{mf.messageCount}})
            </mat-list-option>
        </mat-selection-list>-->
        <div class="example-menu" style="margin-top:-.6em">
          <ng-template ngFor let-mf [ngForOf]="messageFolderList" let-i="index">
            <div class="example-menu-item" [ngClass]="{'selected':mf.selected}">
              <button class="example-menu-item" (click)="filterMessageList(mf)" [ngClass]="{'selected':mf.selected,'ml-2': mf.isSubfolder}">
                {{mf.messageFolderName}} ({{mf.messageCount | number}})
              </button>
            </div>
          </ng-template>
        </div>

        <!-- <div *ngIf="showNewFolderInput">
          <input type="text" [(ngModel)]="folderName" class="form-control form-control-sm" />
          <span style="color: red;" *ngIf="folderExist">Folder Exists</span>
          <div style="display: flex; justify-content: center;">
            <button mat-button class="btn" (click)="createNewFolder()">Done</button>
            <button mat-button class="btn" (click)="cancelNewFolder()">Cancel</button>
          </div>
        </div>
        <button mat-button class="btn-high " style="  text-align: left; " (click)="showNewFolderInput = true">
          <i class="material-icons" style="font-size:  large; margin-top: -.2em;">create_new_folder </i>
          New Folder
        </button> -->

      </div>
      <div class="message-content">

        <app-message-list [(messageFolder)]="messageFolderList" [hasChanges]="hasChanges"></app-message-list>
      </div>
    </div>
  </div>
</div>
