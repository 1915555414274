
import { AdvisoryDayHoursModel } from './advisory-day-hours.model';
import { AdvisoryDocumentModel } from './advisory-document.model';

export class AdvisoryModel {
  advisoryId: number;
  advisoryRating: string;
  lk_AdvisoryRatingID: number;
  isUTC: boolean;
  effectiveDate: Date;
  expirationDate: Date;
  advisoryValidTimeStart: string;
  advisoryValidTimeEnd: string;

  localEffectiveDate: string;
  localExpirationDate: string;
  localAdvisoryValidTimeStart: string;
  localAdvisoryValidTimeEnd: string;
  countryID: number;
  airportID: number;
  hotelGUID: string;
  vendorGUID: string;
  countryName: string;
  icao: string;
  iata: string;
  vendorName: string;
  hotelName: string;
  advisoryType: string;
  title: string;
  weblink: string;
  issue: string;
  resolution: string;
  daysRestriction: string;
  isHeadlineNews: boolean;
  isPublicNews: boolean;
  isActive: boolean;
  serviceStatusOverride: string;
  source: string;
  advisoryDayHoursRestrictions: AdvisoryDayHoursModel[];
  
  startDate: Date;
  endDate: Date;
  startDateText: string;
  endDateText: string;
  effectiveDateText: string;
  expirationDateText: string;
  sortBy: string;
  pageIndex: number;
  pageSize: number;
  advisoryTagDescription: string;
  selectedTagTypeIds: string;
  advisoryTypeID: number;
  advisoryTagIDs: string;
  advisorySourceID: number;
  
  advisoryTags: AdvisoryLookupModel[];
  ratings: AdvisoryLookupModel[];
  sources: AdvisoryLookupModel[];
  advisoryDayHoursList: AdvisoryLookupModel[];
  advisoryDocumentsList: AdvisoryDocumentModel[];
  
  groundHandlerGUID: string;
  ssnVendorGUID: string;
  selectedCountryIdList: string;
  selectedAirportIdList: string;
  selectedHotelIdList: string;
  selectedVendorIdList: string;
  selectedRatingIdList: string;
  selectedAdvisoryTypeIdList: string;

  advisoryList: AdvisoryModel[];
  countryFilter: AdvisoryLookupModel[];
  airportFilter: AdvisoryLookupModel[];
  hotelFilter: AdvisoryLookupModel[];
  vendorFilter: AdvisoryLookupModel[];
  groundHandlerFilter: AdvisoryLookupModel[];
  ssnVendorFilter: AdvisoryLookupModel[];
  intoplaneAgentList: AdvisoryLookupModel[];
  fuelSupplierList: AdvisoryLookupModel[];
  advisoryTypeFilter: AdvisoryLookupModel[];
  ratingFilter: AdvisoryLookupModel[];

  vendorFuelID: number;
  ipaVendorID: number;
  ssnVendorID: number;
  ghVendorID: number;
  intoplaneAgent: string;
  
  fuelSupplierName: string;
  fuelSupplierGUID: string;
  advisoryTypeDescription: string;
  groundHandlerVendorName: string;
  ssnVendorName: string;
  modifiedBy: string;
  modifiedDate: string;
  airportName: string;
  stateProvinceID: number;
  stateProvinceName: string;
  stateProvinceAbbreviation: string;
  airportLocation: string;
  airportLocationWithCountryNameAbbr: string;
  hasDocument: boolean;
  areaOfInterest: string;
  effectiveDatesDisplay: string;
  icon: string;
  storyHeader: string;
  legDetails: string;
  queryHour: number;
  storyValue: string;  
  customerName: string;
  customerGUID: string;
  aircraftGUID: string;
  tripCodeGUID: string;
  tripCode: string;
  registration: string;
  departureDateTime: string;
  fullTitle: string;
}


export class AdvisoryLookupModel {
  lookupId: number;
  description: string;
  lookupGUID: string;
  selected: boolean = false;
  advisoryTagTypeClass: string;
  vendorType: string;
  advisoryTagTypeClassID: number;


}
