import { Component, Inject, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GroundStopModel } from "../models/ground-stop.model";
import { AircraftTripModel } from "../models/aircraft-trip.model";
import { Observable, forkJoin, of } from "rxjs";
import { GroundStopTaskModel } from "../models/ground-stop-task.model";
import { HotelModel } from "../models/hotel.model";
import { GroundStopAdvancedService } from "../services/ground-stop-advanced.service";
import { GroundStopService } from "../services/ground-stop.service";
import { ResponseModel } from "../models/response.model";
import { CommonService } from "../services/common.service";
import { BusinessRulesService } from "../services/business-rules-service";
import { GroundStopClientService } from "../services/ground-stop-client.service";
import { AccountingStatusModel } from "../models/accounting-status.model";
import { FileItem, FileUploader, ParsedResponseHeaders } from "ng2-file-upload";
import { FileModel } from "../models/file.model";
import { GroundStopTaskDocumentModel } from "../models/ground-stop-task-document.model";
import { CountryModel } from "../models/country.model";
import { VendorModel } from "../models/vendor.model";
import { TripTaskStatusModel } from "../models/trip-task-status.model";
import { ServiceArrangementModel } from "../models/service-arrangement.model";
import { AuthenticateService } from "../services/authenticate.service";
import { ActivatedRoute } from "@angular/router";
import { GlobalConstant } from "../common-utility/global-constant";
import { CustomValidators } from "../common-utility/custom.validators";
import { TripAuditDialogComponent } from "../ground-stops/trip-audit-dialog.component";
import { EmailInternalDialogComponent } from "../messages/email-internal-dialog.component";
import { TripMessageCenterComponent } from "../ground-stops/trip-message-center.component";
import { TaskCloseConfirmationDialog } from "./task-close-confirmation-dialog.component";
import { ConfirmDialogComponent } from "../common-utility/confirm-dialog.component";
import { DialogService } from "../services/dialog.service";
import { GenerateApisCaricomComponent } from "../apis/generate-apis-caricom.component";
import { GenerateApis135Component } from "../apis/generate-apis-135.component";
import { GenerateApis91Component } from "../apis/generate-apis-91.component";
import { GenerateTaskDocumentsComponent } from "./generate-task-documents.component";
import { TemplateService } from "../services/template.service";
import { UtilityFunctions } from "../common-utility/utility.functions";
import { DatePipe } from "@angular/common";
import { AirportLocatorComponent } from "../airports/airport-locator.component";
import { UkGarModel } from "../models/uk-gar.model";
import { ReturnObjModel } from "../models/return-obj.model";

const datepipe: DatePipe = new DatePipe('en-US')
@Component({
  selector: 'app-internal-public-ground-stop-task-edit',
  templateUrl: './internal-public-ground-stop-task-edit-dialog.component.html',
  styleUrls: ['./internal-public-ground-stop-task-edit-dialog.component.css']
})

export class InternalPublicGroundStopTaskEditDialogComponent implements OnInit {
  internal_task_edit_form: UntypedFormGroup
  countryList: CountryModel[];
  vendorList: VendorModel[];
  fboList: VendorModel[];
  taskStatusList: TripTaskStatusModel[];
  serviceArrangementList: ServiceArrangementModel[];
  groundStopGUID: string;
  groundStopTaskGUID: string;
  tripCodeGUID: string;
  registration: string;
  tripCode: string = "";
  icao: string;
  nextICAO: string;
  prevICAO: string;
  arrivalDateTime: string = "";
  departureDateTime: string = "";
  arrivalDateTimeLocal: string = "";
  departureDateTimeLocal: string = "";
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string;
  selectedOverflightCountry: CountryModel;
  selectedOverflightCountryGUID: string;
  isRevisionRequired: boolean;
  vendorReq: boolean;
  lead_timeChanged: boolean = false;
  due_dateChanged: boolean = false;
  due_datetime: string;
  leadTime_Min_Value: number = 0;
  dueDate_Min_Value: number = 0;
  methodOfPayment: string = "";
  hasTaskAudit: boolean;
  errMsg2: string = '';
  serviceTypeDesc: string;
  isActive: number;
  immediateAction: number = 0;
  nextGroundStopGUID: string;
  aircraftGUID: string;
  customerGUID: string;
  clientName: string;
  depGroundStopGUID: string = "";
  selectedTaskStatusGUID: string;
  selectedTaskStatusDescription: string;
  leg: string;
  isModified: boolean = false;
  serviceTypeID: number;
  farTypeID: number;
  defaultEmail: string;
  lockdownTrip: boolean = false;
  lead_time: string = "";
  due_date: string = "";
  submitted: boolean = false;
  loading: boolean = false;
  showSuccessMsg: boolean = false;
  selectedVendorGUID: string;
  selectedFBOGUID: string;
  selectedLandingVendorGUID: string = "";
  selectedLandingCountryGUID: string = "";
  selectedServiceArrangementID: number;
  permit_order_number: string = "";
  confirmation_number: string;
  public_notes: string = "";
  vendor_notes: string = "";
  country_overflight_notes: string = "";
  internal_notes: string = "";
  onHoldText: string = '';
  tripCodeType: string;
  selectedAcctStatusGUID: string;
  selectedAcctStatusDescription: string;
  selectedAcctStatusTPGUID: string;
  selectedAcctStatusTPDescription: string;
  acctStatusList: AccountingStatusModel[];
  acctStatus_TPList: AccountingStatusModel[];
  uploader: FileUploader;
  finishAllUploads: boolean = true;
  fileList: FileModel[] = [];
  errMsg: string = "";
  maxFileSize: number;
  maxFileUploadNumber: number;
  allowedFileTypeList: string[];
  totalMessageAttachmentFileSize: number = 0;
  allowedFileType: string;
  maxTempDocId: number = 0;
  taskDocumentList: GroundStopTaskDocumentModel[] = [];
  allTaskDocumentList: GroundStopTaskDocumentModel[] = [];
  selectedGroundStopList: GroundStopModel[] = [];
  avoidLocationMsg: string;
  avoidBusinessRuleID: number;
  hasOverflightPermitDocument: boolean;
  avoidList: any;
  avoidLocation: boolean;
  canChangeBilling: boolean = false;
  businessRulesID: number;
  modifiedBy: string = "";
  modifiedDate: string = "";
  permitRequired: string = "";
  showDocuments: boolean = true;
  methodOfPaymentColor: string = "";
  validityTime: string = "";
  requiredDocumentCount: number = 0;
  hasExistingDocument: boolean = false;
  isGTSBillable: boolean = false;
  isThirdPartyBillable: boolean = false;
  hasModifiedDocuments: boolean = false;
  hasPermit: boolean = false;
  airportCountry: string = "";
  isDocLoading: boolean = false;
  taskColor: string = "";
  nextArrivalDateTimeLocal: string = "";
  nextArrivalDateTime: string = "";
  serviceLocation: string;
  showSpin: boolean = true;
  revisionCounter: number = 0;
  @ViewChild('internalNotesWrapper') internalNotesTemplate: TemplateRef<any>;
  @ViewChild('publicNotesWrapper') publicNotesTemplate: TemplateRef<any>;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  isRevisionRequiredValue: number;
  grantAccessToTripCloseout: boolean = false;
  showDeletedDocuments: boolean = false;
  prevDepartureDateTime:string
  prevDepartureDateTimeLocal: string;

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private readonly _dialogRef: MatDialogRef<InternalPublicGroundStopTaskEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService,
    private readonly _groundStopService: GroundStopService,
    private readonly _commonService: CommonService,
    private readonly _businessRulesService: BusinessRulesService,
    private readonly _groundStopClientService: GroundStopClientService,
    private readonly _authService: AuthenticateService,
    private readonly _route: ActivatedRoute,
    private readonly _dialogService: DialogService,
    private readonly _templateService: TemplateService,
    private readonly _dialog: MatDialog) {
    this.groundStopGUID = _data.groundStopGUID;
    this.groundStopTaskGUID = _data.groundStopTaskGUID;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.icao = _data.icao;
    this.serviceTypeDesc = _data.serviceTypeDesc;
    this.customerGUID = _data.customerGUID;
    this.leg = _data.leg;
    this.nextGroundStopGUID = _data.nextGroundStopGUID;
    this.depGroundStopGUID = _data.depGroundStopGUID;
    this.clientName = _data.clientName;
    this.serviceTypeID = _data.serviceTypeID;
  }

  ngOnInit() {

    if (localStorage.getItem('up.tClose') == 'true') {
      this.grantAccessToTripCloseout = true;
    }
    else {
      this.grantAccessToTripCloseout = false;
      // this._dialogService.openAlertDialog("Permission denied.", "OK");

      // return;
    }
    
    this.showSpin = true;
    this.countryList = [];
    this.vendorList = [];
    this.serviceArrangementList = [];
    this.country_overflight_notes = "";
    this.nextICAO = "";
    this.prevICAO = "";
    this.registration = "";
    this.isActive = 1;
    this.leadTime_Min_Value = 0;
    this.dueDate_Min_Value = 0;
    this.tripCodeType = this._route.snapshot.paramMap.get('tType');
    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Common/uploadFiles",
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false
    });
    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
    };

    this.getAllData().subscribe(responses => {
      if (responses[0] !== null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let gs = new GroundStopModel();
          gs = responses[0].model;

          this.registration = gs.registration;
          this.tripCode = gs.tripCode;
          this.icao = gs.icao;
          this.arrivalDateTime = gs.arrivalDateTextUTC;
          this.departureDateTime = gs.departureDateTextUTC;
          this.arrivalDateTimeLocal = gs.arrivalDateTextLocal;
          this.departureDateTimeLocal = gs.departureDateTextLocal;
          this.prevICAO = gs.prevICAO;
          this.nextICAO = gs.nextICAO;
          this.aircraftManufacturer = gs.aircraftManufacturer;
          this.aircraftModel = gs.aircraftModel;
          this.aircraftInfo = gs.aircraftInfo;
          this.aircraftGUID = gs.aircraftGUID;
          this.farTypeID = gs.farTypeID;
          this.airportCountry = gs.airportState_CountryCode;
          this.nextArrivalDateTime = gs.nextArrivalDateUTC;
          this.prevDepartureDateTime = gs.prevDepartureDateTimeUTC;
          this.prevDepartureDateTimeLocal = gs.prevDepartureDateTimeLocal;
          this.nextArrivalDateTime = gs.nextArrivalDateTimeUTC
          this.nextArrivalDateTimeLocal = gs.nextArrivalDateTimeLocal;
          this.lockdownTrip = gs.lockdownTrip;
         
        }
      }
      if (responses[1].code == "200") {
        this.taskStatusList = responses[1].model;
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          let tempacctStatusList: AccountingStatusModel[];
          tempacctStatusList = [];
          tempacctStatusList = responses[2].model;
          //this.acctStatusList = responses[2].model;
          this.acctStatusList = tempacctStatusList.filter(x => x.isGTSFlag == true);
          this.acctStatus_TPList = tempacctStatusList.filter(x => x.isGTSFlag != true);
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[3] !== null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          let gst = new GroundStopTaskModel();
          gst = responses[3].model;

          this.due_datetime = gst.due_DateDisplay;
          this.selectedServiceArrangementID = gst.serviceArrangementID;
          this.lead_time = gst.leadTime_Min;
          this.due_date = gst.dueDate_Min;
          this.confirmation_number = gst.fpiConfirmationReference;
          this.public_notes = gst.publicNote;
          this.internal_notes = gst.internalNote;
          this.vendor_notes = gst.vendorNote;
          this.businessRulesID = gst.businessRulesID;
          this.hasTaskAudit = gst.hasTaskAudit;
          this.modifiedBy = gst.modifiedBy;
          this.modifiedDate = gst.modifiedDate;
          this.serviceTypeID = gst.serviceTypeID;
          this.isRevisionRequiredValue = gst.isRevisionRequiredValue;
          if (this.isRevisionRequiredValue != 0) {
            this.isRevisionRequired = true;
          }

          if(this.serviceTypeID == 50 || this.serviceTypeID == 51 || this.serviceTypeID == 52 || this.serviceTypeID == 53 
            || this.serviceTypeID == 59 || this.serviceTypeID == 67) {
            this.hasPermit = true;
          }

          if (gst.overflightPermitOrderNo > 0)
            this.permit_order_number = gst.overflightPermitOrderNo.toString();

          if (gst.countryGUID == '00000000-0000-0000-0000-000000000000')
            this.selectedOverflightCountryGUID = gst.countryGUID;
          if (gst.vendorGUID != "") {
            this.selectedVendorGUID = gst.vendorGUID.toLowerCase();
            this.selectedOverflightCountryGUID = gst.countryGUID.toLowerCase();
            // if (this.selectedVendorGUID != "") {
            //   this.getPermitVendorList(gst.countryGUID);
            // }
          }

          this.isGTSBillable = gst.isGTSBillable;
          this.isThirdPartyBillable = gst.isThirdPartyVisible;

          this.selectedTaskStatusGUID = gst.taskStatusGUID.toLowerCase();
          this.selectedAcctStatusGUID = gst.accountingStatusGUID;
          this.selectedAcctStatusTPGUID = gst.accountingStatusGUID_TP.toLowerCase();
          if (this.selectedAcctStatusGUID != "")
            this.selectedAcctStatusGUID = this.selectedAcctStatusGUID.toLowerCase();

          this.selectedAcctStatusDescription = this.acctStatusList.filter(x => x.accountingStatusGUID === this.selectedAcctStatusGUID)[0]?.accountingStatusDescription;
          this.selectedTaskStatusDescription = this.acctStatus_TPList.filter(x => x.accountingStatusGUID === this.selectedAcctStatusTPGUID)[0]?.accountingStatusDescription;

          if (gst.taskStatusDescription == "Client Setup") {
            this.selectedAcctStatusDescription = this.selectedTaskStatusDescription = "Do Not Bill";
          }

          if (localStorage.getItem('up.lockTrip') == 'true') {
            this.canChangeBilling = true;
          }
          else {
            this.canChangeBilling = false;
          }

          this.serviceLocation = gst.serviceLocation;
          if (gst.serviceLocation == 'D' && gst.departureOnHold) {
            this.onHoldText = "On Hold";
          }
          else {
            if (gst.serviceLocation == 'A' && gst.arrivalOnHold) {
              this.onHoldText = "On Hold";
            }
          }
          if (gst.isActive == true)
            this.isActive = 1;
          else
            this.isActive = 0;
          if (gst.immediateActionRequired) {
            this.immediateAction = 1;
          }
          else {
            this.immediateAction = 0;
          }

          this.taskColor = this.taskStatusList.filter(x => x.taskStatusGUID.toLowerCase() == this.selectedTaskStatusGUID)[0].hexColor;
          this.revisionCounter = gst.revisionCounter;

        }
      }
      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          if (responses[4].model.length > 0) {
            this.allTaskDocumentList = responses[4].model;
            this.taskDocumentList = this.allTaskDocumentList.filter(x => x.isActive == true).slice();
            this.hasExistingDocument = true;
          }
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          if (responses[5].model != null) {
            let fileObj = new FileModel();
            fileObj = responses[5].model;
            this.allowedFileTypeList = fileObj.allowedFileTypeList;
            this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
            this.maxFileSize = fileObj.fileSize;
            this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
          }
        }
        else {
          if (responses[5].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[6].code == "200") {
        // this.countryList = responses[6].model;
        // this.selectedOverflightCountry = this.countryList.find(v => v.countryGUID === this.selectedOverflightCountryGUID);
        // let c = new CountryModel();
        // c.countryGUID = '00000000-0000-0000-0000-000000000000';
        // c.countryName = 'Not Required';
        // this.countryList.push(c);
        // if (this.selectedOverflightCountryGUID != "" && this.selectedOverflightCountryGUID != '00000000-0000-0000-0000-000000000000')
        //   this.hasOverflightPermitDocument = this.countryList.find(x => x.countryGUID == this.selectedOverflightCountryGUID).hasOverflightPermitDocument;
      }
      if (responses[7].code == "200") {
        this.vendorList = responses[7].model;
      }
      if (responses[8] != null) {
        if (responses[8].code == "200" && responses[7].message == "") {
          this.fboList = responses[8].model;
          if (this.selectedFBOGUID != "") {
            this.methodOfPayment = this.fboList.find(x => x.vendorGUID == this.selectedFBOGUID).vendorMethodOfPaymentDescription;
          }
        }
        else {
          if (responses[7].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[12] !== null) {
        if (responses[12].code == "200") {
          this.serviceArrangementList = responses[12].model;
        }
      }
      if (responses[13] !== null) {
        if (responses[13].code == "200") {
          this.selectedGroundStopList = responses[13].model;
          this.selectedGroundStopList = this.selectedGroundStopList.filter(v => v.nextGroundStopGUID != '');
          this.selectedGroundStopList = this.selectedGroundStopList.filter(v => v.groundStopTypeDescription != 'Contingency');
        }
      }
      if (responses[15] != null) {
        if (responses[15].code == "200" && responses[15].message == "") {
          this.avoidList = responses[15].model;
        }
        else {
          if (responses[15].code == "401") {
            this._authService.signOut();
          }
        }
      }
      this.initControls();
      this.showSpin = false;
    });

   // this.initControls();
  }

  initControls() {
    this.internal_task_edit_form = this._formBuilder.group({
      internal_note: [this.internal_notes],
      public_note: [this.public_notes],
      isRevisionRequired: [this.isRevisionRequired],
      task_status_select: [this.selectedTaskStatusGUID],
      acct_status_select: [this.selectedAcctStatusGUID],
      acct_status_tp_select: [this.selectedAcctStatusTPGUID],
      confirmation_number: [this.confirmation_number],
      record_status: this.isActive,
      immediateAction: this.immediateAction,
      lead_time: [this.lead_time, Validators.pattern("^(([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?)$")],
      due_date: [this.due_date, Validators.pattern("^([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?$")],
      document: [null],
    });
  }

  get f() { return this.internal_task_edit_form.controls; }

  clickClose() {
    let returnObj = new ReturnObjModel();
    returnObj.isModified = this.isModified;
    returnObj.groundStopGUIDList = [];
    
    if ((this.internal_task_edit_form.dirty && !this.isModified) || (this.hasModifiedDocuments && !this.isModified)) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes you just made before close the page?");
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.clickSave();
        }
        else {
          this._dialogRef.close(returnObj);
        }

        this.hasModifiedDocuments = false;
      });
    }
    else {
      this._dialogRef.close(returnObj);
    }
  }

  clickOpenInNew(index: number) {
    switch (index) {
      case 1:
        this._dialog.open(this.publicNotesTemplate);
        break;
      case 2:
        this._dialog.open(this.internalNotesTemplate);
        break;
    }
  }

  clickPreviewDocument(e: any, item: GroundStopTaskDocumentModel) {
    let request = new GroundStopTaskDocumentModel();
    request.groundStopTaskDocumentGUID = item.groundStopTaskDocumentGUID;
    if (item.groundStopTaskDocumentGUID != "") {
      this._groundStopAdvancedService.getGroundStopTaskDocumentBygstdId<ResponseModel<GroundStopTaskDocumentModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let obj = new GroundStopTaskDocumentModel();
            obj = response.model;
            let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
            let file = new Blob([byteArray], { type: obj.mimeType });
            var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display:none");
            a.href = fileURL;
            a.target = "_blank";
            switch (obj.fileExtension) {
              case "doc":
              case "docx":
              case "xls":
              case "xlsx":
                a.download = obj.documentName;
            }
            a.click();
            document.body.removeChild(a);

          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }
    else {
      let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
      let file = new Blob([byteArray], { type: item.mimeType });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }

  }

  openAirportDetails(airportCode: string, nextAirportCode: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "78em";//"1000px";
    dialogConfig.height = "38.75em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      searchKey: `${airportCode},${nextAirportCode}`
    };

    const dialogRef = this._dialog.open(AirportLocatorComponent, dialogConfig);
  }

  updateNotes(event: any, isPublic: boolean) {
    if (isPublic)
      this.public_notes = event;
    else
      this.internal_notes = event;
  }

  closeNotesPopup() {
    this.f.internal_note.setValue(this.internal_notes);
  }

  onFileClick(event) {
    this.errMsg = "";
    event.target.value = '';
  }

  importFile(event: any) {
    this.errMsg = "";

    if ((this.fileList.length + event.target.files.length) > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    //this.fileList = [];
    var files = [];
    if (event.target.files.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    else {
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;

        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {
          //if (event.target.files[i].size >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {
          //  this.errMsg = 'The file size "' + filename + '" is too big to upload.';
          //  this.finishAllUploads = true;
          //  return;
          //}
          //else {
          //  files.push(event.target.files[i]);

          //}
          if (event.target.files[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {             
            this.errMsg = 'Total file sizes are over limit.';
            this.finishAllUploads = true;
            return;
          }
          else {
            files.push(event.target.files[i]);

          }
        }
        else {
          this.finishAllUploads = true;
          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          return;
        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
  }

  generatePermit() {
    switch (this.serviceTypeID) {
      case 50:
        this.generateApis91();
        break;
      case 51:
        this.generateApis135();
        break;
      case 52:
        this.generateMexicoAPIS();
        break;
      case 53:
        this.generateApisCaricom();
        break;
      case 59:
        this.generateGenDec();
        break;
      case 67:
        this.generateGenDec();
        break;
    }
  }

  generateGenDec() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;

    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let isDeparture = false;
    let country = this.airportCountry;
    if (this.serviceTypeID == 59) {
      isDeparture = true;
    }
    if (this.serviceTypeID == 67) {
      isDeparture = false;
    }

    dialogConfig.data = { groundStopGUID: this.groundStopGUID, tripCodeGUID: this.tripCodeGUID, isDeparture: isDeparture, v: this._authService.getCurrentTimeNumber(), groundStopTaskGUID: this.groundStopTaskGUID, country: country, icao: this.icao };

    //const dialogRef = this._dialog.open(GroundStopServiceSelectionDialogComponent, dialogConfig);
    const dialogRef = this._dialog.open(GenerateTaskDocumentsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTaskDocumentList();
      }
      this.isModified = result;
    });

  }

  generateApis91() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;

    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = { groundStopGUID: this.groundStopGUID, tripCodeGUID: this.tripCodeGUID, v: this._authService.getCurrentTimeNumber(), groundStopTaskGUID: this.groundStopTaskGUID };

    //const dialogRef = this._dialog.open(GroundStopServiceSelectionDialogComponent, dialogConfig);
    const dialogRef = this._dialog.open(GenerateApis91Component, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.isModified = true;
        this.ngOnInit();
      }
      this.getTaskDocumentList();

    });

  }

  generateApis135() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;

    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";

    dialogConfig.data = { groundStopGUID: this.groundStopGUID, tripCodeGUID: this.tripCodeGUID, v: this._authService.getCurrentTimeNumber(), groundStopTaskGUID: this.groundStopTaskGUID };

    //const dialogRef = this._dialog.open(GroundStopServiceSelectionDialogComponent, dialogConfig);
    const dialogRef = this._dialog.open(GenerateApis135Component, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.isModified = true;
        this.ngOnInit();
      }
      this.getTaskDocumentList();

    });

  }

  generateApisCaricom() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;

    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";

    dialogConfig.data = { groundStopGUID: this.groundStopGUID, tripCodeGUID: this.tripCodeGUID, v: this._authService.getCurrentTimeNumber(), groundStopTaskGUID: this.groundStopTaskGUID };

    //const dialogRef = this._dialog.open(GroundStopServiceSelectionDialogComponent, dialogConfig);
    const dialogRef = this._dialog.open(GenerateApisCaricomComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.isModified = true;
        this.ngOnInit();
      }
      this.getTaskDocumentList();

    });

  }

  generateMexicoAPIS() {
    this.errMsg2 = "";
    this.isDocLoading = true;
    this._templateService.generateMexicoAPIS(this.tripCodeGUID, this.groundStopGUID, this.groundStopTaskGUID, this.tripCode).subscribe(r => {
      if (r.remarks != "")
        this.errMsg2 = r.remarks;
      this.isDocLoading = false;
      this.getTaskDocumentList();
    });
  }

  taskStatusChange(e: any, item: TripTaskStatusModel) {
    this.taskColor = this.taskStatusList.filter(x => x.taskStatusGUID.toLowerCase() == this.f.task_status_select.value.toLowerCase())[0].hexColor;
    this.selectedTaskStatusGUID = this.f.task_status_select.value.toLowerCase();
  }

  leadTimeChange(e: any) {
    if (e.target.value != "") {
      this.lead_timeChanged = true;
      if (this.f.lead_time.errors) {
        return;
      }
      this.lead_timeChanged = false;
      this.calculateLeadTime_DueDate('l', e.target.value);
    }
    else {
      //this.f.lead_datetime.setValue('');
      this.leadTime_Min_Value = 0;
    }
  }

  dueDateChange(e: any) {
    if (e.target.value != "") {
      this.due_dateChanged = true;
      if (this.f.due_date.errors) {
        return;
      }
      this.due_dateChanged = false;
      this.calculateLeadTime_DueDate('d', e.target.value);
    }
    else {
      //this.f.due_datetime.setValue('');
      this.dueDate_Min_Value = 0;
    }
  }

  getAllData(): Observable<any[]> {
    let request = new GroundStopModel();
    request.groundStopGUID = this.groundStopGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    let getGSResponse = this._groundStopAdvancedService.getGroundStopForTaskEditBygsId<ResponseModel<GroundStopModel>>(request);
    let getTaskStatusResponse = this._groundStopService.getTripTaskStatusList();
    let getAcctStatusResponse = this._groundStopAdvancedService.getAccountingStatusList();
    let req2 = new GroundStopTaskModel();
    req2.groundStopTaskGUID = this.groundStopTaskGUID;
    req2.groundStopGUID = this.groundStopGUID
    let getTaskResponse = this._groundStopAdvancedService.getGroundStopTaskBygstId(req2);
    let getTaskDocResponse = this._groundStopAdvancedService.getGroundStopTaskDocumentBygstId(req2);
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();

    let getCountryList;
    getCountryList = this._commonService.getCountryList();

    request.icao = this.icao
    request.serviceTypeDescription = this.serviceTypeDesc;
    let getFBOResponse = of(null);
    // if (this.serviceTypeID != 3 && this.serviceTypeID != 5 && this.serviceTypeID != 26 && this.serviceTypeID != 88 && this.vendorReq == true) {
    //   //getFBOResponse = this._groundStopAdvancedService.getFBOListByAirport(request);
    //   getFBOResponse = this._groundStopAdvancedService.getFBOListByAirportServiceType(request);
    // }
    // else {
    //   getFBOResponse = of(null);
    // }

    let getVendorResponse = this._groundStopAdvancedService.getVendorListForPermitByAirportCountry(request);

    let getfpRecallNumberResponse;
    getfpRecallNumberResponse = this._groundStopAdvancedService.getFlightPlanRecallNumberListBygsId(req2);

    let req3 = new HotelModel();
    //req3.countryGUID = this.countryGUID;
    req3.icao = this.icao;
    req3.distanceFromAirport = 50;

    let getHotelResponse = of(null);
    // if (this.serviceTypeID == 7 || this.serviceTypeID == 20) {
    //   //getHotelResponse = this._commonService.getHotelList(req3);
    //   getHotelResponse = this._commonService.getHotelListByAirport(req3);
    // }
    // else {
    //   getHotelResponse = of(null);
    // }

    let getFuelSupplierResponse = of(null);
    // if (this.serviceTypeID == 56) {
    //   getFuelSupplierResponse = this._groundStopAdvancedService.getFuelSupplierListByAirport(request);
    // }
    // else {
    //   getFuelSupplierResponse = of(null);
    // }

    let getServiceArrangementResponse;
    if (this.vendorReq == true) {
      getServiceArrangementResponse = this._groundStopAdvancedService.getServiceArrangementList();
    }
    else {
      getServiceArrangementResponse = of(null);
    }

    let getGroundStopList = of(null);
    // if (this.serviceTypeID == 34 || this.serviceTypeID == 79 || this.serviceTypeID == 32 || this.serviceTypeID == 85 || this.serviceTypeID == 87 || this.serviceTypeID == 83 || this.serviceTypeID == 84 || this.serviceTypeID == 91) {
    //   let request = new GroundStopModel;
    //   request.tripCodeGUID = this.tripCodeGUID;
    //   request.groundStopGUID = this.groundStopGUID;
    //   request.customerGUID = this.customerGUID;
    //   getGroundStopList = this._groundStopService.getGroundStopPairListBytId(request); //this._groundStopService.getGroundStopListForFlightBrief(request);
    // }
    // else {
    //   getGroundStopList = of(null);
    // }

    let getSSNVendorResponse = this._groundStopService.getSSNVendorIDbygsID(this.groundStopGUID);
    let brRequest = new GroundStopModel();
    brRequest.tripCodeGUID = this.tripCodeGUID;
    brRequest.groundStopGUID = this.groundStopGUID;
    let getAvoidList = this._businessRulesService.getBusinessRulesLocationsToAvoidByTripCodeGUID(brRequest);

    let getCustomsResponse = of(null);
    // if (this.serviceTypeID == 54 || this.serviceTypeID == 55 || this.serviceTypeID == 98 || this.serviceTypeID == 73) {
    //   request.serviceTypeIDList = this.serviceTypeID.toString();
    //   getCustomsResponse = this._groundStopAdvancedService.getVendorListForCustomsByAirport(request);
    // }
    // else {
    //   getCustomsResponse = of(null);
    // }

    let getTripResponse;
    let tripRequest = new AircraftTripModel();
    tripRequest.aircraftGUID = this.aircraftGUID;
    tripRequest.tripCodeType = this.tripCodeType;
    tripRequest.startDateText = localStorage.getItem('trip.sd');
    tripRequest.endDateText = localStorage.getItem('trip.ed');
    tripRequest.tripCodeGUID = this.tripCodeGUID;
    getTripResponse = this._groundStopClientService.getTripBytId(tripRequest);

    return forkJoin([getGSResponse, getTaskStatusResponse, getAcctStatusResponse, getTaskResponse, getTaskDocResponse,
      getAllowedDocTypeResponse, getCountryList, getVendorResponse, getFBOResponse, getfpRecallNumberResponse, getHotelResponse,
      getFuelSupplierResponse, getServiceArrangementResponse, getGroundStopList,
      getSSNVendorResponse, getAvoidList, getCustomsResponse]);

  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {
          result.model.forEach((item, index) => {
            let obj = new FileModel();
            let obj2 = new GroundStopTaskDocumentModel();
            this.maxTempDocId += 1;
            obj.fileName = item.fileName;
            obj.filePath = item.filePath;
            obj.fileSize = item.fileSize;
            obj.bFile = item.bFile;
            obj.mimeType = item.mimeType;
            obj.fileType = item.fileType;
            obj.tempId = this.maxTempDocId;
            obj.isInternal = item.isInternal;
            obj.isActive = true;
            this.totalMessageAttachmentFileSize += item.fileSize * 1024;
            this.fileList.push(obj);

            obj2.documentName = item.fileName;
            obj2.fileExtension = item.fileType;
            obj2.documentSizeInKB = item.fileSize;
            obj2.mimeType = item.mimeType;
            obj2.bFile = item.bFile;
            obj2.tempId = this.maxTempDocId;
            obj2.groundStopTaskDocumentGUID = "";
            obj2.isActive = true;
            this.taskDocumentList.push(obj2);
            this.allTaskDocumentList.push(obj2);
          });
        }
      }
      else {
        this.errMsg = result.message;
      }
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }

  calculateLeadTime_DueDate(type: string, s: string) {
    if (type == 'l') {
      //this.f.lead_datetime.setValue('');
    }
    if (type == 'd') {
      //this.f.due_datetime.setValue('');
    }
    if (s != "") {
      let d = 0;
      s = s.toLowerCase();
      var i = -1;
      i = s.indexOf('d');
      if (i > 0) {
        let sd = s.substring(0, i);
        if (Number.isInteger(Number(sd))) {
          d = Number(sd);
          s = s.substring(i + 1);
        }
      }

      let h = 0;
      if (s != "") {
        i = s.indexOf('h');
        if (i > 0) {
          let sh = s.substring(0, i);
          if (Number.isInteger(Number(sh))) {
            h = Number(sh);
            s = s.substring(i + 1);
          }
        }
      }

      let mi = 0;
      if (s != "") {
        i = s.indexOf('m');
        if (i > 0) {
          let sm = s.substring(0, i);
          if (Number.isInteger(Number(sm))) {
            mi = Number(sm);
            s = s.substring(i + 1);
          }
        }
      }

      let totalmin = 0;
      if (d > 0) {
        totalmin += d * 24 * 60;
      }
      if (h > 0) {
        totalmin += h * 60;
      }
      if (mi > 0) {
        totalmin += mi;
      }
      if (type == 'l') {
        this.leadTime_Min_Value = totalmin;
      }
      if (type == 'd') {
        this.dueDate_Min_Value = totalmin;
      }
      if (totalmin > 0) {
        if (type == 'l') {
          this.leadTime_Min_Value = totalmin;
        }
        if (type == 'd') {
          this.dueDate_Min_Value = totalmin;
        }
        if (this.arrivalDateTime != "") {
          let dt = new Date(this.arrivalDateTime);
          dt.setMinutes(dt.getMinutes() - totalmin);
          if (dt != null) {
            let m = dt.getMonth() + 1;
            let tempMin = dt.getMinutes();
            let stempMin = "";
            if (tempMin < 10) {
              stempMin = "0" + tempMin.toString();
            }
            else {
              stempMin = tempMin.toString();
            }
            let sdt = m.toString() + "/" + dt.getDate().toString() + '/' + dt.getFullYear().toString() + ' ' + dt.getHours().toString() + ':' + stempMin;
            //if (type == 'l') {
            //  this.f.lead_datetime.setValue(sdt);             
            //}
            //if (type == 'd') {
            //  this.f.due_datetime.setValue(sdt);
            //  this.f.due_datetime.setErrors(null);
            //}
          }
        }
        else {
          if (this.departureDateTime != "") {
            let dt = new Date(this.departureDateTime);
            dt.setMinutes(dt.getMinutes() - totalmin);
            if (dt != null) {
              let m = dt.getMonth() + 1;
              let tempMin = dt.getMinutes();
              let stempMin = "";
              if (tempMin < 10) {
                stempMin = "0" + tempMin.toString();
              }
              else {
                stempMin = tempMin.toString();
              }
              let sdt = m.toString() + "/" + dt.getDate().toString() + '/' + dt.getFullYear().toString() + ' ' + dt.getHours().toString() + ':' + stempMin;
              //if (type == 'l') {
              //  this.f.lead_datetime.setValue(sdt);              
              //}
              //if (type == 'd') {
              //  this.f.due_datetime.setValue(sdt);
              //  this.f.due_datetime.setErrors(null);
              //}
            }
          }
        }
      }
    }
  }

  getTaskDocumentList() {
    let request = new GroundStopTaskModel()
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    this._groundStopAdvancedService.getGroundStopTaskDocumentBygstId<ResponseModel<GroundStopTaskDocumentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.allTaskDocumentList = [];
        this.allTaskDocumentList = response.model;
        if (this.allTaskDocumentList.filter(x => x.isActive).length > 0) {
          this.hasExistingDocument = true;
        }
        if (this.fileList.length > 0) {
          this.fileList.forEach(x => {
            let obj = new GroundStopTaskDocumentModel();
            obj.documentName = x.fileName;
            obj.fileExtension = x.fileType;
            obj.documentSizeInKB = x.fileSize;
            obj.mimeType = x.mimeType;
            obj.bFile = x.bFile;
            obj.tempId = x.tempId;
            obj.groundStopTaskDocumentGUID = "";
            obj.isInternal = x.isInternal;
            obj.isActive = x.isActive;
            this.allTaskDocumentList.push(obj);
          });
        }
        if (this.showDeletedDocuments)
          this.taskDocumentList = this.allTaskDocumentList.slice()
        else
          this.taskDocumentList = this.allTaskDocumentList.filter(x => x.isActive == true).slice();
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }

    });
  }

  dropFile(event: any) {
    this.errMsg = "";
    //setTimeout(() => {
    //  this.errMsg="", 1000;
    //});

    if ((this.fileList.length + event.length) > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    //this.fileList = [];
    var files = [];
    if (event.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.';
      //this.finishAllUploads = true;
      //return;
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;
        setTimeout(() => this.removeFromUploaderQueue(filename), 100);
      }
    }
    else {
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;

        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {
          //if (event[i].size >= this.maxFileSize) {
          //  this.errMsg = 'The file size "' + filename + '" is too big to upload.';
          //  setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          //}
          if (event[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {
            if (this.errMsg != "") {
              this.errMsg += "\n";
            }
            this.errMsg += 'Total file sizes are over limit';
            setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          }

        }
        else {

          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          setTimeout(() => this.removeFromUploaderQueue(filename), 100);

        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }
    this.errMsg = "";
    this.uploadSubmit();
  }

  toggleInternalFile(item: GroundStopTaskDocumentModel) {
    var file = this.fileList.find(x => x.fileName == item.documentName);
    if (file !== undefined)
      file.isInternal = !file.isInternal;

    var document = this.taskDocumentList.find(x => x.documentName == item.documentName);
    document.isInternal = !document.isInternal;

    this.hasModifiedDocuments = true;
  }

  toggleRemoveFile(item: GroundStopTaskDocumentModel) {
    var file = this.fileList.find(x => x.fileName == item.documentName);
    if (file !== undefined)
      file.isActive = !file.isActive;

    var document = this.taskDocumentList.find(x => x.documentName == item.documentName);
    document.isActive = !document.isActive;

    this.hasModifiedDocuments = true;
  }

  uploadSubmit() {
    this.finishAllUploads = false;
    this.hasModifiedDocuments = true;
    this.uploader.uploadAll();
  }

  clickAudit() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: "", aircraftGUID: "", tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.groundStopGUID,
      groundStopTaskGUID: this.groundStopTaskGUID, pastHours: 0,
      userName: "", v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });
  }

  clickRemoveDocument(item: GroundStopTaskDocumentModel) {
    this.errMsg = "";
    this.isModified = true;
    this.hasModifiedDocuments = true;
    if (item.groundStopTaskDocumentGUID != "") {
      // remove doc from database, get newlist and then add unsaved file list
      let request = new GroundStopTaskDocumentModel();
      request.groundStopTaskDocumentGUID = item.groundStopTaskDocumentGUID;
      request.isActive = !item.isActive;
      this._groundStopAdvancedService.removeGroundStopTaskDocumentBygstdocId<ResponseModel<boolean>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model) {
            this.getTaskDocumentList();
          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }
    else {
      this.fileList.forEach((x, index) => {
        if (x.tempId == item.tempId) {
          this.totalMessageAttachmentFileSize -= item.fileSize * 1024;
          this.fileList.splice(index, 1);
        }
      }
      );
      this.taskDocumentList.forEach(
        (x, index) => {
          if (x.tempId == item.tempId && x.groundStopTaskDocumentGUID == "") {
            this.taskDocumentList.splice(index, 1);
          }
        }
      )
    }
  }

  clickEmail() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    let taskGroundStopGUID: string = "";
    if (this.groundStopGUID.toLowerCase() == this.nextGroundStopGUID.toLowerCase()) {
      taskGroundStopGUID = this.nextGroundStopGUID
    }
    else {
      taskGroundStopGUID = this.groundStopGUID
    }
    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.depGroundStopGUID, groundStopTaskGUID: this.groundStopTaskGUID, nextGroundStopGUID: this.nextGroundStopGUID, serviceTypeID: this.serviceTypeID,
      aircraftGUID: this.aircraftGUID, customerGUID: this.customerGUID, taskGroundStopGUID: taskGroundStopGUID, v: this._authService.getCurrentTimeNumber(),
      fromTaskEditDaialog: true, clientName: this.clientName, tripCode: this.tripCode, leg: this.leg, serviceDesc: this.serviceTypeDesc, requiredGsId: this.groundStopGUID,
      servcieStatus: this.taskStatusList.filter(s => s.taskStatusGUID == this.selectedTaskStatusGUID)[0].taskStatusDescription, farTypeID: this.farTypeID, defaultEmail: this.defaultEmail, lockdownTrip: this.lockdownTrip, icao: this.icao
    };

    const dialogRef = this._dialog.open(EmailInternalDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.getGroundStopAltAirports(item.groundStopGUID);
        this.isModified = true;
      }
    });
  }

  clickTripMessages() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "105em";//"1000px";
    dialogConfig.height = "45em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, customerGUID: this.customerGUID, aircraftGUID: this.aircraftGUID,
      registration: this.registration, selectedGroundStopList: this.selectedGroundStopList, tripCodeText: this.tripCode,
      groundStopGUID: this.groundStopGUID, groundStopTaskGUID: this.groundStopTaskGUID, serviceLocation: this.serviceLocation, includeActiveStopOnly: false,
      lockdownTrip: this.lockdownTrip
    };

    const dialogRef = this._dialog.open(TripMessageCenterComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.getGroundStopAltAirports(item.groundStopGUID);
        //this.isModified = true;
      }
    });
  }

  removeFromUploaderQueue(filename: string) {
    var index = -1;
    for (let i = 0; i < this.uploader.queue.length; i++) {
      let fileItem = this.uploader.queue[i]._file;
      if (filename == fileItem.name) {
        index = i;
        break;
      }
    }

    if (index >= 0) {
      this.uploader.queue[index].remove();
    }

  }

  clickSave(callFrom: string = "") {
    this._authService.updateAccessTime();
    this.errMsg = "";

    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.fileList.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; //GlobalConstant.maxUploadFileNum + '.';
      return;
    }

    if (this.totalMessageAttachmentFileSize >= this.maxFileSize) {
      this.errMsg = "The total file size over the limit. Please remove some of documents.";
      return;
    }

    // if (this.requiresServiceDate && this.f.task_status_select.value.toLowerCase() == "ae2ee6b7-437a-4f5a-9bdb-0e0a810de2a8") {
    //   if ((this.f.service_date.value == "" || this.f.service_date.value == null) || (this.f.service_time.value == "" || this.f.service_time.value == null)) {
    //     this.errMsg = "Service date/time is required."
    //     return;
    //   }
    // }

    //if (this.serviceTypeID != 35) {
    // if (this.requiresServiceDate) {
    //   if (this.service_dateRef.nativeElement.value !== "") {
    //     if (this.f.service_date.value != "" && this.f.service_date.value != null && this.f.service_date.value != undefined) {
    //       let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.service_date.value));
    //       let f1 = CustomValidators.isValidDate(s1);
    //       if (!f1) {
    //         this.f.service_date.setErrors({ isValidDate: f1 });

    //       }
    //     }
    //     else {
    //       this.f.service_date.setErrors({ isValidDate: false });

    //     }
    //   }
    // }
    if (this.internal_task_edit_form.invalid || this.errMsg != "") {
      return;
    }

    if (this.f.lead_time.value != "" && this.leadTime_Min_Value == 0) {
      this.calculateLeadTime_DueDate('l', this.f.lead_time.value);
    }
    if (this.f.due_date.value != "" && this.dueDate_Min_Value == 0) {
      this.calculateLeadTime_DueDate('d', this.f.due_date.value);
    }

    let request = new GroundStopTaskModel();

    request.groundStopGUID = this.groundStopGUID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    request.serviceTypeDescription = this.serviceTypeDesc;
    request.vendorGUID = "";
    request.countryGUID = "";
    request.modifiedBy = this.modifiedBy;
    request.modifiedDate = this.modifiedDate;

    // if (this.f.overflight_country_select.value != null)
    //   request.countryGUID = this.f.overflight_country_select.value['countryGUID'];

    request.flightPlanRecallNo = "";

    //request.overflightPermitOrderNo = 0;

    //request.flightPlanRecallNo = this.f.fp_recall_number_select.value;
    // if (this.f.overflight_country_select.value != null)
    //   request.countryGUID = this.f.overflight_country_select.value['countryGUID'];
    // if (this.f.permit_order_number.value != "")
    //   request.overflightPermitOrderNo = Number(this.f.permit_order_number.value);
    request.hotelGUID = "";
    request.fuelSupplierGUID = "";
    request.fuelIntoPlaneAgentName = "";
    // if (this.serviceTypeID == 56) {
    //   request.fuelSupplierGUID = this.f.intoplane_agent_select.value;
    //   if (request.fuelSupplierGUID != "")
    //     request.fuelIntoPlaneAgentName = this.intoPlaneAgentList.find(x => x.vendorGUID.toLowerCase() == request.fuelSupplierGUID.toLowerCase()).intoPlaneAgent;
    // }

    request.isActive = true;
    if (this.f.record_status.value == "1")
      request.isActive = true;
    else
      request.isActive = false;
    request.taskStatusGUID = this.f.task_status_select.value;
    request.accountingStatusGUID = this.f.acct_status_select.value;
    request.accountingStatusGUID_TP = this.f.acct_status_tp_select.value;
    request.leadTime_Min = this.f.lead_time.value;
    request.leadTime_Min_Value = this.leadTime_Min_Value;
    request.fpiConfirmationReference = this.f.confirmation_number.value;
    //if (this.serviceTypeID != 35)
    // if (this.requiresServiceDate)
    //   request.serviceDate = this.service_dateRef.nativeElement.value;
    //request.serviceTime = this.f.service_time.value;
    request.dueDate_Min = this.f.due_date.value;
    request.dueDate_Min_Value = this.dueDate_Min_Value;
    request.serviceArrangementID = 0;
    if (this.vendorReq) {
      switch (this.serviceTypeID) {
        case 3: // overflight permit
        case 5: // landing permit
        case 26: // charter
        case 88:
          request.serviceArrangementID = Number(this.f.service_arrangement_landing_select.value);
          break;
        default:
          request.serviceArrangementID = Number(this.f.service_arrangement_handling_select.value);
          break;
      }
    }
    else {
      if (this.serviceTypeID == 56) {
        request.serviceArrangementID = Number(this.f.service_arrangement_fuel_select.value);
      }
    }
    if (this.f.isRevisionRequired.value == true) {
      request.isRevisionRequiredValue = this.isRevisionRequiredValue;
    }
    else {
      request.isRevisionRequiredValue = 0;//this.f.isRevisionRequired.value;
    }

    request.revisionCounter = this.revisionCounter; //Number(this.f.revision_counter.value);
    request.publicNote = this.f.public_note.value;
    //request.vendorNote = this.f.vendor_note.value;
    request.internalNote = this.f.internal_note.value;
    // request.importantNote = this.f.important_note.value;
    // request.weatherNote = this.f.weather_note.value;
    // request.notamsNote = this.f.notams_note.value;
    // request.routeNote = this.f.route_note.value;

    request.groundStopTaskDocumentList = [];

    if (this.fileList.length > 0) {
      this.fileList.forEach(x => {
        let obj = new GroundStopTaskDocumentModel();
        obj.documentName = x.fileName;
        obj.fileExtension = x.fileType;
        obj.documentSizeInKB = x.fileSize;
        obj.mimeType = x.mimeType;
        obj.bFile = x.bFile;
        obj.groundStopTaskDocumentGUID = "";
        obj.isInternal = x.isInternal;
        obj.isActive = x.isActive;
        request.groundStopTaskDocumentList.push(obj);
      });
    }

    // request.terminalForecast = this.f.terminal_note.value;
    // request.metars = this.f.metars_note.value;
    // request.sigmets = this.f.sigmets_note.value;
    // request.airmets = this.f.airmets_note.value;
    // request.pireps = this.f.pireps_note.value;
    // request.tropicalAdvisory = this.f.tropical_note.value;
    // request.vaAdvisory = this.f.va_note.value;
    // request.departureNotams = this.f.departure_note.value;
    // request.destinationNotams = this.f.destination_note.value;
    // request.etpNotams = this.f.etp_note.value;
    // request.fdcNotams = this.f.fdc_note.value;
    // request.trackMessage = this.f.track_note.value;
    // request.tfrNotams = this.f.tfr_notams.value;
    // request.synopsis = this.f.synopsis_note.value;
    // request.turbulence = this.f.turbulence_note.value;
    // request.immediateActionRequired = false;
    if (this.f.immediateAction.value == 1) {
      request.immediateActionRequired = true;
    }
    this.loading = true;


    request.isAttachmentIcon = (
      (request.groundStopTaskDocumentList?.filter(doc => doc.isInternal === false && doc.isActive).length > 0) ||
      (this.taskDocumentList?.filter(doc => doc.isInternal === false && doc.isActive).length > 0)
    ) ? true : false;
    request.isInternalAttachmentIcon = (
      (request.groundStopTaskDocumentList?.filter(doc => doc.isInternal === true && doc.isActive).length > 0) ||
      (this.taskDocumentList?.filter(doc => doc.isInternal === true && doc.isActive).length > 0)
    ) ? true : false;
    request.isPublicNoteIcon = (request.publicNote.trim() != '' && request.publicNote.trim() != null) ? true : false;
    request.isInternalNoteIcon = (request.internalNote.trim() != '' && request.internalNote.trim() != null) ? true : false;


    if (this.selectedTaskStatusGUID?.toLowerCase() == 'ae2ee6b7-437a-4f5a-9bdb-0e0a810de2a8') {
      request.immediateActionRequired = false;
    }
    
    this._groundStopAdvancedService.updateGroundStopTaskBygstId<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.fileList = [];
          this.maxTempDocId = 0;
          this.errMsg = "";
          this.errMsg2 = "";
          this.getTaskDocumentList();
          //this._dialogService.openAlertDialog("");
          this.isModified = true;
          if (callFrom != "flightBrief") {
            this.showSuccessMsg = true;
            this.modifiedBy = localStorage.getItem('un');
            this.modifiedDate = Date.now().toString();
            setTimeout(() => {
              this.showSuccessMsg = false;
              this.clickClose();
            }, 1000); //change to 1 second
          }
        }
        else {
          //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          this.errMsg2 = "Failed to save this record at this time. Please try later.";
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg2 = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

    if (this.fileList.length == 0 && this.taskDocumentList.length > 0) {
      this.taskDocumentList.forEach(x => {
        let obj = new GroundStopTaskDocumentModel();
        obj.documentName = x.documentName;
        obj.fileExtension = x.fileExtension;
        obj.documentSizeInKB = x.fileSize;
        obj.mimeType = x.mimeType;
        obj.bFile = x.bFile;
        obj.groundStopTaskDocumentGUID = x.groundStopTaskDocumentGUID;
        obj.isInternal = x.isInternal;
        obj.isActive = x.isActive;
        request.groundStopTaskDocumentList.push(obj);
      })

      this._groundStopAdvancedService.updateExistingDocumentsBygstId<ResponseModel<boolean>>(request).subscribe(response => {

      })
    }
  }

  exportNote(format: string, isPublic: boolean) {
    this.errMsg2 = "";
    let s: string = "";
    if (isPublic)
      s = this.f.public_note.value;
    else
      s = this.f.internal_note.value;


    let includePageNo = false;
    if (this.serviceTypeID == 31 || this.serviceTypeID == 35 || this.serviceTypeID == 29)
      includePageNo = true;
    let docName = this.registration + ' ' + this.icao + '-' + this.nextICAO + ' ' + this.serviceTypeDesc + ' ' + datepipe.transform(new Date().toUTCString(), 'MMddyy', "UTC");
    this._templateService.exportNotes(this.serviceTypeDesc.toUpperCase(), s, format, docName, includePageNo).subscribe(r => {
      if (r.remarks != "")
        this.errMsg2 = r.remarks;
      if (r.refresh)
        this.getTaskDocumentList();

    });
  }

  clickCopyNote(isPublic: boolean) {
    let s: string = "";
    if (isPublic)
      s = this.f.public_note.value;
    else
      s = this.f.internal_note.value;

    let sNew: string = "";

    if (s != "") {
      var s1 = s.split('\n');
      for (var i = 0; i < s1.length; i++) {
        if (s1[i].trim() == "") {
          s1[i] = " ";
        }
        sNew += s1[i] + "\n";
      }

    }
    else {
      sNew = s;
    }
    return sNew;
  }

  toggleDeleted() {
    this.showDeletedDocuments = !this.showDeletedDocuments;
    if (this.showDeletedDocuments)
      this.taskDocumentList = this.allTaskDocumentList.slice()
    else
      this.taskDocumentList = this.allTaskDocumentList.filter(x => x.isActive == true).slice();

  }

}
