<div *ngIf="upPII" style="overflow-y: hidden">
 
  <div style="display: flex; width: 100%; justify-content: space-between;">

    <div style="display: flex;padding-left:1em;" cdkDrag
      cdkDragRootElement=".cdk-overlay-pane"
      cdkDragHandle>
      <div *ngIf="isRevisionRequiredValue==1" style="margin-top:0.3em"><i class="material-icons-two-tone icon_orange" style="font-size: xx-large;">notifications_active</i></div>
      <div *ngIf="isRevisionRequiredValue==2" style="margin-top:0.3em"><i class="material-icons-two-tone icon_red" style="font-size: xx-large;">notifications_active</i></div>
      <div style="margin-top:0.3125em;"><h5>TASK WAS FLAGGED FOR {{isRevisionRequiredValue==2?'CRITICAL REVISION':'REVISION REQUIRED'}}</h5></div>
    </div>
    <button mat-icon-button  mat-dialog-close>
      <i class="material-icons" style="font-size: x-large;">close </i>
    </button>
  </div>

  <form *ngIf="task_revision__edit_form" [formGroup]="task_revision__edit_form">
    <div>

      <div style="font-size: small;padding-left: 1em; padding-right:1em">
        <div style="display: flex">
          <div style="margin-right:5em; display: flex">
            <div style="font-weight: bold; font-size:medium; margin-top:0.5em; margin-right:3em">{{serviceTypeDescription}}</div>
            <div *ngIf="isRevisionRequiredValue==1">
              <div [ngClass]="f.isRevisionRequired.value==true?'alert alert-danger d-flex align-items-center':'alert alert-success d-flex align-items-center'" style="height:2.5em; margin-top:0.25em; margin-right:2em;padding-left:0.25em; padding-right:0.25em; font-size:small; display: flex; border-radius:0.25em; width:17.75em">
                <div>
                  <mat-slide-toggle formControlName="isRevisionRequired" style="transform: scale(0.9) " [checked]="isRevisionRequired">
                  </mat-slide-toggle>
                </div>
                <div *ngIf="f.isRevisionRequired.value==true">
                  <div>Revision Required - Turn off </div>
                  <div style="margin-top:-0.5em">IF NOT REQUIRED</div>
                </div>
                <div *ngIf="f.isRevisionRequired.value==false" style="margin-left:2em; margin-right:2em">
                  <div style="margin-top:0em">Revision Cleared</div>
                  <div style="margin-top:-0.5em">No action required</div>
                </div>
                <div>
                  <div *ngIf="f.isRevisionRequired.value==true" style="margin-top:0.5em">
                    <i class="material-icons-two-tone icon_orange" style="font-size: x-large;">notifications_active</i>
                  </div>
                  <div *ngIf="f.isRevisionRequired.value==false" style="margin-top:0.5em">
                    <i class="material-icons" style="font-size: x-large; background-color: transparent; color: black; border-color: #5e5e5e">task_alt</i>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="isRevisionRequiredValue==2">
              <div [ngClass]="f.isRevisionRequired.value==true?'alert alert-danger d-flex align-items-center':'alert alert-success d-flex align-items-center'" style="height:2.5em; margin-top:0.25em; margin-right:2em;padding-left:0.25em; padding-right:0.25em; font-size:small; display: flex; border-radius:0.25em; width:17.75em">
                <div>
                  <mat-slide-toggle formControlName="isRevisionRequired" style="transform: scale(0.9) " [checked]="isRevisionRequired">
                  </mat-slide-toggle>
                </div>
                <div *ngIf="f.isRevisionRequired.value==true">
                  <div>Revision Required - Turn off </div>
                  <div style="margin-top:-0.5em">IF NOT REQUIRED</div>
                </div>
                <div *ngIf="f.isRevisionRequired.value==false" style="margin-left:2em; margin-right:2em">
                  <div style="margin-top:0em">Revision Cleared</div>
                  <div style="margin-top:-0.5em">No action required</div>
                </div>
                <div>
                  <div *ngIf="f.isRevisionRequired.value==true" style="margin-top:0.5em">
                    <i class="material-icons-two-tone icon_red" style="font-size: x-large;">notifications_active</i>
                  </div>
                  <div *ngIf="f.isRevisionRequired.value==false" style="margin-top:0.5em">
                    <i class="material-icons" style="font-size: x-large; background-color: transparent; color: black; border-color: #5e5e5e">task_alt</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style="display:flex">
              <div style="margin-right: 1em">
                <div>Trip Code</div>
                <div style="font-weight: bold">{{tripCode}}</div>
              </div>
              <div style="margin-right: 1em">
                <div>Registration</div>
                <div style="font-weight: bold">{{registration}}</div>
              </div>
            </div>
            <div style="display:flex">
              <div style="margin-right: 1em">
                <div>Business Stop</div>
                <div style="font-weight: bold">{{icao}}{{iata!=''?' / '+iata:''}}</div>
              </div>
              <div style="margin-right: 1em" *ngIf="arrUTCDateTime!=''">
                <div>Arrival ({{icao}})</div>
                <div style="font-weight: bold">{{arrUTCDateTime}}</div>
                <div>{{arrLocalDateTime}}</div>
              </div>
              <div style="margin-right: 1em" *ngIf="depUTCDateTime!=''">
                <div>Departure ({{icao}})</div>
                <div style="font-weight: bold">{{depUTCDateTime}}</div>
                <div>{{depLocalDateTime}}</div>
              </div>
            </div>
          </div>
        </div>
        <div style="overflow-y: auto; overflow-x: hidden; max-height:25em">
          <table mat-table *ngIf="recordList.length>0" [dataSource]="recordList" class="example-container mat-elevation-z8">
            <tr>
              <ng-container matColumnDef="transactionDate">
                <th mat-header-cell *matHeaderCellDef style="width:20%; text-align:left !important; border-right: 0.0625em solid lightgray">
                  <div style="margin-top:-0.25em; white-space: nowrap">Transaction Date/Time</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                  <div>{{element.transactionDateTime}}</div>
                  <div style="font-weight:bold">{{element.userName}}</div>
                </td>
              </ng-container>

              <ng-container matColumnDef="changeRequest">
                <th mat-header-cell *matHeaderCellDef style="width:40%; text-align:left !important; border-right: 0.0625em solid lightgray">
                  <div style="margin-top:-0.25em;">Change Request</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                  <div style="white-space:pre-wrap">{{element.changeRequest}}</div>

                </td>
              </ng-container>
              <!-- <ng-container matColumnDef="userComments">
                <th mat-header-cell *matHeaderCellDef style="width:40%; text-align:left !important; border-right: 0.0625em solid lightgray">
                  <div style="margin-top:-0.25em;">User Comments</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                  <div style="white-space: pre-wrap">{{element.userComments}}</div>
                </td>
              </ng-container> -->
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>

        </div>
        <div *ngIf="f.isRevisionRequired.value==false" style="margin-top:0.5em">
          <div style="display: flex">
            <div style="min-width: 28em; margin-right:0.5em">
              <div style="font-weight:bold">Choose Canned Comments</div>
              <div *ngIf="isRevisionRequiredValue==1">
                <div style="display: flex;margin-top:0em">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="checkRegularRevisionComment1" value="Time change not sufficient to send a revision notification." style="transform: scale(0.75)" (change)="checkRegularRevisionComment1Change($event)"></mat-checkbox>
                  <div style="margin-top:-0.25em">
                    <label for="checkRegularRevisionComment1" style="font-size:small">Time change not sufficient to send a revision notification.</label>
                  </div>
                </div>
                <div style="display: flex;margin-top:0em">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="checkRegularRevisionComment2" value="Task change request sent with Handling revision message." style="transform: scale(0.75)" (change)="checkRegularRevisionComment2Change($event)"></mat-checkbox>
                  <div style="margin-top:-0.25em">
                    <label for="checkRegularRevisionComment2" style="font-size:small">Task change request sent with Handling revision message.</label>
                  </div>
                </div>
                <div style="display: flex;margin-top:0em">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="checkRegularRevisionComment3" value="Manifest change notification not required for this location." style="transform: scale(0.75)" (change)="checkRegularRevisionComment3Change($event)"></mat-checkbox>
                  <div style="margin-top:-0.25em">
                    <label for="checkRegularRevisionComment3" style="font-size:small">Manifest change notification not required for this location.</label>
                  </div>
                </div>
                <div style="display: flex;margin-top:0em">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="checkRegularRevisionComment4" value="Revision/change was received from ground agent." style="transform: scale(0.75)" (change)="checkRegularRevisionComment4Change($event)"></mat-checkbox>
                  <div style="margin-top:-0.25em">
                    <label for="checkRegularRevisionComment4" style="font-size:small">Revision/change was received from ground agent.</label>
                  </div>
                </div>
                <div style="display: flex;margin-top:0em">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="checkRegularRevisionComment5" value="Change does not affect Flight Plan." style="transform: scale(0.75)" (change)="checkRegularRevisionComment5Change($event)"></mat-checkbox>
                  <div style="margin-top:-0.25em">
                    <label for="checkRegularRevisionComment5" style="font-size:small">Change does not affect Flight Plan.</label>
                  </div>
                </div>
              </div>
              <div *ngIf="isRevisionRequiredValue==2">
                <div style="display: flex;margin-top:0em">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="checkCriticalRevisionComment1" value="Client cancelled stop." style="transform: scale(0.75)" (change)="checkCriticalRevisionComment1Change($event)"></mat-checkbox>
                  <div style="margin-top:-0.25em">
                    <label for="checkCriticalRevisionComment1" style="font-size:small">Client cancelled stop.</label>
                  </div>
                </div>
                <div style="display: flex;margin-top:0em">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="checkCriticalRevisionComment2" value="Diverted to another location." style="transform: scale(0.75)" (change)="checkCriticalRevisionComment2Change($event)"></mat-checkbox>
                  <div style="margin-top:-0.25em">
                    <label for="checkCriticalRevisionComment2" style="font-size:small">Diverted to another location.</label>
                  </div>
                </div>
                <div style="display: flex;margin-top:0em">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="checkCriticalRevisionComment3" value="Bad weather." style="transform: scale(0.75)" (change)="checkCriticalRevisionComment3Change($event)"></mat-checkbox>
                  <div style="margin-top:-0.25em">
                    <label for="checkRegularCriticalComment3" style="font-size:small">Bad weather.</label>
                  </div>
                </div>
                <div style="display: flex;margin-top:0em">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="checkCriticalRevisionComment4" value="New Restrictions." style="transform: scale(0.75)" (change)="checkCriticalRevisionComment4Change($event)"></mat-checkbox>
                  <div style="margin-top:-0.25em">
                    <label for="checkCriticalRevisionComment4" style="font-size:small">New Restrictions.</label>
                  </div>
                </div>
                <div style="display: flex;margin-top:0em">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="checkCriticalRevisionComment5" value="No Fuel." style="transform: scale(0.75)" (change)="checkCriticalRevisionComment5Change($event)"></mat-checkbox>
                  <div style="margin-top:-0.25em">
                    <label for="checkCriticalRevisionComment5" style="font-size:small">No Fuel.</label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div >
                <div style="font-weight: bold">Update Selected Task - Operation Status</div>
                <div style="margin-top:0.5em; display: flex">
                  <label for="taskstatus_select" style="font-size:small">Operation Status&nbsp;</label>
                  <div style="margin-top:0em;">
                    <select *ngIf="f" formControlName="taskstatus_select" [ngClass]="{ 'is-invalid': submitted && f.taskstatus_select.errors, 'is-valid': !f.taskstatus_select.errors}" style="font-size: small;border-radius:0.25em;">
                      <option value="" selected></option>
                      <option *ngFor="let t of taskStatusList" [ngValue]="t.taskStatusGUID">{{t.taskStatusDescription}}</option>
                    </select> 
                  </div>
                  <div *ngIf="submitted && f.taskstatus_select.errors" style="margin-top:0em">
                    <div *ngIf="f.taskstatus_select.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:15em; font-size: small">Operation status is required.</div>                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex">
            <div style="min-width: 28em; margin-right:0.5em">
              <div >
                <label for="userComments" style="font-size:small; font-weight: bold">Add Comment if revision not Required</label>
                <div style="margin-left: 0em; margin-top:-0.5em">
                  <textarea formControlName="userComments" style="width:25em; height:6em;font-family:Courier New, Courier, monospace; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd; border-radius:0.25em" (change)="userCommentsChange($event)">   
             </textarea>
                </div>
                <div *ngIf="submitted && f.userComments.errors" style="margin-top:0em">
                  <div *ngIf="f.userComments.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:30em; font-size: small">User comment is a required field and at least 6 characters.</div>
                  <div *ngIf="f.userComments.errors?.minlength" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:30em; font-size: small">User comment is a required field and at least 6 characters.</div>
                </div>
              </div>
            </div>
            <div>
              <label style="font-size:small; font-weight: bold">Include Other Services Flagged for Revision Required</label>
              <div style="background-color: #E9ECEF; min-height:3em ; min-width:45em; margin-top: -0.5em; border-radius: 0.25em; padding-left:0.5em; padding-right: 0.5em; display: flex; max-height:15em; overflow-y: auto; overflow-x: hidden;">
                <div *ngIf="airportServiceTaskList.length>0" style="font-size:small;min-width:10em ">
                  <div style="font-weight: bold">Airport Services</div>
                  <div *ngFor="let gst of airportServiceTaskList; let i=index" style="margin-top:0em">
                    <div style="display: flex; font-size: small" [style.margin-top.em]="i>0? -0.25:0">
                      <div>
                        <mat-checkbox style="transform: scale(0.75);" (click)="$event.stopPropagation()" (change)="onCheckServiceTypeChange($event, gst, 'airport')"></mat-checkbox>
                      </div>
                      <div style="margin-top:0.1em;margin-left:0em; padding:0em;"><div style="margin-top:-0.3em">{{gst.serviceTypeDescription}}</div></div>
                    </div>
                  </div>
                </div>
                <div *ngIf="flightPlanServiceTaskList.length>0" style="font-size:small;min-width:14em">
                  <div style="font-weight: bold">Flight Plan WX & Permites</div>
                  <div *ngFor="let gst of flightPlanServiceTaskList; let i=index" style="margin-top:0em">
                    <div style="display: flex; font-size: small" [style.margin-top.em]="i>0? -0.25:0">
                      <div>
                        <mat-checkbox style="transform: scale(0.75);" (click)="$event.stopPropagation()" (change)="onCheckServiceTypeChange($event, gst, 'flightplan')"></mat-checkbox>
                      </div>
                      <div style="margin-top:0.1em;margin-left:0em; padding:0em;"><div style="margin-top:-0.3em">{{gst.serviceTypeDescription}}</div></div>
                    </div>
                  </div>
                </div>
                <div *ngIf="preflightServiceTaskList.length>0" style="font-size:small; min-width:10em ">
                  <div style="font-weight: bold">Preflight Services</div>
                  <div *ngFor="let gst of preflightServiceTaskList; let i=index" style="margin-top:0em">
                    <div style="display: flex; font-size: small" [style.margin-top.em]="i>0? -0.25:0">
                      <div>
                        <mat-checkbox style="transform: scale(0.75);" (click)="$event.stopPropagation()" (change)="onCheckServiceTypeChange($event, gst, 'preflight')"></mat-checkbox>
                      </div>
                      <div style="margin-top:0.1em;margin-left:0em; padding:0em;"><div style="margin-top:-0.3em">{{gst.serviceTypeDescription}}</div></div>
                    </div>
                  </div>
                </div>
                <div *ngIf="flightFollowingTaskList.length>0" style="font-size:small; min-width:10em ">
                  <div style="font-weight: bold">Flight Following</div>
                  <div *ngFor="let gst of flightFollowingTaskList; let i=index" style="margin-top:0em">
                    <div style="display: flex; font-size: small" [style.margin-top.em]="i>0? -0.25:0">
                      <div>
                        <mat-checkbox style="transform: scale(0.75);" (click)="$event.stopPropagation()" (change)="onCheckServiceTypeChange($event, gst, 'flightfollowing')"></mat-checkbox>
                      </div>
                      <div style="margin-top:0.1em;margin-left:0em; padding:0em;"><div style="margin-top:-0.3em">{{gst.serviceTypeDescription}}</div></div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
           </div>
         </div>
        </div>

      </div>
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
      <div style="margin-right:1em">
        <button class="btn btn-primary" *ngIf="f.isRevisionRequired.value==false" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>Save</div></div></button>&nbsp;
        <button type="button" class="btn btn-secondary" (click)="clickClose()" style="background-color:grey; color: white; "><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
      </div>
      <div *ngIf="errMsg!=''" class="d-block" style="margin-top:0em; margin-left:0.25em; display:flex; background-color:#f9d2de; padding-left:0.25em; width:24em;font-size: small ">{{errMsg}}</div>
      <div *ngIf="showSuccessMsg" style="margin-top: 0em;">
        <app-success-message [successMsg]=""></app-success-message>
      </div>
    </div>
  </form>
</div>

  <app-spin *ngIf="showSpin"></app-spin>
