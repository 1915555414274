<div *ngIf="upPII && grantAccessToManageManifestProfiles" style="background-color:#f8f8f8;" >
  <div style="display: flex; justify-content: space-between;" cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle>
    <div style="padding-left:1em;margin-top:0.3125em;"><h5><!--Travel-->Document {{personName}} >> Visa - {{dialogTitle}}</h5></div>
    <!--<div style="font-weight: 700; margin-top:0.3125em; cursor: pointer;padding-right:0.5em;">
      <i class="material-icons" style="color:black;" (click)="clickClose()">close</i>
    </div>-->
  </div>
  <div>
    <form *ngIf="person_visa_edit_form" [formGroup]="person_visa_edit_form" (ngSubmit)="clickSave()">
      <div style="margin-top:0em; padding-left: 1em; padding-right:1em">
        <div style="margin-top: 0em; background-color: white; padding-left: 0.5em;padding-bottom:1em">
          <div style="display: flex;">
            <div>
              <div style="display:flex">
                <div>
                  <div style="display:flex">
                    <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                      <label for="documentNumber" style="font-size:small">Document Number</label>
                      <div style="margin-left: 0em; margin-top:-0.25em; margin-bottom:0em">
                        <input type="text" formControlName="documentNumber" class="form-control" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.documentNumber.errors }" style="padding: 0em;width:11em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                      </div>

                    </div>
                    <div>
                      <div style="margin-right: 0.625em;margin-top:0em">
                        <label for="issing_country_select" style="font-size:small">Issuing Country</label>
                        <div style="margin-top:-0.25em;">
                          <select *ngIf="f" formControlName="issing_country_select" [ngClass]="{ 'is-invalid': submitted && f.issing_country_select.errors }" style="width: 22em;" class="form-control form-control-sm dropdown">
                            <option value="" selected></option>
                            <option *ngFor="let cty of countryList" [ngValue]="cty.countryName">{{cty.countryName}}</option>
                          </select>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div *ngIf="submitted && f.documentNumber.errors" class="invalid-feedback" style="margin-top:-2em">
                    <div *ngIf="f.documentNumber.errors.required" style="background-color: #f9d2de;margin-top:0.5em;padding-left:0.25em; width:17.5em">Document number is a required field.</div>
                  </div>
                  <div *ngIf="submitted && f.issing_country_select.errors" class="invalid-feedback" style="margin-top:-2em">
                    <div *ngIf="f.issing_country_select.errors.required" style="background-color: #f9d2de;margin-top:0.5em;padding-left:0.25em; width:16em">Issuing country is a required field.</div>
                  </div>
                </div>
                <div>
                  <div style="display:flex">
                    <div>
                      <label for="effectiveDate" style="font-size:small">Effective Date</label>
                      <div style="display:flex;margin-top:-0.35em">
                        <input type="text" [matDatepicker]="effectivedatepicker" placeholder="MM/DD/YYYY" formControlName="effectiveDate" #effectiveDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.effectiveDate.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                        <mat-datepicker-toggle matSuffix [for]="effectivedatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                        <mat-datepicker #effectivedatepicker></mat-datepicker>
                      </div>
                    </div>
                    <div style="margin-left:1em">
                      <label for="expirationDate" style="font-size:small">Expiration Date</label>
                      <div style="display:flex;margin-top:-0.35em">
                        <input type="text" [matDatepicker]="expirationdatepicker" placeholder="MM/DD/YYYY" formControlName="expirationDate" #expirationDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.expirationDate.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                        <mat-datepicker-toggle matSuffix [for]="expirationdatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em"></mat-datepicker-toggle>
                        <mat-datepicker #expirationdatepicker></mat-datepicker>
                      </div>
                    </div>

                  </div>
                  <div *ngIf="submitted && f.effectiveDate.errors" class="invalid-feedback" style="margin-top:-1em">
                    <div *ngIf="f.effectiveDate.errors.required" style="background-color: #f9d2de;padding-left:0.25em; width:15em">Effective date is a required field.</div>
                    <div *ngIf="f.effectiveDate.errors.isValidDate==false" style="background-color: #f9d2de;padding-left:0.25em; width:20em">Invalid effective date. format: MM/DD/YYYY</div>
                  </div>
                  <div *ngIf="submitted && f.expirationDate.errors" class="invalid-feedback" style="margin-top:-0.5em">
                    <div *ngIf="f.expirationDate.errors.required" style="background-color: #f9d2de;padding-left:0.25em; width:18em">Expiration date is a required field.</div>
                    <div *ngIf="f.expirationDate.errors.isValidDate==false" style="background-color: #f9d2de;padding-left:0.25em; width:20em">Invalid expiration date. format: MM/DD/YYYY</div>
                  </div>
                </div>
              </div>
              <div style="display:flex">
                <div>
                  <div style="display:flex">
                    <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                      <label for="firstName" style="font-size:small">First Name</label>
                      <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                        <input type="text" formControlName="firstName" class="form-control" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" style="padding: 0em;width:10.5em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                      </div>

                    </div>
                    <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                      <label for="middleName" style="font-size:small">Middle Name</label>
                      <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                        <input type="text" formControlName="middleName" class="form-control" maxlength="255" style="padding: 0em;width:10.5em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                      </div>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required" style="background-color: #f9d2de;margin-top:0.5em;padding-left:0.25em; width:13.5em">First name is a required field.</div>
                  </div>
                  <div>
                    <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                      <label for="lastName" style="font-size:small">Last Name</label>
                      <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                        <input type="text" formControlName="lastName" class="form-control" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" style="padding: 0em;width:22em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                      </div>
                      <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback" style="margin-top:-2em">
                        <div *ngIf="f.lastName.errors.required" style="background-color: #f9d2de;margin-top:0.5em;padding-left:0.25em; width:13.5em">Last name is a required field.</div>
                      </div>
                    </div>
                    <div *ngIf="personTravelSubDocumentGUID!='' && userType=='internal'" style="margin-top:-0.25em; margin-right:0.625em">
                      <label for="data_entry_status_select" style="font-size:small">Data Entry Status</label>
                      <div style="margin-top:-0.5em;margin-bottom:0em">
                        <select *ngIf="f" formControlName="data_entry_status_select" class="form-control form-control-sm dropdown">
                          <option *ngFor="let d of dataEntryStatusList" [ngValue]="d.dataEntryStatusID">{{d.dataEntryStatusDescription}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-inline-flex position-relative">
                  <div>
                    <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                      <label for="remarks" style="font-size:small">Remarks</label>
                      <div style="margin-left: 0em; margin-top:-0.5em">
                        <textarea formControlName="remarks" class="form-control" maxlength=255 style="width:22em; height:8em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
                      </textarea>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="position:absolute;bottom:0em">

                      <div class="small" style="width: max-content; font-size: small; display: grid;">
                        Document:
                        <a *ngIf="fileList && fileList.length>0" style="color:blue; cursor:pointer; text-decoration:underline" (click)="clickPreviewDocument()">{{fileList[0].fileName}}</a>
                      </div>
                      <div class="d-inline-flex">
                        <div *ngIf="fileList && fileList.length>0" style="margin-top:.25em;">
                          <button type="button" mat-button class="btn-high" (click)="clickPreviewDocument()">Preview</button>
                        </div>
                        <div *ngIf="fileList && fileList.length>0" style="margin-top:.25em;">
                          <button type="button" class="btn btn-danger pl-3 pr-3 ml-2" (click)="clickRemoveDocument()">&nbsp;Remove&nbsp;Document&nbsp;</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="d-inline-flex">
              <div *ngIf="personTravelSubDocumentGUID!=''" style="margin-top:0em; margin-right:0.625em">
                <label for="person_doc_status" style="font-size:small">Disable Record</label>
                <div style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="person_doc_status" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; width:8em; border-color:lightgrey">
                    <option value="1" selected>No</option>
                    <option value="0">Yes</option>
                  </select>
                </div>
              </div>
              <div>
                <div style="margin-right:1em">
                  <label for="modifiedBy" style="font-size:small">Modified By<!--{{personTravelSubDocumentGUID!=''? 'Modified by': 'Created by' }}--></label>
                  <div style="margin-top:-0.5em;">
                    <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:9em;height:1.5625em;font-size:small;padding:0" />
                  </div>
                </div>
                <div>
                  <label for="modifiedDate" style="font-size:small">Modified Date<!--{{personTravelSubDocumentGUID!=''? 'Modified Date': 'Date' }}--></label>
                  <div style="margin-top:-0.5em;">
                    <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:9em;height:1.5625em;font-size:small;padding:0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="errMsg!=''" class="d-block" style="padding-left:0.25em; display:flex;margin-top:0.5em;margin-bottom:-1em; white-space:pre-wrap; background-color: #f9d2de;width:30em"><label style="color:black; font-size: small">{{errMsg}}</label></div>

        </div>
      </div>
    </form>
  </div>

  <div class="d-inline-flex" style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;height:3em">
    <button mat-button class="btn-high" [disabled]="loading" *ngIf="(personTravelSubDocumentGUID=='' && userType=='customer') || userType=='internal'" (click)="clickSave()" style="height:2em"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;Save&nbsp;</button>&nbsp;
    <button mat-button class="btn-high" *ngIf="personTravelSubDocumentGUID!='' && userType=='internal'" style="height:2em" (click)="clickAddNew()">Add new Record</button>&nbsp;
    <div *ngIf="(personTravelSubDocumentGUID=='' && userType=='customer') || userType=='internal'">
      <div style="margin-left:0em; margin-right:.75em;margin-top: -0.15em;">
        <label class="btn-high" mat-button style="border: 0.0625em solid #7095c8; height:2em; border-radius: 0.25em; cursor:pointer">
          <input #document formControlName="document" id="document" type="file" ng2FileSelect [uploader]="uploader" style="display:none" accept="*" (change)="importFile($event)" maxFileSize="28000000" (click)="onFileClick($event)" />
          Load Document<span *ngIf="!finishAllUploads" class="spinner-border spinner-border-sm mr-1"></span>
        </label>
      </div>
    </div>
    <button mat-button class="btn-low" (click)="clickClose()" style="height:2em">Close</button>&nbsp;
    <div class="small" style="font-size:x-small; margin-right:1em">
      <div>
        Maximum file size 5MB 
      </div>
      <div>
        Allowed file types: {{allowedFileType}}
      </div>
    </div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]=""></app-success-message>
    </div>

  </div>
</div>
  <app-spin *ngIf="showSpin"></app-spin>
