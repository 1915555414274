import { Component, Inject, OnInit, Optional } from "@angular/core";
import { CustomerModel } from "../models/customer.model";
import { CustomerService } from "../services/customer.service";
import { ResponseModel } from "../models/response.model";
import { AuthenticateService } from "../services/authenticate.service";
import { CommonService } from "../services/common.service";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { CustomerGroupService } from "../services/customer-group-service";
import { CustomerGroupModel } from "../models/customer-group.model";
import { MarketingRegionModel } from "../models/marketing-region.model";
import { CustomerTypeModel } from "../models/customer-type.model";
import { MaintenanceDashboardComponent } from "../common-utility/maintenance-dashboard.component";
import { AccountGrouplistComponent } from "../account-groups/account-grouplist.component";
import { CustomerProfileMainEditDialogComponent } from "./customer-profile-main-edit-dialog.component";
import { CustomerInformationDialogComponent } from "./customer-information-dialog.component";
import { CustomerProfileSetupEditComponent } from "./customer-profile-setup-edit.component";
import { AircraftProfileComponent } from "../aircraft/aircraft-profile.component";
import { AdminServicesClientComponent } from "../user-accounts/admin-services-client.component";
import { ManageContactDetailsComponent } from "../persons/manage-contact-details.component";

@Component({
  selector: 'app-customer-profile-setup2',
  templateUrl: './customer-profile-setup2.component.html',
  styleUrls: ['./customer-profile-setup2.component.css']
})

export class CustomerProfileSetupComponent2 implements OnInit {
  customer_profile_setup_form: UntypedFormGroup;
  recordList: CustomerModel[] = [];
  filteredRecordList: CustomerModel[] = [];
  accountGroupingList: CustomerGroupModel[] = [];
  salesTeamList: MarketingRegionModel[] = [];
  selectedSalesTeamID: number;
  customerList: CustomerModel[];
  userType: string;
  loading: boolean = false;
  submitted: boolean = false;
  showSpin: boolean = true;
  errMsg: string;
  msg: string;
  totalRecordsText: string;
  totalRecords: number = 0;
  customerType: number = 1;
  customerTypeList: CustomerTypeModel[] = [];
  pageSize: number = 10;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  displayedColumn: string[];
  displayedColumn1: string[] = ['customer_name', 'acct_setup', 'client_information', 'aircraft', 'crew_pax_support', 'admin_services', 'feedback'];
  isDialog: boolean = false;
  isActive: boolean = true;
  textFilterUsed: boolean = false;
  accountGroupingFilterUsed: boolean = false;
  salesTeamFilterUsed: boolean = false;
  statusFilterUsed: boolean = false;

  constructor(
    @Optional() private readonly _dialogRef: MatDialogRef<CustomerProfileSetupComponent2>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _customerService: CustomerService,
    private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _customerGroupService: CustomerGroupService,
    private readonly _dialog: MatDialog) {
    if (this.data) {
      this.isDialog = true;
    }
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.customerList = [];
    this.recordList = [];
    this.filteredRecordList = [];
    this.displayedColumn = this.displayedColumn1;
    this.isActive = true;

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    if (this.userType == "internal") {
      this._commonService.getCustomerListByType<ResponseModel<CustomerModel[]>>(this.customerType).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model.length > 0) {
            this.customerList = response.model;
          }
          else {

          }
        }
        this.showSpin = false;
      })
    }
    this.initControls();
    this.getSearchResults(this.customerType);
  }

  initControls() {
    this.customer_profile_setup_form = this._formBuilder.group({
      text_filter: [],
      account_grouping: [],
      sales_team: [this.salesTeamList.filter(x => x.marketingRegionID === this.selectedSalesTeamID)],
      status: []
    });
  }

  getSearchResults(cusType: number) {
    this.totalRecords = 0;
    this.showSpin = true;
    this.loading = true;
    this._authService.updateAccessTime();
    let request = new CustomerModel();
    request.customerGUID = null;
    if (request.customerGUID == "") {
      request.customerGUID = null;
    }
    request.textSearch = "";
    request.filterBy = "CreditStatus";
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    request.customerTypeId = cusType;

    this._customerService.getCustomerProfileList<ResponseModel<CustomerModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          this.filteredRecordList = this.recordList;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)
            this.msg = "";
          }
        }
        else {
          this.recordList = [];
          //this.msg = "No data returned.";
        }
      }
      else {
        this.recordList = [];
        this.msg = "";
      }
      this.loading = false;
      this.showSpin = false;
    });

    this._commonService.getMarketingRegionList<ResponseModel<MarketingRegionModel[]>>().subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.salesTeamList = response.model;
          this.selectedSalesTeamID = this.salesTeamList[0].marketingRegionID;
        }
        else {
          this.salesTeamList = [];
          //this.msg = "No data return.";          
          this.msg = "No records found.";
        }
      }
      else {

      }
    });

    let request2 = new CustomerGroupModel();
    request2.isActive = this.isActive;
    this._customerGroupService.getAllCustomerGroup<ResponseModel<CustomerGroupModel[]>>(request2).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.accountGroupingList = response.model;
        }
        else {
          this.accountGroupingList = [];
          //this.msg = "No data return.";          
          this.msg = "No records found.";
        }
      }
      else {
        this.accountGroupingList = [];
      }

    });

    this._commonService.getCustomerTypeList<ResponseModel<CustomerTypeModel[]>>().subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.customerTypeList = response.model;
        }
        else {
          this.customerTypeList = [];
          //this.msg = "No data return.";          
          this.msg = "No records found.";
        }
      }
      else {
        this.customerTypeList = [];
      }
    });
  }

  get f() { return this.customer_profile_setup_form.controls; }

  clickRouteNew(route: string, display: string) {
    this.clickClose(true);
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }

  clickClose(val) {
    this._dialogRef.close(val);
  }

  openSelection(columnName: string, customerGUID: string) {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "39.5em";//"1000px";
    //config.maxWidth = "65em";//"1000px";
    config.width = "65em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: customerGUID, customerName: this.customerList.find(x => x.customerGUID === customerGUID).customerName, userType: 'customer' };
    config.position = { left: "10px", top: "140px" };

    if (this.isDialog) {
      this.clickClose(true);
    }

    switch (columnName) {
      case 'Acct Setup':
        config.height = "50em";
        config.width = "90em";
        config.maxWidth = "90em";
        config.maxHeight = "50em";
        config.position = { left: "10px", top: "140px" };
        this._dialog.open(CustomerProfileSetupEditComponent, config);
        break;
      case 'Client Information':
        config.width = "69em";
        this._dialog.open(CustomerInformationDialogComponent, config);
        break;
      case 'Aircraft':
        config.width = "92em";
        config.maxWidth = "92em";
        this._dialog.open(AircraftProfileComponent, config);
        break;
      case 'Crew, PAX & Support':
        config.height = "auto";
        config.maxHeight = "80em";
        config.width = "81em";
        this._dialog.open(ManageContactDetailsComponent, config);
        break;
      case 'Admin Services':
        config.height = "max-content";
        config.width = "max-content";
        this._dialog.open(AdminServicesClientComponent, config);
        break;
    }

  }

  searchClientAccounts(e: any) {
    if (e.target.value === "") {
      this.resetFilters();
      return;
    }

    this.filteredRecordList = this.recordList.filter(x => x.customerName.toLowerCase().includes(e.target.value.toLowerCase()));
    this.totalRecords = this.filteredRecordList.length;

    this.f.account_grouping.disable();
    this.f.text_filter.enable();
    this.f.sales_team.disable();
    this.f.status.disable();
  }

  filterClientAccountsByAccountGrouping(e: any) {
    var selectedAccountGrouping = this.accountGroupingList.filter(x => x.customerGroupName.toLowerCase() === e.target.value.toLowerCase())[0];
    if (selectedAccountGrouping === undefined) {
      this.resetFilters();

      return;
    }

    this._customerGroupService.getSelectedCustomerListBycgrpId<ResponseModel<CustomerModel[]>>(selectedAccountGrouping).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.customerList = response.model;
          this.filteredRecordList = this.customerList;
          this.totalRecords = this.filteredRecordList.length;

          this.f.account_grouping.enable();
          this.f.text_filter.disable();
          this.f.sales_team.disable();
          this.f.status.disable();
        }
        else {

        }
      }
    });
  }

  filterClientAccountsBySalesTeam(e: any) {
    this.selectedSalesTeamID = this.salesTeamList.filter(x => x.marketingRegionDesc.toLowerCase() === e.target.value.toLowerCase())[0]?.marketingRegionID;
    if (this.selectedSalesTeamID === undefined) {
      this.resetFilters();
      return;
    }

    this._commonService.getCustomerListBySalesTeam<ResponseModel<CustomerModel[]>>(this.selectedSalesTeamID).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.customerList = response.model;
          this.filteredRecordList = this.customerList;
          this.totalRecords = this.filteredRecordList.length;

          this.f.account_grouping.disable();
          this.f.text_filter.disable();
          this.f.sales_team.enable();
          this.f.status.disable();
        }
        else {

        }
      }
    })
  }

  filterClientAccountsByStatus(e: any) {
    switch (e.target.value.toLowerCase()) {
      case "active":
        this.customerType = 1;
        this.resetFilters();
        return;
      case "prospect":
        this.customerType = 2;
        break;
      case "suspended":
        this.customerType = 4;
        break;
      case "closed":
        this.customerType = 5;
        break;
    }
    this._commonService.getCustomerListByType<ResponseModel<CustomerModel[]>>(this.customerType).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.customerList = response.model;
          this.filteredRecordList = this.customerList;
          this.totalRecords = this.filteredRecordList.length;

          this.f.account_grouping.disable();
          this.f.text_filter.disable();
          this.f.sales_team.disable();
          this.f.status.enable();
        }
        else {

        }
      }
    });
  }

  openMaintenanceComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "34em";
    config.width = "43em";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    config.position = { left: "10px", top: "140px" };

    if (this.isDialog) {
      this.clickClose(true);
    }

    this._dialog.open(MaintenanceDashboardComponent, config);
  }

  openAccountGroupingComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "34em";//"1000px";
    config.maxWidth = "64.5em";//"1000px";
    config.width = "37.5em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };

    if (this.isDialog) {
      this.clickClose(true);
    }

    this._dialog.open(AccountGrouplistComponent, config);
  }

  openAddClientComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: "", dialogTitle: "Add New Client", };

    if (this.isDialog) {
      this.clickClose(true);
    }

    this._dialog.open(CustomerProfileMainEditDialogComponent, config);
  }

  resetFilters() {
    this.f.text_filter.setValue("");
    this.f.account_grouping.setValue("");
    this.f.sales_team.setValue("");
    this.f.status.setValue("Active");

    this.customerList = this.recordList;
    this.filteredRecordList = this.customerList;
    this.totalRecords = this.filteredRecordList.length;

    this.f.account_grouping.enable();
    this.f.text_filter.enable();
    this.f.sales_team.enable();
    this.f.status.enable();
  }
}
