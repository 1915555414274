import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { HelpDashboardClientComponent } from '../help-guides/help-dashboard-client.component';
import { AuthenticateService } from '../services/authenticate.service';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.css']
})
/** contact-us component*/


export class ContactUsComponent{
  isInternal: boolean = true;
  isDialog: boolean = false;
    /** contact-us ctor */
  constructor(@Inject(MAT_DIALOG_DATA) private _data: any, private readonly _dialogRef: MatDialogRef<ContactUsComponent>, private readonly _dialog: MatDialog, private readonly _authService: AuthenticateService) {
    if (this._data) {
      this.isDialog = true;
    }

    this.isInternal = _data.isInternal;  

  }
  ngOnInit() {
    this._dialogRef.afterClosed().subscribe(() => {
      this.isDialog = false; 
    });
    

  }
  openHelpDashboardClientComponent() {
    // Close the current Help Guides dialog
    this._dialogRef.close();

    // Close any existing Help Dashboard dialogs
    this._dialog.openDialogs.forEach(dialog => {
      if (dialog.componentInstance instanceof HelpDashboardClientComponent) {
        dialog.close();
      }
    });

    // Open a new Help Dashboard
    setTimeout(() => {
      const config = new MatDialogConfig();
      config.panelClass = "custom-dialog-container3";
      config.height = "18.5em";
      config.width = "45em";
      config.restoreFocus = false;
      config.hasBackdrop = false;
      config.data = { v: this._authService.getCurrentTimeNumber(), isDialog: true};
      config.position = { left: "20px", top: "138px" };     
      this._dialog.open(HelpDashboardClientComponent, config);
    }, 200); // Add delay to ensure dialogs are closed before opening a new one
  }

  GoBack() {
    this._dialogRef.close();
  }

}
