import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of } from 'rxjs';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { CompanyEventModel } from '../models/company-event.model';
import { ContentDocumentModel } from '../models/content-document.model';
import { CustomerGroupModel } from '../models/customer-group.model';
import { CustomerModel } from '../models/customer.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerGroupService } from '../services/customer-group-service';
import { CustomerService } from '../services/customer.service';
import { DialogService } from '../services/dialog.service';
import { CompanyEventDialogComponent } from './company-event-dialog.component';
import { CompanyEventEditDialogComponent } from './company-event-edit-dialog.component';
import { MaintenanceDashboardClientComponent } from '../common-utility/maintenance-dashboard-client.component';

@Component({
    selector: 'app-company-event-list',
    templateUrl: './company-event-list.component.html',
    styleUrls: ['./company-event-list.component.css']
})

/** company-event-list component*/
export class CompanyEventListComponent implements OnInit {
  companyEventList: CompanyEventModel[];
  showSpin: boolean = false;
  customerName: string;
  includeDisabled: boolean = false;
  includeExpired: boolean = false;
  hasPermission: boolean = false;
  company_event_form: UntypedFormGroup;
  customerGuid: string = "";
  grantAccessToAccountGrouping: boolean = false;
  customerList: CustomerModel[];
  userType: string;
  isDialog: boolean = false;
  displayedColumn: string[] = ['title', 'story', 'attachment_count', 'weblink', 'display_start','display_end', 'effective_date', 'expiration_date', 'disabled', 'action'];
    /** company-event-list ctor */
  constructor(private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder, private readonly _customerGroupService:CustomerGroupService,
    private readonly _customerService: CustomerService, private readonly _dialogService: DialogService, private readonly _dialog: MatDialog, @Optional() private readonly _dialogRef: MatDialogRef<CompanyEventListComponent>, @Optional() @Inject(MAT_DIALOG_DATA) private data: any) {
    if (data) {
      this.isDialog = true;
    }
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.cEvent') != 'true') {
      this.hasPermission = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    else {
      this.hasPermission = true;
    }
    if (localStorage.getItem('up.cGrp') == 'true') {
      this.grantAccessToAccountGrouping = true;
    }
    this.customerName = localStorage.getItem('cn');
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    this.showSpin = true;
    this.getData();
  }

  initControls() {
    this.company_event_form = this._formBuilder.group({
      customer_select: this.customerGuid
    });
  }

  getAllData(): Observable<any> {
    let getClistResponse;
    if (this.grantAccessToAccountGrouping) {
      let req = new CustomerGroupModel();
      getClistResponse = this._customerGroupService.getSelectedCustomerListBycgrpId(req);
    }
    else {
      getClistResponse = of(null);
    }
    let companyEvent = new CompanyEventModel();
    companyEvent.includeExpired = this.includeExpired;
    companyEvent.isActive = !this.includeDisabled;
    companyEvent.isDisplayOnly = false;
    if (this.customerGuid != "") {
      companyEvent.customerGUID = this.customerGuid;
    }
    let companyEventResponse = this._customerService.getCompanyEventListBycId(companyEvent);
    return forkJoin([getClistResponse, companyEventResponse]);
  }
   
  getData() {
    this.getAllData().subscribe( responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.customerList = responses[0].model;
          if (this.userType == "customer" && this.grantAccessToAccountGrouping) {
            if (this.customerGuid == "")
              this.customerGuid = this.customerList.find(x => x.isDefaultClient == true).customerGUID.toLowerCase();
          }
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.companyEventList = responses[1].model;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      this.showSpin = false;
      this.initControls();
    });
  }


  clickEdit(event: any, cEvent: CompanyEventModel) {

    let s = "";
    let companyEventID=0;
    if (cEvent == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      companyEventID = cEvent.companyEventID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { companyEventID: companyEventID, customerGUID: this.customerGuid };


    const dialogRef = this._dialog.open(CompanyEventEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });


  }

  openCompanyEvent(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { companyEventID: id };
    this._dialog.open(CompanyEventDialogComponent, dialogConfig);


  }

  get f() { return this.company_event_form.controls; }


  customerChange(e: any) {
    this.customerName = e.target.options[e.target.options.selectedIndex].text

    let companyEvent = new CompanyEventModel();
    companyEvent.includeExpired = this.includeExpired;
    companyEvent.isActive = !this.includeDisabled;
    companyEvent.isDisplayOnly = false;
    companyEvent.customerGUID = this.f.customer_select.value;
    this.customerGuid = this.f.customer_select.value;

    this._customerService.getCompanyEventListBycId<ResponseModel<CompanyEventModel[]>>(companyEvent).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.companyEventList = response.model;
        }
      }
    });
  }

  openDataMaintenanceComponent() {
    if (this.isDialog) {
      // Close the current dialog
      this._dialogRef.close();

      // Proceed after dialog closes
      setTimeout(() => {
        const config = new MatDialogConfig();
        config.panelClass = "custom-dialog-container3";
        config.height = "23.3em";
        config.width = "52.6em";
        config.restoreFocus = false;
        config.hasBackdrop = false;
        config.data = {
          isDialog: true,
          v: this._authService.getCurrentTimeNumber()
        };
        config.position = { left: "20px", top: "138px" };
        this._dialog.open(MaintenanceDashboardClientComponent, config);
      }, 200); // Delay ensures the current dialog is fully closed
    } else {
      // If not in a dialog, open directly
      const config = new MatDialogConfig();
      config.panelClass = "custom-dialog-container3";
      config.height = "23.3em";
      config.width = "52.6em";
      config.restoreFocus = false;
      config.hasBackdrop = false;
      config.data = {
        v: this._authService.getCurrentTimeNumber()
      };
      
      this._dialog.open(MaintenanceDashboardClientComponent, config);
    }
  }
  clickRouteNew(route: string, display: string) {
    this._dialogRef.close(true);
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }



}
