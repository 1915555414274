import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { TripRevisionModel } from '../models/trip-revision.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { TripTaskStatusModel } from '../models/trip-task-status.model';

@Component({
  selector: 'app-task-revision-required-edit-dialog',
  templateUrl: './task-revision-required-edit-dialog.component.html',
  styleUrls: ['./task-revision-required-edit-dialog.component.css']
})

export class TaskRevisionRequiredEditDialogComponent implements OnInit {
  userType: string; 
  groundStopTaskGUID: string;
  groundStopGUID: string;
  isRevisionRequiredValue: number;
  task_revision__edit_form: UntypedFormGroup;
  errMsg: string;
  msg: string;
  tripCode: string;
  registration: string;
  icao: string;
  iata: string;

  arrUTCDateTime: string;
  depUTCDateTime: string;
  arrLocalDateTime: string;
  depLocalDateTime: string;
  serviceTypeDescription: string;
  isRevisionRequired: boolean;
  
  showSpin: boolean = true;
  loading: boolean = false;
  submitted: boolean = false;
  v: number = this._authService.getCurrentTimeNumber();

  recordList: TripRevisionModel[];
  airportServiceTaskList: GroundStopTaskModel[];
  flightPlanServiceTaskList: GroundStopTaskModel[];
  preflightServiceTaskList: GroundStopTaskModel[];
  flightFollowingTaskList: GroundStopTaskModel[];
  selectedAirportServiceTaskList: GroundStopTaskModel[];
  selectedFlightPlanServiceTaskList: GroundStopTaskModel[];
  selectedPreflightServiceTaskList: GroundStopTaskModel[];
  selectedFlightFollowingTaskList: GroundStopTaskModel[];
  //displayedColumn: string[] = ['transactionDate', 'changeRequestType', 'changeRequest', 'userComments'];
  displayedColumn: string[] = ['transactionDate', 'changeRequest'];//, 'userComments'];
  //auditHourText: string;
  isModified: boolean = false;
  showSuccessMsg: boolean = false;
  taskStatusList: TripTaskStatusModel[];
  upPII: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<TaskRevisionRequiredEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopService: GroundStopService, private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialogService: DialogService) {

    this.groundStopGUID = _data.groundStopGUID;
    this.groundStopTaskGUID = _data.groundStopTaskGUID;
    this.isRevisionRequiredValue = _data.isRevisionRequiredValue;

  }

  getAllData(): Observable<any[]> {
    let request = new TripRevisionModel();
    request.groundStopGUID = this.groundStopGUID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    request.isRevisionRequiredValue = this.isRevisionRequiredValue;
    let getGSTResponse = this._groundStopAdvancedService.getTaskRevisionRequiredBygstId<ResponseModel<TripRevisionModel[]>>(request);
    let getOtherGSTResponse = this._groundStopAdvancedService.getTaskRevisionRequiredBygsId_excludegstId<ResponseModel<TripRevisionModel>>(request);
    let getTaskStatusResponse;
    // if (this.isRevisionRequiredValue == 2) {
    //   getTaskStatusResponse = this._groundStopAdvancedService.getTripTaskStatusListForRevision2();
    // }
    // else {
    //   getTaskStatusResponse = this._groundStopService.getTripTaskStatusList();
    // }
    getTaskStatusResponse = this._groundStopService.getTripTaskStatusList();
    return forkJoin([getGSTResponse, getOtherGSTResponse, getTaskStatusResponse]);

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.tripCode = "";
    this.registration = "";
    this.icao = "";
    this.iata = "";
    this.arrUTCDateTime = "";
    this.depUTCDateTime = "";
    this.arrLocalDateTime = "";
    this.depLocalDateTime = "";
    this.serviceTypeDescription = "";
    this.isRevisionRequired = false;
    this.airportServiceTaskList = [];
    this.flightPlanServiceTaskList=[];
    this.preflightServiceTaskList=[];
    this.flightFollowingTaskList = [];
    this.selectedAirportServiceTaskList=[];
    this.selectedFlightPlanServiceTaskList=[];
    this.selectedPreflightServiceTaskList=[];
    this.selectedFlightFollowingTaskList = [];
    this.taskStatusList = [];
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.recordList = responses[0].model;
          this.tripCode = this.recordList[0].tripCode;
          this.registration = this.recordList[0].registration;
          this.icao = this.recordList[0].icao;
          this.iata = this.recordList[0].iata;
          this.arrUTCDateTime = this.recordList[0].arrivalDateTimeUTC;
          this.depUTCDateTime = this.recordList[0].departureDateTimeUTC;
          this.arrLocalDateTime = this.recordList[0].arrivalDateTimeLocal;
          this.depLocalDateTime = this.recordList[0].departureDateTimeLocal;
          this.serviceTypeDescription = this.recordList[0].serviceTypeDescription;
          if (this.isRevisionRequiredValue > 0) {
            this.isRevisionRequired = true;
          }
          else {
            this.isRevisionRequired = false;
          }
          //this.isRevisionRequired = this.recordList[0].isRevisionRequired;

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          let obj = new TripRevisionModel();
          obj = responses[1].model;
          this.airportServiceTaskList = obj.airportServiceTaskList;
          this.flightPlanServiceTaskList = obj.flightPlanServiceTaskList;
          this.preflightServiceTaskList = obj.preflightServiceTaskList;
          this.flightFollowingTaskList = obj.flightFollowingTaskList;
          

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.taskStatusList = responses[2].model;
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      if (this.isRevisionRequiredValue == 1) {
        this.f.taskstatus_select.clearValidators();
        this.f.taskstatus_select.updateValueAndValidity();
      }

      this.showSpin = false;

    });

  }

  initControls() {
    this.task_revision__edit_form = this._formBuilder.group({
      isRevisionRequired: [this.isRevisionRequired],
      userComments: ['', [Validators.required, Validators.minLength(6)]],
      checkRegularRevisionComment1: [false],
      checkRegularRevisionComment2: [false],
      checkRegularRevisionComment3: [false],
      checkRegularRevisionComment4: [false],
      checkRegularRevisionComment5: [false],
      checkCriticalRevisionComment1: [false],
      checkCriticalRevisionComment2: [false],
      checkCriticalRevisionComment3: [false],
      checkCriticalRevisionComment4: [false],
      checkCriticalRevisionComment5: [false],
      taskstatus_select: ['', Validators.required]
    });


  }

  get f() { return this.task_revision__edit_form.controls; }

  //getRevision() {
  //  let obj = new TripRevisionModel();
  //  obj.groundStopGUID = this.groundStopGUID;
  //  obj.groundStopTaskGUID = this.groundStopTaskGUID;
    
  //  this._groundStopAdvancedService.getTaskRevisionRequiredBygstId<ResponseModel<TripRevisionModel[]>>(obj).subscribe(response => {
  //    if (response != null && response.code == "200") {
  //      if (response.model.length > 0) {
  //        this.recordList = response.model;
  //        this.tripCode = this.recordList[0].tripCode;
  //        this.registration = this.recordList[0].registration;
  //        this.icao = this.recordList[0].icao;
  //        this.iata = this.recordList[0]. iata;
  //        this.arrUTCDateTime = this.recordList[0].arrivalDateTimeUTC;
  //        this.depUTCDateTime = this.recordList[0].departureDateTimeUTC;
  //        this.arrLocalDateTime = this.recordList[0].arrivalDateTimeLocal;
  //        this.depLocalDateTime = this.recordList[0].departureDateTimeLocal;
  //        this.serviceTypeDescription = this.recordList[0].serviceTypeDescription;
  //        this.isRevisionRequired = this.recordList[0].isRevisionRequired;

  //        this.initControls();
  //      }
  //      else {
  //        this.msg = "No data returned";
  //      }
  //    }
  //    else {
  //      if (response.code == "401") {
  //        //this.errMsg = response.message
  //        this._authService.signOut();
  //      }

  //    }
  //    this.showSpin = false;
  //  })
  //}

  onCheckServiceTypeChange(e: any, item: GroundStopTaskModel, className) {
    item.selected = e.checked;
    this.setSelectedTaskList(className);
  }

  setSelectedTaskList(className: string) {    
    switch (className) {
      case "airport":
        this.selectedAirportServiceTaskList = this.airportServiceTaskList.filter(u => u.selected == true);
        break;
      case "flightplan":
        this.selectedFlightPlanServiceTaskList = this.flightPlanServiceTaskList.filter(u => u.selected == true);
        break;
      case "preflight":
        this.selectedPreflightServiceTaskList = this.preflightServiceTaskList.filter(u => u.selected == true);
        break;
      case "flightfollowing":
        this.selectedFlightFollowingTaskList = this.flightFollowingTaskList.filter(u => u.selected == true);
        break;

    }
    
  }

  checkRegularRevisionComment1Change(e: any) {
    let s: string;
    s = this.f.userComments.value;
    s = s.trim();
    if (e.checked) {
      if (s != "") {
        if (s.indexOf(e.source.value) == -1) {         
          s += "\n";         
          s += e.source.value;
        }
      }
      else {
        s += e.source.value;
      }
      
    }
    
    this.f.userComments.setValue(s);
  }

  checkRegularRevisionComment2Change(e: any) {
    let s: string;
    s = this.f.userComments.value;
    s = s.trim();
    if (e.checked) {
      if (s != "") {
        if (s.indexOf(e.source.value) == -1) {         
          s += "\n";        
          s += e.source.value;
        }
      }
      else {
        s += e.source.value;
      }

    }

    this.f.userComments.setValue(s);
  }

  checkRegularRevisionComment3Change(e: any) {
    let s: string;
    s = this.f.userComments.value;
    s = s.trim();
    if (e.checked) {
      if (s != "") {
        if (s.indexOf(e.source.value) == -1) {         
          s += "\n";         
          s += e.source.value;
        }
      }
      else {
        s += e.source.value;
      }

    }

    this.f.userComments.setValue(s);
  }

  checkRegularRevisionComment4Change(e: any) {
    let s: string;
    s = this.f.userComments.value;
    s = s.trim();
    if (e.checked) {
      if (s != "") {
        if (s.indexOf(e.source.value) == -1) {         
          s += "\n";        
          s += e.source.value;
        }
      }
      else {
        s += e.source.value;
      }

    }

    this.f.userComments.setValue(s);
  }

  checkRegularRevisionComment5Change(e: any) {
    let s: string;
    s = this.f.userComments.value;
    s = s.trim();
    if (e.checked) {
      if (s != "") {
        if (s.indexOf(e.source.value) == -1) {         
          s += "\n";          
          s += e.source.value;
        }
      }
      else {
        s += e.source.value;
      }

    }

    this.f.userComments.setValue(s);
  }

  checkCriticalRevisionComment1Change(e: any) {
    let s: string;
    s = this.f.userComments.value;
    s = s.trim();
    if (e.checked) {
      if (s != "") {
        if (s.indexOf(e.source.value) == -1) {          
          s += "\n";         
          s += e.source.value;
        }
      }
      else {
        s += e.source.value;
      }

    }

    this.f.userComments.setValue(s);
  }

  checkCriticalRevisionComment2Change(e: any) {
    let s: string;
    s = this.f.userComments.value;
    s = s.trim();
    if (e.checked) {
      if (s != "") {
        if (s.indexOf(e.source.value) == -1) {          
          s += "\n";         
          s += e.source.value;
        }
      }
      else {
        s += e.source.value;
      }

    }

    this.f.userComments.setValue(s);
  }

  checkCriticalRevisionComment3Change(e: any) {
    let s: string;
    s = this.f.userComments.value;
    s = s.trim();
    if (e.checked) {
      if (s != "") {
        if (s.indexOf(e.source.value) == -1) {          
          s += "\n";         
          s += e.source.value;
        }
      }
      else {
        s += e.source.value;
      }

    }

    this.f.userComments.setValue(s);
  }

  checkCriticalRevisionComment4Change(e: any) {
    let s: string;
    s = this.f.userComments.value;
    s = s.trim();
    if (e.checked) {
      if (s != "") {
        if (s.indexOf(e.source.value) == -1) {          
          s += "\n";         
          s += e.source.value;
        }
      }
      else {
        s += e.source.value;
      }

    }

    this.f.userComments.setValue(s);
  }

  checkCriticalRevisionComment5Change(e: any) {
    let s: string;
    s = this.f.userComments.value;
    s = s.trim();
    if (e.checked) {
      if (s != "") {
        if (s.indexOf(e.source.value) == -1) {         
          s += "\n";         
          s += e.source.value;
        }
      }
      else {
        s += e.source.value;
      }

    }

    this.f.userComments.setValue(s);
  }
  // add end

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.task_revision__edit_form.invalid || this.errMsg != "") {
      return;
    }

    let request = new TripRevisionModel();
    request.groundStopGUID = this.groundStopGUID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    request.userComments = this.f.userComments.value;
    request.airportServiceTaskList = this.selectedAirportServiceTaskList;
    request.flightPlanServiceTaskList = this.selectedFlightPlanServiceTaskList;
    request.preflightServiceTaskList = this.selectedPreflightServiceTaskList;
    request.flightFollowingTaskList = this.selectedFlightFollowingTaskList;
    request.isRevisionRequiredValue = this.isRevisionRequiredValue;
    request.taskStatusGUID = this.f.taskstatus_select.value;
    
    this.loading = true;

    this._groundStopAdvancedService.turnOffGroundStopTaskIsRevisionRequiredBygstIdList<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.clickClose();
          }, 1000);
        }
        else {
          //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          this.errMsg = "Failed to save this record at this time. Please try later.";
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }








}
