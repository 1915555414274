import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { ContentDocumentModel } from '../models/content-document.model';
import { HeadlineModel } from '../models/headline.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { CustomerService } from '../services/customer.service';
import { DialogService } from '../services/dialog.service';
import { HeadlineEditDialogComponent } from './headline-edit-dialog.component';
import { FPCNewsEditDialogComponent } from './fpc-news-edit-dialog.component';

@Component({
    selector: 'app-fpc-news-list',
    templateUrl: './fpc-news-list.component.html',
  styleUrls: ['./fpc-news-list.component.css']
})

/** company-event-list component*/
export class FPCNewsListComponent implements OnInit {
  headlineList: HeadlineModel[];
  showSpin: boolean = false;
  customerName: string;
  includeDisabled: boolean = false;
  includeExpired: boolean = false;
  hasPermission: boolean = false;
  displayedColumn: string[] = ['headline_type','title', 'story', 'attachment_count', 'weblink', 'display_start','display_end', 'effective_date', 'expiration_date', 'is_released','is_restricted','customer_list', 'disabled', 'action'];
 
  statuses = [
    { label: 'Released', selected: true },
    { label: 'Not Released', selected: false }, 
    { label: 'Expired', selected: false },
    { label: 'Disabled', selected: false } 
  ];
 
 
  filterStatuses = [ 
    { label: 'Client and Internal', selected: true },
    { label: 'Internal', selected: false },
    { label: 'Maintenance', selected: false }
  ];
 
 
  /** company-event-list ctor */
  constructor(private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder, 
    private readonly _commonService: CommonService, private readonly _dialogService: DialogService, private readonly _dialog: MatDialog) {

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.news') != 'true') {
      this.hasPermission = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    else {
      this.hasPermission = true;
    }
    this.showSpin = true;
    this.getData();
  }
   
  getData() {
    let headline = new HeadlineModel();
    // headline.includePast = this.includeExpired;
    // headline.isActive = !this.includeDisabled;
    headline.isDisplayOnly = false;
    headline.isEventReleased = this.statuses.find(status => status.label === 'Released')?.selected ?? false;
    headline.notReleased = this.statuses.find(status => status.label === 'Not Released')?.selected ?? false;
    headline.includePast = this.statuses.find(status => status.label === 'Expired')?.selected ?? false;
    headline.isActive = !(this.statuses.find(status => status.label === 'Disabled')?.selected ?? false);
    
    
    headline.isInternalOnly = this.filterStatuses.find(status => status.label === 'Internal')?.selected ?? false;
    headline.isMaintenance = this.filterStatuses.find(status => status.label === 'Maintenance')?.selected ?? false;
    headline.isPublic = this.filterStatuses.find(status => status.label === 'Public')?.selected ?? false;



    this._commonService.getHeadlineListForInternal<ResponseModel<HeadlineModel[]>>(headline).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.headlineList = response.model;


          this.headlineList = this.headlineList.filter(x => x.headlineTypeID != 10 && x.isFpc == true); 
        }
      }
      this.showSpin = false;
    });
  }


  clickEdit(event: any, headline: HeadlineModel) {

    let s = "";
    let headlineID=0;
    if (headline == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      headlineID = headline.headlineID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { dialogTitle: s, headlineID: headlineID };


    const dialogRef = this._dialog.open(FPCNewsEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });


  }

  toggleStatus(index: number) { 

    this.statuses.forEach(x => x.selected = false);
    this.statuses[index].selected = true;
    
    // this.statuses.forEach((status, i) => {
    //   status.selected = i === index ? !status.selected : false;
    // });
    this.getData(); 
  }

  toggleFilterStatus(index: number) { 
    
    this.filterStatuses.forEach(x => x.selected = false);
    this.filterStatuses[index].selected = !this.filterStatuses[index].selected;


    // this.filterStatuses.forEach((status, i) => {
    //   status.selected = i === index ? !status.selected : false;
    // }); 
      this.getData(); 
  }


}
